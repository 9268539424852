import axios from 'axios';
import { ICrudGetAction, ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IPatientCase } from 'app/shared/model/patient-case.model';

export const ACTION_TYPES = {
  FETCH_CASE_LIST: 'patient-case/FETCH_CASE_LIST',
  FETCH_CASE: 'patient-case/FETCH_CASE',
  UPDATE_CASE: 'patient-case/UPDATE_CASE',
  RESET: 'patient-case/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IPatientCase>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false
};

export type PatientCaseState = Readonly<typeof initialState>;

// Reducer

export default (state: PatientCaseState = initialState, action): PatientCaseState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_CASE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_CASE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.UPDATE_CASE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_CASE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_CASE):
    case FAILURE(ACTION_TYPES.UPDATE_CASE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_CASE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_CASE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.UPDATE_CASE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case ACTION_TYPES.RESET:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        entities: [] as ReadonlyArray<IPatientCase>,
        entity: defaultValue,
        updating: false,
        updateSuccess: false
      };
    default:
      return state;
  }
};

const apiUrl = 'api/patient-cases';

// Actions

export const getEntity: ICrudGetAction<IPatientCase> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_CASE,
    payload: axios.get<IPatientCase>(requestUrl)
  };
};

export const updateEntity: ICrudPutAction<IPatientCase> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CASE,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
