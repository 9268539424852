import React from 'react';
import {Switch} from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import VillageRoute from './village-route';
import VillageRouteDetail from './villge-route-detail';
import CreateRoute from './create-route';
import RouteDeleteDialog from './route-delete-dialogue';
const VillageRoutes = ({ match }) => {
  return (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/route-detail/:id`} component={VillageRouteDetail} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={RouteDeleteDialog} />
      <ErrorBoundaryRoute exact path={`${match.url}/create-route`} component={CreateRoute} />
      <ErrorBoundaryRoute path={match.url} component={VillageRoute} />
    </Switch>
  </>
)};

export default VillageRoutes;
