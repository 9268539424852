import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './patient-baby.reducer';
import { getEntity as getPatient } from 'app/entities/patient/patient.reducer';
import { getSettings as getOutcomeSettings } from 'app/entities/patient-outcome/patient-outcome.reducer';

export interface IPatientBabyDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const PatientBabyDetail = (props: IPatientBabyDetailProps) => {
  const { patientBabyEntity, patientEntity, outcomeSettings } = props;

  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  useEffect(() => {
    if (patientBabyEntity.patientId) {
      props.getPatient(patientBabyEntity.patientId);
    }
  }, [patientBabyEntity]);

  useEffect(() => {
    if (patientEntity.countryId) {
      props.getOutcomeSettings(patientEntity.countryId);
    }
  }, [patientEntity]);

  return (
    <Row>
      <Col md="8">
        <h2>
          PatientBaby [
          <b>
            {patientEntity?.firstName + ' ' + patientEntity?.lastName + ' - ' + patientEntity?.wcId}
          </b>
          ]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="patientBabyName">Name</span>
          </dt>
          <dd>{patientBabyEntity.firstName + ' ' + patientBabyEntity.lastName}</dd>
          <dt>
            <span id="sex">Sex</span>
          </dt>
          <dd>{patientBabyEntity.sex}</dd>
          <dt>
            <span id="birthWeight">Birth Weight (kg)</span>
          </dt>
          <dd>{patientBabyEntity.birthWeight}</dd>
          <dt>
            <span id="getstationAge">Gestation Term</span>
          </dt>
          <dd>{patientBabyEntity.gestationTermEntity?.enDescription}</dd>
          <dt>
            <span id="dob">Date of Birth</span>
          </dt>
          <dd>{patientBabyEntity.birthDate}</dd>
          <dt>
            <span id="birthCertificateNumber">Newborn Birth Certificate Registration Number</span>
          </dt>
          <dd>{patientBabyEntity.birthCertificateNumber}</dd>
          <dt>
            <span id="birthVillage">Birth Location</span>
          </dt>
          <dd>{patientBabyEntity.birthLocation}</dd>
          <dt>
            <span id="outbornVillage">Outborn</span>
          </dt>
          <dd>{patientBabyEntity.outbornType}</dd>
          <dt>
            <span id="outbornVillage">Name of Other Facility</span>
          </dt>
          <dd>{patientBabyEntity.outbornComment}</dd>
        </dl>
        <Button tag={Link} to="/patient" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline ml-1">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/patient-baby/${patientBabyEntity.id}/edit`} color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline ml-1">Edit</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/patient-baby/${patientBabyEntity.id}/discharge`} style={{ background: 'blue' }}>
          <FontAwesomeIcon icon="arrow-right" /><span className="d-none d-md-inline ml-2">Discharge</span>
        </Button>
        {patientBabyEntity.patientCase?.babyDischargedAt !== null && (
          <>
            &nbsp;
            <Button tag={Link} to={`/patient-visit/create?patientBabyId=${patientBabyEntity.id}&visitCount=1`}
                    style={{ background: 'darkred' }}>
              <FontAwesomeIcon icon="plus" />
              <span className="d-none d-md-inline ml-1">Triage</span>
            </Button>

            &nbsp;
            <Button tag={Link} to={`/patient-visit/journey?patientBabyId=${patientBabyEntity.id}`}
                    style={{ background: 'darkred' }}>
              <FontAwesomeIcon icon="plus" />
              <span className="d-none d-md-inline ml-1">Follow-up/Journey</span>
            </Button>

            <br/>

            { /* loop through outcome settings and create buttons for each */}
            {outcomeSettings.map(setting => (
              <>
                &nbsp;
                <Button className="mt-2" tag={Link} key={setting.index}
                        to={`/patient-outcome/create?patientBabyId=${patientBabyEntity.id}&outcome=${setting.index}`}
                        style={{ background: 'teal' }}>
                  <FontAwesomeIcon icon="phone-alt" />
                  <span className="d-none d-md-inline ml-2">{setting.callDay} Day Outcome</span>
                </Button>
              </>
            ))}
          </>
        )}
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ patientBaby, patient, patientOutcome }: IRootState) => ({
  patientBabyEntity: patientBaby.entity,
  patientEntity: patient.entity,
  outcomeSettings: patientOutcome.settings
});

const mapDispatchToProps = { getEntity, getPatient, getOutcomeSettings };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientBabyDetail);
