import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import "./pieChart.scss";

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    scroll: true,
    scrollY: true
};

const formatter = (value, ctx) => {
    const total = ctx.dataset.data.reduce((partialSum, a) => partialSum + a, 0);
    return `${value}\n${(value / total * 100).toFixed(0)}%`;
};

export const PieChart = ({ chartData }) => {
    const demoMap = new Map();
    const sortedTop10Data = [];
    const sortedTop10DataLabel = [];
    const newSortedData = [];
    const [dataLength, setDataLength] = useState(0);
    const [legendData, setLegendData] = useState([]);

    useEffect(() => {
        setLegendData(chartData.map(el => el["bgColor"]));
        setDataLength((sortedTop10Data).length);
    }, [])

    chartData.map((el) => {
        demoMap.set(el.label, el.data)
    })

    const top10Data = new Map([...demoMap.entries()].sort((a: any, b: any) => b[1] - a[1]));

    top10Data.forEach((keys, values) => {
        sortedTop10Data.push(values);
        sortedTop10DataLabel.push(keys);
    })

    for (let i = 0; i < sortedTop10Data.length; i++) {
        const total = sortedTop10DataLabel.reduce((partialSum, a) => partialSum + a, 0);
        const totalper = (sortedTop10DataLabel[i] / total * 100).toFixed(0)
        newSortedData.push(sortedTop10Data[i] + " : " + `${totalper}%`)
    }

    const data = {
        labels: chartData.map(el => el["label"]),
        datasets: [
            {
                label: '',
                data: sortedTop10DataLabel.map(el => el),
                backgroundColor: chartData.map(el => el["bgColor"]),
                borderColor: chartData.map(el => el["bdColor"]),
                borderWidth: 1,
                datalabels: {
                    display: true,
                    color: "black",
                    font: {
                        size: 9,
                        weight: 600,
                    },
                    padding: 2,
                    formatter
                },
            },
        ],
    };

    return (
        <div style={{ height: "400px", display: 'flex', flexDirection: 'row', overflow: 'hidden', gap: "60px" }}>
            <div className="pie-box" >
                <ul>
                    {newSortedData.length &&
                        newSortedData.map((item, index) => {
                            return (
                                <li key={item.text} style={{
                                    listStyle: "none",
                                    textAlign: "left",
                                    display: "flex",
                                    flexDirection: "row",
                                    margin: "8px",
                                    fontSize: 10,
                                }}>
                                    <div
                                        style={{
                                            marginRight: "8px",
                                            width: "10px",
                                            height: "10px",
                                            backgroundColor: chartData[index]["bgColor"],
                                            borderColor: chartData[index]["bdColor"],
                                            borderWidth: 1,
                                        }}
                                    />

                                    {item}
                                </li>
                            );
                        })}
                </ul>
            </div>
            <div style={{ textAlign: 'justify' }}>
                <Pie options={{
                    ...options,
                    plugins: {
                        legend: {
                            labels: {
                                boxWidth: 8,
                                font: {
                                    size: 10
                                },
                            },
                            display: false,
                            position: 'left',
                            maxWidth: undefined,
                        }
                    }
                }} data={data} />
            </div>

        </div>
    )
}