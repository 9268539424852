import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Label, Table} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {hasAnyPermission} from 'app/shared/auth/permissions';
import {IRootState} from 'app/shared/reducers';
import {getEntities} from './symptom.reducer';
import {AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {IRegion} from "app/shared/model/region.model";
import {EmergencyType} from "app/shared/model/enumerations/emergency-type.model";
import {RiskProfile} from "app/shared/model/enumerations/risk-profile.model";

export interface ISymptomProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

export const Symptom = (props: ISymptomProps) => {
  const emptyRegion: IRegion = null
  const {symptomList, match, loading, userCountryId} = props;
  const [activeCountry, setActiveCountry] = useState<IRegion>({})
  const [symptomsPerCountry, setSymptomsPerCountry] = useState([])
  const [filterList, setFilterList] = useState({
    countryId: '',
    activePage: 0,
    emergencyType: '',
    riskProfile: '',
    sort: {sortOrder: 'asc', sortType: 'id'}
  });

  useEffect(() => {
    props.getEntities();
  }, []);

  useEffect(() => {
    const ac = props.countries.find(country => country.id === userCountryId)
    setActiveCountry(ac)
  }, [props.countries, userCountryId]);

  const updateSymptomList = () => {
    let updatedSymptoms = props.symptomList;

    if (filterList.countryId !== null && filterList.countryId !== '') {
      updatedSymptoms = updatedSymptoms.filter(symptom => symptom.country && symptom.country.id === filterList.countryId);
    }

    if (filterList.riskProfile !== null && filterList.riskProfile !== '') {
      updatedSymptoms = updatedSymptoms.filter(symptom => symptom.riskProfile.toLowerCase().includes(filterList.riskProfile.toLowerCase()));
    }

    if (filterList.emergencyType !== null && filterList.emergencyType !== '') {
      updatedSymptoms = updatedSymptoms.filter(symptom => symptom.emergencyType.toLowerCase().includes(filterList.emergencyType.toLowerCase()));
    }

    setSymptomsPerCountry([...updatedSymptoms]);
  }

  useEffect(() => {
    if (props.symptomList && activeCountry) {
      updateSymptomList()
    }
  }, [props.symptomList, filterList]);

  const onCountryChange = (event) => {
    const filterListObj = {...filterList};
    filterListObj.countryId = event.target.value
    setFilterList(filterListObj)

    const countryId = event.target.value
    const newActiveCountry = props.countries.find(country => country.id === countryId)
    setActiveCountry(newActiveCountry)
  }
  const regCountriesList = props.countries.filter((ele) => {
    return props.isAdmin ? 1 : ele.id === activeCountry?.id;
  })

  return (
    <div>
      <h2 id="symptom-heading">
        Conditions
      </h2>
      <br/>
      {(activeCountry && typeof activeCountry.id !== 'undefined') && (
        <Link to={`${match.url}/new?country_id=${activeCountry.id}`}
              className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus"/>
          &nbsp; Create new Condition
        </Link>
      )
      }
      <br/>
      {activeCountry && (
        <AvForm>
          <AvGroup>
            <Label id="typeLabel" for="symptom-country">Country</Label>
            <AvInput
              id="symptom-country"
              type="select"
              className="form-control"
              name="country"
              value={activeCountry.id}
              onChange={onCountryChange}>
              {regCountriesList
                ? regCountriesList.map(country => (
                  <option value={country.id} key={country.id}>
                    {country.name}
                  </option>
                ))
                : null}
            </AvInput>
          </AvGroup>

          <AvGroup>
            <Label for="emergency-type-filter">Condition Type</Label>
            <AvInput
              onChange={(event) => {
                const filterListObj = {...filterList};
                filterListObj.emergencyType = event.target.value
                setFilterList(filterListObj)
              }}
              id="emergency-type-filter"
              type="select"
              className="form-control"
              name={'emergency-type-filter'}
              value={filterList.emergencyType}
            >
              <option value='' key='ALL'>ALL</option>
              {Object.values(EmergencyType).map(s => (<option value={s} key={s}>{s}</option>))}
            </AvInput>
          </AvGroup>

          <AvGroup>
            <Label for="risk-profile-filter">Risk Profile</Label>
            <AvInput
              onChange={(event) => {
                const filterListObj = {...filterList};
                filterListObj.riskProfile = event.target.value
                setFilterList(filterListObj)
              }}
              id="risk-profile-filter"
              type="select"
              className="form-control"
              name={'risk-profile-filter'}
              value={filterList.riskProfile}
            >
              <option value='' key='ALL'>ALL</option>
              {Object.values(RiskProfile).map(s => (<option value={s} key={s}>{s}</option>))}
            </AvInput>
          </AvGroup>

        </AvForm>
      )
      }
      <div className="table-responsive">
        {symptomsPerCountry && symptomsPerCountry.length > 0 ? (
          <Table responsive>
            <thead>
            <tr>
              <th>Condition Type</th>
              <th>Condition (English)</th>
              <th>Condition (Local Language)</th>
              <th>Required Facility Level</th>
              <th>Required Vehicle Level</th>
              <th>Risk Profile</th>
              <th>Is Static</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {symptomsPerCountry.map((symptom, i) => (
              <tr key={`entity-${i}`}>
                <td>{symptom.emergencyType}</td>
                <td>{symptom.enCondition}</td>
                <td>{symptom.stCondition}</td>
                <td>{symptom.requiredFacilityLevel}</td>
                <td>{symptom.requiredVehicleLevel}</td>
                <td>{symptom.riskProfile}</td>
                <td>{symptom.isStatic ? 'Yes' : 'No'}</td>
                <td>{symptom.isActive ? 'Active' : 'Inactive'}</td>
                <td className="text-right">
                  <div className="btn-group flex-btn-group-container">
                    <Button tag={Link} to={`${match.url}/${symptom.id}`} color="info" size="sm">
                      <FontAwesomeIcon icon="eye"/> <span className="d-none d-md-inline">View</span>
                    </Button>
                    <Button tag={Link} to={`${match.url}/${symptom.id}/edit`} color="primary" size="sm">
                      <FontAwesomeIcon icon="pencil-alt"/> <span className="d-none d-md-inline">Edit</span>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">No Symptoms found</div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({symptom, authentication}: IRootState) => ({
  symptomList: symptom.entities,
  loading: symptom.loading,
  countries: symptom.countries,
  userCountryId: authentication.userCountryId,
  isAdmin: hasAnyPermission(authentication.account.roles, ['ADMIN'])
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Symptom);
