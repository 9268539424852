import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Label, Row, Table } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { IRootState } from 'app/shared/reducers';
import { getPayments, getPaymentsByDriverName, getPaymentsByFilter, searchByPaymentId } from './payment.reducer';
import { PaymentStatus } from 'app/shared/model/enumerations/payment-status.model';
import { convertDateTimeFromServer } from 'app/shared/util/date-utils';
import { fromCents } from 'app/shared/util/money-utils';
import { getSortState, JhiItemCount, JhiPagination } from 'react-jhipster';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { hasAnyPermission } from 'app/shared/auth/permissions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import Loader from 'react-loader-spinner';

export interface IPaymentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

export const Payment = (props: IPaymentProps) => {
  const [filterList, setFilterList] = useState({
    countryId: '', status: 'REQUESTED', activePage: 0, paymentId: '', driverName: '',
    sort: { sortOrder: 'asc', sortType: 'id' }
  });
  const [activeCountry, setCountry] = useState('');
  const [drivername, setDrivername] = useState('');
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState({
      hash: props.location.hash,
      key: props.location.key,
      pathname: props.location.pathname,
      search: '?page=1&sort=createdDateTime,asc',
      state: props.location.state
    }, ITEMS_PER_PAGE), props.location.search)
  );
  const [downloadLoader, setDownloadLoader] = useState(false);

  const { match, loading, RegCountries, paymentList, totalItems, userRoles, permissionSettings } = props;

  const permissionArray = permissionSettings.filter((item: any) => item.role === userRoles[0]);
  // console.log("ROLESSS>>>>", permissionSettings.filter((item:any) => item.role === userRoles[0]));

  const getAllEntities = (countryId: string) => {
    const filterData = { ...filterList };

    filterData.sort.sortOrder = paginationState.order === 'asc' ? 'desc' : 'asc';
    filterData.sort.sortType = paginationState.sort;

    filterData.activePage = paginationState.activePage - 1;
    filterData.countryId = countryId;


    props.getPaymentsByFilter(filterData);
    // props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`, countryId);

  };

  const sortEntities = (countryId: string) => {
    getAllEntities(countryId);
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }

  };

  useEffect(() => {
    // if (RegCountries.length > 0 && filterList.countryId === '') {
    if (filterList.countryId === '') {


      // const ac = RegCountries.find(country => country.id === props.userCountryId)
      setCountry(props.userCountryId);

      filterList.countryId = props.userCountryId;
    }
    // sortEntities(props.userCountryId)
    // props.getPaymentsByFilter(filterList);
    /* setPaginationState({
       ...paginationState,
       activePage: 1,
     });*/
  }, [props.userCountryId]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1]
      });
    }
    const filterlistData = { ...filterList };
    console.log('pagination is called', paginationState);
    sortEntities(filterlistData.countryId ? filterlistData.countryId : activeCountry);
  }, [props.location.search, activeCountry]);

  useEffect(() => {
    sortEntities(filterList.countryId ? filterList.countryId : activeCountry);
  }, [paginationState.activePage, filterList.status, paginationState.order, paginationState.sort]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p
    });
    // filterList.sort.sortOrder = paginationState.order === 'asc' ? 'desc' : 'asc';
    // filterList.sort.sortType = p;
    // props.getPaymentsByFilter(filterList);
  };


  const handlePagination = currentPage =>

    setPaginationState({
      ...paginationState,
      activePage: currentPage
    });

  // console.log("activepagecred", paginationState, props, filterList)

  const regCountriesList = RegCountries.filter((ele) => {
    return props.isAdmin ? 1 : ele.id === activeCountry;
  });

  const countryName = (id: string) => {
    const foundCountry = regCountriesList.find(country => country.id === id);
    return foundCountry?.name;
  };

  const downloadPayment = async () => {
    // call API here
    console.log('DOWNLOAD PAYMENTS API CALLED', filterList.countryId, filterList.status);
    setDownloadLoader(true);

    await axios.get(`api/downloadPaymentsByFilter?countryId=${filterList.countryId}${filterList.status && `&status=${filterList.status}`}`, {
      responseType: 'blob'
    }).then((response: any) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${filterList.status} Payments - ${Date.now()}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setDownloadLoader(false);
    }).catch(error => {
      console.log('ERROR', error);
      setDownloadLoader(false);
    });
  };

  return (
    <div>
      <h2 id="payment-heading">
        Payments
        {((permissionArray.length > 0 && permissionArray[0].permissions.includes('PAYMENT_EXPORT')) || props.isAdmin)
          && (<Button className="btn btn-primary float-right download-payments" id="download-payments"
                      onClick={() => downloadPayment()}
                      disabled={downloadLoader}
          >
            {downloadLoader ? <Loader type="TailSpin" color="#FFFFFF" height={15} width={15}> Loading...</Loader> :
              <span><FontAwesomeIcon icon="save" />
                &nbsp; Download</span>}
          </Button>)}
      </h2>
      <AvForm model={{}}>
        {regCountriesList ?
          <AvGroup>
            <Label id="typeLabel" for="region-type">
              Country
            </Label>
            <AvInput
              id="region-type"
              type="select"
              className="form-control"
              name="type"
              value={activeCountry}
              onChange={(event) => {
                const filterListObj = { ...filterList };
                filterListObj.countryId = event.target.value;
                setFilterList(filterListObj);
                setCountry(event.target.value);
              }}
            >
              {regCountriesList.length > 0 && regCountriesList?.map((country) => {

                return <option value={country.id} key={'country-' + country.name}>{country.name}</option>;

              })}
            </AvInput>
          </AvGroup>
          : ''
        }


        <AvGroup>
          <Label for="status-filter">Status</Label>
          <AvInput
            onChange={(event) => {
              const filterListObj = { ...filterList };
              filterListObj.status = event.target.value;

              setFilterList(filterListObj);
            }}
            id="status-filter"
            type="select"
            className="form-control"
            name={'status-filter'}
            value={filterList.status}
          >
            <option value="" key="ALL">ALL</option>
            {Object.values(PaymentStatus).map(s => (<option value={s} key={s}>{s}</option>))}
          </AvInput>
        </AvGroup>

        <AvGroup>
          <Label for="status-filter">Search By Payment Id</Label>
          <AvInput

            id="status-filter"
            type="input"
            className="form-control"
            name={'status-filter'}

            onChange={(e) => {

              const filterListData = { ...filterList };
              filterListData.paymentId = e.target.value;
              // if(e.target.value.length > 2 ){
              setFilterList(filterListData);
              props.getPaymentsByFilter(filterListData);
              // }
            }}
            // value={filterList.status}
          >
          </AvInput>
        </AvGroup>


        <AvGroup>
          <Label for="status-filter">Search by Driver Name (Community/Ambulance)</Label>
          <AvInput
            id="status-filter"
            type="input"
            className="form-control"
            name={'status-filter'}
            onChange={(e) => {
              const filterListData = { ...filterList };
              filterListData.driverName = e.target.value;
              setFilterList(filterListData);
              props.getPaymentsByFilter(filterListData);
            }}
          >
          </AvInput>
        </AvGroup>

      </AvForm>

      <div className="table-responsive">
        {/* {console.log("PAYMENTS LISTS",paymentList.filter((item:any) => item?.countryId === activeCountry), paymentList && paymentList.filter((item:any) => item?.countryId === activeCountry).length > 0, activeCountry)} */}
        {paymentList && paymentList.filter((item: any) => item?.countryId === activeCountry).length > 0 ? (
          <Table responsive>
            <thead>
            <tr>
              <th style={{

                width: '15%',
                wordBreak: 'break-all'

              }} className="hand" onClick={sort('id')}>ID <FontAwesomeIcon icon="sort" /></th>
              <th style={{

                width: '10%',
                wordBreak: 'break-all'

              }} className="hand" onClick={sort('createdDateTime')}>Created Date <FontAwesomeIcon icon="sort" /></th>
              <th style={{

                width: '10%',
                wordBreak: 'break-all'

              }}>Country
              </th>
              <th style={{

                width: '10%',
                wordBreak: 'break-all'

              }}>Driver/Physician
              </th>
              <th style={{

                width: '10%',
                wordBreak: 'break-all'

              }}>Status
              </th>
              <th style={{

                width: '10%',
                wordBreak: 'break-all'

              }}>Distance
              </th>
              {/* <th style={{

                  width: "10%",
                  wordBreak: "break-all"

                }}>Duration (Minutes)</th> */}
              <th style={{

                width: '10%',
                wordBreak: 'break-all'

              }}>Cost
              </th>
              <th style={{

                width: '10%',
                wordBreak: 'break-all'

              }}>Incident
              </th>
              <th style={{

                width: '15%',
                wordBreak: 'break-all'

              }} className="hand" onClick={sort('processingError')}>
                Processing Error <FontAwesomeIcon icon="sort" /></th>


            </tr>
            </thead>
            <tbody>
            {paymentList.filter((item: any) => item?.countryId === activeCountry).map((payment, i) => (
              <tr key={`payment-${i}`}>
                <td>
                  <Button tag={Link} to={`${match.url}/${payment.id}`} color="link" size="sm">
                    {payment.id}
                  </Button>
                </td>
                <td>
                  {convertDateTimeFromServer(payment.createdDateTime)}
                </td>
                <td>
                  {countryName(payment.countryId)}
                </td>
                <td>
                  {payment.driverJourney?.driver ? payment.driverJourney?.driver?.firstName : payment.physicianJourney?.physician?.firstName}
                  &nbsp;
                  {payment.driverJourney?.driver ? payment?.driverJourney?.driver?.lastName : payment.physicianJourney?.physician?.lastName}
                  &nbsp;:
                  {payment.driverJourney ? ' Driver' : ' Physician'}
                </td>
                <td>
                  {payment.status}
                </td>
                <td>
                  {payment.driverJourney?.totalDistance ? payment.driverJourney?.totalDistance + 'km' : '-'}
                </td>
                {/* <td>
                    {displaySecondsAsHoursMinutes(payment.journey?.totalDurationSeconds)}
                  </td> */}
                <td>
                  {fromCents(payment.overrideAmount || payment.requestedAmount)}
                </td>
                <td>
                  {payment.driverJourney?.incidentReportDetail?.enDescription ?? (payment.physicianJourney?.incidentReportDetail?.enDescription ?? 'No Incidents')}
                  {payment.driverJourney?.incidentReportDescription ? ` - ${payment.driverJourney?.incidentReportDescription}` : (payment.physicianJourney?.incidentReportDescription ? ` - ${payment.physicianJourney?.incidentReportDescription}` : '')}
                </td>
                <td>
                  {payment.processingError}
                </td>


              </tr>
            ))}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">No Payments found for Status: {filterList.status}</div>
        )}
      </div>

      {props.totalItems && (!drivername) ? (
        <div
          className={paymentList && paymentList.filter((item: any) => item?.countryId === activeCountry).length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems}
                          itemsPerPage={paginationState.itemsPerPage} />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={props.totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = ({ payment, authentication, permissionSettings }: IRootState) => ({
  paymentList: payment.payments,
  loading: payment.loading,
  totalItems: payment.totalItems,
  RegCountries: authentication.countries,
  userCountryId: authentication.userCountryId,
  isAdmin: hasAnyPermission(authentication.account.roles, ['ADMIN']),
  userRoles: authentication.account.roles,
  permissionSettings: permissionSettings.settings
});

const mapDispatchToProps = {
  getPayments,
  getPaymentsByFilter,
  searchByPaymentId,
  getPaymentsByDriverName
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
