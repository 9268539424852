import React from 'react';

import {NavbarBrand, NavItem, NavLink} from 'reactstrap';
import {NavLink as Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import logo from './../../../../content/images/WATOTO-CARE_Linear_Earth_RGB.jpg';
import appConfig from 'app/config/constants';
// WATOTO-CARE_Linear_Earth_RGB-White.png
export const BrandIcon = props => (
  <div {...props} className="brand-icon">
    <img src="content/images/watotoCare_logo.png" alt="Logo" />
  </div>
);

export const Brand = props => (
  <NavbarBrand tag={Link} to="/" className="brand-logo">
    <img  className="brand-icon " src="content/images/watotoCare_logo_white.png" alt="Logo" />
    &nbsp;
    watotoCare
  </NavbarBrand>
);

export const Home = props => (
  <>
    <NavItem>
      <NavLink tag={Link} to="/" className="d-flex align-items-center">
        <FontAwesomeIcon icon="home" />
        <span>Home</span>
      </NavLink>
    </NavItem>
  </>
);
