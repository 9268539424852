import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";
import "./barChart.scss";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);


const formatterBoth = (value, ctx) => {
    const total = ctx.dataset.data.reduce((partialSum, a) => partialSum + a, 0);
    return `${value.toLocaleString()}\n${(value / total * 100).toFixed(0)}%`;
};

const formatterDataOnly = (value, ctx) => {
    return `${value.toLocaleString()}`;
};

export const BarChart = ({ chartLabel, labels, dataSet, bdColor, bgColor, axis, flag, xAxisLabels }: Props) => {
    const options = {
        elements: {
            bar: {
                borderWidth: 2
            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    font: {
                        size: 9,
                    },
                    color: "black"
                }
            },
            y: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    min: 15,
                    stepSize: 100,
                    font: {
                        size: 9,
                    },
                    color: "black"
                }
            }
        },
        maintainAspectRatio: false
    };
    const formatter = flag ? formatterBoth : formatterDataOnly;

    const data = {
        labels,
        datasets: [
            {
                label: chartLabel,
                data: dataSet.map((value) => value),
                borderColor: bdColor,
                backgroundColor: bgColor,
                datalabels: {
                    display: true,
                    color: "black",
                    font: {
                        size: 9,
                        weight: 600,
                    },
                    padding: 2,
                    formatter
                },
            }
        ],

    };
    if (axis === "y") {
        return (
            <div className="bar-chart-box">
                <div style={data.labels.length > 6 ?
                    { height: `${400 + ((data.labels.length - 6) * 20)}px` } : { height: "400px" }}
                >
                    <Bar options={{ ...options, indexAxis: axis }} data={data} />
                </div>
            </div>
        )
    } else {
        return (
            <div style={{ height: "300px" }}>
                <Bar options={{ ...options, indexAxis: axis }} data={data} />
            </div>
        )
    }
}

interface Props {
    chartLabel: string,
    labels: string[],
    dataSet: any[],
    bdColor: string,
    bgColor: string,
    axis: any,
    flag: boolean,
    xAxisLabels?: string[]
}

const defaultProps: Props = {
    chartLabel: '',
    labels: [''],
    dataSet: [],
    bdColor: '',
    bgColor: '',
    axis: "x",
    flag: false,
    xAxisLabels: ['']
}

BarChart.defaultProps = defaultProps;


// x: {
//     grid: {
//         display: false,
//         drawBorder: false,
//     },
//     ticks: xAxisLabels.length > 1 ? {
//         font: {
//             size: 9,
//         },
//         color: "black",
//         min: 12,
//         max: 12,
//         stepSize: 330,
//         callback(val, index) {
//             return xAxisLabels[index]
//         },
//     } : {
//         font: {
//             size: 9,
//         },
//         color: "black",
//     }
// },
