import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import {NavDropdown} from './menu-components';

export const EntitiesMenu = props => {

  return (
    <NavDropdown  show={true} icon="th-list" name="Entities" id="entity-menu" style={{ maxHeight: '80vh', overflow: 'auto' }}>
      <MenuItem show={true}  icon="asterisk" to="/village">
        Villages
      </MenuItem>

      <MenuItem   show={true}  icon="asterisk" to="/ambulance">
        Ambulances
      </MenuItem>

      <MenuItem show={true}  icon="asterisk" to="/driver">
        Drivers
      </MenuItem>
      <MenuItem show={true}  icon="asterisk" to="/healthcare-facility">
        Healthcare Facilities
      </MenuItem>
      {/* <MenuItem icon="asterisk" to="/incident-report">
        Incident Reports
  </MenuItem>*/}
      <MenuItem show={true}  icon="asterisk" to="/route">
        Routes
      </MenuItem>
      <MenuItem show={true}  icon="asterisk" to="/symptom">
        Symptoms
      </MenuItem>
      <MenuItem show={true}  icon="asterisk" to="/condition">
        Conditions
      </MenuItem>
      <MenuItem show={true}  icon="asterisk" to="/payment">
        Payments
      </MenuItem>
      <MenuItem  show={true}  icon="asterisk" to="/region">
       Regions
      </MenuItem>
      <MenuItem show={true}  icon="asterisk" to="/dispatch-center">
       Dispatch Center
      </MenuItem>
    </NavDropdown>
  );
}
