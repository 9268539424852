import {IDispatchCenter} from 'app/shared/model/dispatch-center.model';
export interface IUser {
  enabled?: boolean;
  id?: any;
  login?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  activated?: boolean;
  mustChangePassword?: boolean;
  langKey?: string;
  roles?: any[];
  createdBy?: string;
  createdByDisplay?: string;
  createdDate?: Date | null;
  lastModifiedBy?: string;
  lastModifiedByDisplay?: string;
  lastModifiedDate?: Date | null;
  countryId?: string;
  country?: any;
  countryName?:string;
  dispatchcenter?:IDispatchCenter;
  dispatchCenterId?:string;
  password?: string;
}

export const defaultValue: Readonly<IUser> = {
  id: '',
  login: '',
  firstName: '',
  lastName: '',
  email: '',
  activated: true,
  mustChangePassword: false,
  langKey: '',
  roles: [],
  createdBy: '',
  createdDate: null,
  lastModifiedBy: '',
  lastModifiedDate: null,
  countryId: '',
  password: '',
  enabled: true
};
