import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Label, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasAnyPermission } from 'app/shared/auth/permissions';
import { IRootState } from 'app/shared/reducers';
import { getEntities } from './physician.reducer';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import VillageAvTypeahead from 'app/shared/layout/menus/village-av-typeahead';
import { getAllFacility } from 'app/entities/healthcare-facility/healthcare-facility.reducer';
import { getAllByType as getCountries } from 'app/entities/region/region.reducer';

export interface IPhysicianProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

export const Physician = (props: IPhysicianProps) => {
  const {
    physicianList,
    match,
    loading,
    isAdmin,
    userCountryId,
    countries,
    allHealthFacilities,
    healthcareFacilityLoading
  } = props;
  const [filterList, setFilterList] = useState({
    countryId: '',
    name: '',
    phoneNumber: '',
    healthcareFacilityId: '',
    activePage: 0,
    itemsPerPage: 25,
    sort: { sortOrder: 'asc', sortType: 'id' }
  });

  useEffect(() => {
    props.getCountries();
  }, []);

  useEffect(() => {
    if (countries.length > 0 && userCountryId !== '') {
      const country = countries.find(c => c.id === userCountryId);
      if (country) {
        setFilterList({ ...filterList, countryId: userCountryId });
        props.getEntities(userCountryId, filterList);
        props.getAllFacility(userCountryId);
      }
    }
  }, [countries, userCountryId]);

  const regCountriesList = props.countries.filter((ele) => {
    return props.isAdmin ? 1 : ele.id === userCountryId;
  });

  const updateFilters = (filters) => {
    const filterListObj = { ...filterList, ...filters };
    setFilterList(filterListObj);
    props.getEntities(filterListObj.countryId, filterListObj);
  };

  const getHfName = (id, hfList) => {
    const hf = hfList.find(v => v.id === id);
    return !hf ?
      { short: '', full: '', council: '', district: '' }
      :
      {
        short: hf.name,
        council: hf?.council?.name,
        district: hf?.council?.parent?.name,
        full: (hf.name + ' / ' + hf?.council?.name + ' / ' + hf?.council?.parent?.name)
      };
  };

  const hfNames = allHealthFacilities ?
    allHealthFacilities.map(hf => ({
      ...getHfName(hf.id, allHealthFacilities),
      id: hf.id
    })) : [];

  const hfOptionList = (includeEmpty = false) => {
    let result = (includeEmpty) ? ([{ short: '', full: '', id: undefined }]) : [];
    result = result.concat(hfNames);
    return result;
  };

  return (
    <div>
      <h2 id="physician-heading">
        Physicians
      </h2>
      <br />
      <Link to={`${match.url}/new`}
            className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
        <FontAwesomeIcon icon="plus" />
        &nbsp; Create new Physician
      </Link>
      <br />
      <AvForm>
        {regCountriesList &&
          <AvGroup>
            <Label for="country-filter">Country</Label>
            <AvInput
              type="select"
              name="country-filter"
              id="country-filter"
              onChange={(event) => {
                updateFilters({ countryId: event.target.value });
              }}
              value={filterList.countryId}
            >
              {regCountriesList.map((country, i) => (
                <option value={country.id} key={`country-${i}`}>
                  {country.name}
                </option>
              ))}
            </AvInput>
          </AvGroup>
        }
        <AvGroup>
          <Label for="name-filter">Name</Label>
          <AvInput
            onChange={(event) => {
              updateFilters({ name: event.target.value });
            }}
            id="name-filter"
            type="input"
            className="form-control"
            name="name-filter"
            value={filterList.name}
          />
        </AvGroup>
        <AvGroup>
          <Label for="phone-number-filter">Phone Number</Label>
          <AvInput
            onChange={(event) => {
              updateFilters({ phoneNumber: event.target.value });
            }}
            id="phone-number-filter"
            type="input"
            className="form-control"
            name="phone-number-filter"
            value={filterList.phoneNumber}
          />
        </AvGroup>
        <AvGroup>
          <Label for="healcare-facility-id-filter">Unit or Department</Label>
          <VillageAvTypeahead
            labelKey="name"
            valueKey="id"
            data={hfOptionList(false)}
            id="physician-homeHealthcareFacilityId"
            loading={healthcareFacilityLoading}
            onChange={(id) => {
              updateFilters({ healthcareFacilityId: id });
            }}
            input={{
              id: 'physician-homeVillage',
              name: 'homeHealthcareFacilityId'
            }}
          />
        </AvGroup>

      </AvForm>
      <div className="table-responsive">
        {physicianList && physicianList.length > 0 ? (
          <Table responsive>
            <thead>
            <tr>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Unit or Department</th>
              <th>Facility Department</th>
              <th>Active Status</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {physicianList.map((physician, i) => (
              <tr key={`entity-${i}`}>
                <td>{physician.firstName} {physician.lastName}</td>
                <td>{physician.phoneNumber}</td>
                <td>{`${physician.healthcareFacility?.name}/${physician.healthcareFacility?.council?.name}/${physician.healthcareFacility?.council?.parent?.name}`}</td>
                <td>{physician.facilityDepartmentEntityDetail?.enDescription}</td>
                <td>{physician.isActive ? 'Active' : 'Inactive'}</td>
                <td className="text-right">
                  <div className="btn-group flex-btn-group-container">
                    <Button tag={Link} to={`${match.url}/${physician.id}/edit`} color="primary" size="sm">
                      <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">View/Edit</span>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">No Physicians found</div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ physician, authentication, healthcareFacility, region }: IRootState) => ({
  physicianList: physician.entities,
  loading: physician.loading,
  userCountryId: authentication.userCountryId,
  countries: region.countries,
  isAdmin: hasAnyPermission(authentication.account.roles, ['ADMIN']),
  healthcareFacilityLoading: healthcareFacility.loading,
  allHealthFacilities: healthcareFacility.allFacility
});

const mapDispatchToProps = {
  getEntities,
  getAllFacility,
  getCountries
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Physician);
