import {DriverType} from 'app/shared/model/enumerations/driver-type.model';
import {IRegion}from 'app/shared/model/region.model';
export interface IDriver {
  id?: string;
  firstName?: string;
  lastName?: string;
  createdDate?: Date;
  vehicleType?: any;
  phoneNumber?: string;
  nationalId?: string;
  birthDate?: string;
  licenseNumber?: string;
  licenseIssueDate?: string;
  licenseExpirationDate?: string;
  mpesaNumber?: string;
  ownerFirstName?: string;
  ownerLastName?: string;
  ownerPhoneNumber?: string;
  phone_number_2?: string;
  phone_number_3?: string;
  comments?: string;
  activated?: boolean;
  countryId?: string;
  homeHealthcareFacilityId?: string;
  homeVillageId?: string;
  neighborHealthcareFacilityIds?: string[];
  neighborVillageIds?: string[];
  homeVillage?:any;
}

export const defaultValue: Readonly<IDriver> = {};
