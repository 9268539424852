import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';
import VillageAvTypeahead from "app/shared/layout/menus/village-av-typeahead";
import {getEntities as getContinents} from 'app/entities/continent/continent.reducer';
import {getAllEntities as getRegions} from 'app/entities/region/region.reducer';
import {createEntity, getEntity, reset, updateEntity,getCountries,getDispatchCenterDetail} from './dispatch-center.reducer';
import {getEntities} from './../country/country.reducer';
import {RegionType} from "app/shared/model/enumerations/region-type.model";
import {IDispatchCenter,IRegion} from 'app/shared/model/dispatch-center.model';
import region from '../region/region';

export const geDistrictName = (id, districtList) => {

  const districtMatchList = districtList.find(v => v.id === id);
  return !districtMatchList ?
    { short:'', full: ''}
    :
    {
      short: districtMatchList.name,
      full: (districtMatchList.name )
    };
}

const neighborVillageSectionStyle = {
  border: "5px solid #e0e0e0",
  padding: "1em",
  marginBottom: "1em"
};

const neighborVillageLabelStyle = {
  marginBottom: "0.75em",
  display: "flex",
  justifyContent: "space-between"
};

const neighborVillageEntryRowStyle = {
  display: "flex",
  justifyContent: "space-between"
}

const addNeighborVillageButtonStyle = {
  fontSize: "smaller"
}

const neighborVillageRemoveButtonStyle = {
  width: "10em",
  margin: "0.3em 0.3em 0.3em 1em",
  paddingLeft: "1em",
  fontSize: "smaller"
}
export interface IRegionUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const RegionUpdate = (props: IRegionUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [regionType, setRegionType] = useState('COUNTRY')
  const [regionParentId, setRegionParentId] = useState('')
  const [parentRegions, setParentRegions] = useState([])
  const [neighborVillageIds, setNeighborVillageIds] = useState([]);
  const { regionEntity, dipatchCenterDetail,continents,RegCountries, regions, loading, updating } = props;
  const [activeCountry, setActiveCountry] = useState<IRegion>()


  const onCountryChange = (event) => {
    const countryId = event.target.value
    const newActiveCountry = RegCountries.find(country => country.id === countryId)
    setActiveCountry(newActiveCountry)
  }

  const regionList =  regions.filter(entity=>{
    if(activeCountry )
    return entity.type === 'REGION' && entity.parentId === activeCountry?.id
    else
    return entity.type === 'REGION'
  });

  const districts =  regions.filter(entity=>{
    let match =false;
    for(let i =0; i< regionList.length; i++){
      if(regionList[i].id ===  entity.parentId){
        match =true
      }
    }
    const preexist = props.match.params.id ? true : (entity.dispatchCenterId === null);
    return entity.type === 'DISTRICT' &&  preexist && match
  });
  const DistrictNames = districts ?
  districts.map(district => ({
    ...geDistrictName(district.id, districts),
    id: district.id
  })) : [];
/*
  const onParentTypeChange = (event) => {
    const parentType = event.target.value
    setParentRegions(props.regions.filter(region => region.type.toString() === parentType))
  }*/

  const handleClose = () => {
    props.history.push('/dispatch-center' + props.location.search);
  };


  const addNeighborVillage = () => {
    setNeighborVillageIds(neighborVillageIds.concat(undefined));
  }

  const removeNeighborVillage = (i) => {
    const newNeighborVillageIds = neighborVillageIds.slice();
    newNeighborVillageIds.splice(i,1);
    setNeighborVillageIds(newNeighborVillageIds);
  }

  const neighborVillageChange = (changedIndex, newValue) => {

    const newNeighborVillageIds = neighborVillageIds.map((val, index) => {
      return (index === changedIndex) ? newValue : val
    })
    setNeighborVillageIds(newNeighborVillageIds);
  }

  const villageOptionList = (includeEmpty = false) => {
    let result = (includeEmpty) ? ([{short: '', full:'', id:undefined}]) : []
    result = result.concat(DistrictNames);
    return result
  }




  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    props.getEntities(1, 10, `ASC`);
    props.getCountries();
    setRegionType(params.get('type'))
    if (isNew) {
      setRegionParentId(params.get('parentId'))
      props.reset();
    } else {
     // props.getEntity(props.match.params.id);
     props.getDispatchCenterDetail(props.match.params.id);
    }

    // setActiveCountry
    const url = new URL("http://localhost:9000/"+props.history.location.search);
        const c = url.searchParams.get("country_id");

    const countryId = url.searchParams.get("country_id");
    const newActiveCountry = RegCountries.find(country => country.id === countryId)
    setActiveCountry(newActiveCountry)
    props.getContinents();
    props.getRegions();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      props.getContinents();
      props.getRegions();
      handleClose();
    }
  }, [props.updateSuccess]);

  useEffect(() => {
    if (isNew && props.regions) {
     // setParentRegions(props.regions.filter(region => region.type === RegionType.COUNTRY))
    }else{
      const districtIds = dipatchCenterDetail.region ? dipatchCenterDetail.region.map((value)=>value.id) : [];

      // console.log("districtIds",districtIds)
      setNeighborVillageIds(districtIds)
    }
  }, [props.regions,dipatchCenterDetail]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...regionEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  const neighborVillageFields = () => {
    const result = [];
    neighborVillageIds.forEach((districtId, i) => {
      result.push (
        <div style={neighborVillageEntryRowStyle}>
          <div style={{flex:"1"}}>
            <VillageAvTypeahead
              onChange={
                (value) => neighborVillageChange(i, value)
              }
              labelKey="name"
              valueKey = "id"
              data={villageOptionList(!districtId)}
              placeholder = {!districtId ? '' : geDistrictName(districtId, districts).full}
              id={"neighbor-district-" + i + "-typeahead"}
              // loading={villages.length > 0}
              value={districtId || ''}
              input={{
                id:"neighbor-district-" + i,
                name:'districtIds[' + i + ']',
                required: true
              }}
            />
          </div>
          <Button style={neighborVillageRemoveButtonStyle} color="danger" id={`remove-neighbor-village-${i}`}
                  onClick={ () => removeNeighborVillage(i) } >
            <FontAwesomeIcon icon="trash" />
            &nbsp; Remove
          </Button>
        </div>
      )
    })
    return result;
  }
const uniqueType = [];
  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="watotocareApp.region.home.createOrEditLabel">Create or edit a Dispatch Center</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : dipatchCenterDetail} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="region-id">ID</Label>
                  <AvInput id="region-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="nameLabel" for="dispatch-center-name">
                  Name
                </Label>
                <AvField id="dispatch-center-name" type="text" name="name" />
              </AvGroup>



              <AvGroup>
                <Label id="typeLabel" for="region-type">
                  Country
                </Label>
                <AvInput
                  id="region-type"
                  type="select"
                  className="form-control"
                  name="countryId"
                  onChange={onCountryChange}
                  value={isNew ? activeCountry?.id : dipatchCenterDetail?.id}

                  >


                    {RegCountries
                      ? RegCountries.map(country => (
                        <option value={country.id} key={country.id}>
                          {country.name}
                        </option>
                      ))
                      : null}



                </AvInput>
              </AvGroup>

              <div style={neighborVillageSectionStyle}>
                <div style={neighborVillageLabelStyle}>
                  <span> Select District</span>
                  <Button color="primary" id="add-neighbor-village" style={addNeighborVillageButtonStyle}
                          onClick={ () => addNeighborVillage() }
                          >
                    <FontAwesomeIcon icon="plus" />
                    &nbsp; Assign District
                  </Button>
                </div>
                { neighborVillageFields() }
              </div>


              <Button tag={Link} id="cancel-save" to="/dispatch-center" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  countryList: storeState.country.entities,
  dipatchCenterDetail:storeState.dispatchCenter.dipatchCenterDetail,
  continents: storeState.continent.entities,
  africa: storeState.continent.entities.filter(continent => continent.name === 'Africa')[0],
  regions: storeState.region.entities,
  regionEntity: storeState.region.entity,
  loading: storeState.region.loading,
  updating: storeState.region.updating,
  updateSuccess: storeState.region.updateSuccess,
  storeState,
  RegCountries:storeState.authentication.countries
});

const mapDispatchToProps = {
  getContinents,
  getRegions,
  getEntity,
  updateEntity,
  getEntities,
  createEntity,
  getCountries,
  reset,
  getDispatchCenterDetail
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RegionUpdate);
