import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";
import "./lineChart.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export const options = {
  responsive: true,
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        font: {
          size: 9,
        },
        color: "black"
      }
    },
    y: {
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        min: 15,
        max: 100,
        stepSize: 100,
        font: {
          size: 9,
        },
        color: "black"
      }
    },
  },
  maintainAspectRatio: false
};

export const LineChart = ({ totalData, label, color }) => {
  const [labels, dataSet] = totalData;
  const data = {
    labels,
    datasets: [
      {
        label,
        data: dataSet,
        borderColor: color,
        backgroundColor: color,
        datalabels: {
          display: true,
          color: "black",
          font: {
            size: 9,
            weight: 600,
          },
          align: 270,
        },
      },
    ],
  };

  return (
    <div style={{ height: "250px" }}>
      <Line options={options} data={data} />
    </div>
  )
}