import React, { useEffect, useState } from 'react'
import { DisplayCardGroup } from './displayCardGroup';
import { DisplayIndicator } from './displayCardIndicator';
import { LineChart, StackedBarChart } from '../../../charts';
import { PRIMARY_CHART_COLOR, SECONDARY_CHART_COLOR, MONTH_LABELS } from '../constants';
import value from "*.json";
import {mergeAndSum} from "app/modules/programDashboard/utils";

export const OverviewSection = ({countReferrals, emergencyReferrals}) => {
    const [labels, setLabels] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [VHFLabels, setVHFLabels] = useState([]);
    const [VHFData, setVHFData] = useState({});

    const TOTAL_CARD_GROUP_DATA = [
        { label: "1.1 Total patient emergencies:", icon: "content/images/new_car.svg", value: countReferrals?.totalEmergenciesCount },
        { label: "1.2 Total maternal emergencies:", icon: "content/images/pregnant_woman_black.svg", value: countReferrals?.motherCount },
        { label: "1.3 Total neonatal emergencies:", icon: "content/images/new_child_friendly.svg", value: countReferrals?.babyCount },
        { label: "1.4 Total both emergencies:", icon: "content/images/mother_holding_baby.svg", value: countReferrals?.bothCount },
        { label: "1.5 Community drivers journeys:", icon: "content/images/new_car.svg", value: countReferrals?.driverCount },
        { label: "1.6 Ambulance drivers journeys:", icon: "content/images/new_ambulance.svg", value: countReferrals?.ambulanceCount }
    ]

    useEffect(() => {
        if (emergencyReferrals?.totalEmergencies && Object.keys(emergencyReferrals?.totalEmergencies)) {
            const sortedKeys = [];
            const sortedData = [];
            const villageHFdata = {
                Community: {
                  label: 'Community',
                  color: PRIMARY_CHART_COLOR,
                  data: [],
                },
                'Health Facility': {
                  label: 'Health Facility',
                  color: '#4b70a9',
                  data: [],
                },
            };

          const aggregatedVHFData = mergeAndSum([
              emergencyReferrals?.communityReferrals,
              emergencyReferrals?.hfReferrals,
          ])

          const VHFDistrictKeys = Object.keys(aggregatedVHFData)
              .sort(function(a,b){return aggregatedVHFData[b]-aggregatedVHFData[a]});

          for(let i = 0; i < VHFDistrictKeys.length; i++) {
              villageHFdata['Community'].data.push(emergencyReferrals?.communityReferrals[VHFDistrictKeys[i]] ?? 0)
              villageHFdata['Health Facility'].data.push(emergencyReferrals?.hfReferrals[VHFDistrictKeys[i]] ?? 0)
          }

            const keys = Object.keys(emergencyReferrals?.totalEmergencies).sort(function(a, b){
                return parseInt(a.split(',')[0], 10) - parseInt(b.split(',')[0], 10);
            });

            for (let i = 0; i < keys.length; i++) {
                sortedKeys.push(keys[i].split(',')[1]);
                sortedData.push(emergencyReferrals?.totalEmergencies[keys[i]])
            }

            setLabels(sortedKeys);
            setGraphData(sortedData);
            setVHFLabels(VHFDistrictKeys);
            setVHFData(villageHFdata);
        }
    }, [emergencyReferrals])

    return (
        <div className='mt-0 mb-3'>
            <p className="mb-2" style={{ fontSize: "18px", fontWeight: 600, color: SECONDARY_CHART_COLOR }}>1. Overview</p>
            <div className="row mb-3">
                <DisplayCardGroup data={TOTAL_CARD_GROUP_DATA.slice(0, 3)} col="col-4" />
            </div>
            <div className="row mb-3">
                <DisplayCardGroup data={TOTAL_CARD_GROUP_DATA.slice(3, 6)} col="col-4" />
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "1.7 Total emergencies per month", value: "" }}>
                        <LineChart totalData={[labels, graphData]} label="Total emergencies per month" color={PRIMARY_CHART_COLOR} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12" >
                    <DisplayIndicator data={{ label: "1.8 Origin of the Emergencies village vs health facility", value: "" }}>
                        <StackedBarChart chartLabels={VHFLabels} chartData={VHFData} axis='y' />
                    </DisplayIndicator>
                </div>
            </div>
        </div >
    )
}
