import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { setFilterOption, setResetFilterOption } from "./child-sub-menu.reducer";
import { Collapse, NavItem, NavLink, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import SelectComp from "./selectComp/selectComp";
import DatePicker from 'react-datepicker';
import "./childSubMenu.scss";
import 'react-datepicker/dist/react-datepicker.css';

const ChildSubMenu = (props) => {
    const { title, optionsFilters, selectedFilterOption, resetFilterOption } = props;
    const [collapsed, setCollapsed] = useState<boolean>(true);
    const [startDate, setStartDate] = useState<any>('');
    const [endDate, setEndDate] = useState<any>('');
    const [startYear, setStartYear] = useState<any>(new Date());

    const toggle = () => setCollapsed(!collapsed);

    const onDateChange = (date: any, text: string) => {
        if(resetFilterOption) {
            props.setResetFilterOption(false);
        }
        if (text === 'startDate') {
            setStartDate(date);
            // setEndDate(new Date(new Date(date).setDate(date?.getDate() + 30)));
        } else {
            setEndDate(date);
        }
    };

    useEffect(() => {
        if (startDate !== '' && endDate !== '') {
            const newFilterOptions = {
                ...selectedFilterOption,
                ["startDate"]: startDate,
                ["endDate"]: new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0) // last day of the month
            };
            delete newFilterOptions["year"];
            props.setFilterOption(newFilterOptions);
            setStartYear('');
        }
    }, [startDate, endDate])

    useEffect(() => {
        if(startYear !== ''){
            const newFilterOptions = {
                ...selectedFilterOption,
                year: [`${startYear.getFullYear()}`]
            };
            delete newFilterOptions["startDate"];
            delete newFilterOptions["endDate"];
            props.setFilterOption(newFilterOptions);
            setStartDate('');
            setEndDate('');
        }
    }, [startYear])

    const onStartYearChange = (date: any) => {
        if(resetFilterOption) {
            props.setResetFilterOption(false);
        }
        setStartYear(date);
    }

    useEffect(() => {
        if (resetFilterOption) {
            setStartDate('');
            setEndDate('')
            setStartYear(new Date());
        }
    }, [resetFilterOption])

    return (
        <div className="child-sub-menu-container">
            <NavItem onClick={toggle}>
                <NavLink className="navlink-container">
                    <span>{title}</span>
                    <div style={{ paddingRight: "10px" }} >
                        <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                </NavLink>
            </NavItem>
            <Collapse
                isOpen={!collapsed}
                navbar
                className="child-collapse-section mt-1 mb-1"
            >
                {optionsFilters.filter(el => el["category"] === title).map((menu, index) => (
                    <div style={menu["label"] === "By Month Range" ? { margin: "20px 0 24px 0" } : {}} key={index} className="child-sub-menu-nav-section px-4 py-1">
                        <Label style={{ display: "inlineBlock", verticalAlign: "center" }} className="sub-menu-label" for="filter">
                            <span>{menu["label"]}</span>
                        </Label>
                        <div className="option-filter-container">
                            {menu["label"] === "By Month Range" ?
                                <div style={{ display: 'flex', flexDirection: "column", gap: "4px" }}>
                                    <div style={{}}>
                                        <DatePicker
                                            id="case-management-startDate"
                                            dateFormat="MMMM yyyy"
                                            showMonthYearPicker
                                            selected={startDate}
                                            name="startDate"
                                            value={startDate}
                                            placeholderText="Start Month"
                                            onChange={(date: any) => onDateChange(date, 'startDate')}
                                        />
                                    </div>
                                    <div style={{}}>
                                        <DatePicker
                                            id="case-management-endDate"
                                            dateFormat="MMMM yyyy"
                                            showMonthYearPicker
                                            selected={endDate}
                                            name="endDate"
                                            value={endDate}
                                            placeholderText="End Month"
                                            minDate={startDate && new Date(new Date(startDate).setDate(startDate?.getDate()))}
                                            onChange={(date: any) => onDateChange(date, 'endDate')}
                                        />
                                    </div>
                                </div> :
                                menu["label"] === "By Year" ? <div style={{ display: 'flex', flexDirection: "column", gap: "4px" }}>
                                    <div style={{}}>
                                    <DatePicker
                                        // id="case-management-startDate"
                                        selected={startYear}
                                        name="startYear"
                                        value={startYear}
                                        placeholderText="Select Year"
                                        showYearPicker
                                        dateFormat="yyyy"
                                        onChange={(date: any) => onStartYearChange(date)}
                                    />
                                    </div>
                                </div> :
                                <SelectComp
                                    filterValues={menu["values"]}
                                    filterSlug={menu["slug"]}
                                />
                            }
                        </div>
                    </div>
                ))}
            </Collapse>
        </div>
    )
}
const mapStateToProps = ({ filterOption }: IRootState) => ({
    selectedFilterOption: filterOption.selectedFilterOption,
    resetFilterOption: filterOption.resetFilterOption,
});

const mapDispatchToProps = {
    setFilterOption,
    setResetFilterOption

};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ChildSubMenu);
