import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";
import "./stackedBarChart.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const formatterBoth = (value, ctx) => {
  let total = 0;

  for(let i = 0; i < 3; i++) {
    if(ctx.chart.data?.datasets[i]?.data[ctx.dataIndex]) {
      total = total + ctx.chart.data?.datasets[i].data[ctx.dataIndex];
    }
  }

  return `${value}\n${(value / total * 100).toFixed(1)}%`;
};

const formatterDataOnly = (value) => {
  return `${value}`;
};

export const options = {
  responsive: true,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        font: {
          size: 9,
        },
        color: "black"
      }
    },
    y: {
      stacked: true,
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        min: 15,
        max: 100,
        stepSize: 100,
        font: {
          size: 9,
        },
        color: "black"
      }
    }
  },
  maintainAspectRatio: false
};

export const StackedBarChart = ({ chartLabels, chartData, axis, flag }: Props) => {
    const formatter = flag ? formatterBoth : formatterDataOnly;

    const data = {
    labels: chartLabels,
    datasets: Object.keys(chartData).map((el, i) => ({
      label: chartData[el]["label"],
      data: chartData[el]["data"],
      backgroundColor: chartData[el]["color"],
      datalabels: {
        display: true,
        color: "black",
        font: {
          size: 9,
          weight: 600,
        },
        padding: 2,
        formatter
      },
    })),
  };

  if (axis === "y") {
    return (
      <div className="stacked-bar-chart-box">
        <div style={data.labels.length > 6 ?
          { height: `${400 + ((data.labels.length - 6) * 20)}px` } : { height: "400px" }}
        >
          <Bar options={{ ...options, indexAxis: axis }} data={data} />
        </div>
      </div>
    )
  } else {
    return (
      <div style={{ height: "300px" }}>
        <Bar options={{ ...options, indexAxis: axis }} data={data} />
      </div>
    )
  }
}

interface Props {
  chartLabels: string[],
  chartData: object, axis?: any,
  flag: boolean,

}

const defaultProps: Props = {
  chartLabels: [""],
  chartData: {},
  axis: "x",
  flag: true,
}

StackedBarChart.defaultProps = defaultProps;
