import moment from 'moment';

import {
  SECONDS_IN_MINUTE,
  SECONDS_IN_HOUR,
  APP_LOCAL_DATETIME_FORMAT,
  APP_LOCAL_DATETIME_FORMAT_Z,
  APP_LOCAL_DATE_FORMAT
} from 'app/config/constants';

export const dateInUserTimezone = () => moment().format(APP_LOCAL_DATE_FORMAT);

export const dateInServerTimezone = () => moment.utc().format(APP_LOCAL_DATE_FORMAT);

export const convertDateTimeFromServer = date => (date ? moment(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertDateTimeToServer = date => (date ? moment(date, APP_LOCAL_DATETIME_FORMAT_Z).toDate() : null);

export const convertUTCDateTimeFromServer = date => (date ? moment(date).utc().format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertUTCDateTimeToServer = date => (date ? moment.parseZone(date).toDate() : null);

export const displayDefaultDateTime = () => moment().startOf('day').format(APP_LOCAL_DATETIME_FORMAT);

export const displaySecondsAsHoursMinutes = (seconds?: number): string => {
  if (seconds == null || seconds < 0) {
    return '';
  }
  const hours = Math.floor((seconds / SECONDS_IN_HOUR));
  const minutes = Math.floor((seconds - (hours * SECONDS_IN_HOUR)) / SECONDS_IN_MINUTE);
  return (hours > 0) ? `${hours}h ${minutes}m` : `${minutes}m`;
}

export const convertMinutesToSeconds = (minutes?: number): number => {
  if (minutes == null || minutes < 0) {
    return null;
  }
  return minutes * SECONDS_IN_MINUTE;
}

export const convertSecondsToMinutes = (seconds?: number): number => {
  if (seconds == null || seconds < 0) {
    return null;
  }
  return seconds / SECONDS_IN_MINUTE;
}

export const daysSince = (date: string): number => {
  return moment().startOf('day').diff(moment(date).startOf('day'), 'days');
}
