import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Alert, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getEntity as getVillage} from '../village/village.reducer'

import {IRootState} from 'app/shared/reducers';
import {getEntity, deleteEntity,reset} from './village-route.reducer';
import {IRegion} from "app/shared/model/region.model";
import {IVillage} from "app/shared/model/village.model";

export interface IRouteDeleteDialogProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const RouteDeleteDialog = (props: IRouteDeleteDialogProps) => {

  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  useEffect(() => {

  }, [props.routeEntity]);

  const handleClose = () => {
    props.reset();
    props.history.push('/village-routes' + props.location.search);
  };

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const {routeEntity, village} = props;

  const confirmDelete = () => {
    props.deleteEntity(props.match.params.id);
  };

  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader toggle={handleClose}>Confirm delete operation</ModalHeader>
      <ModalBody id="watotocareApp.region.delete.question">Are you sure you want to delete this Route?
        { props.deleteFailure && (
          <Row className="justify-content-center">
            <Alert color="danger"><strong>Unable to delete Route</strong></Alert>
          </Row>
        )
        }
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          <FontAwesomeIcon icon="ban"/>
          &nbsp; Cancel
        </Button>
        <Button id="jhi-confirm-delete-region" color="danger" onClick={confirmDelete}>
          <FontAwesomeIcon icon="trash"/>
          &nbsp; Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = ({route,village}: IRootState) => ({
  routeEntity: route.entity,
  updateSuccess: route.updateSuccess,
  deleteFailure: route.deleteFailure,
  village: village.entity
});

const mapDispatchToProps = {getEntity, deleteEntity, reset, getVillage};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RouteDeleteDialog);
