import React, {useEffect, useRef, useState} from "react";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {AvField} from "availity-reactstrap-validation";

const VillageAvTypeahead = (props: any) => {
  const {
    id,
    labelKey,
    valueKey,
    placeholder,
    data,
    loading,
    value,
    input,
    onChange,
    clearValue,
    defaultValue
  } = props;

  const ref = useRef<any>();

  const [inputValue, setInputValue] = useState(value);
  const [options, setOptions] = useState([])

  useEffect(() => {
    if (typeof clearValue !== 'undefined') {
      ref.current.clear()
    }
  }, [clearValue]);

  const findOptions = (query) => {
    query = query.toLowerCase()
    const matches = data.filter(d =>
      // Try an exact match
      d.short.toLowerCase().match(query) ||
      // Try match filtering out punctuation
      d.short.replace(/[^\w\s]/g, '').toLowerCase().match(query))

    // Put matches that start with the search string at the start of the list
    const matchesStart = []
    const rest = []
    matches.forEach(m => {
      if (m.short.toLowerCase().startsWith(query)) {
        matchesStart.push(m);
      } else {
        rest.push(m)
      }
    })
    // Sort the results
    const compare = (a, b) => {
      return (a[labelKey] > b[labelKey]) ? 1 : ((b[labelKey] > a[labelKey]) ? -1 : 0)
    }
    return [...matchesStart.sort(compare), ...rest.sort(compare)]
  }

  return (
    <>
      <AsyncTypeahead
        filterBy={() => true}
        id={id}
        labelKey='full'
        minLength={2}
        placeholder={placeholder}
        isLoading={loading}
        options={options}
        highlightOnlyResult={true}
        ref={ref}
        defaultInputValue={defaultValue}
        onChange={selected => {
          let newValue = null;
          if (selected && selected.length === 1) {
            newValue = (valueKey) ? selected[0][valueKey] : selected[0];
          }
          setInputValue(newValue);
          if (onChange) {
            onChange(newValue);
          }
        }}
        onSearch={(query) => {
          setOptions(findOptions(query))
        }}
      />
      <div style={{display: "none"}}>
        <AvField {...input} value={inputValue}/>
      </div>
    </>
  );
};

export default VillageAvTypeahead;
