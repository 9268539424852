import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './healthcare-facility-level.reducer';
import { IHealthcareFacilityLevel } from 'app/shared/model/healthcare-facility-level.model';

export interface IHealthcareFacilityLevelDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ countryId: string, level: string }> {}

export const HealthcareFacilityLevelDetail = (props: IHealthcareFacilityLevelDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.countryId, props.match.params.level);
  }, []);

  const { healthcareFacilityLevelEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          HealthcareFacility Level
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="country">Country</span>
          </dt>
          <dd>{healthcareFacilityLevelEntity.countryId?.name}</dd>
          <dt>
            <span id="level">Level</span>
          </dt>
          <dd>{healthcareFacilityLevelEntity.level}</dd>
          <dt>
            <span id="en_description">En Description</span>
          </dt>
          <dd>{healthcareFacilityLevelEntity.en_description}</dd>
          <dt>
            <span id="st_description">St Description</span>
          </dt>
          <dd>{healthcareFacilityLevelEntity.st_description}</dd>
        </dl>
        <Button tag={Link} to="/healthcare-facility-level" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/healthcare-facility-level/${healthcareFacilityLevelEntity.countryId?.id}/${healthcareFacilityLevelEntity.level}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ healthcareFacilityLevel }: IRootState) => ({
  healthcareFacilityLevelEntity: healthcareFacilityLevel.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(HealthcareFacilityLevelDetail);
