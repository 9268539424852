import {ICouncil} from "app/shared/model/council.model";

export interface IGeofenceRoute {
  id?: string;
  distance?: number;
  durationSeconds?: number;
  overridePrice?: number;
  description?: string;
  isActive?: boolean;
  startCouncilId?: string;
  endCouncilId?: string;
  startCouncil?: ICouncil;
  endCouncil?: ICouncil;
}

export const defaultValue: Readonly<IGeofenceRoute> = {
  isActive: false,
};
