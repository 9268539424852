import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction, IPayloadResult } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IJourney } from 'app/shared/model/journey.model';
import { IPayload } from 'react-jhipster/src/type/redux-action.type';

export const ACTION_TYPES = {
  FETCH_JOURNEY_LIST: 'journey/FETCH_JOURNEY_LIST',
  FETCH_JOURNEY: 'journey/FETCH_JOURNEY',
  CREATE_JOURNEY: 'journey/CREATE_JOURNEY',
  UPDATE_JOURNEY: 'journey/UPDATE_JOURNEY',
  DELETE_JOURNEY: 'journey/DELETE_JOURNEY',
  RESET: 'journey/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IJourney>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  fetchSuccess: false,
  totalItems: 0
};

export type JourneyState = Readonly<typeof initialState>;

// Reducer

export default (state: JourneyState = initialState, action): JourneyState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_JOURNEY_LIST):
    case REQUEST(ACTION_TYPES.FETCH_JOURNEY):
      return {
        ...state,
        errorMessage: null,
        fetchSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_JOURNEY):
    case REQUEST(ACTION_TYPES.UPDATE_JOURNEY):
    case REQUEST(ACTION_TYPES.DELETE_JOURNEY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_JOURNEY_LIST):
    case FAILURE(ACTION_TYPES.FETCH_JOURNEY):
      return {
        ...state,
        loading: false,
        fetchSuccess: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case FAILURE(ACTION_TYPES.CREATE_JOURNEY):
    case FAILURE(ACTION_TYPES.UPDATE_JOURNEY):
    case FAILURE(ACTION_TYPES.DELETE_JOURNEY):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_JOURNEY_LIST):
      return {
        ...state,
        loading: false,
        fetchSuccess: true,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_JOURNEY):
      return {
        ...state,
        loading: false,
        fetchSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_JOURNEY):
    case SUCCESS(ACTION_TYPES.UPDATE_JOURNEY):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_JOURNEY):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/journeys';

// Actions

export const getEntities: (patientCaseId?: string, filters?: any) => IPayload<IJourney> | IPayloadResult<IJourney> = (patientCaseId?: string, filters?: any) => {
  let requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;

  if (patientCaseId && patientCaseId !== '') {
    requestUrl += `&patientCaseId=${patientCaseId}`;
  }

  if (filters?.upcomingOnly && filters?.upcomingOnly !== '') {
    requestUrl += `&upcomingOnly=${filters.upcomingOnly}`;
  }

  if (filters?.countryId && filters?.countryId !== '') {
    requestUrl += `&countryId=${filters.countryId}`;
  }

  if (typeof filters?.activePage !== 'undefined' && filters?.activePage !== null && filters?.activePage !== '') {
    requestUrl += `&page=${filters.activePage}`;
  }

  if (filters?.itemsPerPage && filters?.itemsPerPage !== '') {
    requestUrl += `&size=${filters.itemsPerPage}`;
  }

  if (filters?.sort?.sortType && filters?.sort?.sortOrder) {
    requestUrl += `&sort=${filters.sort.sortType},${filters.sort.sortOrder}`;
  }

  if (filters?.patientName && filters?.patientName !== '') {
    requestUrl += `&patientName=${filters.patientName}`;
  }

  if (filters?.driverId && filters?.driverId !== '') {
    requestUrl += `&driverId=${filters.driverId}`;
  }

  if (filters?.physicianId && filters?.physicianId !== '') {
    requestUrl += `&physicianId=${filters.physicianId}`;
  }

  if (filters?.startVillageId && filters?.startVillageId !== '') {
    requestUrl += `&startVillageId=${filters.startVillageId}`;
  }

  if (filters?.endHealthcareFacilityId && filters?.endHealthcareFacilityId !== '') {
    requestUrl += `&endHealthcareFacilityId=${filters.endHealthcareFacilityId}`;
  }

  if (filters?.scheduledDate && filters?.scheduledDate !== '') {
    requestUrl += `&scheduledDate=${filters.scheduledDate}`;
  }

  return {
    type: ACTION_TYPES.FETCH_JOURNEY_LIST,
    payload: axios.get<IJourney>(requestUrl)
  };
};

export const getEntity: ICrudGetAction<IJourney> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_JOURNEY,
    payload: axios.get<IJourney>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IJourney> = entity => async dispatch => {
  return await dispatch({
    type: ACTION_TYPES.CREATE_JOURNEY,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
};

export const updateEntity: ICrudPutAction<IJourney> = entity => async dispatch => {
  return await dispatch({
    type: ACTION_TYPES.UPDATE_JOURNEY,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
};

export const deleteEntity: ICrudDeleteAction<IJourney> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  return await dispatch({
    type: ACTION_TYPES.DELETE_JOURNEY,
    payload: axios.delete(requestUrl)
  });
};

export const reset = () => dispatch => {
  return dispatch({
    type: ACTION_TYPES.RESET
  });
};
