import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Physician from './physician';
import PhysicianUpdate from './physician-update';

const Routes = ({match}) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={PhysicianUpdate}/>
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={PhysicianUpdate}/>
      <ErrorBoundaryRoute path={match.url} component={Physician}/>
    </Switch>
  </>
);

export default Routes;
