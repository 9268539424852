import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import {NavDropdown} from './menu-components';
import { faChartArea } from '@fortawesome/free-solid-svg-icons'


const routes = {


}

export const ReportsMenu = props => {

  console.log("props data",props.reportMenus)
  return (
    <NavDropdown icon={faChartArea} name="Program Dashboard" id="report-menu" style={{ overflow: 'auto' }}>

     
    
         {/* 
          <MenuItem icon="asterisk" to="/report/me-report">
      M&E report
          </MenuItem> 
        } <MenuItem icon="asterisk" to="/report/transportation-analysis">
            Transportation Analysis
          </MenuItem>
          <MenuItem icon="asterisk" to="/report/triage-analysis">
            Triage Analysis
  </MenuItem>
      
      
      <MenuItem icon="asterisk" to="/report/dispatcher-dashboard">
      Dispatcher Dashboard
      </MenuItem>
      <MenuItem icon="asterisk" to="/report/government-dashboard">
      Government Dashboard
      </MenuItem>

      <MenuItem icon="asterisk" to="/report/case-report">
      Case Report
      </MenuItem>

      <MenuItem icon="asterisk" to="/report/payment-report">
      Payment Report
      </MenuItem>*/}

      {props.reportMenus.length >0 ? props.reportMenus.map((menu,index)=>{
        return (
        <MenuItem key={index+"-"+menu.slug}  icon="asterisk" to={"/report/"+menu.slug}>
          {menu.label}
        </MenuItem>
        )

      })
      :
      <></>
      }
      
    </NavDropdown>
  );
}
