import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

export const SortableHeaderRow = (props) => {
  const {sort, cols} = props;
  return (
    <tr>
      { cols.map(c => {
        const [name, text] = c;
        if(name){
          return (
            <SortableHeader key={name} sort={sort} name={name} text={text}/>
          )
        }else{
          return (
            <th>{text}</th>
          )
        }
        }
      )}
    </tr>
  )
}

export const SortableHeader = (props) => {
  const {sort, name, text} = props;
  return (
    <th className="hand" onClick={sort(name)}>
      {text} <FontAwesomeIcon icon="sort" />
    </th>
  )
}
