import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faStickyNote, faArrowCircleLeft, faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { NavItem, NavLink, Nav } from "reactstrap";
import { Link } from "react-router-dom";
import { HashLink, NavHashLink } from 'react-router-hash-link';
import SubMenu from "./subMenu/subMenu";
import classNames from "classnames";
import "./sidebar.scss"

const SideBar = (props) => {
    const { optionsFilters, optionsFiltersCategories, menuItems, activeTab } = props
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <div className={classNames("sidebar", { "is-open": isOpen })}>
            <div className="is-open-icon-container" style={isOpen ? { marginLeft: "94%" } : { width: "10px", margin: "0 auto" }} onClick={toggle}>
                <FontAwesomeIcon icon={isOpen ? faArrowCircleLeft : faArrowCircleRight} className="mr-1" />
            </div>
            <div className="side-menu">
                <Nav vertical className="list-unstyled pb-3">
                    <div className={classNames({ "collapse-styles": !isOpen })}>
                        <SubMenu title="Filters" icon={faFilter} optionsFilters={optionsFilters} optionsFiltersCategories={optionsFiltersCategories} />
                    </div>
                    <NavItem className={classNames({ "collapse-styles": isOpen })}>
                        <NavLink onClick={toggle} tag={Link} >
                            <FontAwesomeIcon icon={faFilter} />
                        </NavLink>
                    </NavItem>
                    <NavLink style={{ fontSize: "18px" }} className="">
                        <FontAwesomeIcon icon={faStickyNote} className="mr-1" />
                        <span className={classNames({ "collapse-styles": !isOpen })}>{"Indicators"}</span>
                    </NavLink>
                    {menuItems.map((menuItem, index) => (
                        <NavItem key={index}>
                            <NavHashLink
                                smooth
                                to={menuItem["path"]}
                                scroll={el => {
                                    el.scrollIntoView(true);
                                    window.scrollBy(0, -78);
                                }}
                                className={`nav-link ${activeTab() === menuItem["label"] ? "sidebar-nav-active" : ""}`}
                            >
                                <FontAwesomeIcon icon={menuItem["icon"]} className="ml-2 mr-1" />
                                <span className={classNames({ "collapse-styles": !isOpen })} style={{ fontSize: "15px" }}>{menuItem["label"]}</span>
                            </NavHashLink>
                        </NavItem>
                    ))}
                </Nav>
            </div>
        </div>
    );
}
export default SideBar;
