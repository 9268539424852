import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import {
    getRegionsByType,
    getDispatchCenters,
    getAllFacility,
    getHealthFacilityLevel,
    getYear,
    getMonth,
    getTransportType,
    getJourneyType,
    getPatientType,
    getEmergencyType,
    getBypassType,
    getCountReferrals,
    getTotalReferralsPerMonth,
    getTotalReferrals,
    filterFacilityByDistrict
} from './me-dashboard.reducer';
import {
    setFilterOption,
    setApplyFilterOption,
} from "../../dashboardWrapper/sidebar/subMenu/childSubMenu/child-sub-menu.reducer"
import {
    faBriefcase,
    faHighlighter,
    faAmbulance,
    faRoad,
    faCar,
    faChartBar
} from "@fortawesome/free-solid-svg-icons";
import DashboardWrapper from '../../dashboardWrapper/dashboardWrapper';
import { useInView } from "react-intersection-observer";
import "./meDashboard.scss";
import { OPTIONS_FILTERS_CATEGORIES, PRIMARY_CHART_COLOR } from './constants';
import { OverviewSection } from './components/overviewSection';
import { EmergencyReferralSection } from './components/emergencyReferralSection';
import { TriageConditionsSection } from './components/triageConditionsSection';
import { ReferralPathwaySection } from './components/referralPathwaySection';
import { TransportSection } from './components/transportSection';
import { OutcomesSection } from './components/outcomesSection';
import LoadingOverlay from 'react-loading-overlay';
// const OverviewSection = React.lazy(() =>  import('./components/overviewSection'))
// const EmergencyReferralSection = React.lazy(() =>  import('./components/emergencyReferralSection'))
// const TriageConditionsSection = React.lazy(() =>  import('./components/triageConditionsSection'))
// const ReferralPathwaySection = React.lazy(() =>  import('./components/referralPathwaySection'))
// const TransportSection = React.lazy(() =>  import('./components/transportSection'))
// const OutcomesSection = React.lazy(() =>  import('./components/outcomesSection'))


const MeDashboard = (props: any) => {
    const {
        selectedFilterOption,
        applyFilterOption,
        resetFilterOption,
        spinner,
        region,
        district,
        council,
        dispatchCenter,
        healthFacility,
        healthFaciliyLevel,
        year,
        month,
        transportType,
        journeyType,
        patientType,
        emergencyType,
        bypassType,
        countReferrals,
        emergencyReferrals,
        referrals
    } = props;


    const OPTIONS_FILTERS = [
        { label: "By Year", values: year, category: "Time", slug: "year" },
        // { label: "By Month", values: month, category: "Time", slug: "month" },
        { label: "By Month Range", values: "", category: "Time", slug: "dateRange" },
        // { label: "By Region", values: region, category: "Geography", slug: "region" },
        { label: "By District", values: district, category: "Geography", slug: "district" },
        // { label: "By Community council", values: council, category: "Geography", slug: "communityCouncil" },
        // { label: "By Dispatch center", values: dispatchCenter, category: "Geography", slug: "dispatchCenter" },
        { label: "By Health Facility", values: healthFacility, category: "Geography", slug: "healthFacility" },
        // { label: "By Health Facility level", values: healthFaciliyLevel, category: "Health Facility", slug: "healthFacilityLevel" },
        { label: "By Type of transport", values: transportType, category: "Cases", slug: "typeOfTransport" },
        { label: "By Journey type", values: journeyType, category: "Cases", slug: "journeyType" },
        { label: "By Patient type", values: patientType, category: "Cases", slug: "PatientType" },
        { label: "By Emergency type", values: emergencyType, category: "Cases", slug: "emergencyType" },
        { label: "By Bypass Emergency", values: bypassType, category: "Cases", slug: "emergencyType" },
    ];

    const MENU_ITEMS = [
        { label: "Overview", icon: faBriefcase, path: `${props.match.url}#overview` },
        { label: "Emergency Referrals", icon: faAmbulance, path: `${props.match.url}#emergency-referrals` },
        { label: "Triage Conditions", icon: faHighlighter, path: `${props.match.url}#triage-conditions` },
        { label: "Referral Pathway", icon: faRoad, path: `${props.match.url}#referral-pathway` },
        { label: "Journey", icon: faCar, path: `${props.match.url}#transport` },
        { label: "Outcomes", icon: faChartBar, path: `${props.match.url}#outcome` },
    ]

    const getGraphData = () => {
        props.getCountReferrals(selectedFilterOption);
        props.getTotalReferralsPerMonth(selectedFilterOption);
        props.getTotalReferrals(selectedFilterOption);
    }

    const getInitData = () => {
        props.getRegionsByType();
        props.getDispatchCenters();
        props.getAllFacility();
        props.getHealthFacilityLevel();
        props.getYear();
        props.getMonth();
        props.getTransportType();
        props.getJourneyType();
        props.getPatientType();
        props.getEmergencyType();
        props.getBypassType();
    }

    useEffect(() => {
        getInitData();
        getGraphData();
    }, []);

    useEffect(() => {
        if (applyFilterOption) {
            getGraphData();
            props.setApplyFilterOption(false);
        }
    }, [applyFilterOption])

    useEffect(() => {
        if (resetFilterOption) {
            getGraphData();
        }
    }, [resetFilterOption])

    useEffect(() => {
        if (selectedFilterOption?.district?.length > 0) {
            props.filterFacilityByDistrict(selectedFilterOption.district.map(d => d.value.id));
        } else {
            props.filterFacilityByDistrict('*');
        }
    }, [selectedFilterOption])

    const [overviewRef, overviewInView] = useInView({});
    const [emergencyReferralsRef, emergencyReferralsInView] = useInView({});
    const [triageConditionsRef, triageConditionsView] = useInView({});
    const [referralPathwayRef, referralPathwayInView] = useInView({});
    const [journeyRef, journeyRefInView] = useInView({});
    const [outcomesRef, outcomesInView] = useInView({});

    const activeTab = () => {
        if (overviewInView) {
            return "Overview";
        } else if (emergencyReferralsInView) {
            return "Emergency Referrals";
        } else if (triageConditionsView) {
            return "Triage Conditions";
        } else if (referralPathwayInView) {
            return "Referral Pathway";
        } else if (journeyRefInView) {
            return "Journey";
        } else if (outcomesInView) {
            return "Outcomes";
        } else {
            return null
        }
    };
    // <Suspense fallback={<div>Loading....</div>}>
    // </Suspense>

    return (
      <LoadingOverlay active={spinner > 0} spinner text='Loading...' >
        <DashboardWrapper optionsFilters={OPTIONS_FILTERS} optionsFiltersCategories={OPTIONS_FILTERS_CATEGORIES} menuItems={MENU_ITEMS} activeTab={activeTab} >
            <div className='content-container container-fluid'>
                <p className="mb-2" style={{ fontSize: "22px", fontWeight: 600, color: PRIMARY_CHART_COLOR }}>watoto-care M&E Dashboard</p>
                <section id="overview" ref={overviewRef} >
                    <OverviewSection countReferrals={countReferrals} emergencyReferrals={emergencyReferrals} />
                </section>
                <section id="emergency-referrals" ref={emergencyReferralsRef}>
                    <EmergencyReferralSection countReferrals={countReferrals} emergencyReferrals={emergencyReferrals} />
                </section>
                <section id="triage-conditions" ref={triageConditionsRef}>
                    <TriageConditionsSection countReferrals={countReferrals} emergencyReferrals={emergencyReferrals} />
                </section>
                <section id="referral-pathway" ref={referralPathwayRef}>
                    <ReferralPathwaySection countReferrals={countReferrals} emergencyReferrals={emergencyReferrals} />
                </section>
                <section id="transport" ref={journeyRef}>
                    <TransportSection countReferrals={countReferrals} emergencyReferrals={emergencyReferrals} referrals={referrals} />
                </section>
                <section id="outcome" ref={outcomesRef}>
                    <OutcomesSection countReferrals={countReferrals} referrals={referrals} />
                </section>
            </div>
        </DashboardWrapper>
      </LoadingOverlay>
    )
}

const mapStateToProps = ({ meDashboard, filterOption }: IRootState) => ({
    selectedFilterOption: filterOption.selectedFilterOption,
    applyFilterOption: filterOption.applyFilterOption,
    resetFilterOption: filterOption.resetFilterOption,
    spinner: meDashboard.spinner,
    region: meDashboard.region,
    district: meDashboard.district,
    council: meDashboard.council,
    dispatchCenter: meDashboard.dispatchCenter,
    healthFacility: meDashboard.healthFacility,
    healthFaciliyLevel: meDashboard.healthFaciliyLevel,
    year: meDashboard.year,
    month: meDashboard.month,
    transportType: meDashboard.transportType,
    journeyType: meDashboard.journeyType,
    patientType: meDashboard.patientType,
    emergencyType: meDashboard.emergencyType,
    bypassType: meDashboard.bypassType,
    countReferrals: meDashboard.countReferrals,
    emergencyReferrals: meDashboard.emergencyReferrals,
    referrals: meDashboard.referrals
});

const mapDispatchToProps = {
    getRegionsByType,
    getDispatchCenters,
    getAllFacility,
    getHealthFacilityLevel,
    getYear,
    getMonth,
    getTransportType,
    getJourneyType,
    getPatientType,
    getEmergencyType,
    getBypassType,
    getCountReferrals,
    getTotalReferralsPerMonth,
    getTotalReferrals,
    setFilterOption,
    setApplyFilterOption,
    filterFacilityByDistrict
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MeDashboard)
