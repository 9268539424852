import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction, IPayloadResult } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IPhysician } from 'app/shared/model/physician.model';
import { IPayload } from 'react-jhipster/src/type/redux-action.type';

export const ACTION_TYPES = {
  FETCH_PHYSICIAN_LIST: 'physician/FETCH_PHYSICIAN_LIST',
  FETCH_PHYSICIAN: 'physician/FETCH_PHYSICIAN',
  CREATE_PHYSICIAN: 'physician/CREATE_PHYSICIAN',
  UPDATE_PHYSICIAN: 'physician/UPDATE_PHYSICIAN',
  DELETE_PHYSICIAN: 'physician/DELETE_PHYSICIAN',
  RESET: 'physician/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IPhysician>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

export type PhysicianState = Readonly<typeof initialState>;

// Reducer

export default (state: PhysicianState = initialState, action): PhysicianState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PHYSICIAN_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PHYSICIAN):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_PHYSICIAN):
    case REQUEST(ACTION_TYPES.UPDATE_PHYSICIAN):
    case REQUEST(ACTION_TYPES.DELETE_PHYSICIAN):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_PHYSICIAN_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PHYSICIAN):
    case FAILURE(ACTION_TYPES.CREATE_PHYSICIAN):
    case FAILURE(ACTION_TYPES.UPDATE_PHYSICIAN):
    case FAILURE(ACTION_TYPES.DELETE_PHYSICIAN):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_PHYSICIAN_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_PHYSICIAN):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_PHYSICIAN):
    case SUCCESS(ACTION_TYPES.UPDATE_PHYSICIAN):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_PHYSICIAN):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        entities: [] as ReadonlyArray<IPhysician>,
        entity: defaultValue,
        updating: false,
        updateSuccess: false
      };
    default:
      return state;
  }
};

const apiUrl = 'api/physicians';

// Actions

export const getEntities: (countryId, filters?) => IPayload<IPhysician> | IPayloadResult<IPhysician> = (countryId, filters?) => async dispatch => {
  let requestUrl = `${apiUrl}?countryId=${countryId}&cacheBuster=${new Date().getTime()}`;

  if(filters?.activePage) {
    requestUrl += `&page=${filters.activePage}`;
  }

  if(filters?.itemsPerPage) {
    requestUrl += `&size=${filters.itemsPerPage}`;
  }

  if(filters?.sort?.sortType && filters?.sort?.sortOrder) {
    requestUrl += `&sort=${filters.sort.sortType},${filters.sort.sortOrder}`;
  }

  if(filters?.name !== '' && filters?.name) {
    requestUrl += `&name=${filters.name}`;
  }

  if(filters?.phoneNumber !== '' && filters?.phoneNumber) {
    requestUrl += `&phoneNumber=${filters.phoneNumber}`;
  }

  if(filters?.healthcareFacilityId !== '' && filters?.healthcareFacilityId) {
    requestUrl += `&healthcareFacilityId=${filters.healthcareFacilityId}`;
  }

  return await dispatch({
    type: ACTION_TYPES.FETCH_PHYSICIAN_LIST,
    payload: axios.get<IPhysician>(requestUrl),
  })
};

export const getEntity: ICrudGetAction<IPhysician> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PHYSICIAN,
    payload: axios.get<IPhysician>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IPhysician> = entity => async dispatch => {
  return await dispatch({
    type: ACTION_TYPES.CREATE_PHYSICIAN,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
};

export const updateEntity: ICrudPutAction<IPhysician> = entity => async dispatch => {
  return await dispatch({
    type: ACTION_TYPES.UPDATE_PHYSICIAN,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
};

export const deleteEntity: ICrudDeleteAction<IPhysician> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  return await dispatch({
    type: ACTION_TYPES.DELETE_PHYSICIAN,
    payload: axios.delete(requestUrl)
  });
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
