import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export const options = {
    responsive: true,
    scales: {
        x: {
            ticks: {
                font: {
                    size: 9,
                },
                color: "black"
            }
        },
        y: {
            ticks: {
                font: {
                    size: 9,
                },
                color: "black"
            }
        }
    },
    maintainAspectRatio: false
};

const dataSet = [
    { name: 'Jan', uv: 500, pv: 2400, amt: 2400 },
    { name: 'Feb', uv: 1500, pv: 2400, amt: 2400 },
    { name: 'Mar', uv: 900, pv: 2400, amt: 2400 },
    { name: 'Apr', uv: 200, pv: 2400, amt: 2400 },
    { name: 'May', uv: 700, pv: 2400, amt: 2400 },
    { name: 'Jun', uv: 500, pv: 2400, amt: 2400 },
    { name: 'Jul', uv: 1400, pv: 2400, amt: 2400 },
];

const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'];

export const AreaChart = ({ label, color, fillColor }) => {
    const data = {
        labels,
        datasets: [
            {
                fill: true,
                label,
                data: dataSet.map((value) => value["uv"]),
                borderColor: color,
                backgroundColor: fillColor,
            },
        ],
    };
    return (
        <Line options={options} data={data} />
    )
}

