import {IRegion} from "app/shared/model/region.model";

export interface IVehicleType {
  id?: string;
  enType?: string;
  stType?: string;
  countryId?: IRegion;
  image?: any;
  paymentRequired?: boolean;
  vehicleLevel?: number;
}

export const defaultValue: Readonly<IVehicleType> = {
  vehicleLevel: 1,
  paymentRequired: false,
};
