import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity ,getDispatchCenterDetail} from './dispatch-center.reducer';
import { IRegion } from 'app/shared/model/region.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import {IDispatchCenter} from 'app/shared/model/dispatch-center.model';
export interface IRegionDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const RegionDetail = (props: IRegionDetailProps) => {
  useEffect(() => {
    props.getDispatchCenterDetail(props.match.params.id);
    
  }, []);

  const { dipatchCenterDetail } = props;

  console.log("dipatchCenterDetail",dipatchCenterDetail)
  return (
    <Row>
      <Col md="8">
        <h2>
          Dispatch center [<b>{/* dipatchCenterDetail.id*/}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="name">Name</span>
          </dt>
          <dd>{dipatchCenterDetail.name}</dd>
          <dt>
            <span id="locationLat">District List</span>
          </dt>

          {
            dipatchCenterDetail.region?.map((region)=>{
              return(
                  <dd>{region.name}</dd>
              )

            })
          }
          
          
        </dl>
        <Button tag={Link} to="/dispatch-center" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/dispatch-center/${dipatchCenterDetail.id}/edit?type=${''}`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  dipatchCenterDetail:storeState.dispatchCenter.dipatchCenterDetail,
});

const mapDispatchToProps = { getDispatchCenterDetail };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RegionDetail);
