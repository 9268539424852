import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction, IPayloadResult } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IPatientVisit } from 'app/shared/model/patient-visit.model';
import { IPayload } from 'react-jhipster/src/type/redux-action.type';

export const ACTION_TYPES = {
  FETCH_VISIT_LIST: 'patient-visit/FETCH_VISIT_LIST',
  FETCH_VISIT: 'patient-visit/FETCH_VISIT',
  CREATE_VISIT: 'patient-visit/CREATE_VISIT',
  UPDATE_VISIT: 'patient-visit/UPDATE_VISIT',
  DELETE_VISIT: 'patient-visit/DELETE_VISIT',
  RESET: 'patient-visit/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IPatientVisit>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  fetchSuccess: false
};

export type PatientVisitState = Readonly<typeof initialState>;

// Reducer

export default (state: PatientVisitState = initialState, action): PatientVisitState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_VISIT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_VISIT):
      return {
        ...state,
        errorMessage: null,
        fetchSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_VISIT):
    case REQUEST(ACTION_TYPES.UPDATE_VISIT):
    case REQUEST(ACTION_TYPES.DELETE_VISIT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_VISIT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_VISIT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        fetchSuccess: false,
        errorMessage: action.payload
      };
    case FAILURE(ACTION_TYPES.CREATE_VISIT):
    case FAILURE(ACTION_TYPES.UPDATE_VISIT):
    case FAILURE(ACTION_TYPES.DELETE_VISIT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_VISIT_LIST):
      return {
        ...state,
        loading: false,
        fetchSuccess: true,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_VISIT):
      return {
        ...state,
        loading: false,
        fetchSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_VISIT):
    case SUCCESS(ACTION_TYPES.UPDATE_VISIT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_VISIT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        entities: [] as ReadonlyArray<IPatientVisit>,
        entity: defaultValue,
        updating: false,
        updateSuccess: false,
        fetchSuccess: false
      };
    default:
      return state;
  }
};

const apiUrl = 'api/patient-visits';

// Actions

export const getEntities: (patientCaseId: string) => IPayload<IPatientVisit[]> | IPayloadResult<IPatientVisit[]> = (patientCaseId) => {
  const requestUrl = `${apiUrl}?patientCaseId=${patientCaseId}`;
  return {
    type: ACTION_TYPES.FETCH_VISIT_LIST,
    payload: axios.get<IPatientVisit[]>(requestUrl)
  };
};

export const getEntity: ICrudGetAction<IPatientVisit> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_VISIT,
    payload: axios.get<IPatientVisit>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IPatientVisit> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_VISIT,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const updateEntity: ICrudPutAction<IPatientVisit> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_VISIT,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IPatientVisit> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_VISIT,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
