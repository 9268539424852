import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Label, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasAnyPermission } from 'app/shared/auth/permissions';
import { IRootState } from 'app/shared/reducers';
import { getEntities } from './patient.reducer';
import { IRegion } from 'app/shared/model/region.model';
import { getAllByType as getCountries } from 'app/entities/region/region.reducer';
import TreeList from 'react-treelist';
import 'react-treelist/build/css/index.css';
import { IPatient } from 'app/shared/model/patient.model';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { JhiItemCount, JhiPagination } from 'react-jhipster';
import moment from 'moment';
import { daysSince } from 'app/shared/util/date-utils';

export interface IPatientProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

export const Patient = (props: IPatientProps) => {
  const emptyRegion: IRegion = null;
  const { patientList, totalItems, match, loading, userCountryId } = props;
  const [activeCountry, setActiveCountry] = useState<IRegion>({});
  const [patientsPerCountry, setPatientsPerCountry] = useState([]);
  const [filterList, setFilterList] = useState({
    wcId: '',
    patientName: '',
    mobileNumber: '',
    activePage: 0,
    itemsPerPage: 25,
    sort: { sortOrder: 'desc', sortType: 'createdAt' }
  });

  useEffect(() => {
    props.getCountries();
  }, []);

  useEffect(() => {
    const ac = props.countries.find(country => country.id === userCountryId);
    setActiveCountry(ac);
    props.getEntities(userCountryId, filterList);
  }, [props.countries, userCountryId]);

  const updatePatientList = (ac) => {
    const patients = props.patientList.filter(patient => patient.countryId && patient.countryId === ac.id);
     /* .sort((a, b) => {
        if (a.patientBabies.length > 0 && b.patientBabies.length > 0) {
          // sort by latest baby created
          const latestBabyA = a.patientBabies.sort((m, n) => new Date(n.createdAt).getTime() - new Date(m.createdAt).getTime())[0];
          const latestBabyB = b.patientBabies.sort((m, n) => new Date(n.createdAt).getTime() - new Date(m.createdAt).getTime())[0];
          return new Date(latestBabyB.createdAt).getTime() - new Date(latestBabyA.createdAt).getTime();
        } else {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        }
      });*/

    const babies = [];
    const patientTree = patients.map((patient: IPatient) => {
      if (patient.patientBabies.length > 0) {
        patient.patientBabies.forEach((baby, i, patientBabies) => {
          const babyInfo = {
            id: baby.id,
            name: baby.firstName + ' ' + baby.lastName,
            type: baby.sex === 'F' ? 'Female' : 'Male',
            phoneNumber: '',
            village: baby.birthLocation + (baby.outbornType ? '/' + baby.outbornType : ''),
            parentId: patient.id,
            actionButtons: [],
            link: {
              href: `/patient-baby/${baby.id}`,
              wcId: `${baby.birthDate} (Age in Days: ${daysSince(baby.birthDate)})`
            }
          };
          babyInfo.actionButtons = [];
          if (baby.patientCase.babyDischargedAt === null) {
            babyInfo.actionButtons.push({
              key: baby.id,
              href: `/patient-baby/${baby.id}/discharge`,
              text: 'Discharge',
              background: 'blue',
              icon: 'arrow-right'
            });
          } else {
            babyInfo.actionButtons.push({
              key: baby.id,
              href: `/patient-visit/journey?patientBabyId=${baby.id}`,
              text: 'Triage/Follow-up',
              background: 'darkred',
              icon: 'plus'
            });
            if (baby.patientCase.patientBabyIsAlive === true) {
              babyInfo.actionButtons.push(
                {
                  key: baby.id,
                  href: `/patient-outcome/create?patientBabyId=${baby.id}`,
                  text: 'Outcome Call',
                  background: 'teal',
                  icon: 'phone-alt'
                });
            }
          }
          babies.push(babyInfo);
        });
      }
      return {
        id: patient.id,
        wcId: patient.wcId,
        name: patient.firstName + ' ' + patient.lastName,
        type: 'Mother',
        phoneNumber: patient.phoneNumber,
        village: patient.village
          ? patient.village?.name + '/' + patient.village?.council?.name + '/' + patient.village?.council?.parent?.name
          : patient.villageNotes,
        parentId: null,
        actionButtons: patient.consent ? [{
          key: patient.id,
          href: `patient-baby/create?parentId=${patient.id}`,
          text: 'Add Baby',
          background: 'green'
        }] : null,
        link: {
          href: `/patient/${patient.id}`,
          wcId: patient.wcId
        }
      };
    });
    setPatientsPerCountry(patientTree.concat(babies));
  };

  useEffect(() => {
    if (props.patientList && activeCountry) {
      updatePatientList(activeCountry);
    }
  }, [props.patientList]);

  const setAction = (actionButtons: any) => {
    if (actionButtons && actionButtons.length > 0) {
      return (
        <>
          {actionButtons.map(actionButton => (
            <Link key={actionButton?.key} style={{ background: actionButton?.background }} to={actionButton?.href}
                  className="btn btn-primary float-right mt-1 jh-create-entity" id="jh-create-entity">
              <FontAwesomeIcon icon={actionButton?.icon || 'plus'} />
              &nbsp; {actionButton?.text}
            </Link>
          ))}
        </>
      );
    }
  };
  const itemViewLink = (link) => {
    return (
      <Button tag={Link} to={link.href} color="link" size="sm">
        {link.wcId}
      </Button>
    );
  };
  const COLUMNS = [
    { 'title': 'Id', 'field': 'link', 'type': 'string', 'formatter': itemViewLink, 'width': 330 },
    { 'title': 'Name', 'field': 'name', 'type': 'string' },
    { 'title': '', 'field': 'type', 'type': 'string' },
    { 'title': 'Mobile Number', 'field': 'phoneNumber', 'type': 'string' },
    { 'title': 'Village/Birth Location', 'field': 'village', 'type': 'string' },
    { 'title': '', 'field': 'actionButtons', 'type': 'string', 'formatter': setAction }
  ];

  const updateFilters = (filters) => {
    const filterListObj = { ...filterList, ...filters };
    setFilterList(filterListObj);
    props.getEntities(activeCountry.id, filterListObj);
  };

  return (
    <div>
      <h2 id="patient-heading">
        Patients
      </h2>
      <br />
      {(activeCountry && typeof activeCountry.id !== 'undefined') && (
        <Link to={`${match.url}/create?country_id=${activeCountry.id}`}
              className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp; Create new Patient
        </Link>
      )
      }
      <br />
      {activeCountry && (
        <AvForm>
          <AvGroup>
            <Label for="wc-id-filter">watotoCare ID</Label>
            <AvInput
              onChange={(event) => {
                updateFilters({ wcId: event.target.value });
              }}
              id="wc-id-filter"
              type="input"
              className="form-control"
              name="emergency-type-filter"
              value={filterList.wcId}
            />
          </AvGroup>

          <AvGroup>
            <Label for="mother-name-filter">Mother<span>&#39;</span>s Name</Label>
            <AvInput
              onChange={(event) => {
                updateFilters({ patientName: event.target.value });
              }}
              id="mother-name-filter"
              type="input"
              className="form-control"
              name="mother-name-filter"
              value={filterList.patientName}
            />
          </AvGroup>

          <AvGroup>
            <Label for="mobile-number-filter">Mobile Number</Label>
            <AvInput
              onChange={(event) => {
                updateFilters({ mobileNumber: event.target.value });
              }}
              id="mobile-number-filter"
              type="input"
              className="form-control"
              name="mobile-number-filter"
              value={filterList.mobileNumber}
            />
          </AvGroup>

        </AvForm>
      )
      }

      <div className="table-responsive">
        {patientsPerCountry && patientsPerCountry.length > 0 ? (
          <TreeList
            data={patientsPerCountry}
            columns={COLUMNS}
            id={'id'}
            parentId={'parentId'}>
          </TreeList>
        ) : (
          !loading && <div className="alert alert-warning">No Patients found</div>
        )}
      </div>

      <br />
      {totalItems ? (
        <div
          className={patientList && patientList.filter(patient => patient.countryId && patient.countryId === activeCountry.id).length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={filterList.activePage + 1} total={totalItems}
                          itemsPerPage={filterList.itemsPerPage} />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={filterList.activePage + 1}
              onSelect={(currentPage) => {
                updateFilters({ activePage: currentPage - 1 });
              }}
              maxButtons={5}
              itemsPerPage={filterList.itemsPerPage}
              totalItems={props.totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = ({ patient, authentication, region }: IRootState) => ({
  patientList: patient.entities,
  totalItems: patient.totalItems,
  loading: patient.loading,
  countries: region.countries,
  userCountryId: authentication.userCountryId,
  isAdmin: hasAnyPermission(authentication.account.roles, ['ADMIN'])
});

const mapDispatchToProps = {
  getEntities,
  getCountries
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Patient);
