import axios from 'axios';
import {ICrudDeleteAction, ICrudGetAction, ICrudPutAction, IPayloadResult} from 'react-jhipster';

import {cleanEntity} from 'app/shared/util/entity-utils';
import {FAILURE, REQUEST, SUCCESS} from 'app/shared/reducers/action-type.util';

import {defaultValue, IAmbulance} from 'app/shared/model/ambulance.model';
import {IPayload} from "react-jhipster/src/type/redux-action.type";

export const ACTION_TYPES = {
  FETCH_AMBULANCE_LIST: 'ambulance/FETCH_AMBULANCE_LIST',
  FETCH_AMBULANCE: 'ambulance/FETCH_AMBULANCE',
  CREATE_AMBULANCE: 'ambulance/CREATE_AMBULANCE',
  UPDATE_AMBULANCE: 'ambulance/UPDATE_AMBULANCE',
  DELETE_AMBULANCE: 'ambulance/DELETE_AMBULANCE',
  RESET: 'ambulance/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IAmbulance>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type AmbulanceState = Readonly<typeof initialState>;

// Reducer

export default (state: AmbulanceState = initialState, action): AmbulanceState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_AMBULANCE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_AMBULANCE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_AMBULANCE):
    case REQUEST(ACTION_TYPES.UPDATE_AMBULANCE):
    case REQUEST(ACTION_TYPES.DELETE_AMBULANCE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_AMBULANCE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_AMBULANCE):
    case FAILURE(ACTION_TYPES.CREATE_AMBULANCE):
    case FAILURE(ACTION_TYPES.UPDATE_AMBULANCE):
    case FAILURE(ACTION_TYPES.DELETE_AMBULANCE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_AMBULANCE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_AMBULANCE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_AMBULANCE):
    case SUCCESS(ACTION_TYPES.UPDATE_AMBULANCE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_AMBULANCE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/ambulances';

// Actions

export const getEntities: (page?, size?, sort?, countryId?) => IPayload<IAmbulance> | IPayloadResult<IAmbulance> = (page?, size?, sort?, countryId?) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&countryId=${countryId}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_AMBULANCE_LIST,
    payload: axios.get<IAmbulance>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};
export const getAmbulanceByName: (searchString, countryId) => IPayload<IAmbulance> | IPayloadResult<IAmbulance> = (searchString, countryId) => {
  const requestUrl = 'api/search/search-ambulance-by-name';
  return {
    type: ACTION_TYPES.FETCH_AMBULANCE_LIST,
    payload: axios.get<IAmbulance>(`${requestUrl + '/' + searchString + '/' + countryId}${'?'}cacheBuster=${new Date().getTime()}`),
  };
};


export const getEntity: ICrudGetAction<IAmbulance> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_AMBULANCE,
    payload: axios.get<IAmbulance>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IAmbulance> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_AMBULANCE,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  // dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IAmbulance> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_AMBULANCE,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IAmbulance> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_AMBULANCE,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
