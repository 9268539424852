import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, ButtonGroup, Col, Label, Row } from 'reactstrap';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { createEntity, getEntity, reset, updateEntity } from './patient.reducer';
import { getVillagesByCountry as getVillages } from 'app/entities/village/village.reducer';
import VillageAvTypeahead from 'app/shared/layout/menus/village-av-typeahead';
import {
  getMasterEntitiesByEnumNames,
  getMasterEntitiesByGroupNames,
  reset as resetWatotoCareEntities
} from 'app/entities/watotocare-entity/watotocare-entity.reducer';
import { IWatotoCareEntityDetail } from 'app/shared/model/watotocare-entity-detail.model';
import { IWatotoCareEntityMaster } from 'app/shared/model/watotocare-entity-master.model';
import { WatotoCareEntityType } from 'app/shared/model/enumerations/watotocare-entity-type.model';

export interface IPatientUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const PatientUpdate = (props: IPatientUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [countryId, setCountryId] = useState(null);
  const [villageId, setVillageId] = useState(null);
  const {
    patientEntity,
    watotoCareEntityList,
    watotoCareEntitiesByGroup,
    villages,
    userCountryId,
    villagesLoading,
    loading,
    updating
  } = props;
  const [villageNames, setVillageNames] = useState([]);
  const [consentStatus, setConsentStatus] = useState(null);
  const [eligibilityStatus, setEligibilityStatus] = useState(null);
  const [overrideWcId, setOverrideWcId] = useState('No');
  const patientParity = ['1', '2', '3', '4+'];

  const [ageRangeEntityDetails, setAgeRangeEntityDetails] = useState<IWatotoCareEntityDetail[]>([]);
  const [noConsentReasonEntityDetails, setNoConsentReasonEntityDetails] = useState<IWatotoCareEntityDetail[]>([]);
  const [ineligibilityReasonEntityDetails, setIneligibilityReasonEntityDetails] = useState<IWatotoCareEntityDetail[]>([]);
  const [enrollmentMasterEntities, setEnrollmentMasterEntities] = useState<IWatotoCareEntityMaster[]>([]);
  const [enrollmentMultiSelectEntityDetails, setEnrollmentMultiSelectEntityDetails] = useState<IWatotoCareEntityDetail[]>([]);
  const [enrollmentEntityIds, setEnrollmentEntityIds] = useState({});
  const [enrollmentMultiSelectEntityIds, setEnrollmentMultiSelectEntityIds] = useState({});
  const WATOTOCARE_ENTITY_ENUM_NAMES = ['AGE_RANGE', 'NO_CONSENT_REASON', 'INELIGIBILITY_REASON'];
  const WATOTOCARE_ENTITY_GROUP_NAMES = ['PATIENT_ENROLLMENT'];

  const villageOptionList = () => {
    return (villageNames.length > 0) ? villageNames : ([{
      short: '',
      full: '',
      id: undefined,
      latitude: 0,
      longitude: 0
    }]);
  };

  const handleClose = () => {
    props.history.push('/patient');
  };

  const addBaby = () => {
    props.history.push('/patient-baby/create?parentId=' + patientEntity.id);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (!isNew && patientEntity.countryId && patientEntity.countryId !== userCountryId) {
      alert('You don\'t have access to view this patient');
      handleClose();
    }
    setCountryId(userCountryId);

    if (!isNew && !patientEntity?.wcId?.toUpperCase().startsWith('WC')) {
      setOverrideWcId('Yes');
    }

    if (!isNew && typeof patientEntity.consent !== 'undefined') {
      setConsentStatus(patientEntity?.consent);
      setEligibilityStatus(patientEntity?.eligible);
    }

    setVillageId(patientEntity?.village?.id);
    props.getMasterEntitiesByEnumNames(WATOTOCARE_ENTITY_ENUM_NAMES, userCountryId);
    props.getMasterEntitiesByGroupNames(WATOTOCARE_ENTITY_GROUP_NAMES, userCountryId);
    props.getVillages(undefined, undefined, undefined, userCountryId);

  }, [patientEntity, userCountryId]);

  useEffect(() => {
    if (watotoCareEntityList.length > 0) {
      // set watotoCareEntityDetails for each entity
      const ageRangeEntity = watotoCareEntityList.find(entity => entity.enumName === 'AGE_RANGE');
      if (ageRangeEntity) {
        setAgeRangeEntityDetails(ageRangeEntity.watotoCareEntityDetails
          .filter(entity => entity.isActive)
          .sort((a, b) => a.displayOrder - b.displayOrder)
        );
      }

      const noConsentReasonEntity = watotoCareEntityList.find(entity => entity.enumName === 'NO_CONSENT_REASON');
      if (noConsentReasonEntity) {
        setNoConsentReasonEntityDetails(noConsentReasonEntity.watotoCareEntityDetails
          .filter(entity => entity.isActive)
          .sort((a, b) => a.displayOrder - b.displayOrder)
        );
      }

      const ineligibilityReasonEntity = watotoCareEntityList.find(entity => entity.enumName === 'INELIGIBILITY_REASON');
      if (ineligibilityReasonEntity) {
        setIneligibilityReasonEntityDetails(ineligibilityReasonEntity.watotoCareEntityDetails
          .filter(entity => entity.isActive)
          .sort((a, b) => a.displayOrder - b.displayOrder)
        );
      }
    }
  }, [watotoCareEntityList]);

  useEffect(() => {
    if (watotoCareEntitiesByGroup[WATOTOCARE_ENTITY_GROUP_NAMES[0]]) {
      const watotoCareEntityListByGroup = watotoCareEntitiesByGroup[WATOTOCARE_ENTITY_GROUP_NAMES[0]];
      // set multi select entities (yes/no)
      const multiSelectEntities = watotoCareEntityListByGroup.find(entity => entity.type === WatotoCareEntityType.MULTISELECT);
      let multiSelectEntityDetails = [];
      if (multiSelectEntities) {
        multiSelectEntityDetails = multiSelectEntities.watotoCareEntityDetails
          .filter(entity => entity.isActive)
          .sort((a, b) => a.displayOrder - b.displayOrder);
        setEnrollmentMultiSelectEntityDetails(multiSelectEntityDetails);
      } else {
        setEnrollmentMultiSelectEntityDetails([]);
      }

      // set single select entities (dropdown)
      const singleSelectEntities = watotoCareEntityListByGroup.filter(entity => entity.type === WatotoCareEntityType.DROPDOWN);
      if (singleSelectEntities.length > 0) {
        setEnrollmentMasterEntities(singleSelectEntities);
      } else {
        setEnrollmentMasterEntities([]);
      }

      if (!isNew && typeof patientEntity.patientEnrollmentEntities !== 'undefined') {
        const previousEnrollmentEntityIds = {};
        const previousEnrollmentMultiSelectEntityIds = {};
        patientEntity.patientEnrollmentEntities.forEach(entity => {
          if (entity.entityDetail.entityMaster.type === WatotoCareEntityType.MULTISELECT) {
            previousEnrollmentMultiSelectEntityIds[entity.entityDetail.id] = true;
          } else if (entity.entityDetail.entityMaster.type === WatotoCareEntityType.DROPDOWN) {
            previousEnrollmentEntityIds[entity.entityDetail.entityMaster.id] = entity.entityDetail.id;
          }
        });

        // loop through multiSelectEntityDetails and set ids not present in previousEnrollmentEntityIds as false
        multiSelectEntityDetails.forEach(val => {
          if (!previousEnrollmentMultiSelectEntityIds[val.id]) {
            previousEnrollmentMultiSelectEntityIds[val.id] = false;
          }
        });

        setEnrollmentMultiSelectEntityIds(previousEnrollmentMultiSelectEntityIds);
        setEnrollmentEntityIds(previousEnrollmentEntityIds);
      }
    }
  }, [watotoCareEntitiesByGroup, patientEntity]);


  const filterVillageByCountry = () => {
    const villagesNames = villages.map(item => ({
      short: item.name,
      council: item?.council?.name,
      district: item?.council?.parent?.name,
      country: item?.council?.parent,
      latitude: item?.locationLat,
      longitude: item?.locationLon,
      full: (item.name + ' / ' + item?.council?.name + ' / ' + item?.council?.parent?.name),
      id: item.id
    }));
    setVillageNames(villagesNames);
  };

  useEffect(() => {
    filterVillageByCountry();
  }, [countryId, villages]);

  useEffect(() => {
    if (props.updateSuccess) {
      if (consentStatus === true && isNew) {
        addBaby();
      } else {
        handleClose();
      }
    }
  }, [props.updateSuccess]);

  useEffect(() => {
    if (props.errorMessage) {
      alert(props.errorMessage);
    }
  }, [props.errorMessage]);

  const saveEntity = (event, errors, values) => {
    // remove blank values
    const filteredValues = { ...values };
    Object.keys(filteredValues).forEach((key) => filteredValues[key] === '' && delete filteredValues[key]);
    if (errors.length === 0) {
      const cid = isNew ? countryId : patientEntity.countryId;

      if (eligibilityStatus === true) {
        filteredValues.ineligibilityReasonId = null;
        filteredValues.ineligibilityComment = null;
      }
      if (consentStatus === true) {
        filteredValues.noConsentReasonId = null;
        filteredValues.noConsentComment = null;
      }

      // get ids of only true values from enrollmentMultiSelectEntityIds
      const patientEnrollmentEntityIds = Object.keys(enrollmentMultiSelectEntityIds).filter(key => enrollmentMultiSelectEntityIds[key] === true);

      // get val from enrollmentEntityIds object and add those to watotoCareEntityIds
      Object.keys(enrollmentEntityIds).forEach(key => {
        patientEnrollmentEntityIds.push(enrollmentEntityIds[key]);
      });

      const entity = {
        ...patientEntity,
        ...filteredValues,
        villageId,
        consent: consentStatus,
        eligible: eligibilityStatus,
        countryId: cid,
        patientEnrollmentEntityIds
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  useEffect(() => () => {
    props.reset();
    props.resetWatotoCareEntities();
    setEnrollmentMasterEntities([]);
    setEnrollmentMultiSelectEntityDetails([]);
  }, []);

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="watotocareApp.patient.home.createOrEditLabel">{isNew ? 'Mother/Caregiver Enrollment' : 'Edit Patient'} </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : patientEntity} onSubmit={saveEntity}>
              <div>
                <h5 className="text-primary mt-3">Eligibility</h5>
                <div>
                  <AvGroup>
                    <Label id="eligibility" for="patient-eligibility">
                      Eligibility for Enrollment
                    </Label>
                    <ButtonGroup style={{ marginLeft: '30px' }}>
                      <Button
                        color={eligibilityStatus === true ? 'primary' : 'secondary'}
                        value={eligibilityStatus}
                        onClick={() => {
                          setEligibilityStatus(true);
                        }}
                      >
                        Yes
                      </Button>

                      <Button
                        color={eligibilityStatus === false ? 'primary' : 'secondary'}
                        value={eligibilityStatus}
                        onClick={() => {
                          setEligibilityStatus(false);
                        }}
                      >
                        No
                      </Button>
                    </ButtonGroup>
                  </AvGroup>
                  {eligibilityStatus === false && (
                    <>
                      <Row className="justify-content-center">
                        <Col md="6">
                          <AvGroup>
                            <Label id="ineligibilityReason" for="patient-ineligibilityReason">
                              Reason for not Eligibility
                            </Label>
                            <AvInput
                              id="patient-ineligibilityReason"
                              type="select"
                              className="form-control"
                              name="ineligibilityReason"
                              validate={{
                                required: { value: true, errorMessage: 'This field is required.' }
                              }}
                            >
                              <option disabled selected value="">-- select an option --</option>
                              {ineligibilityReasonEntityDetails.map(val => (
                                <option value={val.id} key={val.id}>
                                  {val.enDescription}
                                </option>
                              ))}
                            </AvInput>
                          </AvGroup>
                        </Col>
                        <Col md="6">
                          <AvGroup>
                            <Label id="ineligibilityComment" for="patient-ineligibilityComment">
                              Comment
                            </Label>
                            <AvField
                              id="patient-ineligibilityComment"
                              type="text"
                              name="ineligibilityComment"
                              placeholder="e.g. please enter a reason if other is selected"
                            />
                          </AvGroup>
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
              </div>
              {eligibilityStatus !== null && (
                <>
                  <div>
                    <h5 className="text-primary mt-3">Consent</h5>
                    <div>
                      <AvGroup>
                        <Label id="consent" for="patient-consent">
                          Consent for Enrollment
                        </Label>
                        <ButtonGroup style={{ marginLeft: '30px' }}>
                          <Button
                            color={consentStatus === true ? 'primary' : 'secondary'}
                            value={consentStatus}
                            onClick={() => {
                              setConsentStatus(true);
                            }}
                          >
                            Yes
                          </Button>

                          <Button
                            color={consentStatus === false ? 'primary' : 'secondary'}
                            value={consentStatus}
                            onClick={() => {
                              setConsentStatus(false);
                            }}
                          >
                            No
                          </Button>
                        </ButtonGroup>
                      </AvGroup>
                      {consentStatus === false && (
                        <>
                          <Row className="justify-content-center">
                            <Col md="6">
                              <AvGroup>
                                <Label id="noConsentReason" for="patient-noConsentReason">
                                  Reason for not Consenting
                                </Label>
                                <AvInput
                                  id="patient-noConsentReason"
                                  type="select"
                                  className="form-control"
                                  name="noConsentReason"
                                  validate={{
                                    required: { value: true, errorMessage: 'This field is required.' }
                                  }}
                                >
                                  <option disabled selected value="">-- select an option --</option>
                                  {noConsentReasonEntityDetails.map(val => (
                                    <option value={val.id} key={val.id}>
                                      {val.enDescription}
                                    </option>
                                  ))}
                                </AvInput>
                              </AvGroup>
                            </Col>
                            <Col md="6">
                              <AvGroup>
                                <Label id="noConsentComment" for="patient-noConsentComment">
                                  Comment
                                </Label>
                                <AvField
                                  id="patient-noConsentComment"
                                  type="text"
                                  name="noConsentComment"
                                  placeholder="e.g. please enter a reason if other is selected"
                                />
                              </AvGroup>
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                  </div>
                  {
                    consentStatus !== null && (
                      <>
                        <br />
                        <h5 className="text-primary">watotoCare ID</h5>
                        <div className="mt-3">
                          <Row className="justify-content-left">
                            {(isNew || patientEntity?.wcId?.toUpperCase().startsWith('WC')) && (
                              <Col md="6">
                                <AvGroup>
                                  <Label id="override" for="patient-override">
                                    Do you want to override watotoCare ID?
                                  </Label>
                                  <AvInput
                                    id="patient-override"
                                    type="select"
                                    className="form-control"
                                    name="override"
                                    value={overrideWcId}
                                    onChange={(e) => {
                                      setOverrideWcId(e.target.value);
                                    }}
                                  >
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </AvInput>
                                </AvGroup>
                              </Col>
                            )}
                            <Col md="6">
                              {(!isNew || overrideWcId === 'Yes') && (
                                <AvGroup>
                                  <Label id="wcId" for="patient-wcId">
                                    watotoCare ID
                                  </Label>
                                  <AvInput
                                    id="patient-wcId"
                                    type="text"
                                    className="form-control"
                                    name="wcId"
                                    disabled={(!isNew && patientEntity?.wcId?.toUpperCase().startsWith('WC')) && overrideWcId !== 'Yes'}
                                    validate={{
                                      required: { value: true, errorMessage: 'This field is required.' },
                                      maxLength: { value: 16 }
                                    }}
                                  />
                                </AvGroup>
                              )}
                            </Col>
                          </Row>
                        </div>

                        <br />
                        <h5 className="text-primary">Personal Details</h5>
                        <div className="mt-3">
                          <Row className="justify-content-left">
                            <Col md="6">
                              <AvGroup>
                                <Label id="rchCardNumber" for="patient-rchCardNumber">
                                  RCH-4 Card No.
                                </Label>
                                <AvField
                                  id="patient-rchCardNumber"
                                  type="text"
                                  name="rchCardNumber"
                                  validate={{
                                    required: { value: true, errorMessage: 'This field is required.' }
                                  }}
                                />
                              </AvGroup>
                            </Col>
                            <Col md="6">
                              <AvGroup>
                                <Label id="nidaNumber" for="patient-nidaNumber">
                                  Mother&apos;s NIDA number
                                </Label>
                                <AvField
                                  id="patient-nidaNumber"
                                  type="text"
                                  name="nidaNumber"
                                />
                              </AvGroup>
                            </Col>
                            <Col md="6">
                              <AvGroup>
                                <Label id="firstName" for="patient-firstName">
                                  First Name
                                </Label>
                                <AvField
                                  id="patient-firstName"
                                  type="text"
                                  name="firstName"
                                  validate={{
                                    required: { value: true, errorMessage: 'This field is required.' }
                                  }}
                                />
                              </AvGroup>
                            </Col>
                            <Col md="6">
                              <AvGroup>
                                <Label id="lastName" for="patient-lastName">
                                  Last Name
                                </Label>
                                <AvField
                                  id="patient-lastName"
                                  type="text"
                                  name="lastName"
                                  validate={{
                                    required: { value: true, errorMessage: 'This field is required.' }
                                  }}
                                />
                              </AvGroup>
                            </Col>
                            <Col md="6">
                              <AvGroup>
                                <Label id="ageRange" for="patient-ageRange">
                                  Age Range
                                </Label>
                                <AvInput
                                  id="patient-ageRange"
                                  type="select"
                                  className="form-control"
                                  name="ageRange"
                                  validate={{
                                    required: { value: true, errorMessage: 'This field is required.' }
                                  }}
                                >
                                  {isNew && <option disabled selected value="">-- select an option --</option>}
                                  {ageRangeEntityDetails.map(val => (
                                    <option value={val.id} key={val.id}>
                                      {val.enDescription}
                                    </option>
                                  ))}
                                </AvInput>
                              </AvGroup>
                            </Col>
                            <Col md="6">
                              <AvGroup>
                                <Label id="parity" for="patient-parity">
                                  Parity
                                </Label>
                                <AvInput
                                  id="patient-parity"
                                  type="select"
                                  className="form-control"
                                  name="parity"
                                  value={patientEntity.parity}
                                  validate={{
                                    required: { value: true, errorMessage: 'This field is required.' }
                                  }}
                                >
                                  {isNew && <option disabled selected value="">-- select an option --</option>}
                                  {patientParity.map(val => (
                                    <option value={val} key={val}>
                                      {val}
                                    </option>
                                  ))}
                                </AvInput>
                              </AvGroup>
                            </Col>
                            <Col md="6">
                              <AvGroup>
                                <Label for="patient-village">
                                  {consentStatus === true ? 'Pick-up Village' : 'Home Village'}
                                </Label>
                                <VillageAvTypeahead
                                  labelKey="name"
                                  valueKey="id"
                                  data={villageOptionList()}
                                  placeholder="Village"
                                  id="patient-village"
                                  loading={villagesLoading}
                                  onChange={(data: any) => setVillageId(data)}
                                  defaultValue={!patientEntity.village ? '' : patientEntity?.village?.name + ' / ' + patientEntity?.village?.council?.name + ' / ' + patientEntity?.village?.council?.parent?.name}
                                  input={{
                                    id: 'patient-village',
                                    name: 'villageId'
                                  }}
                                />
                              </AvGroup>
                            </Col>
                            <Col md="6">
                              <AvGroup>
                                <Label id="villageNotes" for="patient-villageNotes">
                                  Notes (for Pick-up Village)
                                </Label>
                                <AvField
                                  id="patient-villageNotes"
                                  type="text"
                                  name="villageNotes"
                                  validate={!villageId && {
                                    required: { value: true, errorMessage: 'This field is required.' }
                                  }}
                                />
                              </AvGroup>
                            </Col>
                            {consentStatus === true &&
                              (
                                <Col md="6">
                                  <AvGroup>
                                    <Label id="nearbyLandmarks" for="patient-nearbyLandmarks">
                                      Nearby Landmarks
                                    </Label>
                                    <AvField
                                      id="patient-nearbyLandmarks"
                                      type="text"
                                      name="nearbyLandmarks"
                                      placeholder="e.g. opposite village church, next to the village hospital etc."
                                      validate={{
                                        required: { value: true, errorMessage: 'This field is required.' }
                                      }}
                                    />
                                  </AvGroup>
                                </Col>
                              )
                            }
                            <Col md="6">
                              <AvGroup>
                                <Label id="phoneNumber" for="patient-phoneNumber">
                                  Mobile Number
                                </Label>
                                <AvField
                                  id="patient-phoneNumber"
                                  type="text"
                                  name="phoneNumber"
                                  validate={consentStatus === true ? {
                                    required: {
                                      value: true,
                                      errorMessage: 'This field is required.'
                                    }
                                  } : {}}
                                />
                              </AvGroup>
                            </Col>
                          </Row>
                        </div>

                        {
                          consentStatus === true && (
                            <>
                              <h5 className="text-primary mt-3">Caregiver details (if applicable)</h5>
                              <div className="mt-3">
                                <Row className="justify-content-left">
                                  <Col md="6">
                                    <AvGroup>
                                      <Label id="caregiverName" for="patient-caregiverName">
                                        Name of Caregiver
                                      </Label>
                                      <AvField
                                        id="patient-caregiverName"
                                        type="text"
                                        name="caregiverName"
                                      />
                                    </AvGroup>
                                  </Col>
                                  <Col md="6">
                                    <AvGroup>
                                      <Label id="caregiverPhoneNumber" for="patient-caregiverPhoneNumber">
                                        Mobile Number of Caregiver
                                      </Label>
                                      <AvField
                                        id="patient-caregiverPhoneNumber"
                                        type="text"
                                        name="caregiverPhoneNumber"
                                      />
                                    </AvGroup>
                                  </Col>
                                </Row>
                              </div>

                              <h5 className="text-primary mt-3">Alternate Contact</h5>
                              <div className="mt-3">
                                <Row className="justify-content-left">
                                  <Col md="6">
                                    <AvGroup>
                                      <Label id="secondaryContactName" for="patient-secondaryContactName">
                                        Alternate Contact Person Name
                                      </Label>
                                      <AvField
                                        id="patient-secondaryContactName"
                                        type="text"
                                        name="secondaryContactName"
                                      />
                                    </AvGroup>
                                  </Col>
                                  <Col md="6">
                                    <AvGroup>
                                      <Label id="secondaryPhoneNumber" for="patient-secondaryPhoneNumber">
                                        Alternate Contact Person Mobile
                                      </Label>
                                      <AvField
                                        id="patient-secondaryPhoneNumber"
                                        type="text"
                                        name="secondaryPhoneNumber"
                                      />
                                    </AvGroup>
                                  </Col>
                                </Row>
                              </div>

                              <h5 className="text-primary mt-3">Additional Details</h5>
                              <div className="mt-3">
                                <Row className="justify-content-left">
                                  {/* loop through enrollment multi select entity details */}
                                  {/* to display button group with two buttons for Yes and No for each outcome */}
                                  {enrollmentMultiSelectEntityDetails.map(val => (
                                    <Col md="12" key={val.id} className="mt-3">
                                      <AvGroup>
                                        <Label id={val.enDescription} for={`patient-${val.enDescription}`}>
                                          {val.enDescription}
                                        </Label>
                                        <ButtonGroup style={{ marginLeft: '30px' }}>
                                          <Button
                                            color={enrollmentMultiSelectEntityIds[val.id] === true ? 'primary' : 'secondary'}
                                            value={enrollmentMultiSelectEntityIds[val.id]}
                                            onClick={() => {
                                              if (enrollmentMultiSelectEntityIds[val.id] !== true) {
                                                setEnrollmentMultiSelectEntityIds({
                                                  ...enrollmentMultiSelectEntityIds,
                                                  [val.id]: true
                                                });
                                              }
                                            }}
                                          >
                                            Yes
                                          </Button>
                                          <Button
                                            color={enrollmentMultiSelectEntityIds[val.id] === false ? 'primary' : 'secondary'}
                                            value={enrollmentMultiSelectEntityIds[val.id]}
                                            onClick={() => {
                                              if (enrollmentMultiSelectEntityIds[val.id] !== false) {
                                                setEnrollmentMultiSelectEntityIds({
                                                  ...enrollmentMultiSelectEntityIds,
                                                  [val.id]: false
                                                });
                                              }
                                            }}
                                          >
                                            No
                                          </Button>

                                        </ButtonGroup>
                                      </AvGroup>
                                    </Col>
                                  ))}

                                  {/* loop through enrollment single select master entities */}
                                  {/* to display dropdown for each outcome */}
                                  {enrollmentMasterEntities
                                    .map(val => (
                                      <Col md="6" key={val.id} className="mt-3">
                                        <AvGroup>
                                          <Label id={val.entityName} for={`patient-${val.entityName}`}>
                                            {val.entityName}
                                          </Label>
                                          <AvInput
                                            id={`patient-${val.entityName}`}
                                            type="select"
                                            className="form-control"
                                            name={val.entityName}
                                            value={enrollmentEntityIds[val.id]}
                                            onChange={(e) => {
                                              setEnrollmentEntityIds({
                                                ...enrollmentEntityIds,
                                                [val.id]: e.target.value
                                              });
                                            }}
                                          >
                                            <option disabled selected value="">-- select an option --</option>
                                            {val.watotoCareEntityDetails
                                              .filter(entity => entity.isActive)
                                              .sort((a, b) => a.displayOrder - b.displayOrder)
                                              .map(entity => (
                                                <option value={entity.id} key={entity.id}>
                                                  {entity.enDescription}
                                                </option>
                                              ))}
                                          </AvInput>
                                        </AvGroup>
                                      </Col>
                                    ))}
                                </Row>
                              </div>
                            </>
                          )
                        }

                        <br />
                        <Button tag={Link} id="cancel-save" to="/patient" replace color="info">
                          <FontAwesomeIcon icon="arrow-left" />
                          &nbsp;
                          <span className="d-none d-md-inline">Back</span>
                        </Button>
                        &nbsp;
                        <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                          <FontAwesomeIcon icon="save" />
                          &nbsp; Save
                        </Button>
                      </>
                    )
                  }
                </>
              )}
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  patientEntity: storeState.patient.entity,
  loading: storeState.patient.loading,
  updating: storeState.patient.updating,
  updateSuccess: storeState.patient.updateSuccess,
  errorMessage: storeState.patient.errorMessage,
  userCountryId: storeState.authentication.userCountryId,
  villages: storeState.village.entities,
  villagesLoading: storeState.village.loading,
  watotoCareEntityList: storeState.watotoCareEntity.masterEntities,
  watotoCareEntitiesByGroup: storeState.watotoCareEntity.masterEntitiesByGroup
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
  resetWatotoCareEntities,
  getVillages,
  getMasterEntitiesByEnumNames,
  getMasterEntitiesByGroupNames
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientUpdate);
