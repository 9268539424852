import axios from 'axios';
import {ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction} from 'react-jhipster';

import {FAILURE, REQUEST, SUCCESS} from 'app/shared/reducers/action-type.util';
import {defaultValue, IUser} from 'app/shared/model/user.model';

export const ACTION_TYPES = {
  FETCH_ROLES: 'userManagement/FETCH_ROLES',
  FETCH_USERS: 'userManagement/FETCH_USERS',
  FETCH_USER: 'userManagement/FETCH_USER',
  FETCH_LOGIN_NAMES: 'userManagement/FETCH_LOGIN_NAMES',
  CREATE_USER: 'userManagement/CREATE_USER',
  UPDATE_USER: 'userManagement/UPDATE_USER',
  DISABLE_USER: 'userManagement/DISABLE_USER',
  DELETE_USER: 'userManagement/DELETE_USER',
  CHANGE_USER_PASSWORD: 'userManagement/CHANGE_USER_PASSWORD',
  RESET: 'userManagement/RESET',
  RESET_MODAL: 'userManagement/RESET_MODAL',
};

const initialState = {
  loading: false,
  errorMessage: null,
  users: [] as ReadonlyArray<IUser>,
  roles: [] as any[],
  loginNames: [] as string[],
  user: defaultValue,
  updating: false,
  updateSuccess: false,
  changePasswordSuccess: false,
  deleteFailure: false,
  totalItems: 0,
};

export type UserManagementState = Readonly<typeof initialState>;

// Reducer
export default (state: UserManagementState = initialState, action): UserManagementState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ROLES):
      return {
        ...state,
      };
    case REQUEST(ACTION_TYPES.FETCH_LOGIN_NAMES):
      return {
        ...state,
      };
    case REQUEST(ACTION_TYPES.FETCH_USERS):
    case REQUEST(ACTION_TYPES.FETCH_USER):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        changePasswordSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_USER):
    case REQUEST(ACTION_TYPES.UPDATE_USER):
    case REQUEST(ACTION_TYPES.DISABLE_USER):
    case REQUEST(ACTION_TYPES.DELETE_USER):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case REQUEST(ACTION_TYPES.CHANGE_USER_PASSWORD):
      return {
        ...state,
        errorMessage: null,
        changePasswordSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_USERS):
    case FAILURE(ACTION_TYPES.FETCH_USER):
    case FAILURE(ACTION_TYPES.FETCH_ROLES):
    case FAILURE(ACTION_TYPES.FETCH_LOGIN_NAMES):
    case FAILURE(ACTION_TYPES.CREATE_USER):
    case FAILURE(ACTION_TYPES.UPDATE_USER):
    case FAILURE(ACTION_TYPES.DISABLE_USER):
    case FAILURE(ACTION_TYPES.CHANGE_USER_PASSWORD):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        changePasswordSuccess: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.DELETE_USER):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        changePasswordSuccess: false,
        errorMessage: action.payload,
        deleteFailure: true,
      };
    case SUCCESS(ACTION_TYPES.FETCH_ROLES):
      return {
        ...state,
        roles: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_LOGIN_NAMES):
      return {
        ...state,
        loginNames: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_USERS):
      return {
        ...state,
        loading: false,
        users: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_USER):
      return {
        ...state,
        loading: false,
        user: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_USER):
    case SUCCESS(ACTION_TYPES.UPDATE_USER):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        user: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CHANGE_USER_PASSWORD):
      return {
        ...state,
        updating: false,
        changePasswordSuccess: true,
      };
    case SUCCESS(ACTION_TYPES.DISABLE_USER):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        user: defaultValue,
      };
    case SUCCESS(ACTION_TYPES.DELETE_USER):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        user: defaultValue,
        deleteFailure: false
      };
    case ACTION_TYPES.RESET_MODAL:
      return {
        ...state,
        deleteFailure: false
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/users';
// Actions
export const getUsers: ICrudGetAllAction<IUser> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_USERS,
    payload: axios.get<IUser>(requestUrl),
  };
};

export const getUsersByName: ICrudGetAllAction<IUser> = (username) => {
  const requestUrl = `api/search/search-user-by-name/${username}`;
  return {
    type: ACTION_TYPES.FETCH_USERS,
    payload: axios.get<IUser>(requestUrl),
  };
};

export const getRoles = () => ({
  type: ACTION_TYPES.FETCH_ROLES,
  payload: axios.get(`${apiUrl}/roles`),
});

export const getUser: ICrudGetAction<IUser> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_USER,
    payload: axios.get<IUser>(requestUrl),
  };
};

export const getUserLogins = () => {
  const requestUrl = `${apiUrl}/loginNames`;
  return {
    type: ACTION_TYPES.FETCH_LOGIN_NAMES,
    payload: axios.get<string []>(requestUrl)
  }
}

export const changeUserPassword = (login, newPassword) => {
  const requestUrl = `${apiUrl}/${login}/change-password`;
  return {
    type: ACTION_TYPES.CHANGE_USER_PASSWORD,
    payload: axios.post(requestUrl, { newPassword }),
    meta: {
      successMessage: 'Password changed!',
      errorMessage: 'An error has occurred! The password could not be changed.',
    },
  };
};

export const createUser: ICrudPutAction<IUser> = user => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_USER,
    payload: axios.post(apiUrl, user),
  });
  dispatch(getUsers());
  return result;
};

export const updateUser: ICrudPutAction<IUser> = user => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_USER,
    payload: axios.put(apiUrl, user),
  });
  dispatch(getUsers());
  return result;
};

export const disableUser: ICrudDeleteAction<IUser> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}/disable`;
  const result = await dispatch({
    type: ACTION_TYPES.DISABLE_USER,
    payload: axios.post(requestUrl),
  });
  dispatch(getUsers());
  return result;
};

export const deleteUser: ICrudDeleteAction<IUser> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_USER,
    payload: axios.delete(requestUrl),
  });
  dispatch(getUsers());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const resetModal = () => ({
  type: ACTION_TYPES.RESET_MODAL
})
