import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, ButtonGroup, Col, Label, Row} from 'reactstrap';
import {AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';
import {getEntities as getHealthcareFacilities} from 'app/entities/healthcare-facility/healthcare-facility.reducer';
import {createEntity, getEntity, reset, updateEntity} from './ambulance.reducer';
import {getAllByType as getCountries} from "app/entities/region/region.reducer";
import {getEntities as getVehicleTypes} from 'app/entities/vehicleType/vehicleType.reducer';
import {IRegion} from "app/shared/model/region.model";
import {hasAnyPermission} from 'app/shared/auth/permissions';
import VillageAvTypeahead from "app/shared/layout/menus/village-av-typeahead";

export interface IAmbulanceUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const AmbulanceUpdate = (props: IAmbulanceUpdateProps) => {
  const [healthcareFacilityId, setHealthcareFacilityId] = useState('0');
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [activeCountry, setActiveCountry] = useState<IRegion>({})
  const [ambulanceCountry, setAmbulanceCountry] = useState<IRegion>({})
  const [healthcareFacilitiesByCountry, setHealthcareFacilitiesByCountry] = useState([])

  const [activeStatus, setActiveStatus] = useState(true);
  const {ambulanceEntity, healthcareFacilities, loading, updating, vehicleTypes} = props;
  const [vehicleTypeList, setVehicleTypeList] = useState([]);

  const filterHealthcareFacilityByCountry = (countryId: string) => {
    const healthcareFacilities1 = props.healthcareFacilities.filter(hf => hf?.council?.parent?.parent?.parent?.id === countryId);
    const healthcareFacNames = healthcareFacilities1.map((item: any) => ({
      short: item.name,
      full: (item.name + ' / ' + item?.council?.name + ' / ' + item?.council?.parent?.name),
      id: item.id
    }));
    setHealthcareFacilitiesByCountry(healthcareFacNames)
    // setHealthcareFacilitiesByCountry(healthcareFacilities1)
  }
  const handleClose = () => {
    props.history.push('/ambulance' + props.location.search);
  };

  const onCountryChange = (event) => {
    const countryId = event.target.value
    const newActiveCountry = props.countries.find(country => country.id === countryId)
    setActiveCountry(newActiveCountry);
    const filteredTypes = vehicleTypes.filter((item: any) => item.countryId === countryId)
    setVehicleTypeList(filteredTypes);
  }
  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getHealthcareFacilities();
    props.getCountries();
    props.getVehicleTypes();
  }, []);

  useEffect(() => {
    if (isNew) {
      const ac = props.countries.find(country => country.id === (props.isAdmin ? props.countries[0].id : props.userCountryId));
      setActiveCountry(ac)
    }
  }, [props.countries, props.healthcareFacilities]);

  useEffect(() => {
    if (vehicleTypes?.length > 0) {
      const filteredTypes = vehicleTypes.filter((item: any) => item.countryId === activeCountry.id)
      setVehicleTypeList(filteredTypes);
    }
  }, [vehicleTypes, activeCountry]);

  useEffect(() => {
    filterHealthcareFacilityByCountry(activeCountry?.id)
  }, [props.healthcareFacilities, activeCountry]);

  useEffect(() => {
    const ambCountry = props.healthcareFacilities.find(hf => hf.id === ambulanceEntity.healthcareFacilityId)?.council?.parent?.parent?.parent;
    const ambulanceStatus = ambulanceEntity?.active
    setActiveStatus(ambulanceStatus)
    if (ambCountry) {
      setActiveCountry(ambCountry)
      setAmbulanceCountry(ambCountry)
    }
  }, [props.ambulanceEntity]);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...ambulanceEntity,
        ...values,
        active: activeStatus,
        vehicleType: vehicleTypes.find(item => item.id === values.vehicleType),
        driverType: "CAR"
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  const healthCareFacilityList = (includeEmpty = false) => {
    let result = (includeEmpty) ? ([{short: '', full: '', id: undefined}]) : []
    result = result.concat(healthcareFacilitiesByCountry);
    return result
  }

  const getHealthcareFacilityName = (id: string) => {
    const hfName = healthcareFacilitiesByCountry?.find(item => item.id === id);
    return hfName?.full
  }

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="watotocareApp.ambulance.home.createOrEditLabel">Create or edit a Ambulance</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : ambulanceEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="ambulance-id">ID</Label>
                  <AvInput id="ambulance-id" type="text" className="form-control" name="id" required readOnly/>
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="lastNameLabel" for="driver-lastName">
                  Active Status
                </Label>
                <ButtonGroup style={{marginLeft: "30px"}}>

                  <Button
                    color={activeStatus ? 'primary' : 'secondary'}
                    onClick={(e) => {
                      setActiveStatus(true)
                    }}
                  >
                    Active
                  </Button>


                  <Button
                    color={!activeStatus ? 'primary' : 'secondary'}
                    onClick={() => {
                      setActiveStatus(false)
                    }}
                  >
                    Inactive
                  </Button>

                </ButtonGroup>
              </AvGroup>

              {props.countries && props.countries.length > 0 && (
                <AvGroup>
                  <Label for="hf-country">Country</Label>
                  <AvInput id="hf-country" type="select" className="form-control" name="country"
                           disabled={isNew ? false : true}
                           value={isNew ? activeCountry?.id : ambulanceCountry?.id} onChange={onCountryChange}>
                    {props.countries
                      && (props.isAdmin ? props.countries.map(country => (
                        <option value={country.id} key={country.id}>
                          {country.name}
                        </option>
                      )) : props.countries.map(country => (
                        props.userCountryId === country.id && <option value={country.id} key={country.id}>
                          {country.name}
                        </option>
                      )))}
                  </AvInput>
                </AvGroup>
              )
              }

              <AvGroup>
                <Label id="driverFirstNameLabel" for="ambulance-driverFirstName">
                  Driver First Name
                </Label>
                <AvField
                  id="ambulance-driverFirstName"
                  type="text"
                  name="driverFirstName"
                  validate={{
                    required: {value: true, errorMessage: 'This field is required.'},
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="driverLastNameLabel" for="ambulance-driverLastName">
                  Driver Last Name
                </Label>
                <AvField
                  id="ambulance-driverLastName"
                  type="text"
                  name="driverLastName"
                  validate={{
                    required: {value: true, errorMessage: 'This field is required.'},
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="vehicleTypeLabel" for="ambulance-vehicleType">
                  Vehicle Type
                </Label>
                <AvInput
                  id="ambulance-vehicleType"
                  type="select"
                  className="form-control"
                  name="vehicleType"
                  value={(!isNew && ambulanceEntity.vehicleType?.id) || vehicleTypeList[0]?.id}
                >
                  {vehicleTypeList.map((item: any, index) => (
                    <option value={item.id} key={index}>{item.enType}</option>
                  ))}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="phoneNumberLabel" for="ambulance-phoneNumber">
                  Phone Number
                </Label>
                <AvField
                  id="ambulance-phoneNumber"
                  type="text"
                  name="phoneNumber"
                  validate={{
                    required: {value: true, errorMessage: 'This field is required.'},
                  }}
                />
              </AvGroup>

              <AvGroup>
                <Label id="ownerphone_number_2" for="driver-ownerPhoneNumber">
                  Phone Number 2
                </Label>
                <AvField
                  id="ambulance-phone_number_2"
                  type="text"
                  name="phone_number_2"
                />
              </AvGroup>

              <AvGroup>
                <Label id="ownerphone_number_3" for="driver-ownerPhoneNumber">
                  Phone Number 3
                </Label>
                <AvField
                  id="ambulance-phone_number_3"
                  type="text"
                  name="phone_number_3"
                />
              </AvGroup>

              <AvGroup>
                <Label for="ambulance-healthcareFacility">Healthcare Facility</Label>
                {/* <AvInput id="ambulance-healthcareFacility" type="select" className="form-control"
                         name="healthcareFacilityId">
                  <option value="" key="0"/>
                  {healthcareFacilitiesByCountry
                    ? healthcareFacilitiesByCountry.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.name}>
                        {otherEntity.name}
                      </option>
                    ))
                    : null}
                </AvInput> */}
                <VillageAvTypeahead
                  labelKey="name"
                  valueKey="id"
                  data={healthCareFacilityList(false)}
                  placeholder={isNew ? "Healthcare Facility" : getHealthcareFacilityName(ambulanceEntity?.healthcareFacilityId)}
                  id="ambulance-healthcareFacility"
                  loading={false}
                  input={{
                    id: "ambulance-healthcareFacility",
                    name: "healthcareFacilityId",
                    required: true
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="mpesaNumberLabel" for="driver-mpesaNumber">
                  M-PESA Number
                </Label>
                <AvField
                  id="driver-mpesaNumber"
                  type="text"
                  name="mpesaNumber"
                  validate={{
                    required: {value: true, errorMessage: 'This field is required.'},
                  }}
                />
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/ambulance" replace color="info">
                <FontAwesomeIcon icon="arrow-left"/>
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save"/>
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  healthcareFacilities: storeState.healthcareFacility.entities,
  ambulanceEntity: storeState.ambulance.entity,
  loading: storeState.ambulance.loading,
  updating: storeState.ambulance.updating,
  updateSuccess: storeState.ambulance.updateSuccess,
  countries: storeState.region.countries,
  userCountryId: storeState.authentication.userCountryId,
  isAdmin: hasAnyPermission(storeState.authentication.account.roles, ['ADMIN']),
  vehicleTypes: storeState.vehicleType.entities
});

const mapDispatchToProps = {
  getHealthcareFacilities,
  getEntity,
  updateEntity,
  createEntity,
  reset,
  getCountries,
  getVehicleTypes
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AmbulanceUpdate);
