import { IRegion } from 'app/shared/model/region.model';

export interface IContinent {
  id?: string;
  name?: string;
  latitude?: number;
  longitude?: number;
  description?: string;
  regions?: IRegion[];
}

export const defaultValue: Readonly<IContinent> = {};
