import {IAmbulance} from 'app/shared/model/ambulance.model';
import {IRegion} from "app/shared/model/region.model";

export interface IHealthcareFacility {
  id?: string;
  name?: string;
  type?: string;
  level?: number;
  facilityCode?: string;
  contactName?: string;
  phoneNumber?: string;
  locationLat?: number;
  locationLon?: number;
  ambulances?: IAmbulance[];
  council?: IRegion;
  phone_number_2?: string;
  phone_number_3?: string;
  activated?: boolean;
  isNcu?: boolean;
}

export const defaultValue: Readonly<IHealthcareFacility> = {};
