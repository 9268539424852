import { ICouncil } from 'app/shared/model/council.model';
import { ICountry } from 'app/shared/model/country.model';

export interface IDistrict {
  id?: string;
  name?: string;
  councils?: ICouncil[];
  country?: ICountry;
}

export const defaultValue: Readonly<IDistrict> = {};
