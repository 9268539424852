import {EmergencyType} from 'app/shared/model/enumerations/emergency-type.model';
import {IRegion} from "app/shared/model/region.model";

export interface ISymptom {
  id?: string;
  emergencyType?: EmergencyType;
  enCondition?: string;
  stCondition?: string;
  requiredFacilityLevel?: number;
  requiredVehicleLevel?: number;
  riskProfile?: string;
  country?: IRegion;
  isStatic?: boolean;
  isActive?: boolean;
}

export const defaultValue: Readonly<ISymptom> = {};
