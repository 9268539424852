import React from 'react';
import {Switch} from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import PatientOutcomeUpdate from './patient-outcome-update';

const Routes = ({match}) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact  key="patient-outcome-add" path={`${match.url}/create`} component={PatientOutcomeUpdate}/>
    </Switch>
  </>
);

export default Routes;
