import React from 'react';
import {Redirect, Switch} from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Route from './route';
import Symptom from './symptom';
import HealthcareFacility from './healthcare-facility';
import HealthcareFacilityLevel from './healthcare-facility-level';
import Driver from './driver';
import Village from './village';
import Payment from './payment';
import Ambulance from './ambulance';
import Region from './region';
import DspatchCenter from './dispatchCenter';
import VillageRoutes from './village-route'
import GeofenceRoutes from './geofence-route'
import VehicleType from './vehicleType';
import {IRootState} from 'app/shared/reducers';
import {connect} from 'react-redux';
import Patient from "app/entities/patient";
import PatientBaby from "app/entities/patient-baby";
import PatientVisit from "app/entities/patient-visit";
import WatotoCareEntity from "app/entities/watotocare-entity";
import PatientOutcome from 'app/entities/patient-outcome';
import Physician from 'app/entities/physician';
import Journey from 'app/entities/journey';


// eslint-disable-next-line complexity
const Routes = ({ match, permissionSettings, userRoles }) => {

  const array = [];
  const finalPermissions = permissionSettings
                            .filter((item:any) => item.role === userRoles[0])
                            .map((item:any) => {return {[item.role] : item.permissions}});

  finalPermissions.map((item: any) => {
      array.push(...Object.values(item))
  })

  console.log("ALL PERMISSIONS>>", userRoles, permissionSettings, finalPermissions , array[0]?.includes("ROUTES_VIEW"));

  return (
  <div>
    <Switch>
      {(array[0]?.includes("AMBULANCE_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}ambulance`} component={Ambulance} /> }
      {(array[0]?.includes("ENTITIES_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}driver`} component={Driver} />}
      {(array[0]?.includes("ENTITIES_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}healthcare-facility`} component={HealthcareFacility} />}
      {(array[0]?.includes("ENTITIES_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}healthcare-facility-level`} component={HealthcareFacilityLevel} />}
      {(array[0]?.includes("PATIENT_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}patient`} component={Patient} />}
      {(array[0]?.includes("PATIENT_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}patient-baby`} component={PatientBaby} />}
      {(array[0]?.includes("PATIENT_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}patient-visit`} component={PatientVisit} />}
      {(array[0]?.includes("PAYMENTS_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}payment`} component={Payment} />}
      {(array[0]?.includes("ROUTES_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}route`} component={Route} />}
      {(array[0]?.includes("ENTITIES_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}symptom`} component={Symptom} />}
      {(array[0]?.includes("ENTITIES_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}village`} component={Village} />}
      {(array[0]?.includes("REGION_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}region`} component={Region} />}
      {(array[0]?.includes("DISPATCH_CENTER_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}dispatch-center`} component={DspatchCenter} />}
      {(array[0]?.includes("ROUTES_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}village-routes`} component={VillageRoutes} />}
      {(array[0]?.includes("ROUTES_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}geofence-routes`} component={GeofenceRoutes} />}
      {(array[0]?.includes("ENTITIES_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}watotocare-entity`} component={WatotoCareEntity} />}
      {(array[0]?.includes("PATIENT_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}patient-outcome`} component={PatientOutcome} />}
      {(array[0]?.includes("ENTITIES_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}physician`} component={Physician} />}
      {(array[0]?.includes("ENTITIES_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}vehicle-type`} component={VehicleType} />}
      {(array[0]?.includes("ENTITIES_VIEW") || userRoles[0] === "ADMIN") && <ErrorBoundaryRoute path={`${match.url}journey`} component={Journey} />}
      <Redirect to={{pathname: "/"}} />
    </Switch>
  </div>
)};

const mapStateToProps = (storeState: IRootState) => ({
  permissionSettings: storeState.permissionSettings.settings,
  userRoles: storeState.authentication.account.roles
});

export default connect(mapStateToProps)(Routes);
