import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { setFilterOption, setResetFilterOption } from "../child-sub-menu.reducer";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import "./selectComp.scss";

export const dropdownButtonLabel = {
    fontSize: "10px",
    minWidth: "55px",
    maxWidth: "55px"
}

const SelectComp = (props) => {
    const { filterValues, filterSlug, selectedFilterOption, resetFilterOption } = props
    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        // Set resetFilterOption as false next time when any option is selected
        if (resetFilterOption && selectedOptions.length > 0) {
            props.setResetFilterOption(false);
        }

        let newFilterOptions;
        if ((selectedOptions.length > 0 && selectedOptions.length === filterValues.length + 1) || !selectedOptions.length) {
            newFilterOptions = {...selectedFilterOption};
            delete newFilterOptions[filterSlug];
        } else {
            newFilterOptions = {...selectedFilterOption, [filterSlug]: selectedOptions};
        }
        props.setFilterOption(newFilterOptions);
    }, [selectedOptions]);

    useEffect(() => {
        // filter specific code
        if(filterSlug === 'healthFacility'){
            if (selectedFilterOption?.district?.length > 0 && selectedFilterOption?.healthFacility?.length > 0) {
                const districtIds = selectedFilterOption.district.map(d => d.value.id);
                const newSelectedOptions = selectedOptions.filter(hf => districtIds.includes(hf.value.council.parent.id));
                if(newSelectedOptions.length < selectedOptions.length){
                    setSelectedOptions(newSelectedOptions);
                }
            }
        }
    }, [selectedFilterOption]);

    useEffect(() => {
        if (resetFilterOption) {
            setSelectedOptions([]);
        }
    }, [resetFilterOption])


    function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
        if (value && value.some((o) => o.value === "*")) {
            return <div style={dropdownButtonLabel}>All Selected</div>
        } else if (!value.length) {
            return <div style={dropdownButtonLabel}>All Selected</div>
        } else {
            return <div style={dropdownButtonLabel}>{`${value.length} selected`}</div>;
        }
    }

    function onChange(value, event) {
        if (event.action === "select-option" && event.option.value === "*") {
            this.setState(this.options);
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            this.setState([]);
        } else if (event.action === "deselect-option") {
            this.setState(value.filter((o) => o.value !== "*"));
        } else if (value.length === this.options.length - 1) {
            this.setState(this.options);
        } else {
            this.setState(value);
        }
    }

    const customStyles = {
        dropdownButton: (provided) => ({
            ...provided,
            background: "#29394b",
            color: "#ffffff",
            border: "1px solid #ffffff",
            height: "30px",
        }),
        control: (provided) => ({
            ...provided,
            border: '1px solid #ffffff',
            borderRadius: '0',
            minHeight: '1px',
            height: '28px',
            minWidth: "80px",
            maxWidth: "auto",
            background: "#29394b",
        }),
        container: (provided) => ({
            ...provided,
            display: 'inline-block',
            minHeight: '1px',
            textAlign: 'left',
            color: "#ffffff",
            fontSize: "10px",
            minWidth: "auto",
            maxWidth: "126px",
            background: "#29394b !important",
            border: "1px solid #ffffff",
        }),
        input: (provided) => ({
            ...provided,
            minHeight: '1px',
            color: "#ffffff",
        }),
        option: (provided) => ({
            ...provided,
            padding: "4px 6px",
            minWidth: "auto",
            fontSize: "12px",
            whiteSpace: "nowrap",
            ':hover': {
                backgroundColor: "#ffffff",
                color: "#000000"
            }
        }),
    };

    return (
        <div className="multi-select-checkboxes">
            <ReactMultiSelectCheckboxes
                className="react-select-container"
                classNamePrefix="react-select"
                styles={customStyles}
                options={[{ label: "All", value: "*" }, ...filterValues]}
                getDropdownButtonLabel={getDropdownButtonLabel}
                value={selectedOptions}
                onChange={onChange}
                setState={setSelectedOptions}
            />
        </div>
    )
}
const mapStateToProps = ({ filterOption }: IRootState) => ({
    selectedFilterOption: filterOption.selectedFilterOption,
    resetFilterOption: filterOption.resetFilterOption,
});

const mapDispatchToProps = {
    setFilterOption,
    setResetFilterOption
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SelectComp);
