import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { setFilterOption, setResetFilterOption, setApplyFilterOption } from "../subMenu/childSubMenu/child-sub-menu.reducer";
import { Collapse, NavItem, NavLink } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChildSubMenu from "./childSubMenu/childSubMenu";
import 'react-datepicker/dist/react-datepicker.css';
import "./subMenu.scss";

const SubMenu = (props) => {
  const { icon, title, optionsFilters, optionsFiltersCategories, selectedFilterOption } = props;

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const toggle = () => setCollapsed(!collapsed);

  return (
    <div className="sub-menu-container">
      <div className="dropdown-toggle filter-section">
        <div onClick={toggle}>
          <NavLink style={{ fontSize: "18px" }} >
            <FontAwesomeIcon icon={icon} className="mr-2" />
            {title}
          </NavLink>
        </div>
        <div className="filter-btn-container">
          <button onClick={(e) => props.setApplyFilterOption(true)} className="filter-btn">Apply</button>
          <button onClick={(e) => props.setResetFilterOption(true)} className="filter-btn">Clear</button>
        </div>
      </div>
      <Collapse
        isOpen={!collapsed}
        navbar
        className="collapse-section mt-2 mb-3"
      >
        {optionsFiltersCategories.map((category, index) => (
          <div key={index} className="sub-menu-nav-section px-2" style={{ fontSize: "15px" }}>
            <ChildSubMenu title={category} optionsFilters={optionsFilters} />
          </div>
        ))}
      </Collapse>
    </div>
  );
};

const mapStateToProps = ({ filterOption }: IRootState) => ({
  selectedFilterOption: filterOption.selectedFilterOption,
  resetFilterOption: filterOption.resetFilterOption,
});

const mapDispatchToProps = {
  setFilterOption,
  setResetFilterOption,
  setApplyFilterOption,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SubMenu);
