import React from 'react';
import {Switch} from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import GeofenceRoute from './geofence-route';
import GeofenceRouteDetail from './geofence-route-detail';
import CreateRoute from './create-route';
import RouteDeleteDialog from './route-delete-dialogue';
const GeofenceRoutes = ({ match }) => {
  return (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/route-detail/:id`} component={GeofenceRouteDetail} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={RouteDeleteDialog} />
      <ErrorBoundaryRoute exact path={`${match.url}/create-route`} component={CreateRoute} />
      <ErrorBoundaryRoute path={match.url} component={GeofenceRoute} />
    </Switch>
  </>
)};

export default GeofenceRoutes;
