import { IPatientCase } from 'app/shared/model/patient-case.model';
import { IVillage } from 'app/shared/model/village.model';
import { IPatientBaby } from 'app/shared/model/patient-baby.model';
import { IWatotoCareEntityDetail } from 'app/shared/model/watotocare-entity-detail.model';

export interface IPatient {
  id?: string;
  wcId?: string;
  rchCardNumber?: string;
  countryId?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  secondaryContactName?: string;
  secondaryPhoneNumber?: string;
  nearbyLandmarks?: string;
  caregiverName?: string;
  caregiverPhoneNumber?: string;
  nidaNumber?: string;
  birthDate?: string;
  ageRange?: string;
  ageRangeEntity?: IWatotoCareEntityDetail;
  noConsentReason?: string;
  noConsentReasonEntity?: IWatotoCareEntityDetail;
  noConsentComment?: string;
  ineligibilityReason?: string;
  ineligibilityReasonEntity?: IWatotoCareEntityDetail;
  ineligibilityComment?: string;
  parity?: number;
  patientCases?: IPatientCase[];
  patientBabies?: IPatientBaby[];
  consent?: boolean;
  eligible?: boolean;
  villageId?: string;
  village?: IVillage;
  villageNotes?: string;
  createdAt?: Date;
  patientEnrollmentEntities?: {
    patientId?: string;
    entityDetailId?: string;
    entityDetail?: IWatotoCareEntityDetail;
  }[];
}

export const defaultValue: Readonly<IPatient> = {};
