import React, {useEffect, useState} from 'react'
import {DisplayCardGroup} from './displayCardGroup';
import {DisplayIndicator} from './displayCardIndicator';
import {BarChart, LineChart, StackedBarChart} from '../../../charts';
import {PRIMARY_CHART_COLOR, SECONDARY_CHART_COLOR, MONTH_LABELS} from '../constants';
import value from "*.json";
import {mergeAndSum} from "app/modules/programDashboard/utils";

export const PaymentSection = ({caseManagementData}) => {

    const [avgCostPerTripLabels, setAvgCostPerTripLabels] = useState([]);
    const [avgCostPerTripData, setAvgCostPerTripData] = useState([]);
    const [totalCostPerDistrict, setTotalCostPerDistrict] = useState([]);
    const [referralsPerPaymentStatus, setReferralsPerPaymentStatus] = useState([]);

    useEffect(() => {
        if (caseManagementData?.totalEmergencies && Object.keys(caseManagementData?.totalEmergencies)) {

            // Average cost per trip
            const avgCostData = [];
            const avgCostKeys = Object.keys(caseManagementData?.avgCostPertrip)
                .sort(function (a, b) {
                    return caseManagementData?.avgCostPertrip[b] - caseManagementData?.avgCostPertrip[a]
                });
            for (let i = 0; i < avgCostKeys.length; i++) {
                avgCostData.push(caseManagementData?.avgCostPertrip[avgCostKeys[i]] ? caseManagementData?.avgCostPertrip[avgCostKeys[i]] / 100 : 0)
            }
            setAvgCostPerTripLabels(avgCostKeys);
            setAvgCostPerTripData(avgCostData);

            // Total cumulative cost
            setTotalCostPerDistrict(
                Object.keys(caseManagementData?.totalCostPerDistrict)
                    .map((el, i) => {
                        return {label: `${el}`, data: caseManagementData?.totalCostPerDistrict[el] / 100};
                    })
                    .sort((a, b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0)
            );

            // Payments by status
            setReferralsPerPaymentStatus(
                Object.keys(caseManagementData?.referralsPerPaymentStatus)
                    .map((el, i) => {
                        return {label: `${el}`, data: caseManagementData?.referralsPerPaymentStatus[el]};
                    })
                    .sort((a, b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0)
            );
        }
    }, [caseManagementData])

    return (
        <div className='mt-0 mb-3'>
            <p className="mb-2" style={{fontSize: "18px", fontWeight: 600, color: SECONDARY_CHART_COLOR}}>4. Payment</p>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{label: "4.1 Average cost per trip", value: ""}}>
                        <BarChart chartLabel="Average cost per trip"
                                  labels={avgCostPerTripLabels}
                                  dataSet={avgCostPerTripData}
                                  bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} axis='x' flag={true}/>
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{label: "4.2 Total cumulative cost per district", value: ""}}>
                        <BarChart chartLabel="Total cumulative cost per district"
                                  labels={totalCostPerDistrict.map(item => item.label)}
                                  dataSet={totalCostPerDistrict.map(item => item.data)}
                                  bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} axis='x' flag={true}/>
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{label: "4.3 Number of payments by payment status", value: ""}}>
                        <BarChart chartLabel="Number of payments by payment status"
                                  labels={referralsPerPaymentStatus.map(item => item.label)}
                                  dataSet={referralsPerPaymentStatus.map(item => item.data)}
                                  bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} axis='x' flag={true}/>
                    </DisplayIndicator>
                </div>
            </div>
        </div>
    )
}
