import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, ButtonGroup, Col, Label, Row } from 'reactstrap';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntity, reset as resetPatientBaby } from './patient-baby.reducer';
import {
  reset as resetPatientCase,
  updateEntity as updatePatientCase
} from 'app/entities/patient-case/patient-case.reducer';
import { getEntity as getPatient, reset as resetPatient } from 'app/entities/patient/patient.reducer';
import { IPatientCase } from 'app/shared/model/patient-case.model';
import { IWatotoCareEntityDetail } from 'app/shared/model/watotocare-entity-detail.model';
import { getMasterEntitiesByEnumNames } from 'app/entities/watotocare-entity/watotocare-entity.reducer';
import { convertDateTimeFromServer, convertDateTimeToServer, daysSince } from 'app/shared/util/date-utils';

export interface IPatientBabyDischargeProps extends StateProps, DispatchProps, RouteComponentProps<{
  parentId: string,
  id: string
}> {
}

export const PatientBabyDischarge = (props: IPatientBabyDischargeProps) => {
  const [isNew, setIsNew] = useState(null);
  const { patientBabyEntity, patientEntity, watotoCareEntityList, loading, updating } = props;
  const [patientCase, setPatientCase] = useState({} as IPatientCase);
  const [aliveStatus, setAliveStatus] = useState(null);
  const [babyAliveStatus, setBabyAliveStatus] = useState(null);
  const [hivStatus, setHivStatus] = useState(null);
  const [educationStatus, setEducationStatus] = useState(null);
  const [dischargeMaterialStatus, setDischargeMaterialStatus] = useState(null);
  const [babyDischargeConditionEntityDetails, setBabyDischargeConditionEntityDetails] = useState([] as IWatotoCareEntityDetail[]);
  const WATOTOCARE_ENTITY_ENUM_NAMES = ['BABY_DISCHARGE_CONDITION'];

  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  useEffect(() => {
    if (patientBabyEntity.patientId) {
      props.getPatient(patientBabyEntity.patientId);
    }
    if (patientBabyEntity.patientCase) {
      if (patientBabyEntity.patientCase.babyDischargedAt) {
        setIsNew(false);
      } else {
        setIsNew(true);
      }
      setPatientCase(patientBabyEntity.patientCase);
      setHivStatus(patientBabyEntity.patientCase.patientHasHiv);
      setAliveStatus(patientBabyEntity.patientCase.patientIsAlive);
      setBabyAliveStatus(patientBabyEntity.patientCase.patientBabyIsAlive);
      setEducationStatus(patientBabyEntity.patientCase.patientDischargeEducationProvided);
      setDischargeMaterialStatus(patientBabyEntity.patientCase.patientDischargeMaterialProvided);
    }
  }, [patientBabyEntity]);

  useEffect(() => {
    if (patientEntity.id) {
      props.getMasterEntitiesByEnumNames(WATOTOCARE_ENTITY_ENUM_NAMES, patientEntity.countryId ? patientEntity.countryId : props.userCountryId);
    }
  }, [patientEntity]);

  useEffect(() => {
    if (watotoCareEntityList.length > 0) {
      const babyDischargeConditionEntity = watotoCareEntityList.find(entity => entity.enumName === 'BABY_DISCHARGE_CONDITION');
      if (babyDischargeConditionEntity) {
        setBabyDischargeConditionEntityDetails(babyDischargeConditionEntity.watotoCareEntityDetails
          .filter(entity => entity.isActive)
          .sort((a, b) => a.displayOrder - b.displayOrder));
      }
    }
  }, [watotoCareEntityList]);


  useEffect(() => {
    if (props.updateSuccess) {
      if (isNew) {
        props.history.push(`/patient-visit/create?patientBabyId=${patientBabyEntity.id}`);
      } else {
        props.history.push('/patient');
      }
    }
  }, [props.updateSuccess]);


  const saveEntity = (event, errors, values) => {
    if (errors.length > 0) return;

    // convert discharge datetime to UTC
    values.babyDischargedAt = convertDateTimeToServer(values.babyDischargedAt);

    if (errors.length === 0) {
      const entity = {
        ...patientCase,
        ...values,
        patientIsAlive: aliveStatus,
        patientBabyIsAlive: babyAliveStatus,
        patientHasHiv: hivStatus,
        patientDischargeEducationProvided: educationStatus,
        patientDischargeMaterialProvided: dischargeMaterialStatus
      };

      props.updatePatientCase(entity);
    }
  };

  const resetForm = () => {
    props.resetPatient();
    props.resetPatientBaby();
    props.resetPatientCase();
    setPatientCase([] as IPatientCase);
    setAliveStatus(null);
    setBabyAliveStatus(null);
    setHivStatus(null);
    setEducationStatus(null);
    setDischargeMaterialStatus(null);
  };

  useEffect(() => () => {
    resetForm();
  }, []);

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="watotocareApp.patient.baby.discharge.createOrEditLabel">
            {isNew ? <>Add Discharge Details</> : <>Edit Discharge Details</>}
          </h2>
        </Col>
      </Row>
      <br />
      <Row className="justify-content-center">
        <Col md="4">
          <h5 className="text-primary">Mother</h5>
          <div>
            watotoCare ID: {patientEntity.wcId}
            <br />
            Mother Name: {`${patientEntity.firstName} ${patientEntity.lastName}`}
          </div>
        </Col>
        <Col md="4">
          <h5 className="text-primary">Baby</h5>
          <div>
            Date of Birth: {patientBabyEntity.birthDate} (Age in Days: {daysSince(patientBabyEntity.birthDate)})
            <br />
            Baby
            Name: {patientBabyEntity.firstName ? `${patientBabyEntity.firstName} ${patientBabyEntity.lastName}` : '-'}
          </div>
        </Col>
      </Row>
      <br />
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : patientCase} onSubmit={saveEntity}>

              <div className="mt-3">
                <h5 className="text-primary">Mother Discharge Details</h5>
                <br />
                <Row className="justify-content-left">
                  <Col md="12">
                    <AvGroup>
                      <Label id="aliveStatus" for="discharge-aliveStatus">
                        Is Alive?
                      </Label>
                      <ButtonGroup style={{ marginLeft: '30px' }} id="discharge-aliveStatus">
                        <Button
                          color={aliveStatus === true ? 'primary' : 'secondary'}
                          onClick={() => {
                            setAliveStatus(true);
                          }}
                        >
                          Yes
                        </Button>

                        <Button
                          color={aliveStatus === false ? 'primary' : 'secondary'}
                          onClick={() => {
                            setAliveStatus(false);
                          }}
                        >
                          No
                        </Button>
                      </ButtonGroup>
                      <AvField
                        name="aliveStatus"
                        type="hidden"
                        value={aliveStatus === null ? null : aliveStatus.toString()}
                        validate={{
                          required: { value: true, errorMessage: 'This field is required.'}
                        }}
                      />
                    </AvGroup>
                  </Col>
                  <Col md="12" className="mt-3">
                    <AvGroup>
                      <Label id="hivStatus">
                        HIV Status
                      </Label>
                      <ButtonGroup style={{ marginLeft: '30px' }}>
                        <Button
                          color={hivStatus === true ? 'primary' : 'secondary'}
                          onClick={() => {
                            setHivStatus(true);
                          }}
                        >
                          Positive
                        </Button>

                        <Button
                          color={hivStatus === false ? 'primary' : 'secondary'}
                          onClick={() => {
                            setHivStatus(false);
                          }}
                        >
                          Negative
                        </Button>
                      </ButtonGroup>
                      <AvField
                        name="hivStatus"
                        type="hidden"
                        value={hivStatus === null ? null : hivStatus.toString()}
                        validate={{
                          required: { value: true, errorMessage: 'This field is required.'}
                        }}
                      />
                    </AvGroup>
                  </Col>

                  <br />
                  <Col md="12" className="mt-3">
                    <AvGroup>
                      <Label id="educationStatus">
                        Post-Natal Care Education Provided by HCW?
                      </Label>
                      <ButtonGroup style={{ marginLeft: '30px' }}>
                        <Button
                          color={educationStatus === true ? 'primary' : 'secondary'}
                          value={educationStatus}
                          onClick={() => {
                            setEducationStatus(true);
                          }}
                        >
                          Yes
                        </Button>

                        <Button
                          color={educationStatus === false ? 'primary' : 'secondary'}
                          value={educationStatus}
                          onClick={() => {
                            setEducationStatus(false);
                          }}
                        >
                          No
                        </Button>
                      </ButtonGroup>
                      <AvField
                        name="educationStatus"
                        type="hidden"
                        value={educationStatus === null ? null : educationStatus.toString()}
                        validate={{
                          required: { value: true, errorMessage: 'This field is required.' }
                        }}
                      />
                    </AvGroup>
                  </Col>

                  <br />
                  <Col md="12" className="mt-3">
                    <AvGroup>
                      <Label id="dischargeMaterialStatus">
                        Take-home Educational Material Provided?
                      </Label>
                      <ButtonGroup style={{ marginLeft: '30px' }}>
                        <Button
                          color={dischargeMaterialStatus === true ? 'primary' : 'secondary'}
                          value={dischargeMaterialStatus}
                          onClick={() => {
                            setDischargeMaterialStatus(true);
                          }}
                        >
                          Yes
                        </Button>

                        <Button
                          color={dischargeMaterialStatus === false ? 'primary' : 'secondary'}
                          value={dischargeMaterialStatus}
                          onClick={() => {
                            setDischargeMaterialStatus(false);
                          }}
                        >
                          No
                        </Button>
                      </ButtonGroup>
                      <AvField
                        name="dischargeMaterialStatus"
                        type="hidden"
                        value={dischargeMaterialStatus === null ? null : dischargeMaterialStatus.toString()}
                        validate={{
                          required: { value: true, errorMessage: 'This field is required.' }
                        }}
                      />
                    </AvGroup>
                  </Col>

                  <br />
                  <Col md="6" className="mt-3">
                    <AvGroup>
                      <Label id="patientDischargeNotes" for="discharge-patientDischargeNotes">
                        Discharge Notes
                      </Label>
                      <AvField
                        id="discharge-patientDischargeNotes"
                        type="text"
                        name="patientDischargeNotes"
                        value={patientCase.patientDischargeNotes}
                      />
                    </AvGroup>
                  </Col>
                </Row>

                <br />
                <h5 className="text-primary">Baby Discharge Details</h5>
                <br />
                <Row className="justify-content-left">
                  <Col md="12">
                    <AvGroup>
                      <Label id="babyAliveStatus" for="discharge-babyAliveStatus">
                        Is Baby Alive?
                      </Label>
                      <ButtonGroup style={{ marginLeft: '30px' }} id="discharge-babyAliveStatus">
                        <Button
                          color={babyAliveStatus === true ? 'primary' : 'secondary'}
                          onClick={() => {
                            setBabyAliveStatus(true);
                          }}
                        >
                          Yes
                        </Button>

                        <Button
                          color={babyAliveStatus === false ? 'primary' : 'secondary'}
                          onClick={() => {
                            setBabyAliveStatus(false);
                          }}
                        >
                          No
                        </Button>
                      </ButtonGroup>
                      <AvField
                        name="babyAliveStatus"
                        type="hidden"
                        value={babyAliveStatus === null ? null : babyAliveStatus.toString()}
                        validate={{
                          required: { value: true, errorMessage: 'This field is required.' }
                        }}
                      />
                    </AvGroup>
                  </Col>

                  <Col md="6" className="mt-3">
                    <AvGroup>
                      <Label id="babyDischargeCondition" for="discharge-babyDischargeCondition">
                        Discharge Condition
                      </Label>
                      <AvInput
                        id="discharge-babyDischargeCondition"
                        type="select"
                        name="babyDischargeCondition"
                        value={(patientCase.babyDischargeCondition) || ''}
                        validate={{
                          required: { value: true, errorMessage: 'This field is required.' }
                        }}
                      >
                        {<option disabled selected value="">-- select an option --</option>}
                        {babyDischargeConditionEntityDetails.map(val => (
                          <option value={val.id} key={val.id}>
                            {val.enDescription}
                          </option>
                        ))}
                      </AvInput>
                    </AvGroup>
                  </Col>
                  <Col md="6" className="mt-3">
                    <AvGroup>
                      <Label id="dischargeWeight" for="discharge-weight">
                        Discharge Weight (kg)
                      </Label>
                      <AvField
                        id="discharge-weight"
                        type="number"
                        min={0}
                        name="babyDischargeWeight"
                        value={patientCase.babyDischargeWeight}
                      />
                    </AvGroup>
                  </Col>

                  <Col md="6">
                    <AvGroup>
                      <Label id="babyDischargedAt" for="discharge-babyDischargedAt">
                        Date and Time of Discharge
                      </Label>
                      <AvField
                        id="discharge-babyDischargedAt"
                        type="datetime-local"
                        name="babyDischargedAt"
                        value={patientCase.babyDischargedAt ? convertDateTimeFromServer(patientCase.babyDischargedAt) : ''}
                        validate={{
                          required: { value: true, errorMessage: 'This field is required.' }
                        }}
                      />
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <AvGroup>
                      <Label id="babyDischargeNotes" for="discharge-babyDischargeNotes">
                        Discharge Notes
                      </Label>
                      <AvField
                        id="discharge-babyDischargeNotes"
                        type="text"
                        name="babyDischargeNotes"
                        value={patientCase.babyDischargeNotes}
                      />
                    </AvGroup>
                  </Col>
                </Row>
              </div>

              <br />
              <Button tag={Link} id="cancel-save" to="/patient" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  patientBabyEntity: storeState.patientBaby.entity,
  patientEntity: storeState.patient.entity,
  loading: storeState.patientBaby.loading,
  updating: storeState.patientCaseEntity.updating,
  updateSuccess: storeState.patientCaseEntity.updateSuccess,
  watotoCareEntityList: storeState.watotoCareEntity.masterEntities,
  userCountryId: storeState.authentication.userCountryId
});

const mapDispatchToProps = {
  getEntity,
  getPatient,
  updatePatientCase,
  getMasterEntitiesByEnumNames,
  resetPatient,
  resetPatientBaby,
  resetPatientCase
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientBabyDischarge);
