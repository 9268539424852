import {IPatient} from "app/shared/model/patient.model";
import { IPatientCase } from 'app/shared/model/patient-case.model';
import { IWatotoCareEntityDetail } from 'app/shared/model/watotocare-entity-detail.model';

export interface IPatientBaby {
  id?: string;
  patientId?: string;
  patient?: IPatient;
  firstName?: string;
  lastName?: string;
  sex?: string;
  birthWeight?: string;
  gestationTerm?: string;
  gestationTermEntity?: IWatotoCareEntityDetail;
  birthDate?: string;
  birthCertificateNumber?: string;
  parent?: IPatient;
  birthLocation?: string;
  outbornType?: string;
  outbornComment?: string;
  temperature?: number;
  oxygenLevel?: number;
  bloodSugarLevel?: number;
  patientCase?: IPatientCase;
  createdAt?: Date;
  pointOfEnrollment?: string;
  pointOfEnrollmentEntity?: IWatotoCareEntityDetail;
  pointOfEnrollmentComment?: string;
  admissionDate?: Date;
  admissionWeight?: number;
  patientBabyEnrollmentEntities?: {
    patientId?: string;
    entityDetailId?: string;
    entityDetail?: IWatotoCareEntityDetail;
  }[];
}

export const defaultValue: Readonly<IPatientBaby> = {};
