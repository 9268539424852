import { has } from 'lodash';

export const monthsMapping = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sept',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

export const getFormattedMonthFromIndexes = data => {
  //   let data = { 1: 203, 2: 128, 3: 96, 4: 115, 5: 145, 6: 127, 7: 164, 8: 140, 9: 170, 10: 73, 11: 76, 12: 56 };
  const keys = Object.keys(data);
  const values = Object.values(data);
  const labels = [];
  keys.forEach(ele => {
    labels.push(monthsMapping[ele]);
  });
  return [labels, values];
};

export const getTotal = data => {
  const [_, values] = data;
  const total = values.reduce((partialSum, a) => partialSum + a, 0);
  return total;
};

export const getFormattedFilterSubMenuOptions = options => {
  const optionList = [];
  options.forEach((el, i) => {
    const option = { id: i + 1, label: el['name'], value: el };
    optionList.push(option);
  });
  return optionList;
};

export const getYearList = () => {
  const max = new Date().getFullYear();
  const min = max - 9;
  const years = [];

  for (let i = max; i >= min; i--) {
    years.push({ id: i, label: i.toString(), value: i });
  }
  return years;
};

export const getMonthList = () => {
  const months = Object.values(monthsMapping);
  const monthList = [];
  months.forEach((el, i) => {
    monthList.push({ id: i + 1, label: el, value: i + 1 });
  });
  return monthList;
};

export const getFormattedDate = date => {
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1; // Months start at 0!
  let dd = date.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  return yyyy + '-' + mm + '-' + dd;
};

export const get6MonthsDateRange = () => {
  const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 6);
  const today = new Date();

  const formattedStartDate = getFormattedDate(startDate);
  const formattedEndDate = getFormattedDate(today);

  return [formattedStartDate, formattedEndDate];
};

export const isAllSelected = (options, key) => {
  if (options[key][0]['value'] === '*') {
    return true;
  } else {
    return false;
  }
};

export const getSelectedOptions = (options, key) => {
  const optionList = [];
  options[key].forEach((el, i) => {
    const val = el['value'];
    let formattedVal = val;
    if (key !== 'month') {
      formattedVal = val.toString();
    }
    optionList.push(formattedVal);
  });
  return optionList;
};

export const getRequestBodyOptions = (filterOptions, key) => {
  if (has(filterOptions, key)) {
    let requestBody, selectedOptions;
    if (key === 'startDate' || key === 'endDate') {
      requestBody = { [key]: getFormattedDate(filterOptions[key]) };
    } else if (key === 'year') {
      requestBody = { [key]: filterOptions[key]}
    } else if (key === 'district') {
      if (isAllSelected(filterOptions, key)) {
        requestBody = {[key + 'Id']: ['All']}
      } else {
        requestBody = {[key + 'Id']: filterOptions[key].map(district => district.value.id)}
      }
    } else if (key === 'healthFacility') {
      if (isAllSelected(filterOptions, key)) {
        requestBody = {['hfId']: ['All']}
      } else {
        requestBody = {['hfId']: filterOptions[key].map(healthFacility => healthFacility.value.id)}
      }
    } else if (isAllSelected(filterOptions, key)) {
      requestBody = { [key]: ['All'] };
    } else {
      selectedOptions = getSelectedOptions(filterOptions, key);
      requestBody = { [key]: selectedOptions };
    }
    return requestBody;
  } else {
    return undefined;
  }
};

export const unique = array => {
  return array.sort.filter((district, i, arr) => {
    if (i > 0) {
      return arr[i - 1] !== district;
    }
    return true;
  });
}

export const mergeAndSum = data => {
    if(typeof data !== "object"){
      return false;
    }

    const result = {};
    data.forEach(obj => {
      for (const [key, value] of Object.entries(obj)) {
        if (result[key]) {
          result[key] += value;
        } else {
          result[key] = value;
        }
      }
    });

    return result;
}

export const getRequestBody = filterOptions => {
  const year = getRequestBodyOptions(filterOptions, 'year');
  const month = getRequestBodyOptions(filterOptions, 'month');
  const startDate = getRequestBodyOptions(filterOptions, 'startDate');
  const endDate = getRequestBodyOptions(filterOptions, 'endDate');
  const district = getRequestBodyOptions(filterOptions, 'district');
  const healthFacility = getRequestBodyOptions(filterOptions, 'healthFacility');

  const dataSet = { ...year, ...month, ...startDate, ...endDate, ...district, ...healthFacility };
  return dataSet;
};
