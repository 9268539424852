import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import {
  createEntity,
  createVehicleType,
  getEntity,
  reset,
  updateEntity,
  updateVehicleType
} from './vehicleType.reducer';
import { ButtonGroup } from '@material-ui/core';
import { hasAnyPermission } from 'app/shared/auth/permissions';

export interface IVehicleTypeUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const VehicleTypeUpdate = (props: IVehicleTypeUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [countryId, setCountryId] = useState(null);
  const [vehicleImage, setVehicleImage] = useState('');
  const [paymentRequired, setPaymentRequired] = useState(false);

  const { vehicleTypeEntity, defaultEntity, loading, updating, hasUpdateAccess } = props;

  const handleClose = () => {
    props.history.push('/vehicle-type');
  };

  useEffect(() => {
    if (isNew) {
      const params = new URLSearchParams(props.location.search);
      setCountryId(params.get('country_id'));
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    setPaymentRequired(props?.vehicleTypeEntity?.paymentRequired);
  }, [props.vehicleTypeEntity]);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const cid = isNew ? countryId : vehicleTypeEntity.countryId;
      const country = props.countries.find(c => c.id === cid);
      const formdata = new FormData();
      formdata.append('enType', values.enType);
      formdata.append('stType', values.stType);
      formdata.append('countryId', values.country);
      formdata.append('vehicleLevel', values.vehicleLevel);
      formdata.append('paymentRequired', paymentRequired ? 'true' : 'false');
      if (vehicleImage) {
        formdata.append('image', vehicleImage);
      }
      // const entity = {
      //   enType:values.enType,
      //   stType:values.stType,
      //   countryId :values.country,
      //   image: vehicleImage
      // };

      // console.log("entity",entity,values ,"FORMDATA> > ", formdata)

      if (isNew) {
        // props.createEntity(entity);
        props.createVehicleType(formdata);
      } else {
        // props.updateEntity(entity);
        props.updateVehicleType(values.id, formdata);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="watotocareApp.vehicleType.home.createOrEditLabel">Create or edit a Vehicle Type</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? defaultEntity : vehicleTypeEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="vehicleType-id">ID</Label>
                  <AvInput id="vehicleType-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}

              <AvGroup>
                <Label id="enConditionLabel" for="vehicleType-enCondition">
                  Vehicle Type (English)
                </Label>
                <AvField
                  id="vehicleType-enCondition"
                  type="text"
                  name="enType"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="stConditionLabel" for="vehicleType-stCondition">
                  Vehicle Type (Local Language)
                </Label>
                <AvField
                  id="vehicleType-st"
                  type="text"
                  name="stType"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="vehicleLevelLabel" for="vehicleType-vehicleLevel">
                  Vehicle Level
                </Label>
                <AvField
                  id="vehicleType-vehicleLevel"
                  type="number"
                  min="1"
                  name="vehicleLevel"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' }
                  }}
                />
              </AvGroup>

              {props.countries && props.countries.length > 0 && (
                <AvGroup>
                  <Label for="vehicleType-country">Country</Label>
                  <AvInput id="vehicleType-country" type="select" className="form-control" name="country"
                           value={isNew ? countryId : vehicleTypeEntity?.countryId}>
                    <option value={''}>
                      Select country
                    </option>
                    {props.countries
                      ? props.countries.map(country => (
                        <option value={country.id} key={country.id}>
                          {country.name}
                        </option>
                      ))
                      : null}
                  </AvInput>
                </AvGroup>
              )

              }

              <AvGroup>
                <Label id="paymentRequiredLabel" for="vehicleType-payementRequired">
                  Payment Required
                </Label>
                <ButtonGroup style={{ marginLeft: '30px' }}>
                  <Button
                    color={paymentRequired ? 'primary' : 'secondary'}
                    onClick={(e) => {
                      setPaymentRequired(true);
                    }}
                  >
                    True
                  </Button>
                  <Button
                    color={!paymentRequired ? 'primary' : 'secondary'}
                    onClick={() => {
                      setPaymentRequired(false);
                    }}
                  >
                    False
                  </Button>
                </ButtonGroup>
              </AvGroup>


              <AvGroup>
                <Label for="vehicleType-image">Vehicle Type Image</Label>
                <br />
                {vehicleTypeEntity?.image &&
                  <img src={`data:image/svg+xml;base64,${vehicleTypeEntity.image}`} alt="vehicle_image" width={50}
                       height={50} />}
                <br />
                <input id="vehicleType-image" type="file" className="form-contorl" name="image"
                       accept="image/svg+xml"
                       onChange={(e: any) => setVehicleImage(e.target.files[0])}
                />
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/vehicle-type" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating || !hasUpdateAccess}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  vehicleTypeEntity: storeState.vehicleType.entity,
  defaultEntity: storeState.vehicleType.defaultEntity,
  loading: storeState.vehicleType.loading,
  updating: storeState.vehicleType.updating,
  updateSuccess: storeState.vehicleType.updateSuccess,
  countries: storeState.authentication.countries,
  hasUpdateAccess: hasAnyPermission(storeState.authentication.permissions, ['ADMIN', 'ENTITIES_MODIFY'])
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createVehicleType,
  updateVehicleType,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VehicleTypeUpdate);
