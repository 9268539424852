import { IHealthcareFacility } from 'app/shared/model/healthcare-facility.model';
import { IWatotoCareEntityDetail } from 'app/shared/model/watotocare-entity-detail.model';

export interface IPhysician {
  id?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  isActive?: boolean;
  healthcareFacilityId?: string;
  healthcareFacility?: IHealthcareFacility;
  mpesaNumber?: string;
  facilityDepartment?: string;
  facilityDepartmentEntityDetail?: IWatotoCareEntityDetail;
}

export const defaultValue: Readonly<IPhysician> = {};
