import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Table, Label, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getSortState, JhiItemCount, JhiPagination } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RouteStartType } from 'app/shared/model/enumerations/route-start-type.model'
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { IRegion } from "app/shared/model/region.model";
import { PERMISSIONS } from 'app/config/constants';
import { hasAnyPermission } from 'app/shared/auth/permissions';
import { IRootState } from 'app/shared/reducers';
import { getEntities, searchEntities, updateEntity } from './village-route.reducer';
import { IRoute } from 'app/shared/model/route.model';
import { ILocation } from 'app/shared/model/location.model'
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { displaySecondsAsHoursMinutes } from 'app/shared/util/date-utils';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import RouteSearchBar from './village-route-search-bar';
import { getWatotoCareSettings, getWatotoCareSettingsById } from 'app/modules/administration/watotocare-settings/watotocare-settings.reducer'
import { RouteForm } from './village-route-update-modal';

export interface IRouteProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }

export interface SearchState {
  startId: string,
  StartLocation:ILocation,
  EndLocation:ILocation,
  endId: string,
  startType: string
}

export const VillageRoute = (props: IRouteProps) => {
  const [activeRoute, setActiveRoute] = useState<IRoute>();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );
  const { match, watotocareSettings, loading, totalItems } = props;

  const [searchState, setSearchState] = useState({
    startId: undefined,
    StartLocation:undefined,
    EndLocation:undefined,
    endId: undefined,
    startType: undefined
  })
  const [activeCountry, setCountry] = useState<IRegion>({});
  const [settings, setSettings] = useState<any>([]);
  useEffect(() => {
    const ac = props.RegCountries.find(country => country.id === props.userCountryId)
    setCountry(ac);
    getWatotoCareSettings();
    setSettings(watotocareSettings.filter(set=>{
      if(set.country.id === activeCountry?.id){
        return set
      }else{
        return set.id ===0
      }

    }))
  }, [props.RegCountries, props.userCountryId, watotocareSettings]);

    // const settings:any = watotocareSettings.filter(set=>{
    //   if(set.country.id === activeCountry?.id){
    //     return set
    //   }else{
    //     return set.id ===0
    //   }

    // })


  const calculateRoutePrice = (distance: number) => {
    const costPerKm = watotocareSettings.find(setting => setting.country.id === (Object.keys(activeCountry).length > 0 ? activeCountry.id : props.userCountryId))
    console.log("PRICEEEEE", activeCountry, watotocareSettings, costPerKm);
    return distance * costPerKm.costKm;
  }

  const getAllEntities = () => {
    const { startId, endId, startType } = searchState
    if (startId || endId) {
      props.searchEntities(startId, endId, startType, paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`);
    }
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    getAllEntities()
  }, [searchState.startId, searchState.endId, searchState.startType])

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    // props.getSettings();
    // props.getWatotoCareSettingsById(1);
    props.getWatotoCareSettings();
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleRouteQuery = (fromLocation: ILocation, toLocation: ILocation) => {
    setSearchState({
      startId: fromLocation ? fromLocation.id : undefined,
      StartLocation:fromLocation ? fromLocation.name: undefined,
      endId: toLocation ? toLocation.id : undefined,
      EndLocation:toLocation ? toLocation.name: undefined,
      startType: fromLocation ? fromLocation.locationType : undefined
    })
  };

  const handleUpdateRoute = (route: IRoute) => {
    setActiveRoute(route);
  }

  const handleUpdateModalClose = () => {
    setActiveRoute(null);
  }

  const handleSaveRoute = (route: IRoute) => {
    props.updateEntity(route);
    setActiveRoute(null);
  }

  const getRouteStart = (route: IRoute): string => {
    return route.startType === RouteStartType.VILLAGE ? route.startVillage?.name : route.startFacility?.name
  }

  const getRouteEnd = (route: IRoute): string => {
    return route.endFacility?.name
  }
  const { RegCountries } = props;
  const regCountriesList = RegCountries.filter((ele) => {
    return props.isAdmin ? 1 : ele.id === activeCountry?.id;
  })



  const { routeList } = props;

  // routeList = routeList.filter(val=>val.startVillage?.council?.parent?.parent?.parent?.id === activeCountry?.id)


  return (
    <div>

      {activeRoute && (
        <RouteForm opened={true} routeEntity={activeRoute} watotocareSettings={settings} onSave={handleSaveRoute} toggle={handleUpdateModalClose} />
      )}


      <h2 id="route-heading">
        Village Routes
      </h2>
      <Row >
      <Col md="12">
      <Link  to={`${match.url}/create-route`}
                className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
            <FontAwesomeIcon icon="plus"/>
            &nbsp; Create new Route
          </Link>
          </Col>
      </Row>
      <Row className="justify-content-left">
        <Col md="12">
          <AvForm model={{}}>
            {regCountriesList ?
              <AvGroup>
                <Label id="typeLabel" for="region-type">
                  Country
                </Label>
                <AvInput
                  id="region-type"
                  type="select"
                  className="form-control"
                  name="type"
                  value={activeCountry?.id}
                  onChange={(event) => {

                    const ac = props.RegCountries.find(country => country.id === event.target.value)
                    setCountry(ac)
                    // setCountry(event.target.value)
                    setSettings(watotocareSettings.filter(set=>{
                      if(set.country.id === ac?.id){
                        return set
                      }else{
                        return set.id ===0
                      }

                    }))
                  }}
                >

                  {regCountriesList.length > 0 && regCountriesList?.map((country) => {

                    return <option value={country.id} key={"country-" + country.name}>{country.name}</option>

                  })}
                </AvInput>
              </AvGroup>
              : ""
            }
          </AvForm>
        </Col>
      </Row>
      <div className="mt-2 mb-2 mr-2">
        <RouteSearchBar activeCountry={activeCountry?.id} test={"test"} onQuery={handleRouteQuery} />
      </div>
      <div className="table-responsive">
        {routeList && routeList.length > 0 && (
          <Table responsive>
            <thead>
              <tr>

                <th className="hand" >
                  Route ID
                </th>
                <th className="hand" >
                  Start
                </th>
                <th className="hand" >
                  End
                </th>
                <th className="hand" onClick={sort('distance')}>
                  Distance (Kilometers)<FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('durationSeconds')}>
                  Duration (Minutes) <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" >
                  Price Type
                </th>
                <th className="hand" onClick={sort('price')}>
                  Price<FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('description')}>
                  Description <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {routeList.map((route, i) => (
                <tr key={`entity-${i}`}>

                  <td>{route.id}</td>
                  <td>{searchState?.StartLocation ? searchState.StartLocation : route.startVillage.name}</td>
                  <td>{searchState?.EndLocation ? searchState.EndLocation : route.endVillage.name }</td>
                  <td>{route.distance}</td>
                  <td>{displaySecondsAsHoursMinutes(route.durationSeconds)}</td>
                  <td>{route.overridePrice ? "Override" : "Computed"}</td>
                  <td>{route.overridePrice || calculateRoutePrice(route.distance)}</td>
                  <td>{route.description}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/route-detail/${route.id}`} color="info" size="sm">
                        <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">View</span>
                      </Button>
                      <Button
                        onClick={() => { handleUpdateRoute(route) }}
                        color="primary"
                        size="sm"
                      >
                        <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                      </Button>

                      <Button
                        tag={Link}
                        to={`${match.url}/${route.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                      >
                      <FontAwesomeIcon icon="trash" /> <span className="d-none d-md-inline">Delete</span>
                    </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
      {props.totalItems ? (
        <div className={routeList && routeList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={props.totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = ({ villageRoute, watotocareSettings, authentication }: IRootState) => ({
  routeList: villageRoute.entities,
  loading: villageRoute.loading,
  totalItems: villageRoute.totalItems,
  watotocareSettings: watotocareSettings.settings,
  // settings: watotocareSettings.settingsDetail,
  RegCountries: authentication.countries,
  userCountryId: authentication.userCountryId,
  isAdmin: hasAnyPermission(authentication.account.roles, ['ADMIN'])
});

const mapDispatchToProps = {
  getEntities,
  getWatotoCareSettings,
  // getWatotoCareSettingsById,
  updateEntity,
  searchEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VillageRoute);
