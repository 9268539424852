import React, {useEffect, useState} from 'react';

import {connect} from 'react-redux';
import {AvField, AvForm} from 'availity-reactstrap-validation';
import {Alert, Button, Col, Row} from 'reactstrap';

import {IRootState} from 'app/shared/reducers';
import {getSession} from 'app/shared/reducers/authentication';
import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';
import {reset, savePassword} from './password.reducer';
import {Link, RouteComponentProps} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface IUserPasswordProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export const PasswordPage = (props: IUserPasswordProps) => {
  const { updateFailure, updateSuccess } = props;

  const [password, setPassword] = useState('');

  useEffect(() => {
    props.reset();
    return () => {
      props.reset();
    };
  }, []);

  const handleValidSubmit = (event, values) => {
    props.savePassword(values.currentPassword, values.newPassword)
  };

  const updatePassword = event => setPassword(event.target.value);

  const handleClose = () => {
    props.history.push('/');
  };

  const handleCloseAfterInitialReset = () => {
    props.getSession()
    props.history.push('/');
  };

  useEffect( () => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);


  const title = props.account.mustChangePassword ?
    'You must change your password before continuing' : "Change your password";

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="password-title">{title}</h2>
          { updateSuccess ? (
              <Alert color="success"><strong>Your password has been updated.</strong></Alert>
            ) : null
          }
          { updateFailure ? (
            <Alert color="danger"><strong>Incorrect Password</strong> Please try again.</Alert>
          ) : null
          }
          <AvForm id="password-form" onValidSubmit={handleValidSubmit}>
            <AvField
              name="currentPassword"
              label="Current password"
              placeholder={'Current password'}
              type="password"
              validate={{
                required: { value: true, errorMessage: 'Your password is required.' },
              }}
            />
            <AvField
              name="newPassword"
              label="New password"
              placeholder={'New password'}
              type="password"
              validate={{
                required: { value: true, errorMessage: 'Your password is required.' },
                minLength: { value: 4, errorMessage: 'Your password is required to be at least 4 characters.' },
                maxLength: { value: 50, errorMessage: 'Your password cannot be longer than 50 characters.' },
              }}
              onChange={updatePassword}
            />
            <PasswordStrengthBar password={password} />
            <AvField
              name="confirmPassword"
              label="New password confirmation"
              placeholder="Confirm the new password"
              type="password"
              validate={{
                required: {
                  value: true,
                  errorMessage: 'Your confirmation password is required.',
                },
                minLength: {
                  value: 4,
                  errorMessage: 'Your confirmation password is required to be at least 4 characters.',
                },
                maxLength: {
                  value: 50,
                  errorMessage: 'Your confirmation password cannot be longer than 50 characters.',
                },
                match: {
                  value: 'newPassword',
                  errorMessage: 'The password and its confirmation do not match!',
                },
              }}
            />
            { !props.account.mustChangePassword ? (
              <>
                <Button tag={Link} id="cancel-save" to="/" replace color="info">
                  <FontAwesomeIcon icon="ban" />
                  &nbsp;Cancel
                </Button>
                &nbsp;
              </>
            ) : null
            }
            <Button color="success" type="submit">
              &nbsp;Change Password
            </Button>
          </AvForm>
        </Col>
      </Row>
      <Row className="justify-content-center">
        { updateSuccess ? (
          <>
            <Button  id="cancel-save" onClick={handleCloseAfterInitialReset} replace color="info">
              &nbsp; Go to Homepage
            </Button>
          </>
        ) : null
        }
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  account: storeState.authentication.account,
  isAuthenticated: storeState.authentication.isAuthenticated,
  updateSuccess: storeState.password.updateSuccess,
  updateFailure: storeState.password.updateFailure,
});

const mapDispatchToProps = { getSession, savePassword, reset};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PasswordPage);
