import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, ButtonGroup, Col, Label, Row} from 'reactstrap';
import {AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';
import {createEntity, getEntity, reset, updateEntity} from './symptom.reducer';
import {RiskProfile} from "app/shared/model/enumerations/risk-profile.model";
import {getAllByType as getCountries} from "app/entities/region/region.reducer";

export interface ISymptomUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const SymptomUpdate = (props: ISymptomUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [countryId, setCountryId] = useState(null)
  const [activeStatus, setActiveStatus] = useState(true);
  const [staticStatus, setStaticStatus] = useState(true);
  const [riskProfile, setRiskProfile] = useState(null);
  const {symptomEntity, loading, updating} = props;

  const handleClose = () => {
    props.history.push('/symptom');
  };

  useEffect(() => {
    if (isNew) {
      const params = new URLSearchParams(props.location.search);
      setCountryId(params.get('country_id'))
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
    props.getCountries();
    return () => {
      props.reset();
    }
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  useEffect(() => {
    if(symptomEntity.id) {
      setActiveStatus(symptomEntity?.isActive);
      setStaticStatus(symptomEntity?.isStatic);
      setRiskProfile(symptomEntity?.riskProfile);
    }
  }, [symptomEntity]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const cid = isNew ? countryId : symptomEntity.country.id;
      const country = props.countries.find(c => c.id === cid)
      const overrideStaticStatus = (riskProfile === RiskProfile.HIGH) ? staticStatus : false;

      const entity = {
        ...symptomEntity,
        ...values,
        riskProfile,
        isActive: activeStatus,
        isStatic: overrideStaticStatus,
        country
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="watotocareApp.symptom.home.createOrEditLabel">Create or edit a Condition</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : symptomEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="symptom-id">ID</Label>
                  <AvInput id="symptom-id" type="text" className="form-control" name="id" required readOnly/>
                </AvGroup>
              ) : null}

              <br/>
              <Row class="justify-content-center">
                <Col md="6">
                  <AvGroup>
                    <Label id="isActive" for="symptom-isActive">
                      Active Status
                    </Label>
                    <ButtonGroup style={{marginLeft: "30px"}}>
                      <Button
                        color={activeStatus ? 'primary' : 'secondary'}
                        onClick={(e) => {
                          setActiveStatus(true)
                        }}
                      >
                        Active
                      </Button>
                      <Button
                        color={!activeStatus ? 'primary' : 'secondary'}
                        onClick={() => {
                          setActiveStatus(false)
                        }}
                      >
                        Inactive
                      </Button>
                    </ButtonGroup>
                  </AvGroup>
                </Col>

                <Col md="6">
                  <AvGroup>
                    <Label id="isStatic" for="condition-isStatic">
                      Is Static
                    </Label>
                    <ButtonGroup style={{marginLeft: "30px"}}>
                      <Button
                        color={(staticStatus && riskProfile === RiskProfile.HIGH) ? 'primary' : 'secondary'}
                        disabled={riskProfile !== RiskProfile.HIGH}
                        onClick={(e) => {
                          setStaticStatus(true)
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        color={(!staticStatus && riskProfile === RiskProfile.HIGH) ? 'primary' : 'secondary'}
                        disabled={riskProfile !== RiskProfile.HIGH}
                        onClick={() => {
                          setStaticStatus(false)
                        }}
                      >
                        No
                      </Button>
                    </ButtonGroup>
                  </AvGroup>
                </Col>
              </Row>

              <AvGroup>
                <Label id="emergencyTypeLabel" for="symptom-emergencyType">
                  Condition Type
                </Label>
                <AvInput
                  id="symptom-emergencyType"
                  type="select"
                  className="form-control"
                  name="emergencyType"
                  value={(!isNew && symptomEntity.emergencyType) || 'MATERNAL'}
                >
                  <option value="MATERNAL">MATERNAL</option>
                  <option value="NEONATAL">NEONATAL</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="enConditionLabel" for="symptom-enCondition">
                  Condition (English)
                </Label>
                <AvField
                  id="symptom-enCondition"
                  type="text"
                  name="enCondition"
                  validate={{
                    required: {value: true, errorMessage: 'This field is required.'},
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="stConditionLabel" for="symptom-stCondition">
                  Condition (Local Language)
                </Label>
                <AvField
                  id="symptom-stCondition"
                  type="text"
                  name="stCondition"
                  validate={{
                    required: {value: true, errorMessage: 'This field is required.'},
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="requiredFacilityLevelLabel" for="symptom-requiredFacilityLevel">
                  Required Facility Level
                </Label>
                <AvField
                  id="symptom-requiredFacilityLevel"
                  type="number"
                  className="form-control"
                  name="requiredFacilityLevel"
                  validate={{
                    required: {value: true, errorMessage: 'This field is required.'},
                    number: {value: true, errorMessage: 'This field should be a number.'},
                  }}
                  min={0}
                  step={1}
                />
              </AvGroup>
              <AvGroup>
                <Label id="requiredVehicleLevelLabel" for="symptom-requiredVehicleLevel">
                  Required Vehicle Level
                </Label>
                <AvField
                  id="symptom-requiredVehicleLevel"
                  type="number"
                  className="form-control"
                  name="requiredVehicleLevel"
                  validate={{
                    required: {value: true, errorMessage: 'This field is required.'},
                    number: {value: true, errorMessage: 'This field should be a number.'},
                  }}
                  min={0}
                  step={1}
                />
              </AvGroup>
              <AvGroup>
                <Label id="riskProfilLabel" for="symptom-riskProfile">
                  Risk Profile
                </Label>
                <AvInput
                  id="symptom-riskProfile"
                  type="select"
                  className="form-control"
                  name="riskProfile"
                  value={symptomEntity.riskProfile}
                  onChange={(event) => {
                    setRiskProfile(event.target.value)
                  }}
                  validate={{
                    required: {value: true, errorMessage: 'This field is required.'},
                  }}
                >
                  {isNew && <option disabled selected value=''>-- select an option --</option>}
                  {Object.values(RiskProfile).map(val => (
                    <option value={val} key={val}>
                      {val}
                    </option>
                  ))}
                </AvInput>
              </AvGroup>
              {props.countries && props.countries.length > 0 && (
                <AvGroup>
                  <Label for="symptom-country">Country</Label>
                  <AvInput id="symptom-country" type="select" className="form-control" name="country" disabled
                           value={isNew ? countryId : symptomEntity?.country?.id}>
                    {props.countries
                      ? props.countries.map(country => (
                        <option value={country.id} key={country.id}>
                          {country.name}
                        </option>
                      ))
                      : null}
                  </AvInput>
                </AvGroup>
              )

              }
              <Button tag={Link} id="cancel-save" to="/symptom" replace color="info">
                <FontAwesomeIcon icon="arrow-left"/>
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save"/>
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  symptomEntity: storeState.symptom.entity,
  loading: storeState.symptom.loading,
  updating: storeState.symptom.updating,
  updateSuccess: storeState.symptom.updateSuccess,
  countries: storeState.region.countries
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  getCountries,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SymptomUpdate);
