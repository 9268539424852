import axios from 'axios';
import {
  ICrudGetAction,
  ICrudGetAllAction,
  ICrudPutAction,
  ICrudSearchAction,
  ICrudDeleteAction,
  IPayloadResult
} from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { IHealthcareFacilityLevel, defaultValue } from 'app/shared/model/healthcare-facility-level.model';
import {IPayload} from "react-jhipster/src/type/redux-action.type";
import {IVillage} from "app/shared/model/village.model";

export const ACTION_TYPES = {
  FETCH_HEALTHCAREFACILITYLEVEL_SEARCH: 'healthcareFacilityLevel/FETCH_HEALTHCAREFACILITYLEVEL_SEARCH',
  FETCH_HEALTHCAREFACILITYLEVEL_LIST: 'healthcareFacilityLevel/FETCH_HEALTHCAREFACILITYLEVEL_LIST',
  ALL_FETCH_HEALTHCAREFACILITYLEVEL_LIST: 'healthcareFacilityLevel/ALL_FETCH_HEALTHCAREFACILITYLEVEL_LIST',
  FETCH_HEALTHCAREFACILITYLEVEL: 'healthcareFacilityLevel/FETCH_HEALTHCAREFACILITYLEVEL',
  CREATE_HEALTHCAREFACILITYLEVEL: 'healthcareFacilityLevel/CREATE_HEALTHCAREFACILITYLEVEL',
  UPDATE_HEALTHCAREFACILITYLEVEL: 'healthcareFacilityLevel/UPDATE_HEALTHCAREFACILITYLEVEL',
  DELETE_HEALTHCAREFACILITYLEVEL: 'healthcareFacilityLevel/DELETE_HEALTHCAREFACILITYLEVEL',
  RESET: 'healthcareFacilityLevel/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IHealthcareFacilityLevel>,
  allFacilityLevel: [] as ReadonlyArray<IHealthcareFacilityLevel>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type HealthcareFacilityLevelState = Readonly<typeof initialState>;

// Reducer

export default (state: HealthcareFacilityLevelState = initialState, action): HealthcareFacilityLevelState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_HEALTHCAREFACILITYLEVEL_SEARCH):
    case REQUEST(ACTION_TYPES.FETCH_HEALTHCAREFACILITYLEVEL_LIST):
    case REQUEST(ACTION_TYPES.ALL_FETCH_HEALTHCAREFACILITYLEVEL_LIST):
    case REQUEST(ACTION_TYPES.FETCH_HEALTHCAREFACILITYLEVEL):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_HEALTHCAREFACILITYLEVEL):
    case REQUEST(ACTION_TYPES.UPDATE_HEALTHCAREFACILITYLEVEL):
    case REQUEST(ACTION_TYPES.DELETE_HEALTHCAREFACILITYLEVEL):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };

    case FAILURE(ACTION_TYPES.FETCH_HEALTHCAREFACILITYLEVEL_SEARCH):
    case FAILURE(ACTION_TYPES.FETCH_HEALTHCAREFACILITYLEVEL_LIST):
    case FAILURE(ACTION_TYPES.FETCH_HEALTHCAREFACILITYLEVEL):
    case FAILURE(ACTION_TYPES.CREATE_HEALTHCAREFACILITYLEVEL):
    case FAILURE(ACTION_TYPES.ALL_FETCH_HEALTHCAREFACILITYLEVEL_LIST):
    case FAILURE(ACTION_TYPES.UPDATE_HEALTHCAREFACILITYLEVEL):
    case FAILURE(ACTION_TYPES.DELETE_HEALTHCAREFACILITYLEVEL):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_HEALTHCAREFACILITYLEVEL_SEARCH):
    case SUCCESS(ACTION_TYPES.FETCH_HEALTHCAREFACILITYLEVEL_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
      case SUCCESS(ACTION_TYPES.ALL_FETCH_HEALTHCAREFACILITYLEVEL_LIST):
        return {
          ...state,
          loading: false,
          allFacilityLevel: action.payload.data,
        };
      
    case SUCCESS(ACTION_TYPES.FETCH_HEALTHCAREFACILITYLEVEL):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_HEALTHCAREFACILITYLEVEL):
    case SUCCESS(ACTION_TYPES.UPDATE_HEALTHCAREFACILITYLEVEL):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_HEALTHCAREFACILITYLEVEL):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/level';

// Actions

export const getEntities: (page?, size?, sort?, countryId?) => IPayload<IHealthcareFacilityLevel> | IPayloadResult<IHealthcareFacilityLevel> = (page?, size?, sort?, countryId?) => {
// export const getEntities: ICrudGetAllAction<IHealthcareFacilityLevel> = (page?, size?, sort?, countryId?) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&countryId=${countryId}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_HEALTHCAREFACILITYLEVEL_LIST,
    payload: axios.get<IHealthcareFacilityLevel>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getAllFacility=(countryId = '') => {
  // export const getEntities: ICrudGetAllAction<IHealthcareFacilityLevel> = (page?, size?, sort?, countryId?) => {
    const requestUrl = countryId !== '' || countryId != null?  `${apiUrl+"?countryId="+countryId}` :  `${apiUrl}`;
    return {
      type: ACTION_TYPES.ALL_FETCH_HEALTHCAREFACILITYLEVEL_LIST,
      payload: axios.get<IHealthcareFacilityLevel>(`${requestUrl}`),
    };
  };


// export const getHfByName: (searchString) => IPayload<IHealthcareFacilityLevel> | IPayloadResult<IHealthcareFacilityLevel> = (searchString) => {
//   // export const getEntities: ICrudGetAllAction<IHealthcareFacilityLevel> = (page?, size?, sort?, countryId?) => {
//     const requestUrl = `api/search/search-healthcare-facility-by-name/`;
//     return {
//       type: ACTION_TYPES.FETCH_HEALTHCAREFACILITYLEVEL_LIST,
//       payload: axios.get<IHealthcareFacilityLevel>(`${requestUrl}${searchString+'?'}cacheBuster=${new Date().getTime()}`),
//     };
//   };


export const getFilteredEntities: ICrudSearchAction<IHealthcareFacilityLevel> = ( search: string, size?: number ) => {
    const page = 0;
    const defaultSize = 30;
    const requestUrl = `${apiUrl}?search=${search}&size=${size || defaultSize}&page=${page}`;
  return {
    type: ACTION_TYPES.FETCH_HEALTHCAREFACILITYLEVEL_SEARCH,
    payload: axios.get<IHealthcareFacilityLevel>(`${requestUrl}&cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity = (countryId: string, level: string) => {
  const requestUrl = `api/levelById?countryId=${countryId}&level=${level}`;
  return {
    type: ACTION_TYPES.FETCH_HEALTHCAREFACILITYLEVEL,
    payload: axios.get<IHealthcareFacilityLevel>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IHealthcareFacilityLevel> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_HEALTHCAREFACILITYLEVEL,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IHealthcareFacilityLevel> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_HEALTHCAREFACILITYLEVEL,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IHealthcareFacilityLevel> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_HEALTHCAREFACILITYLEVEL,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
