import React, { useEffect, useState } from 'react'
import { DisplayCardGroup } from './displayCardGroup';
import { DisplayIndicator } from './displayCardIndicator';
import { BarChart, PieChart } from '../../../charts';
import { PRIMARY_CHART_COLOR, SECONDARY_CHART_COLOR } from '../constants';
import AgGridComp from '../../../common/AgGridComp';

export const OutcomesSection = ({countReferrals, referrals}) => {
    const [maternalData, setMaternalData] = useState([]);
    const [babyMaternalData, setBabyMaternalData] = useState([]);
    // const [motherOutcomesData, setMotherOutcomesData] = useState([]);
    const [babyFollowupsData, setBabyFollowupsData] = useState([]);
    const [tableData, setTableData] = useState([]);

    const [maternalConditionData, setMaternalConditionData] = useState([]);
    const [babyMaternalConditionData, setBabyMaternalConditionData] = useState([]);
    const [babyConditionsData, setBabyConditionsData] = useState([]);

    const TOTAL_CARD_GROUP_DATA = [
        { label: "6.1 Number of follow up conducted :", icon: "content/images/new_ambulance.svg", value: countReferrals?.totalFollowups },
        { label: "6.2 Number of maternal deaths:", icon: "content/images/mother_holding_baby.svg", value: countReferrals?.motherDead },
        { label: "6.3 Number of neonatal deaths:", icon: "content/images/new_child_friendly.svg", value: countReferrals?.babyDead },
    ]

    useEffect(() => {
        if(referrals?.followupMaternal && Object.keys(referrals?.followupMaternal)) {

            const maternalKeys = Object.keys(referrals?.followupMaternal).map((el, i) => {
                return { label: el, data: referrals?.followupMaternal[el]}
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            const babyMaternalKeys = Object.keys(referrals?.babyFollowupMaternal).map((el, i) => {
                return { label: el, data: referrals?.babyFollowupMaternal[el]}
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            const babyFollowupsKeys = Object.keys(referrals?.babyFollowups).map((el, i) => {
                return { label: el, data: referrals?.babyFollowups[el]}
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            const tableKeys = [];

            Object.keys(referrals?.motherCsection).sort().map((item, i) => {
                // if(item !== "NULL") {
                    tableKeys.push({
                        ' ': item,
                        'Mother is alive, C-section delivery': referrals?.motherCsection[item],
                        'Mother is alive, normal delivery': referrals?.motherNormal[item],
                        'Mother is alive, pregnant': referrals?.motherPregnant[item],
                        'Mother is dead': referrals?.motherDead[item],
                        'Grand Total': referrals?.motherCsection[item] + referrals?.motherNormal[item] + referrals?.motherPregnant[item] + referrals?.motherDead[item],
                    })
                // }
            })

            setMaternalData(maternalKeys);
            setMaternalData(maternalKeys);
            setMaternalData(maternalKeys);
            // setMotherOutcomesData(motherOutcomesKeys);
            setBabyMaternalData(babyMaternalKeys);
            setBabyMaternalData(babyMaternalKeys);
            setBabyMaternalData(babyMaternalKeys);
            setBabyFollowupsData(babyFollowupsKeys);
            setTableData(tableKeys)
        }
    }, [referrals])


    useEffect(() => {
        if(referrals?.conditionMaternal && Object.keys(referrals?.conditionMaternal)) {
            const maternalConditionKeys = Object.keys(referrals?.conditionMaternal).map((el, i) => {
                return { label: el, data: referrals?.conditionMaternal[el]}
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            const babyMaternalConditionKeys = Object.keys(referrals?.babyConditionMaternal).map((el, i) => {
                return { label: el, data: referrals?.babyConditionMaternal[el]}
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            const babyConditionsKeys = Object.keys(referrals?.babyConditions).map((el, i) => {
                return { label: el, data: referrals?.babyConditions[el]}
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            setMaternalConditionData(maternalConditionKeys);
            setMaternalConditionData(maternalConditionKeys);
            setMaternalConditionData(maternalConditionKeys);
            setBabyMaternalConditionData(babyMaternalConditionKeys);
            setBabyMaternalConditionData(babyMaternalConditionKeys);
            setBabyMaternalConditionData(babyMaternalConditionKeys);
            setBabyConditionsData(babyConditionsKeys);
        }
    }, [referrals])

    return (
        <div className='mt-0 mb-3'>
            <p className="mb-2" style={{ fontSize: "18px", fontWeight: 600, color: SECONDARY_CHART_COLOR }}>6. Outcomes</p>
            <div className="row mb-3">
                <DisplayCardGroup data={TOTAL_CARD_GROUP_DATA} col="col-4" />
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.4 Maternal outcomes - Maternal", value: "" }}>
                        {/* <PieChart chartData={MATERNAL_OUTCOMES_PIE_DATA} /> */}
                        <BarChart chartLabel='Maternal outcomes - Maternal' labels={maternalData.map(item => item.label)} dataSet={maternalData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.5 Maternal outcomes - In maternal", value: "" }}>
                        {/* <PieChart chartData={MATERNAL_OUTCOMES_PIE_DATA} /> */}
                        <BarChart chartLabel='Maternal outcomes - In maternal' labels={maternalData.map(item => item.label)} dataSet={maternalData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.6 Maternal outcomes - Maternal", value: "" }}>
                        {/* <PieChart chartData={MATERNAL_OUTCOMES_PIE_DATA} /> */}
                        <BarChart chartLabel='Maternal outcomes - Maternal' labels={maternalData.map(item => item.label)} dataSet={maternalData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            {/* <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.7 Baby outcomes (maternal emergencies)", value: "" }}> */}
                        {/* <PieChart chartData={NEONATAL_OUTCOMES_PIE_DATA} /> */}
                        {/* <BarChart chartLabel='Baby outcomes (maternal emergencies)' labels={motherOutcomesData.map(item => item.label)} dataSet={motherOutcomesData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} />
                    </DisplayIndicator>
                </div>
            </div> */}
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.7 Baby Outcomes for “Maternal” Emergencies", value: "" }}>
                        <BarChart chartLabel='Baby outcomes - Baby Outcomes for “Maternal” Emergencies' labels={babyMaternalData.map(item => item.label)} dataSet={babyMaternalData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.8 Baby Outcomes for “Maternal” Emergencies", value: "" }}>
                        <BarChart chartLabel='Baby Outcomes for “Maternal” Emergencies' labels={babyMaternalData.map(item => item.label)} dataSet={babyMaternalData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.9 Baby Outcomes for “Maternal” Emergencies", value: "" }}>
                        <BarChart chartLabel='Baby Outcomes for “Maternal” Emergencies' labels={babyMaternalData.map(item => item.label)} dataSet={babyMaternalData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.10 Baby Outcomes for “Neonatal” Emergencies", value: "" }}>
                        <BarChart chartLabel='Baby Outcomes for “Neonatal” Emergencies' labels={babyFollowupsData.map(item => item.label)} dataSet={babyFollowupsData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            {/* <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.8 Outcomes Table", value: "" }}>
                        {tableData.length && <AgGridComp rowData={tableData} />}
                    </DisplayIndicator>
                </div>
            </div> */}
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.4 Maternal conditions - Maternal", value: "" }}>
                        <BarChart chartLabel='Maternal conditions - Maternal' labels={maternalConditionData.map(item => item.label)} dataSet={maternalConditionData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.5 Maternal conditions - In maternal", value: "" }}>
                        <BarChart chartLabel='Maternal conditions - In maternal' labels={maternalConditionData.map(item => item.label)} dataSet={maternalConditionData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.6 Maternal conditions - Maternal", value: "" }}>
                        <BarChart chartLabel='Maternal conditions - Maternal' labels={maternalConditionData.map(item => item.label)} dataSet={maternalConditionData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.7 Baby Conditions for “Maternal” Emergencies", value: "" }}>
                        <BarChart chartLabel='Baby Conditions for “Maternal” Emergencies' labels={babyMaternalConditionData.map(item => item.label)} dataSet={babyMaternalConditionData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.8 Baby Conditions for “Maternal” Emergencies", value: "" }}>
                        <BarChart chartLabel='Baby Conditions for “Maternal” Emergencies' labels={babyMaternalConditionData.map(item => item.label)} dataSet={babyMaternalConditionData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.9 Baby Conditions for “Maternal” Emergencies", value: "" }}>
                        <BarChart chartLabel='Baby Conditions for “Maternal” Emergencies' labels={babyMaternalConditionData.map(item => item.label)} dataSet={babyMaternalConditionData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "6.10 Baby Conditions for “Neonatal” Emergencies", value: "" }}>
                        <BarChart chartLabel='Baby Conditions for “Neonatal” Emergencies' labels={babyConditionsData.map(item => item.label)} dataSet={babyConditionsData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
        </div>
    )
}
