import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';

import {NavDropdown} from './menu-components';
import {NavItem, NavLink} from 'reactstrap';
import {NavLink as Link} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const AccountMenu = ({isAuthenticated = false}) => (
  <>
    {/* <NavItem> */}
    {/*  <NavLink tag={Link} to="/patient/create" active={window.location.pathname === '/patient/create'}*/}
    {/*           className="d-flex align-items-center">*/}
    {/*    <FontAwesomeIcon icon="plus"/>*/}
    {/*    <span>Add New Patient</span>*/}
    {/*  </NavLink>*/}
    {/* </NavItem> */}
    <NavDropdown show={true} icon="user" name="Account" id="account-menu">
      <>
        <MenuItem icon="wrench" show={true} to="/account/settings">
          Settings
        </MenuItem>
        <MenuItem icon="lock" show={true} to="/account/password">
          Password
        </MenuItem>
        <MenuItem icon="sign-out-alt" show={true} to="/logout">
          Sign out
        </MenuItem>
      </>
    </NavDropdown>
  </>
);

export default AccountMenu;
