export const toCents = (amount: number): number => {
  return Math.round(100 * amount);
}

export const fromCents = (amount: number): number => {
  return amount/100;
}

export const toPercent = (amount: number): number => {
  return amount/100;
}

export const fromPercent = (amount: number): number => {
  return Math.round(100 * amount);
}

export const validateCentsString = (amount?: string): boolean => {
  return (!amount || amount?.match(/^\d{1,}(\.\d{0,2})?$/) != null);
};