export const enum RegionType {
  COUNTRY = 'COUNTRY',

  COUNCIL = 'COUNCIL',

  DISPATCH_CENTER = 'DISPATCH_CENTER',

  DISTRICT = 'DISTRICT',

  PROVINCE = 'PROVINCE',

  STATE = 'STATE',

  REGION = 'REGION',
}
