import React from 'react';
import {Switch} from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import WatotoCareEntityUpdate from './watotocare-entity-update';
import WatotoCareEntity from "app/entities/watotocare-entity/watotocare-entity";

const Routes = ({match}) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/create`} component={WatotoCareEntityUpdate}/>
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={WatotoCareEntityUpdate}/>
      <ErrorBoundaryRoute path={match.url} component={WatotoCareEntity}/>
    </Switch>
  </>
);

export default Routes;
