import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction, IPayloadResult } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IPayment, IPaymentFilter, IPaymentUpdate } from 'app/shared/model/payment.model';
import { PaymentStatus } from 'app/shared/model/enumerations/payment-status.model';
import { IPayload } from 'react-jhipster/src/type/redux-action.type';
import { IPatientCase } from 'app/shared/model/patient-case.model';

export const ACTION_TYPES = {
  FETCH_PAYMENT_LIST: 'payment/FETCH_PAYMENT_LIST',
  FETCH_PAYMENT: 'payment/FETCH_PAYMENT',
  FETCH_PAYMENT_PATIENT_CASE: 'payment/FETCH_PAYMENT_PATIENT_CASE',
  PATCH_PAYMENT: 'payment/PATCH_PAYMENT',
  CREATE_PAYMENT: 'payment/CREATE_PAYMENT',
  UPDATE_PAYMENT: 'payment/UPDATE_PAYMENT',
  DELETE_PAYMENT: 'payment/DELETE_PAYMENT',
  PROCESS_PAYMENT_ONLINE: 'payment/PROCESS_PAYMENT_ONLINE',
  RESET: 'payment/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  payments: [] as ReadonlyArray<IPayment>,
  totalItems: 0,
  payment: defaultValue,
  updating: false,
  updateSuccess: false
};

export type PaymentState = Readonly<typeof initialState>;

// Reducer

export default (state: PaymentState = initialState, action): PaymentState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PAYMENT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PAYMENT):
    case REQUEST(ACTION_TYPES.FETCH_PAYMENT_PATIENT_CASE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.PATCH_PAYMENT):
    case REQUEST(ACTION_TYPES.CREATE_PAYMENT):
    case REQUEST(ACTION_TYPES.UPDATE_PAYMENT):
    case REQUEST(ACTION_TYPES.DELETE_PAYMENT):
    case REQUEST(ACTION_TYPES.PROCESS_PAYMENT_ONLINE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_PAYMENT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PAYMENT):
    case FAILURE(ACTION_TYPES.CREATE_PAYMENT):
    case FAILURE(ACTION_TYPES.UPDATE_PAYMENT):
    case FAILURE(ACTION_TYPES.DELETE_PAYMENT):
    case FAILURE(ACTION_TYPES.FETCH_PAYMENT_PATIENT_CASE):
    case FAILURE(ACTION_TYPES.PATCH_PAYMENT):
    case FAILURE(ACTION_TYPES.PROCESS_PAYMENT_ONLINE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload?.response?.data?.detail || action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_PAYMENT_LIST):

      return {
        ...state,
        loading: false,
        payments: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };

    case SUCCESS(ACTION_TYPES.FETCH_PAYMENT):
      return {
        ...state,
        loading: false,
        payment: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_PAYMENT_PATIENT_CASE):
      return {
        ...state,
        loading: false,
        payment: {
          ...state.payment,
          driverJourney: {
            ...state.payment.driverJourney,
            patientCase: action.payload.data
          },
          physicianJourney: {
            ...state.payment.physicianJourney,
            patientCase: action.payload.data
          }
        }
      };
    case SUCCESS(ACTION_TYPES.CREATE_PAYMENT):
    case SUCCESS(ACTION_TYPES.UPDATE_PAYMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        payment: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_PAYMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        payment: { id: null, status: null, requestedAmount: null } // TODO
      };
    case SUCCESS(ACTION_TYPES.PATCH_PAYMENT):
    case SUCCESS(ACTION_TYPES.PROCESS_PAYMENT_ONLINE): {
      const data = action.payload.data;
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        payment: {
          ...state.payment,
          overrideAmount: data.overrideAmount,
          status: data.status
        } // TODO
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/payments';

// Actions

export type ICrudGetFilteredPaymentAction<T> = (statusFilters?: PaymentStatus []) => IPayload<T> | ((dispatch: any) => IPayload<T>);


export const getPayments: ICrudGetFilteredPaymentAction<IPayment> = (statusFilters?: PaymentStatus []) => {
  let requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
  if (statusFilters && statusFilters.length && statusFilters[0]) {
    requestUrl += `&status=${statusFilters.join(',')}`;
  }
  return {
    type: ACTION_TYPES.FETCH_PAYMENT_LIST,
    payload: axios.get<IPayment>(requestUrl),
    countryId: ''
  };
};

export const getPaymentsByFilter = (filter: IPaymentFilter) => {
  console.log('countryId cal', filter);
  let requestUrl = `api/getPaymentsByFilter?cacheBuster=${new Date().getTime()}&page=${filter.activePage}&size=25&sort=${filter.sort.sortType},${filter.sort.sortOrder}`;
  if (filter.paymentId !== '' && filter.paymentId) {
    requestUrl += `&paymentId=${filter.paymentId}`;
  }
  if (filter.countryId !== '' && filter.countryId) {
    requestUrl += `&countryId=${filter.countryId}`;
  }
  if (filter.status !== '' && filter.status) {
    requestUrl += `&status=${filter.status}`;
  }
  if (filter.driverName !== '' && filter.driverName) {
    requestUrl += `&driverName=${filter.driverName}`;
  }

  return {
    type: ACTION_TYPES.FETCH_PAYMENT_LIST,
    payload: axios.get<IPayment>(requestUrl)
  };
};


export const getPaymentsByDriverName = (searchString) => {
  const requestUrl = 'api/getPaymentsByFilter?driverName=';
  return {
    type: ACTION_TYPES.FETCH_PAYMENT_LIST,
    payload: axios.get<IPayment>(`${requestUrl + '' + searchString}${'&'}cacheBuster=${new Date().getTime()}`)
  };
};

const getPatientCase: ICrudGetAction<IPatientCase> = id => {
  const requestUrl = `api/patient-cases/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PAYMENT_PATIENT_CASE,
    payload: axios.get<IPatientCase>(requestUrl)
  };
};

export const getPaymentAndPatientCase: (id?: string | number) => IPayload<IPatientCase> | IPayloadResult<IPatientCase> = id => async dispatch => {
  const paymentRequestUrl = `${apiUrl}/${id}`;
  const paymentResponse = await dispatch({
    type: ACTION_TYPES.FETCH_PAYMENT,
    payload: axios.get<IPayment>(paymentRequestUrl)
  });

  let patientCaseId;
  if (paymentResponse.value.data.driverJourney) {
    patientCaseId = paymentResponse.value.data.driverJourney.patientCaseId;
  } else {
    patientCaseId = paymentResponse.value.data.physicianJourney.patientCaseId;
  }
  if (patientCaseId) {
    // Load the patient case as well.
    dispatch(patientCaseId);
  }
  return paymentResponse;
};

export const patchPayment: (id?: string | number, data?: IPaymentUpdate) => IPayload<IPayment> | IPayloadResult<IPayment> = (id, data) => async dispatch => {
  const paymentRequestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.PATCH_PAYMENT,
    payload: axios.patch(paymentRequestUrl, data)
  });
  return result;
};

export const processPaymentOnline: (id?: string | number) => IPayload<IPayment> | IPayloadResult<IPayment> = (id) => async dispatch => {
  const paymentRequestUrl = `${apiUrl}/${id}/processOnline`;
  const result = await dispatch({
    type: ACTION_TYPES.PROCESS_PAYMENT_ONLINE,
    payload: axios.post(paymentRequestUrl)
  });
  return result;
};


export const changePaymentStatus: (id?: string | number, paymentStatus?: string) => IPayload<IPayment> | IPayloadResult<IPayment> = (id, paymentStatus) => async dispatch => {
  const paymentRequestUrl = `api/payments-status-update`;
  const result = await dispatch({
    type: ACTION_TYPES.PATCH_PAYMENT,
    payload: axios.put(paymentRequestUrl, {
      paymentId: id,
      status: paymentStatus
    })
  });
  return result;
};

export const getPayment: ICrudGetAction<IPayment> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PAYMENT,
    payload: axios.get<IPayment>(requestUrl)
  };
};


export const searchByPaymentId: ICrudGetFilteredPaymentAction<IPayment> = id => {
  // http://localhost:8080/api/payments/8b074522-af7c-46aa-b05f-7ce2d79e78b8
  const requestUrl = `${'api/getPaymentsByFilter?cacheBuster=1644403359947&page=0&size=25&sort=id,asc&countryId=9a475ef0-49f6-11eb-89d2-a85e45d05e66&status=PROCESSED&paymentId=02'}`;

  return {
    type: ACTION_TYPES.FETCH_PAYMENT_LIST,
    payload: axios.get<IPayment>(requestUrl),
    countryId: ''
  };
};


export const createPayment: ICrudPutAction<IPayment> = payment => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_PAYMENT,
    payload: axios.post(apiUrl, cleanEntity(payment))
  });
  dispatch(getPayments());
  return result;
};

export const updatePayment: ICrudPutAction<IPayment> = payment => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_PAYMENT,
    payload: axios.put(apiUrl, cleanEntity(payment))
  });
  return result;
};

export const deletePayment: ICrudDeleteAction<IPayment> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PAYMENT,
    payload: axios.delete(requestUrl)
  });
  dispatch(getPayments());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
