import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasAnyPermission } from 'app/shared/auth/permissions';
import { IRootState } from 'app/shared/reducers';
import { getEntities } from './vehicleType.reducer';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { IRegion } from 'app/shared/model/region.model';

export interface IVehicleTypeProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

export const VehicleType = (props: IVehicleTypeProps) => {
  const emptyRegion: IRegion = null;
  const { vehicleTypeList, match, loading, userCountryId } = props;
  const [activeCountry, setActiveCountry] = useState<IRegion>({});
  const [vehicleTypesPerCountry, setVehicleTypePerCountry] = useState([]);

  useEffect(() => {
    props.getEntities();
  }, []);

  useEffect(() => {
    const ac = props.countries.find(country => country.id === userCountryId);
    setActiveCountry(ac);
  }, [props.countries, userCountryId]);

  const updateVehicleTypeList = (ac) => {
    setVehicleTypePerCountry(props.vehicleTypeList.filter(vehicleType => vehicleType.countryId && vehicleType.countryId === ac.id));
  };

  useEffect(() => {
    if (props.vehicleTypeList && activeCountry) {
      updateVehicleTypeList(activeCountry);
    }
  }, [props.vehicleTypeList]);

  const onCountryChange = (event) => {
    const countryId = event.target.value;
    const newActiveCountry = props.countries.find(country => country.id === countryId);
    setActiveCountry(newActiveCountry);
    updateVehicleTypeList(newActiveCountry);
  };
  const regCountriesList = props.countries.filter((ele) => {
    return props.isAdmin ? 1 : ele.id === activeCountry?.id;
  });

  return (
    <div>
      <h2 id="vehicleType-heading">
        Vehicle Type
      </h2>
      <br />
      {activeCountry && (<AvForm>
          <AvGroup>
            <AvInput id="payment-countries" type="select" className="form-control" name="payment-country"
                     value={activeCountry.id} onChange={onCountryChange}>
              {regCountriesList
                ? regCountriesList.map(country => (
                  <option value={country.id} key={country.id}>
                    {country.name}
                  </option>
                ))
                : null}
            </AvInput>
          </AvGroup>
        </AvForm>
      )
      }
      {(activeCountry && typeof activeCountry.id !== 'undefined') && (
        <Link to={`${match.url}/new?country_id=${activeCountry.id}`}
              className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp; Create new VehicleType
        </Link>
      )
      }
      <div className="table-responsive">
        {vehicleTypeList && vehicleTypeList.length > 0 ? (
          <Table responsive>
            <thead>
            <tr>
              <th>Vehicle Type (English)</th>
              <th>Vehicle Type (Local Language)</th>
              <th>Vehicle Level</th>
              <th>Vehicle Type Country</th>
              <th>Payment Required</th>
              <th>Vehicle Type Image</th>
            </tr>
            </thead>
            <tbody>
            {vehicleTypeList?.filter((v) => activeCountry?.id === v.countryId).map((vehicleType, i) => (

              <tr key={`entity-${i}`}>

                <td>{vehicleType.enType}</td>
                <td>{vehicleType.stType}</td>
                <td>{vehicleType.vehicleLevel}</td>
                <td>{props.countries.find(country => country.id === vehicleType.countryId)?.name}</td>
                <td>{vehicleType.paymentRequired ? 'True' : 'False'}</td>
                <td><img src={`data:image/svg+xml;base64,${vehicleType.image}`} alt="vehicle_image" width={40}
                         height={40} /></td>
                <td className="text-right">
                  <div className="btn-group flex-btn-group-container">
                    <Button tag={Link} to={`${match.url}/${vehicleType.id}`} color="info" size="sm">
                      <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">View</span>
                    </Button>
                    <Button tag={Link} to={`${match.url}/${vehicleType.id}/edit`} color="primary" size="sm">
                      <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">No Vehicle Type Found</div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ vehicleType, authentication }: IRootState) => ({
  vehicleTypeList: vehicleType.entities,
  loading: vehicleType.loading,
  countries: authentication.countries,
  userCountryId: authentication.userCountryId,
  isAdmin: hasAnyPermission(authentication.account.roles, ['ADMIN'])
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VehicleType);
