import React, {useEffect, useState} from 'react'
import {DisplayIndicator} from './displayCardIndicator';
import {BarChart} from '../../../charts';
import {PRIMARY_CHART_COLOR, SECONDARY_CHART_COLOR, MONTH_LABELS} from '../constants';

export const PerformanceSection = ({caseManagementData}) => {
    const [referralsPerCaseStatus, setReferralsPerCaseStatus] = useState([]);
    const [referralsPerPaymentStatus, setReferralsPerPaymentStatus] = useState([]);
    const [caseCountPerDispatcherId, setCaseCountPerDispatcherId] = useState([]);

    useEffect(() => {
        if (Object.keys(caseManagementData).length > 0) {

            // Number of referral per case status
            setReferralsPerCaseStatus(
                Object.keys(caseManagementData?.referralsPerCaseStatus)
                    .map((el, i) => {
                        return {label: `${el}`, data: caseManagementData?.referralsPerCaseStatus[el]};
                    })
                    .sort((a, b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0)
            );

            // Number of referral per payment status
            setReferralsPerPaymentStatus(
                Object.keys(caseManagementData?.referralsPerPaymentStatus)
                    .map((el, i) => {
                        return {label: `${el}`, data: caseManagementData?.referralsPerPaymentStatus[el]};
                    })
                    .sort((a, b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0)
            );

            // Number of cases per dispatcher
            setCaseCountPerDispatcherId(Object.keys(caseManagementData?.caseCountPerDispatcherId)
                .map((el, i) => {
                    return {label: `${el}`, data: caseManagementData?.caseCountPerDispatcherId[el]};
                })
                .sort((a, b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0)
            );
        }
    }, [caseManagementData])

    return (
        <div className='mt-0 mb-3'>
            <p className="mb-2" style={{fontSize: "18px", fontWeight: 600, color: SECONDARY_CHART_COLOR}}>2.
                Performance</p>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{label: "2.1 Number of referral per case status", value: ""}}>
                        <BarChart chartLabel="Number of referral per case status"
                                  labels={referralsPerCaseStatus.map(item => item.label)}
                                  dataSet={referralsPerCaseStatus.map(item => item.data)} bdColor={PRIMARY_CHART_COLOR}
                                  bgColor={SECONDARY_CHART_COLOR} axis='x' flag={true}/>
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{label: "2.2 Number of referral per payment status", value: ""}}>
                        <BarChart chartLabel="Number of referral per payment status"
                                  labels={referralsPerPaymentStatus.map(item => item.label)}
                                  dataSet={referralsPerPaymentStatus.map(item => item.data)}
                                  bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} axis='x' flag={true}/>
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{label: "2.3 Number of cases per dispatcher", value: ""}}>
                        <BarChart chartLabel="Number of cases per dispatcher"
                                  labels={caseCountPerDispatcherId.map(item => item.label)}
                                  dataSet={caseCountPerDispatcherId.map(item => item.data)}
                                  bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} axis='x' flag={true}/>
                    </DisplayIndicator>
                </div>
            </div>
        </div>
    )
}
