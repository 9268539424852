import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
// import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
// import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';
// import ReactMapGL from 'react-map-gl';
// import axios from 'axios';
import 'mapbox-gl/dist/mapbox-gl.css';
import {MAPBOX_ACCESS_TOKEN} from "app/config/constants";

mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

export const googleMapsApiKey = 'AIzaSyB1IEjh9nXMuohZPhEixzHRuy947RmytjE';
export function getDistanceBetweenTwoCordinates(lat1, lon1, lat2, lon2, unit = 'k') {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'K') {
      dist = dist * 1.609344;
    }
    if (unit === 'N') {
      dist = dist * 0.8684;
    }
    return dist;
  }
}

export default function Map({ pathLocation, mapboxRouteObject }) {
  console.log('PATH LOCATIOn', pathLocation);

  const mapContainer = useRef(null);
  const map = useRef(null);
  // const [lng, setLng] = useState(29.8732);
  // const [lat, setLat] = useState(-24.7351);
  // const [zoom, setZoom] = useState(12);

  // const [viewport, setViewport] = useState({
  //   latitude: -24.7351,
  //   longitude: 29.8732,
  //   zoom: 8,
  // });

  // const getMap = () => {
  //   const maps = new mapboxgl.Map({
  //     container: mapContainer.current,
  //     style: 'mapbox://styles/mapbox/streets-v11',
  //     center: [pathLocation.startLocation.longitude, pathLocation.startLocation.latitude],
  //     zoom: 8,
  //   });
  //   return maps;
  // };

  // const getRoute = async (map: any) => {
  //   const query = await fetch(
  //     `https://api.mapbox.com/directions/v5/mapbox/driving/${pathLocation.startLocation.longitude},${pathLocation.startLocation.latitude};${pathLocation.endLocation.longitude},${pathLocation.endLocation.latitude}?alternatives=true&geometries=geojson&language=en&overview=full&steps=true&access_token=${mapboxgl.accessToken}`,
  //     { method: 'GET' }
  //   );

  //   const json = await query.json();
  //   const data = json.routes[0];
  //   const route = data.geometry.coordinates;
  //   const geojson = {
  //     type: 'Feature',
  //     properties: {},
  //     geometry: {
  //       type: 'LineString',
  //       coordinates: route,
  //     },
  //   };

  //   if (map.getSource('route')) {
  //     map.getSource('route').setData(geojson);
  //   } else {
  //     map.addLayer({
  //       id: 'route',
  //       type: 'line',
  //       source: {
  //         type: 'geojson',
  //         data: geojson
  //       },
  //       layout: {
  //         'line-join': 'round',
  //         'line-cap': 'round'
  //       },
  //       paint: {
  //         'line-color': '#3887be',
  //         'line-width': 5,
  //         'line-opacity': 0.75
  //       }
  //     });
  //   }
  // };

  useEffect(() => {
    // const map = getMap();
    // getRoute(map);
    if (map.current) return; // initialize map only once
    if (pathLocation.startLocation.longitude && pathLocation.startLocation.latitude) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [pathLocation.startLocation.longitude, pathLocation.startLocation.latitude],
        zoom: 9,
      });
    }

    //   // const nav = new mapboxgl.NavigationControl();
    //   // map.addControl(nav);

    //   const directions = new MapboxDirections({
    //     accessToken: mapboxgl.accessToken,
    //     unit: 'metric',
    //     profile: 'mapbox/driving',
    //   });

    //   map.addControl(directions, 'top-left');

    // map.addLayer({
    //   id: 'point',
    //   type: 'circle',
    //   source: {
    //     type: 'geojson',
    //     data: {
    //       type: 'FeatureCollection',
    //       features: [
    //         {
    //           type: 'Feature',
    //           properties: {},
    //           geometry: {
    //             type: 'Point',
    //             coordinates: [pathLocation.startLocation.longitude,pathLocation.startLocation.latitude]
    //           }
    //         }
    //       ]
    //     }
    //   },
    //   paint: {
    //     'circle-radius': 10,
    //     'circle-color': '#3887be'
    //   }
    // });
    // this is where the code from the next step will go

    return () => {
      map.current = ""
    };
  }, [pathLocation]);

  useEffect(() => {
    if (map.current) {
      if (pathLocation.startLocation.longitude && pathLocation.startLocation.latitude) {
        const startMarker = new mapboxgl.Marker({id: "startMarker"})
          .setLngLat([pathLocation.startLocation.longitude.toFixed(3), pathLocation.startLocation.latitude.toFixed(3)])
          .addTo(map.current);
      }

      if (pathLocation.endLocation.longitude && pathLocation.endLocation.latitude) {
        const endMarker = new mapboxgl.Marker({ color: '#e74c3c' })
          .setLngLat([pathLocation.endLocation.longitude.toFixed(3), pathLocation.endLocation.latitude.toFixed(3)])
          .addTo(map.current);
      }
    }
  }, [pathLocation]);

  useEffect(() => {
    if (Object.keys(mapboxRouteObject).length > 0) {
      // console.log("MAPBOXROUTEOBJECT", mapboxRouteObject, map.current);

      const route = mapboxRouteObject.geometry.coordinates;
      const geojson = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: route,
        },
      };

      // if the route already exists on the map, we'll reset it using setData
      if (map.current.getSource('route')) {
        map.current.getSource('route').setData(geojson);
      }

      // otherwise, we'll make a new request
      else {
        map.current.addLayer({
          id: 'route',
          type: 'line',
          source: {
            type: 'geojson',
            data: geojson,
          },
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#3887be',
            'line-width': 5,
            'line-opacity': 0.75,
          },
        });
      }
  }
  }, [mapboxRouteObject]);

  // useEffect(() => {
  //   if (!map.current) return; // wait for map to initialize
  //   map.current.on('move', () => {
  //     setLng(map.current.getCenter().lng.toFixed(4));
  //     setLat(map.current.getCenter().lat.toFixed(4));
  //     setZoom(map.current.getZoom().toFixed(2));
  //   });
  // });

  // const getDirections = async () => {
  //   await Promise.resolve(
  //     axios.get(
  //       `https://api.mapbox.com/directions/v5/mapbox/driving/${pathLocation.startLocation.longitude},${pathLocation.startLocation.latitude};${pathLocation.endLocation.longitude},${pathLocation.endLocation.latitude}?alternatives=true&geometries=geojson&language=en&overview=full&steps=true&access_token=${mapboxgl.accessToken}`
  //     )
  //   ).then((data: any) => {
  //     console.log('MAPDATARESPONSE', data);
  //     const mapData = data.routes[0];
  //     const route = mapData.geometry.coordinates;
  //     const geojson = {
  //       type: 'Feature',
  //       properties: {},
  //       geometry: {
  //         type: 'LineString',
  //         coordinates: route,
  //       },
  //     };

  //     // if the route already exists on the map, we'll reset it using setData
  //     if (map.current.getSource('route')) {
  //       map.current.getSource('route').setData(geojson);
  //     }

  //     // otherwise, we'll make a new request
  //     else {
  //       map.current.addLayer({
  //         id: 'route',
  //         type: 'line',
  //         source: {
  //           type: 'geojson',
  //           data: geojson,
  //         },
  //         layout: {
  //           'line-join': 'round',
  //           'line-cap': 'round',
  //         },
  //         paint: {
  //           'line-color': '#3887be',
  //           'line-width': 5,
  //           'line-opacity': 0.75,
  //         },
  //       });
  //     }
  //     // add turn instructions here at the end
  //   });
  // };

  // useEffect(() => {
  //   getDirections();
  // }, []);

  return (
    <div>
      {/* <div className="sidebar">
        Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
      </div> */}
      <div
        ref={mapContainer}
        className="map-container"
        style={{ height: '70vh', outlineColor: 'black', outlineWidth: '2px', outlineStyle: 'solid' }}
        id="mapContainer"
      >{!pathLocation.startLocation.longitude && !pathLocation.startLocation.latitude && "PLEASE SELECT START FACILITY OR START VILLAGE"}</div>
      {/* <ReactMapGL
	  	{...viewport}
		mapboxAccessToken={mapboxgl.accessToken}
		initialViewState={{...viewport}}
		style={{width: "100vw", height: "70vh"}}
		mapStyle="mapbox://styles/mapbox/streets-v11"
		interactive={true}
		onMove={evt => setViewport(evt.viewState)}
	  >
	  </ReactMapGL> */}
    </div>
  );
}
// export default Map;
