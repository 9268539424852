import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { fromCents } from 'app/shared/util/money-utils';
import {IGeofenceRoute, defaultValue} from "app/shared/model/geofence-route.model";

export const ACTION_TYPES = {
  SEARCH_ROUTES: 'geofenceRoute/SEARCH_ROUTES',
  FETCH_ROUTE_LIST: 'geofenceRoute/FETCH_ROUTE_LIST',
  FETCH_ROUTE: 'geofenceRoute/FETCH_ROUTE',
  CREATE_ROUTE: 'geofenceRoute/CREATE_ROUTE',
  UPDATE_ROUTE: 'geofenceRoute/UPDATE_ROUTE',
  DELETE_ROUTE: 'geofenceRoute/DELETE_ROUTE',
  RESET: 'geofenceRoute/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IGeofenceRoute>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  deleteFailure: false,
};

export type geofenceRouteState = Readonly<typeof initialState>;

// Reducer

export default (state: geofenceRouteState = initialState, action): geofenceRouteState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_ROUTES):
    case REQUEST(ACTION_TYPES.FETCH_ROUTE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ROUTE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_ROUTE):
    case REQUEST(ACTION_TYPES.UPDATE_ROUTE):
    case REQUEST(ACTION_TYPES.DELETE_ROUTE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.SEARCH_ROUTES):
    case FAILURE(ACTION_TYPES.FETCH_ROUTE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ROUTE):
    case FAILURE(ACTION_TYPES.CREATE_ROUTE):
    case FAILURE(ACTION_TYPES.UPDATE_ROUTE):
    case FAILURE(ACTION_TYPES.DELETE_ROUTE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_ROUTE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data.map((entity) => ({...entity, overridePrice: fromCents(entity.overridePrice)})),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.SEARCH_ROUTES):
      // console.log('fetchCount', action.payload.data.length)
      return {
        ...state,
        loading: false,
        entities: action.payload.data.map((entity) => ({...entity, overridePrice: fromCents(entity.overridePrice)})),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      }
    case SUCCESS(ACTION_TYPES.FETCH_ROUTE): {
      const data = action.payload.data;
      return {
        ...state,
        loading: false,
        entity: {
          ...data,
          overridePrice: fromCents(data.overridePrice),
        },
      };
    }
    case SUCCESS(ACTION_TYPES.CREATE_ROUTE):
    case SUCCESS(ACTION_TYPES.UPDATE_ROUTE): {
      const data = { ...action.payload.data, overridePrice: fromCents(action.payload.data.overridePrice) }
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: data,
        entities: state.entities.map((value) => {
          if (value.id === data.id) {
            return data;
          }
          return value;
        })
      };
    }
    case SUCCESS(ACTION_TYPES.DELETE_ROUTE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/geofence-routes';
const searchUrl = 'api/search/geofence-routes'

// Actions

export const searchEntities = (startId: string, endId: string, page, size, sort) => {
  const params = {
    ...startId ? { startId } : {},
    ...endId ? { endId } : {},
    ...{ page },
    ...size ? { size } : {},
    ...sort ? { sort } : {}
  }
  const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  const requestUrl = `${searchUrl}?${queryString}`

  return {
    type: ACTION_TYPES.SEARCH_ROUTES,
    payload: axios.get<IGeofenceRoute>(requestUrl)
  }
}

export const getEntities: ICrudGetAllAction<IGeofenceRoute> = (page, size, sort) => {
  // http://localhost:8080/api/search/search-village
 // http://localhost:8080/api/search/geofence-routes?startId=dd5fd3a0-41ab-11ec-8720-02f146ec93af&endId=dd5fe82c-41ab-11ec-8726-02f146ec93af
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_ROUTE_LIST,
    payload: axios.get<IGeofenceRoute>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IGeofenceRoute> = id => {
  const requestUrl = `${'api/geofence-routes'}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_ROUTE,
    payload: axios.get<IGeofenceRoute>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IGeofenceRoute> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_ROUTE,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  // dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IGeofenceRoute> = entity => async dispatch => {

  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ROUTE,
    payload: axios.put('api/geofence-routes', cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IGeofenceRoute> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_ROUTE,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
