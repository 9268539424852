import {IHealthcareFacility} from "app/shared/model/healthcare-facility.model";

export interface IAmbulance {
  id?: string;
  driverFirstName?: string;
  driverLastName?: string;
  vehicleType?: any;
  phoneNumber?: string;
  healthcareFacilityId?: string;
  phone_number_2?: string;
  phone_number_3?: string;
  active?: boolean;
  healthcareFacility?: IHealthcareFacility;
  mpesaNumber?: string;
}

export const defaultValue: Readonly<IAmbulance> = {};
