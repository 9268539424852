import React, {useEffect, useState} from 'react'
import {DisplayCardGroup} from './displayCardGroup';
import {DisplayIndicator} from './displayCardIndicator';
import {BarChart, LineChart, StackedBarChart} from '../../../charts';
import {PRIMARY_CHART_COLOR, SECONDARY_CHART_COLOR, MONTH_LABELS} from '../constants';
import value from "*.json";
import {mergeAndSum} from "app/modules/programDashboard/utils";

export const TransportSection = ({caseManagementCountData, caseManagementData, caseManagementGroupData}) => {
    const [avgDriverTripData, setAvgDriverTripData] = useState([]);
    const [avgDriverTripLabels, setAvgDriverTripLabels] = useState([]);
    const [activeInactiveDriverData, setActiveInactiveDriverData] = useState({});
    const [activeInactiveDriverLabels, setActiveInactiveDriverLabels] = useState([]);
    const [villageCoverageData, setVillageCoverageData] = useState({});
    const [villageCoverageLabels, setVillageCoverageLabels] = useState([]);
    const TOTAL_CARD_GROUP_DATA = [
        {
            label: "3.1 Total cumulative cost:",
            icon: "content/images/new_car.svg",
            value: (caseManagementCountData?.totalCost / 100).toLocaleString()
        },
    ]

    useEffect(() => {
        if (Object.keys(caseManagementData).length > 0 && Object.keys(caseManagementGroupData).length > 0) {

            // Average trips by drivers per district
            const avgTripData = [];
            const avgTripKeys = Object.keys(caseManagementData?.avgCostPertrip)
                .sort(function (a, b) {
                    return caseManagementData?.avgTripsPerDriverPerDistrictPerHundred[b] - caseManagementData?.avgTripsPerDriverPerDistrictPerHundred[a]
                });
            for (let i = 0; i < avgTripKeys.length; i++) {
                avgTripData.push(caseManagementData?.avgTripsPerDriverPerDistrictPerHundred[avgTripKeys[i]] ? caseManagementData?.avgTripsPerDriverPerDistrictPerHundred[avgTripKeys[i]] / 100 : 0)
            }
            setAvgDriverTripLabels(avgTripKeys);
            setAvgDriverTripData(avgTripData);

            // Active/Inactive drivers
            const activeInactiveDrivers = {
                'Active': {
                    label: 'Active',
                    color: PRIMARY_CHART_COLOR,
                    data: [],
                },
                'Inactive': {
                    label: 'Inactive',
                    color: SECONDARY_CHART_COLOR,
                    data: [],
                }
            };
            const driverData = caseManagementGroupData.driversPerDistrictPerIsActive;
            const driverKeys = Object.keys(driverData)
                .sort(function (a, b) {
                    return (driverData[b]['active'] + driverData[b]['inactive']) - (driverData[a]['active'] + driverData[a]['inactive'])
                });
            for (let i = 0; i < driverKeys.length; i++) {
                activeInactiveDrivers['Active'].data.push(driverData[driverKeys[i]]['active'] ?? 0)
                activeInactiveDrivers['Inactive'].data.push(driverData[driverKeys[i]]['inactive'] ?? 0)
            }
            setActiveInactiveDriverData(activeInactiveDrivers);
            setActiveInactiveDriverLabels(driverKeys);

            // Driver count and percentage coverage of villages
            const villageCoverage = {
                'Count': {
                    label: 'Count',
                    color: PRIMARY_CHART_COLOR,
                    data: [],
                },
                'Percentage': {
                    label: 'Percentage of Villages Not Covered',
                    color: SECONDARY_CHART_COLOR,
                    data: [],
                }
            };
            const driverVillageData = caseManagementGroupData.villagesByDriversPerDistrict;
            const villageCoverageKeys = Object.keys(driverVillageData)
                .sort(function (a, b) {
                    return driverVillageData[b]['count'] - driverVillageData[a]['count']
                });
            for (let i = 0; i < villageCoverageKeys.length; i++) {
                villageCoverage['Count'].data.push(driverVillageData[villageCoverageKeys[i]]['count'] ?? 0)
                villageCoverage['Percentage'].data.push(driverVillageData[villageCoverageKeys[i]]['percentage'] ?? 0)
            }
            setVillageCoverageData(villageCoverage);
            setVillageCoverageLabels(villageCoverageKeys);

        }
    }, [caseManagementData, caseManagementGroupData])

    return (
        <div className='mt-0 mb-3'>
            <p className="mb-2" style={{fontSize: "18px", fontWeight: 600, color: SECONDARY_CHART_COLOR}}>3.
                Transport</p>
            <div className="row mb-3">
                <DisplayCardGroup data={TOTAL_CARD_GROUP_DATA} col="col-6"/>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{
                        label: "3.2 Number and percentage of villages not covered by community drivers per district",
                        value: ""
                    }}>
                        <StackedBarChart chartLabels={villageCoverageLabels} chartData={villageCoverageData} axis='y'
                                         flag={false}/>
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{label: "3.3 Average number of trip by drivers per district", value: ""}}>
                        <BarChart chartLabel="Average number of trip by drivers per district"
                                  labels={avgDriverTripLabels}
                                  dataSet={avgDriverTripData}
                                  bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} axis='x'/>
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{label: "3.4 Number active/inactive drivers per district", value: ""}}>
                        <StackedBarChart chartLabels={activeInactiveDriverLabels} chartData={activeInactiveDriverData}
                                         axis='y'/>
                    </DisplayIndicator>
                </div>
            </div>
        </div>
    )
}
