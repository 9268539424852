import {IVillage} from 'app/shared/model/village.model';
import {IHealthcareFacility} from 'app/shared/model/healthcare-facility.model';
import {IDistrict} from 'app/shared/model/district.model';

export interface ICouncil {
  id?: string;
  name?: string;
  villages?: IVillage[];
  healthcareFacilities?: IHealthcareFacility[];
  district?: IDistrict;
  locationLon?: number;
  locationLat?: number;
}

export const defaultValue: Readonly<ICouncil> = {};
