import './home.scss';

import React, { useEffect } from 'react';
import {
  Card,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
  PopoverHeader,
  Row,
  TabContent,
  Table,
  TabPane
} from 'reactstrap';
import { IRootState } from 'app/shared/reducers';
import { hasAnyPermission } from 'app/shared/auth/permissions';
import { connect } from 'react-redux';
import {
  getDispatcherDashboardEntries,
  getOutcomeDashboardEntries
} from 'app/entities/patient-baby/patient-baby.reducer';
import { getSettings as getOutcomeSettings } from 'app/entities/patient-outcome/patient-outcome.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RouteComponentProps } from 'react-router-dom';
import { dateInServerTimezone, dateInUserTimezone } from 'app/shared/util/date-utils';
import moment from 'moment';
import { JourneyStatus } from 'app/shared/model/enumerations/journey-status.model';
import { JourneyType } from 'app/shared/model/enumerations/journey-type.model';


export interface IHomeProps extends StateProps, DispatchProps, RouteComponentProps {
}

export const Home = (props: IHomeProps) => {
    const { userCountryId, outcomeDashboardEntries, dispatcherDashboardEntries, outcomeSettings } = props;
    const [outcomeDashboard, setOutcomeDashboard] = React.useState([]);
    const [dispatcherDashboard, setDispatcherDashboard] = React.useState([]);
    const [popoverOpen, setPopoverOpen] = React.useState({});
    const [sortConfig, setSortConfig] = React.useState({ key: 'motherName', direction: 'asc' });
    const TABS = {
      OUTCOMES: { tabId: '1', title: 'Outcomes Dashboard' },
      DISPATCHER: { tabId: '2', title: 'Dispatcher Dashboard' }
    };
    const [activeTab, setActiveTab] = React.useState(TABS.OUTCOMES.tabId);

    useEffect(() => {
      if (userCountryId) {
        props.getOutcomeDashboardEntries(userCountryId, dateInUserTimezone());
        props.getOutcomeSettings(userCountryId);
      }
    }, [userCountryId]);

    const handleSort = (key) => {
      let direction = 'asc';
      if (sortConfig.key === key) {
        direction = sortConfig.direction === 'asc' ? 'desc' : 'asc';
      }
      setSortConfig({ key, direction });
    };

    const sortDashboard = (dashboard) => {
      const sortableItems = [...dashboard];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (typeof a[sortConfig.key] === 'string' && typeof b[sortConfig.key] === 'string') {
            return sortConfig.direction === 'asc' ? a[sortConfig.key].localeCompare(b[sortConfig.key]) : b[sortConfig.key].localeCompare(a[sortConfig.key]);
          } else {
            if (a[sortConfig.key] < b[sortConfig.key]) {
              return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
              return sortConfig.direction === 'asc' ? 1 : -1;
            }
          }
          return 0;
        });
      }
      return sortableItems;
    };

    const sortedOutcomeDashboard = React.useMemo(() => {
      return sortDashboard(outcomeDashboard);
    }, [outcomeDashboard, sortConfig]);

    const sortedDispatcherDashboard = React.useMemo(() => {
      return sortDashboard(dispatcherDashboard);
    }, [dispatcherDashboard, sortConfig]);

    const selectTab = (tabId) => {
      setActiveTab(tabId);
      // lazy loading the dashboard entries
      if (tabId === TABS.OUTCOMES.tabId && userCountryId) {
        props.getOutcomeDashboardEntries(userCountryId, dateInUserTimezone());
      }
      if (tabId === TABS.DISPATCHER.tabId && userCountryId) {
        props.getDispatcherDashboardEntries(userCountryId, dateInServerTimezone());
      }
    };

    useEffect(() => {
      if (outcomeDashboardEntries.length > 0 && outcomeSettings.length > 0) {

        // convert outcome settings to a map for easy access
        const outcomeSettingsByIndex = {};
        outcomeSettings.forEach((setting) => {
          outcomeSettingsByIndex[setting.index] = setting;
        });

        const tempOutcomeDashboard = [];
        outcomeDashboardEntries.forEach((entry) => {
          tempOutcomeDashboard.push({
            babyId: entry.patientBabyId,
            motherName: entry.patientName || '-',
            babyName: entry.patientBabyName || '-',
            dob: entry.patientBabyDateOfBirth,
            ageInDays: moment().startOf('day').diff(moment(entry.patientBabyDateOfBirth).startOf('day'), 'days'),
            outcomeDay: outcomeSettingsByIndex[entry.outcomesCompleted + 1]['callDay'],
            outcomeAttempts: entry.outcomeAttempts,
            motherMobile: entry.patientPhoneNumber,
            caregiverName: entry.caregiverName || '-',
            caregiverPhoneNumber: entry.caregiverPhoneNumber || '-',
            secondaryContactName: entry.secondaryContactName || '-',
            secondaryPhoneNumber: entry.secondaryPhoneNumber || '-'
          });
        });
        // sort by outcome day and then by mother name
        tempOutcomeDashboard.sort((a, b) => a.outcomeDay - b.outcomeDay || a.motherName.localeCompare(b.motherName));
        setOutcomeDashboard(tempOutcomeDashboard);
      }
    }, [outcomeDashboardEntries, outcomeSettings]);

    const getJourneyType = (type) => {
      if (type) {
        if (type === JourneyType.TELECONSULTATION) {
          return 'Teleconsultation';
        } else if (type === JourneyType.PHYSICAL_TRANSPORT) {
          return 'Physical Transport';
        } else if (type === JourneyType.BYPASS) {
          return 'Journey Skipped';
        }
      }
      return '-';
    };

    const getJourneyStatus = (status, scheduledSlotStart) => {
      if (status) {
        if (status === JourneyStatus.COMPLETE) {
          return { journeyStatus: 'Needs to update conditions', journeyStatusColor: 'yellowgreen' };
        } else if (status === JourneyStatus.IN_PROGRESS) {
          return { journeyStatus: 'In Progress', journeyStatusColor: 'blue' };
        } else if (status === JourneyStatus.CANCELED) {
          return { journeyStatus: 'Journey Canceled', journeyStatusColor: 'red' };
        } else if (status === JourneyStatus.SCHEDULED) {
          // check if scheduledSlotStart is for today or some other day
          const scheduledSlotStartMoment = moment(scheduledSlotStart);
          const today = moment();
          if (scheduledSlotStartMoment.isSame(today, 'day')) {
            // convert scheduled slot to readable format with am pm
            const start = moment(scheduledSlotStart).format('hh:mm A');
            return { journeyStatus: `Scheduled for today@${start}`, journeyStatusColor: 'orange' };
          } else {
            // Format start time to format like July 15th, 2021
            const start = moment(scheduledSlotStart).format('MMMM Do, YYYY');
            return { journeyStatus: `Scheduled for ${start}`, journeyStatusColor: 'orange' };
          }
        } else {
          return { journeyStatus: 'Needs an appointment', journeyStatusColor: 'red' };
        }
      }
      return { journeyStatus: 'Needs an appointment', journeyStatusColor: 'red' };
    };

    useEffect(() => {
      if (dispatcherDashboardEntries.length > 0) {
        const tempDispatcherDashboard = [];
        dispatcherDashboardEntries.forEach((entry) => {
          tempDispatcherDashboard.push({
            babyId: entry.patientBabyId,
            motherName: entry.patientName || '-',
            babyName: entry.patientBabyName || '-',
            visitCount: entry.visitCount,
            journeyType: getJourneyType(entry.journeyType),
            ...getJourneyStatus(entry.journeyStatus, entry.scheduledSlotStart),
            motherMobile: entry.patientPhoneNumber || '-',
            caregiverName: entry.caregiverName || '-',
            caregiverPhoneNumber: entry.caregiverPhoneNumber || '-',
            secondaryContactName: entry.secondaryContactName || '-',
            secondaryPhoneNumber: entry.secondaryPhoneNumber || '-',
            ageInDays: moment().startOf('day').diff(moment(entry.patientBabyDateOfBirth).startOf('day'), 'days'),
            dob: entry.patientBabyDateOfBirth
          });
        });

        // sort by visit count and then by mother name
        tempDispatcherDashboard.sort((a, b) => a.visitCount - b.visitCount || a.motherName.localeCompare(b.motherName));
        setDispatcherDashboard(tempDispatcherDashboard);
      }
    }, [dispatcherDashboardEntries]);

    return (
      <Container>
        <Row>
          <Col md="12">
            <Card>
              <Row>
                <Col md="12">
                  <Nav tabs>
                    <NavItem>
                      <NavLink href="/patient/create">
                        <FontAwesomeIcon icon="plus" /> Add New Patient
                      </NavLink>
                    </NavItem>
                    {Object.values(TABS).map((tab) => (
                      <NavItem key={tab.tabId}>
                        <NavLink
                          className={activeTab === tab.tabId ? 'active' : ''}
                          onClick={() => selectTab(tab.tabId)}
                        >
                          {tab.title}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  <TabContent activeTab={activeTab} style={{ minHeight: '600px' }}>
                    <TabPane tabId={TABS.OUTCOMES.tabId}>
                      <Table responsive hover>
                        <thead>
                        <tr>
                          <th style={{ width: '30%' }} className="hand border-top-0"
                              onClick={() => handleSort('motherName')}>
                            Mother Name &nbsp;
                            <FontAwesomeIcon
                              icon={sortConfig.key === 'motherName' ? (sortConfig.direction === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                          </th>
                          <th style={{ width: '30%' }} className="hand border-top-0"
                              onClick={() => handleSort('babyName')}>
                            Baby Name &nbsp;
                            <FontAwesomeIcon
                              icon={sortConfig.key === 'babyName' ? (sortConfig.direction === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                          </th>
                          <th style={{ width: '14%' }} className="hand border-top-0"
                              onClick={() => handleSort('ageInDays')}>
                            Baby Age (in days) &nbsp;
                            <FontAwesomeIcon
                              icon={sortConfig.key === 'ageInDays' ? (sortConfig.direction === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                          </th>
                          <th style={{ width: '13%' }} className="hand border-top-0"
                              onClick={() => handleSort('outcomeAttempts')}>
                            Outcome Attempts &nbsp;
                            <FontAwesomeIcon
                              icon={sortConfig.key === 'outcomeAttempts' ? (sortConfig.direction === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                          </th>
                          <th style={{ width: '13%' }} className="hand border-top-0"
                              onClick={() => handleSort('outcomeDay')}>
                            Outcome for Day &nbsp;
                            <FontAwesomeIcon
                              icon={sortConfig.key === 'outcomeDay' ? (sortConfig.direction === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        {outcomeDashboardEntries.length > 0 && sortedOutcomeDashboard.map((item, index) => (
                          <tr
                            key={index}
                            className="hand"
                            onClick={() => props.history.push(`/patient-outcome/create?patientBabyId=${item.babyId}`)}
                          >
                            <td>
                              {item.motherName}&nbsp;
                              <span className="superscript-icon" id={`popover-${item.babyId}`}
                                    onMouseEnter={() => setPopoverOpen({ ...popoverOpen, [item.babyId]: true })}
                                    onMouseLeave={() => setPopoverOpen({ ...popoverOpen, [item.babyId]: false })}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setPopoverOpen({ ...popoverOpen, [item.babyId]: true });
                                    }}
                              >
                                  <FontAwesomeIcon icon="info-circle" />
                                </span>
                              <Popover
                                flip
                                target={`popover-${item.babyId}`}
                                isOpen={popoverOpen[item.babyId]}
                              >
                                <PopoverHeader>
                                  Additional Info
                                </PopoverHeader>
                                <PopoverBody>
                                  <Row>
                                    <Col md="12">Mother Mobile Number: {item.motherMobile}</Col>
                                    <Col md="12">Caregiver Name: {item.caregiverName}</Col>
                                    <Col md="12">Caregiver Mobile Number: {item.caregiverPhoneNumber}</Col>
                                    <Col md="12">Alternate Contact Name: {item.secondaryContactName}</Col>
                                    <Col md="12">Alternate Mobile Number: {item.secondaryPhoneNumber}</Col>
                                  </Row>
                                </PopoverBody>
                              </Popover>
                            </td>
                            <td>{item.babyName}</td>
                            <td>{item.ageInDays}</td>
                            <td>{item.outcomeAttempts}</td>
                            <td>{item.outcomeDay}</td>
                          </tr>
                        ))}
                        </tbody>
                      </Table>
                    </TabPane>
                    <TabPane tabId={TABS.DISPATCHER.tabId}>
                      <Table responsive hover>
                        <thead>
                        <tr>
                          <th style={{ width: '24%' }} className="hand border-top-0"
                              onClick={() => handleSort('motherName')}>
                            Mother Name &nbsp;
                            <FontAwesomeIcon
                              icon={sortConfig.key === 'motherName' ? (sortConfig.direction === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                          </th>
                          <th style={{ width: '24%' }} className="hand border-top-0"
                              onClick={() => handleSort('babyName')}>
                            Baby Name &nbsp;
                            <FontAwesomeIcon
                              icon={sortConfig.key === 'babyName' ? (sortConfig.direction === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                          </th>
                          <th style={{ width: '13%' }} className="hand border-top-0"
                              onClick={() => handleSort('ageInDays')}>
                            Baby Age (in days) &nbsp;
                            <FontAwesomeIcon
                              icon={sortConfig.key === 'ageInDays' ? (sortConfig.direction === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                          </th>
                          <th style={{ width: '13%' }} className="hand border-top-0"
                              onClick={() => handleSort('visitCount')}>
                            Visit Number &nbsp;
                            <FontAwesomeIcon
                              icon={sortConfig.key === 'visitCount' ? (sortConfig.direction === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                          </th>
                          <th style={{ width: '13%' }} className="hand border-top-0"
                              onClick={() => handleSort('journeyType')}>
                            Journey Type &nbsp;
                            <FontAwesomeIcon
                              icon={sortConfig.key === 'journeyType' ? (sortConfig.direction === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                          </th>
                          <th style={{ width: '13%' }} className="hand border-top-0"
                              onClick={() => handleSort('journeyStatus')}>
                            Action &nbsp;
                            <FontAwesomeIcon
                              icon={sortConfig.key === 'journeyStatus' ? (sortConfig.direction === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        {dispatcherDashboardEntries.length > 0 && sortedDispatcherDashboard.map((item, index) => (
                          <tr
                            key={index}
                            className="hand"
                            onClick={() => props.history.push(`/patient-visit/journey?patientBabyId=${item.babyId}`)}
                          >
                            <td>
                              {item.motherName}&nbsp;
                              <span className="superscript-icon" id={`dispatcher-popover-${item.babyId}`}
                                    onMouseEnter={() => setPopoverOpen({ ...popoverOpen, [item.babyId]: true })}
                                    onMouseLeave={() => setPopoverOpen({ ...popoverOpen, [item.babyId]: false })}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setPopoverOpen({ ...popoverOpen, [item.babyId]: true });
                                    }}
                              >
                                  <FontAwesomeIcon icon="info-circle" />
                                </span>
                              <Popover
                                flip
                                target={`dispatcher-popover-${item.babyId}`}
                                isOpen={popoverOpen[item.babyId]}
                              >
                                <PopoverHeader>
                                  Additional Info
                                </PopoverHeader>
                                <PopoverBody>
                                  <Row>
                                    <Col md="12">Mother Mobile Number: {item.motherMobile}</Col>
                                    <Col md="12">Caregiver Name: {item.caregiverName}</Col>
                                    <Col md="12">Caregiver Mobile Number: {item.caregiverPhoneNumber}</Col>
                                    <Col md="12">Alternate Contact Name: {item.secondaryContactName}</Col>
                                    <Col md="12">Alternate Mobile Number: {item.secondaryPhoneNumber}</Col>
                                  </Row>
                                </PopoverBody>
                              </Popover>
                            </td>
                            <td>{item.babyName}</td>
                            <td>{item.ageInDays}</td>
                            <td>{item.visitCount}</td>
                            <td>{item.journeyType}</td>
                            <td style={{ color: item.journeyStatusColor }}>{item.journeyStatus}</td>
                          </tr>
                        ))}
                        </tbody>
                      </Table>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>);
  }
;


const mapStateToProps = ({ authentication, patientBaby, patientOutcome }: IRootState) => ({
  userCountryId: authentication.userCountryId,
  isAdmin: hasAnyPermission(authentication.account.roles, ['ADMIN']),
  accountDetail: authentication.account,
  outcomeDashboardEntries: patientBaby.outcomeDashboardEntries,
  dispatcherDashboardEntries: patientBaby.dispatcherDashboardEntries,
  outcomeSettings: patientOutcome.settings
});

const mapDispatchToProps = {
  getOutcomeDashboardEntries,
  getDispatcherDashboardEntries,
  getOutcomeSettings
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Home);
