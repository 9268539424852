import axios from 'axios';
import { ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { defaultValue, IWatotoCareSettings } from 'app/shared/model/watotocare-settings-model';
import { fromCents, fromPercent } from 'app/shared/util/money-utils';

export const ACTION_TYPES = {
  FETCH: 'watotocareSettings/FETCH',
  FETCH_BY_ID: 'watotocareSettings/FETCH_BY_ID',
  UPDATE: 'watotocareSettings/UPDATE',
  RESET: 'watotocareSettings/RESET',
  DELETE_REPORT: 'DELETE_REPORT',
};

const initialState = {
  loading: false,
  errorMessage: null,
  settings: [],
  settingsDetail: defaultValue,
  updating: false,
  updateSuccess: false,
};

export type WatotoCareSettingsState = Readonly<typeof initialState>;

// Reducer
export default (state: WatotoCareSettingsState = initialState, action): WatotoCareSettingsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.UPDATE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case REQUEST(ACTION_TYPES.DELETE_REPORT):
      return {
        ...state
      };
    case FAILURE(ACTION_TYPES.FETCH):
    case FAILURE(ACTION_TYPES.UPDATE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.DELETE_REPORT):
      return {
        ...state
      };

    case SUCCESS(ACTION_TYPES.DELETE_REPORT):
      return {
        ...state
      };

    case SUCCESS(ACTION_TYPES.FETCH): {
      const data = action.payload.data
      console.log("getWatotoCareSettingsById", data)
      data.forEach((item) => {

        item.costKm = fromCents(item.costKm);
        item.flatDriverFee = fromCents(item.flatDriverFee);
        item.driverServiceFee = fromCents(item.driverServiceFee);
        item.driverWaitingFee = fromCents(item.driverWaitingFee);
        item.physicianVisitFee = fromCents(item.physicianVisitFee);
        item.paymentEditThreshold = fromPercent(item.paymentEditThreshold);

        return item;

      });

      return {
        ...state,
        settings: [
          ...data,
          // costKm: fromCents(data.costKm),
          // flatDriverFee: fromCents(data.flatDriverFee),
          // paymentEditThreshold: fromPercent(data.paymentEditThreshold)
        ],
      };
    }


    case SUCCESS(ACTION_TYPES.FETCH_BY_ID): {
      const data = action.payload.data
      return {
        ...state,
        settingsDetail: {
          ...data,
          costKm: fromCents(data.costKm),
          flatDriverFee: fromCents(data.flatDriverFee),
          driverServiceFee: fromCents(data.driverServiceFee),
          driverWaitingFee: fromCents(data.driverWaitingFee),
          physicianVisitFee: fromCents(data.physicianVisitFee),
          paymentEditThreshold: fromPercent(data.paymentEditThreshold),
        },
      };
    }



    case SUCCESS(ACTION_TYPES.UPDATE):
      {
        const data = action.payload.data

        // data.forEach((item) => {
        //   item.costKm = fromCents(item.costKm);
        //   item.flatDriverFee = fromCents(item.flatDriverFee);
        //   item.paymentEditThreshold = fromPercent(item.paymentEditThreshold);

        //   return item;

        // });
        return {
          ...state,
          updating: false,
          updateSuccess: true,
          // settings: [
          //   ...data
          // ],
        };
      }

    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/watotocareSettings';
// Actions

export const getWatotoCareSettings: ICrudGetAllAction<WatotoCareSettingsState> = () => {
  const requestUrl = `${apiUrl}`;
  console.log("getWatotoCareSettingsById", apiUrl)
  return {
    type: ACTION_TYPES.FETCH,
    payload: axios.get(requestUrl),
  };
};

export const getWatotoCareSettingsById: ICrudGetAllAction<WatotoCareSettingsState> = (id) => {
  const requestUrl = `${apiUrl + '/' + id}`;
  return {
    type: ACTION_TYPES.FETCH_BY_ID,
    payload: axios.get(requestUrl),
  };
};


export const getWatotoCareSettingsByCountryID = (id) => {
  const requestUrl = `${apiUrl + '/byCountryId/' + id}`;
  return {
    type: ACTION_TYPES.FETCH_BY_ID,
    payload: axios.get(requestUrl),
  };
};


export const deleteReportDashboard = (reportId) => {
  const requestUrl = `api/delete-report-menu/${reportId}`;
  return {
    type: ACTION_TYPES.DELETE_REPORT,
    payload: axios.get(requestUrl),
  };
};

export const updateWatotoCareSettings: ICrudPutAction<IWatotoCareSettings> = (watotocareSettings) => async dispatch => {
  const id = watotocareSettings.id;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE,
    payload: axios.put(apiUrl + '/' + id, watotocareSettings),
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
