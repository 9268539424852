import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Journey from './journey';

const Routes = ({match}) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={match.url} component={Journey}/>
    </Switch>
  </>
);

export default Routes;
