import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getAllFacility} from "app/entities/healthcare-facility/healthcare-facility.reducer";
import {IRootState} from 'app/shared/reducers';
import {getEntity} from './driver.reducer';
import {getEntities as getVillages} from "app/entities/village/village.reducer";
import {getVillageName} from "app/entities/village/village";
import { convertDateTimeFromServer } from 'app/shared/util/date-utils';

export interface IDriverDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const DriverDetail = (props: IDriverDetailProps) => {
  const { villages } = props;

  useEffect(() => {
    props.getEntity(props.match.params.id);
    props.getVillages();
    props.getAllFacility();
  }, []);
  const getHfName = (id, hfList) => {
 
    const hf = hfList.find(v => v.id === id);
    return !hf ?
      { short:'', full: '',council:'',district:''}
      :
      {
        short: hf.name,
        council:  hf?.council?.name,
        district:  hf?.council?.parent?.name,
        full: (hf.name + ' / ' + hf?.council?.name + ' / ' + hf?.council?.parent?.name)
      };
  }
  const { driverEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          Driver [<b>{driverEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>First Name</dt>
          <dd>{driverEntity.firstName}</dd>
          <dt>Last Name</dt>
          <dd>{driverEntity.lastName}</dd>
          <dt>Created Date</dt>
          <dd>{convertDateTimeFromServer(driverEntity.createdDate)}</dd>
          <dt>Vehicle Type</dt>
          <dd>{driverEntity.vehicleType?.enType}</dd>
          <dt>Phone Number</dt>
          <dd>{driverEntity.phoneNumber}</dd>
          <dt>National ID / Passport Number</dt>
          <dd>{driverEntity.nationalId}</dd>
          <dt>Birth Date</dt>
          <dd>{driverEntity.birthDate}</dd>
          <dt>License Number</dt>
          <dd>{driverEntity.licenseNumber}</dd>
          <dt>License Issue Date</dt>
          <dd>{driverEntity.licenseIssueDate}</dd>
          <dt>License Expiroation Date</dt>
          <dd>{driverEntity.licenseExpirationDate}</dd>
          <dt>MPESA Number</dt>
          <dd>{driverEntity.mpesaNumber}</dd>
          <dt>Vehicle Owner</dt>
          <dd>{driverEntity.ownerFirstName}&nbsp;{driverEntity.ownerLastName}&nbsp;{driverEntity.ownerPhoneNumber}</dd>
          <dt>
            <span id="phoneNumber2">Phone Number 2</span>
          </dt>
          <dd>{driverEntity.phone_number_2}</dd>
          <dt>
            <span id="phoneNumber3">Phone Number 3</span>
          </dt>
          <dd>{driverEntity.phone_number_3}</dd>
          <dt>Comments</dt>
          <dd>{driverEntity.comments}</dd>
          <dt> Home Healthcare Facility Center</dt>
          <dd>{driverEntity.homeHealthcareFacilityId ? getHfName(driverEntity.homeHealthcareFacilityId, props.allHealthfacility).full : ''}</dd>

          <dt>Village</dt>
          <dd>{driverEntity.homeVillageId ? getVillageName(driverEntity.homeVillageId, villages).full : ''}</dd>

          <dt>Neighbor Villages</dt>
          { !driverEntity?.neighborVillageIds ||
            driverEntity.neighborVillageIds.map(id => <dd key={id}>{ getVillageName(id, villages).full }</dd>) }

          <dt>Neighbor Healthcare Facility Center</dt>
          { !driverEntity?.neighborHealthcareFacilityIds ||
            driverEntity.neighborHealthcareFacilityIds.map(id => <dd key={id}>{ getVillageName(id, props.allHealthfacility).full }</dd>) }
        </dl>
        <Button tag={Link} to="/driver" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/driver/${driverEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  driverEntity: storeState.driver.entity,
  villages: storeState.village.entities,
  allHealthfacility: storeState.healthcareFacility.allFacility,
});

const mapDispatchToProps = {
  getEntity,
  getVillages,
  getAllFacility
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DriverDetail);
