import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';
import {getEntities as getJourneys} from 'app/entities/journey/journey.reducer';
import {getUsers} from 'app/modules/administration/user-management/user-management.reducer';
import {createPayment, getPayment, reset, updatePayment} from './payment.reducer';
import {convertDateTimeToServer} from 'app/shared/util/date-utils';

export interface IPaymentUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const PaymentUpdate = (props: IPaymentUpdateProps) => {
  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const {paymentPayment, journeys, watotoCareUsers, loading, updating} = props;

  const handleClose = () => {
    props.history.push('/payment');
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getPayment(props.match.params.id);
    }

    props.getJourneys();
    props.getUsers();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const savePayment = (event, errors, values) => {
    values.tenderedDateTime = convertDateTimeToServer(values.tenderedDateTime);
    values.adminApprovedDateTime = convertDateTimeToServer(values.adminApprovedDateTime);
    values.govApprovedDateTime = convertDateTimeToServer(values.govApprovedDateTime);

    if (errors.length === 0) {
      const payment = {
        ...paymentPayment,
        ...values,
      };

      if (isNew) {
        props.createPayment(payment);
      } else {
        props.updatePayment(payment);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="watotocareApp.payment.home.createOrEditLabel">Create or edit a Payment</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : paymentPayment} onSubmit={savePayment}>
              {!isNew ? (
                <AvGroup>
                  <Label for="payment-id">ID</Label>
                  <AvInput id="payment-id" type="text" className="form-control" name="id" required readOnly/>
                </AvGroup>
              ) : null}
              {/*  <AvGroup>
                <Label id="statusLabel" for="payment-status">
                  Status
                </Label>
                <AvInput
                  id="payment-status"
                  type="select"
                  className="form-control"
                  name="status"
                  value={(!isNew && paymentPayment.status) || 'REQUESTED'}
                >
                  <option value="REQUESTED">REQUESTED</option>
                  <option value="APPROVED">APPROVED</option>
                  <option value="PROCESSED">PROCESSED</option>
                  <option value="REJECTED">REJECTED</option>
                </AvInput>
              </AvGroup>*/}
              <AvGroup>
                <Label id="amountLabel" for="payment-amount">
                  Amount
                </Label>
                <AvField
                  id="payment-amount"
                  type="string"
                  className="form-control"
                  name="requestedAmount"
                  validate={{
                    required: {value: true, errorMessage: 'This field is required.'},
                    number: {value: true, errorMessage: 'This field should be a number.'},
                  }}
                />
              </AvGroup>
              {/*       <AvGroup>
                <Label id="tenderedDateTimeLabel" for="payment-tenderedDateTime">
                  Tendered Date Time
                </Label>
                <AvInput
                  id="payment-tenderedDateTime"
                  type="datetime-local"
                  className="form-control"
                  name="tenderedDateTime"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.paymentPayment.tenderedDateTime)}
                />
              </AvGroup>
             <AvGroup>
                <Label id="adminApprovedDateTimeLabel" for="payment-adminApprovedDateTime">
                  Admin Approved Date Time
                </Label>
                <AvInput
                  id="payment-adminApprovedDateTime"
                  type="datetime-local"
                  className="form-control"
                  name="approvedDateTime"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.paymentPayment.adminApprovedDateTime)}
                />
              </AvGroup>
              <AvGroup>
                <Label id="govApprovedDateTimeLabel" for="payment-govApprovedDateTime">
                  Processed Date Time
                </Label>
                <AvInput
                  id="payment-govApprovedDateTime"
                  type="datetime-local"
                  className="form-control"
                  name="processedDateTime"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.paymentPayment.govApprovedDateTime)}
                />
              </AvGroup>*/}
              <AvGroup>
                <Label for="payment-journey">Journey</Label>
                <AvInput id="payment-journey" type="select" className="form-control" name="journeyId">
                  <option value="" key="0"/>
                  {journeys
                    ? journeys.map(otherPayment => (
                      <option value={otherPayment.id} key={otherPayment.id}>
                        {otherPayment.id}
                      </option>
                    ))
                    : null}
                </AvInput>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/payment" replace color="info">
                <FontAwesomeIcon icon="arrow-left"/>
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-payment" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save"/>
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  journeys: storeState.journey.entities,
  watotoCareUsers: storeState.userManagement.users,
  paymentPayment: storeState.payment.payment,
  loading: storeState.payment.loading,
  updating: storeState.payment.updating,
  updateSuccess: storeState.payment.updateSuccess,
});

const mapDispatchToProps = {
  getJourneys,
  getUsers,
  getPayment,
  updatePayment,
  createPayment,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PaymentUpdate);
