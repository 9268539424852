import {IRegion} from "app/shared/model/region.model";
import {IWatotoCareEntityMaster} from "app/shared/model/watotocare-entity-master.model";

export interface IWatotoCareEntityDetail {
  id?: string;
  enDescription?: string;
  stDescription?: string;
  countryId?: string;
  country?: IRegion;
  isActive?: boolean;
  displayOrder?: number;
  entityMasterId?: number;
  entityMaster?: IWatotoCareEntityMaster
}

export const defaultValue: Readonly<IWatotoCareEntityDetail> = {};
