import React from 'react';
import {Switch} from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Patient from './patient';
import PatientDetail from './patient-detail';
import PatientUpdate from './patient-update';
import PatientCreate from './patient-update';

const Routes = ({match}) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact key="patient-add" path={`${match.url}/create`} component={PatientCreate}/>
      <ErrorBoundaryRoute exact key="patient-update" path={`${match.url}/:id/edit`} component={PatientUpdate}/>
      <ErrorBoundaryRoute exact key="patient-view" path={`${match.url}/:id`} component={PatientDetail}/>
      <ErrorBoundaryRoute key="patient-list" path={match.url} component={Patient}/>
    </Switch>
  </>
);

export default Routes;
