import {IVillage} from 'app/shared/model/village.model';
import {IHealthcareFacility} from 'app/shared/model/healthcare-facility.model';
import {IWaypoint} from 'app/shared/model/waypoint.model';
import {IJourney} from 'app/shared/model/journey.model';
import {RouteStartType} from 'app/shared/model/enumerations/route-start-type.model';
import {RouteEndType} from 'app/shared/model/enumerations/route-end-type.model';

export interface IRoute {
  id?: string;
  distance?: number;
  durationSeconds?: number;
  overridePrice?: number;
  description?: string;
  isActive?: boolean;
  startType?: RouteStartType;
  endType?: RouteEndType;
  startVillage?: IVillage;
  startFacility?: IHealthcareFacility;
  endFacility?: IHealthcareFacility;
  waypoints?: IWaypoint[];
  journey?: IJourney;
  startVillageId?:string;
  endVillage?: IVillage;
  endVillageId?:string;
}

export const defaultValue: Readonly<IRoute> = {
  isActive: false,
};
