import { IWatotoCareEntityDetail } from 'app/shared/model/watotocare-entity-detail.model';

export interface IPatientOutcome {
  id?: string;
  patientCaseId?: string;
  createdAt?: Date;
  outcomeNumber?: number;
  motherIsAlive?: boolean;
  babyIsAlive?: boolean;
  babyHasHiv?: boolean;
  lastRecordedWeight?: number;
  weightRecordedAt?: string;
  // readmissionReason?: string;
  // readmissionReasonEntity?: IWatotoCareEntityDetail;
  // readmissionReasonComment?: string;
  // feedingMethod?: string;
  // feedingMethodEntity?: IWatotoCareEntityDetail;
  // feedingMethodComment?: string;
  // notBreastfeedingReason?: string;
  // notBreastfeedingReasonEntity?: IWatotoCareEntityDetail;
  // notBreastfeedingComment?: string;
  followupVisitsCompleted?: number;
  reasonForNotCompleted?: string;
  reasonForNotCompletedEntity?: IWatotoCareEntityDetail;
  notCompletedComment?: string;
  // breastHealthSigns?: string;
  // breastHealthSignsEntity?: IWatotoCareEntityDetail;
  // postpartumMentalHealth?: string;
  // postpartumMentalHealthEntity?: IWatotoCareEntityDetail;
  // postpartumBleeding?: string;
  // postpartumBleedingEntity?: IWatotoCareEntityDetail;
  // modernContraceptiveUptake?: string;
  facilityName?: string;
  outcomeAttemptRequired?: boolean;
  patientOutcomeEntities?: {
    patientOutcomeId?: string;
    entityDetailId?: string;
    entityDetail?: IWatotoCareEntityDetail;
    comment?: string;
  }[];
  patientBabyOutcomeEntities?: {
    patientOutcomeId?: string;
    entityDetailId?: string;
    entityDetail?: IWatotoCareEntityDetail;
    comment?: string;
  }[];
}

export const defaultValue: Readonly<IPatientOutcome> = {};
