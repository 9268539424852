import 'react-toastify/dist/ReactToastify.css';
import './app.scss';

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { hot } from 'react-hot-loader';

import { IRootState } from 'app/shared/reducers';
import { getSession, getReportMenu, getMainMenu } from 'app/shared/reducers/authentication';
import { getPermissionSettings } from "app/modules/administration/permission-settings/permission-settings.reducer";
import { getProfile } from 'app/shared/reducers/application-profile';
import Header from 'app/shared/layout/header/header';
import Footer from 'app/shared/layout/footer/footer';
import { hasAnyPermission } from 'app/shared/auth/permissions';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { PERMISSIONS } from 'app/config/constants';
import AppRoutes from 'app/routes';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export interface IAppProps extends StateProps, DispatchProps { }

export const App = (props: IAppProps) => {

  const { isAuthenticated, mustChangePassword, loading } = props;

  useEffect(() => {
    props.getSession();
    props.getProfile();
    // props.getReportMenu(1);
  }, []);
  useEffect(() => {
    if(isAuthenticated && !mustChangePassword) {
      props.getReportMenu(1);
      props.getMainMenu(1);
      props.getPermissionSettings();
    }
  }, [ isAuthenticated ]);

  // useEffect(() => {
  //   const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
  //   events.forEach((event) => {
  //     window.addEventListener(event, resetTimeout);
  //   });
  //   const logoutTimeout = setTimeout(() => {
  //     alert('You will be logged out automatically in 1 minute.');
  //   }, 10000);

  //   return () => {
  //     clearTimeout(logoutTimeout);
  //   }
  // }, [isAuthenticated])

  const paddingTop = '70px';
  console.log("authenticationData", props.authenticationData)
  if (loading !== false) {
    // If loading is undefined or true, show nothing to avoid flicker on refresh
    return (
      <div></div>
    )
  } else {
    return (
      <Router basename={baseHref}>
        <div className="app-container" style={{ paddingTop }}>
          {isAuthenticated && !mustChangePassword ? (
            <>
              <ErrorBoundary>
                <Header
                  isAuthenticated={isAuthenticated}
                  permissions={props.permissions}
                  ribbonEnv={props.ribbonEnv}
                  isInProduction={props.isInProduction}
                  isSwaggerEnabled={props.isSwaggerEnabled}
                />
              </ErrorBoundary>
            </>
          ) :
            null
          }
          <div className="container-fluid view-container" id="app-view-container">
            <ErrorBoundary>
              <AppRoutes isAuthenticated={isAuthenticated} isAdmin={props.isAdmin} mustChangePassword={mustChangePassword} permissionSettings={props.permissionSettings} />
            </ErrorBoundary>
            {/* <Footer /> */}
          </div>
        </div>
      </Router>
    )
  }
}

const mapStateToProps = ({ authentication, applicationProfile, permissionSettings }: IRootState) => ({
  loading: authentication.loading,
  isAuthenticated: authentication.isAuthenticated,
  mustChangePassword: authentication?.account?.mustChangePassword,
  isAdmin: hasAnyPermission(authentication.account.roles, ['ADMIN']),
  permissions: authentication.permissions,
  authenticationData: authentication.account.roles,
  ribbonEnv: applicationProfile.ribbonEnv,
  isInProduction: applicationProfile.inProduction,
  isSwaggerEnabled: applicationProfile.isSwaggerEnabled,
  permissionSettings: permissionSettings.settings,
});

const mapDispatchToProps = { getSession, getProfile, getReportMenu, getMainMenu, getPermissionSettings };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(App));
