import React from 'react';
import {Switch} from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import PatientVisitUpdate from './patient-visit-update';
import PatientVisitJourney from 'app/entities/patient-visit/patient-visit-journey';

const Routes = ({match}) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact  key="patient-visit-add" path={`${match.url}/create`} component={PatientVisitUpdate}/>
      <ErrorBoundaryRoute exact  key="patient-visit-journey" path={`${match.url}/journey`} component={PatientVisitJourney}/>
    </Switch>
  </>
);

export default Routes;
