import React, { createRef, useEffect, useRef, useState } from 'react'

export const PRIMARY_CHART_COLOR = '#008631';
export const SECONDARY_CHART_COLOR = '#00ab41';
export const OPTIONS_FILTERS_CATEGORIES = ["Time", "Geography" /* , "Health Facility", "Cases" */];
export const MONTH_LABELS = [
    { month: 1, shortMonth: 'Jan', fullMonth: 'Janaury'},
    { month: 2, shortMonth: 'Feb', fullMonth: 'February'},
    { month: 3, shortMonth: 'Mar', fullMonth: 'March'},
    { month: 4, shortMonth: 'Apr', fullMonth: 'April'},
    { month: 5, shortMonth: 'May', fullMonth: 'May'},
    { month: 6, shortMonth: 'Jun', fullMonth: 'June'},
    { month: 7, shortMonth: 'Jul', fullMonth: 'July'},
    { month: 8, shortMonth: 'Aug', fullMonth: 'August'},
    { month: 9, shortMonth: 'Sep', fullMonth: 'September'},
    { month: 10, shortMonth: 'Oct', fullMonth: 'October'},
    { month: 11, shortMonth: 'Nov', fullMonth: 'November'},
    { month: 12, shortMonth: 'Dec', fullMonth: 'December'},
  ];
export const GREEN_COLORS = ['#008631', '#00ab41', '#00c04b', '#1fd655', '#39e75f', '#5ced73', '#83f28f', '#abf7b1', '#cefad0'];
export const BLUE_COLORS = [
  '#2d4365',
  '#354e76',
  '#3c5a87',
  '#446598',
  '#4b70a9',
  '#4b70a9',
  '#5d7eb2',
  '#6f8dba',
  '#819bc3',
  '#93a9cb',
  '#a5b8d4',
];
export const COLORS = [...BLUE_COLORS, ...GREEN_COLORS, ...BLUE_COLORS, ...GREEN_COLORS];
