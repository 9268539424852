import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, ButtonGroup, Col, Label, Row } from 'reactstrap';
import { AvFeedback, AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { createEntity, getEntity, reset, updateEntity } from './physician.reducer';
import VillageAvTypeahead from 'app/shared/layout/menus/village-av-typeahead';
import { getAllFacility } from 'app/entities/healthcare-facility/healthcare-facility.reducer';
import { hasAnyPermission } from 'app/shared/auth/permissions';
import { getHfName } from 'app/entities/healthcare-facility/healthcare-facility';
import { IWatotoCareEntityMaster } from 'app/shared/model/watotocare-entity-master.model';
import { getMasterEntitiesByEnumNames } from 'app/entities/watotocare-entity/watotocare-entity.reducer';
import { IWatotoCareEntityDetail } from 'app/shared/model/watotocare-entity-detail.model';
import { getAllByType as getCountries } from 'app/entities/region/region.reducer';

export interface IPhysicianUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const PhysicianUpdate = (props: IPhysicianUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [activeStatus, setActiveStatus] = useState(true);
  const [redirecting, setRedirecting] = useState(false);
  const [activeCountry, setActiveCountry] = useState(null);
  const [facilityDepartmentEntity, setFacilityDepartmentEntity] = useState<IWatotoCareEntityMaster>(null);
  const WATOTOCARE_ENTITY_ENUM_NAMES = ['PHYSICIAN_FACILITY_DEPARTMENT'];

  const {
    physicianEntity,
    loading,
    updating,
    isAdmin,
    userCountryId,
    countries,
    allHealthFacilities,
    watotoCareEntityList,
    healthcareFacilityLoading,
    hasUpdateAccess
  } = props;

  const handleClose = (message = '') => {
    if (!redirecting) {
      if (message && message !== '') {
        alert(message);
      }
      setRedirecting(true);
      props.history.push('/physician');
    }
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
    props.getCountries();
  }, []);


  useEffect(() => {
    if (isNew && countries.length > 0 && userCountryId && userCountryId !== '') {
      const ac = countries.find(country => country.id === (isAdmin ? countries[0].id : userCountryId));
      setActiveCountry(ac);
      props.getAllFacility(ac.id);
      props.getMasterEntitiesByEnumNames(WATOTOCARE_ENTITY_ENUM_NAMES, ac.id);
    }
  }, [countries]);

  useEffect(() => {
    if (physicianEntity.id && userCountryId && userCountryId !== '' && countries.length > 0) {
      const physicianCountryId = physicianEntity.healthcareFacility?.council?.parent?.parent?.parent?.id;
      if (!isAdmin && userCountryId !== physicianCountryId) {
        handleClose('You do not have access to this record');
      }
      const ac = countries.find(country => country.id === physicianCountryId);
      setActiveCountry(ac);
      props.getAllFacility(physicianCountryId);
      props.getMasterEntitiesByEnumNames(WATOTOCARE_ENTITY_ENUM_NAMES, physicianCountryId);
    }
  }, [physicianEntity, countries]);

  const regCountriesList = countries.filter((ele) => {
    return isAdmin ? 1 : ele.id === activeCountry?.id;
  });

  useEffect(() => {
    if (watotoCareEntityList.length > 0) {
      // set watotoCareEntity for each entity in watotoCareEntityList
      const facilityDepartmentEntityMaster = watotoCareEntityList.find(entity => entity.enumName === 'PHYSICIAN_FACILITY_DEPARTMENT');
      if (facilityDepartmentEntityMaster) {
        setFacilityDepartmentEntity(facilityDepartmentEntityMaster);
      }
    }
  }, [watotoCareEntityList]);


  const onCountryChange = (event) => {
    const countryId = event.target.value;
    const newActiveCountry = props.countries.find(country => country.id === countryId);
    setActiveCountry(newActiveCountry);
    props.getAllFacility(countryId);
    props.getMasterEntitiesByEnumNames(WATOTOCARE_ENTITY_ENUM_NAMES, countryId);
  };

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  useEffect(() => {
    if (!isNew) {
      setActiveStatus(physicianEntity?.isActive);
    }
  }, [physicianEntity]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...physicianEntity,
        ...values,
        isActive: activeStatus
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  const hfNames = allHealthFacilities ?
    allHealthFacilities.map(hf => ({
      ...getHfName(hf.id, allHealthFacilities),
      id: hf.id
    })) : [];

  const hfOptionList = (includeEmpty = false) => {
    let result = (includeEmpty) ? ([{ short: '', full: '', id: undefined }]) : [];
    result = result.concat(hfNames);
    return result;
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="watotocareApp.physician.home.createOrEditLabel">Create or edit a Physician</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : physicianEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="physician-id">ID</Label>
                  <AvInput id="physician-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}

              <Row class="justify-content-center">
                <Col md="12" className="mt-4">
                  <AvGroup>
                    <Label id="isActive" for="physician-isActive">
                      Active Status
                    </Label>
                    <ButtonGroup style={{ marginLeft: '30px' }}>
                      <Button
                        color={activeStatus ? 'primary' : 'secondary'}
                        disabled={!hasUpdateAccess}
                        onClick={() => {
                          setActiveStatus(true);
                        }}
                      >
                        Active
                      </Button>
                      <Button
                        color={!activeStatus ? 'primary' : 'secondary'}
                        disabled={!hasUpdateAccess}
                        onClick={() => {
                          setActiveStatus(false);
                        }}
                      >
                        Inactive
                      </Button>
                    </ButtonGroup>
                  </AvGroup>
                </Col>
              </Row>

              <br />
              {regCountriesList && regCountriesList.length > 0 && (
                <AvGroup>
                  <Label for="hf-country">Country</Label>
                  <AvInput id="hf-country" type="select" className="form-control" name="country"
                           disabled={!isNew}
                           value={activeCountry?.id} onChange={onCountryChange}>
                    {regCountriesList
                      ? regCountriesList.map(country => (
                        <option value={country.id} key={country.id}>
                          {country.name}
                        </option>
                      ))
                      : null}
                  </AvInput>
                </AvGroup>
              )
              }
              <AvGroup>
                <Label id="firstName" for="physician-firstName">
                  First Name
                </Label>
                <AvField
                  id="physician-firstName"
                  type="text"
                  name="firstName"
                  disabled={!hasUpdateAccess}
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="lastName" for="physician-lastName">
                  Last Name
                </Label>
                <AvField
                  id="physician-lastName"
                  type="text"
                  name="lastName"
                  disabled={!hasUpdateAccess}
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="phoneNumber" for="physician-phoneNumber">
                  Phone Number
                </Label>
                <AvField
                  id="physician-phoneNumber"
                  type="number"
                  name="phoneNumber"
                  disabled={!hasUpdateAccess}
                  validate={{
                    max: { value: 9999999999, errorMessage: 'Invalid phone number.' },
                    required: { value: true, errorMessage: 'This field is required.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="mpesaNumberLabel" for="physician-mpesaNumber">
                  M-PESA Number
                </Label>
                <AvField
                  id="physician-mpesaNumber"
                  type="text"
                  name="mpesaNumber"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' },
                    maxLength: { value: 10, errorMessage: 'Maximum length is 10 digits' },
                    number: { value: true, errorMessage: 'This field should be a number.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="healthcareFacilityId" for="physician-healthcareFacilityId">
                  Unit or Department
                </Label>
                {hasUpdateAccess ?
                  <>
                    <VillageAvTypeahead
                      labelKey="name"
                      valueKey="id"
                      data={hfOptionList(false)}
                      placeholder={isNew ? 'Unit or Department' : getHfName(physicianEntity.healthcareFacilityId, allHealthFacilities).full}
                      id="physician-healthcareFacilityId"
                      loading={healthcareFacilityLoading}
                      input={{
                        id: 'physician-healthcareFacilityId',
                        name: 'healthcareFacilityId',
                        required: true
                      }}
                    />
                    <AvFeedback>This field is required.</AvFeedback>
                  </> :
                  <AvInput
                    type="text"
                    disabled
                    name="healthcareFacilityId"
                    value={getHfName(physicianEntity.healthcareFacilityId, allHealthFacilities).full}
                  />
                }

              </AvGroup>

              {/* Facility Department Select */}
              <AvGroup>
                <AvField
                  label={facilityDepartmentEntity?.entityName || 'Facility Department'}
                  type="select"
                  id="physician-facilityDepartment"
                  name="facilityDepartment"
                  disabled={!hasUpdateAccess}
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' }
                  }}
                >
                  <option disabled selected value="">-- select an option --</option>
                  {facilityDepartmentEntity?.watotoCareEntityDetails
                    .filter((entity: IWatotoCareEntityDetail) => entity.isActive)
                    .sort((a: IWatotoCareEntityDetail, b: IWatotoCareEntityDetail) => a.displayOrder - b.displayOrder)
                    .map((entity: IWatotoCareEntityDetail) => (
                      <option key={entity.id} value={entity.id}>
                        {entity.enDescription}
                      </option>
                    ))}
                </AvField>
              </AvGroup>


              <br />

              <Button tag={Link} id="cancel-save" to="/physician" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating || !hasUpdateAccess}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  physicianEntity: storeState.physician.entity,
  loading: storeState.physician.loading,
  updating: storeState.physician.updating,
  updateSuccess: storeState.physician.updateSuccess,
  healthcareFacilityLoading: storeState.healthcareFacility.loading,
  isAdmin: storeState.authentication.account?.isAdmin,
  userCountryId: storeState.authentication.account?.countryId,
  countries: storeState.region.countries,
  allHealthFacilities: storeState.healthcareFacility.allFacility,
  watotoCareEntityList: storeState.watotoCareEntity.masterEntities,
  hasUpdateAccess: hasAnyPermission(storeState.authentication.permissions, ['ADMIN', 'ENTITIES_MODIFY'])
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
  getAllFacility,
  getCountries,
  getMasterEntitiesByEnumNames
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PhysicianUpdate);
