import {RegionType} from 'app/shared/model/enumerations/region-type.model';
import {ILocation} from "app/shared/model/location.model";

export interface IRegion {
  id?: string;
  name?: string;
  locationLat?: number;
  locationLon?: number;
  description?: string;
  dispatchCenterId?: string;
  type?: RegionType;
  continentName?: string;
  continentId?: number;
  parentName?: string;
  parentId?: string;
  children?: IRegion[];
  level?: number
  key?: string;
  label?: string;
  nodes?: IRegion[];
  parent?: IRegion;
}

export interface IDispatchCenter {
  id?: string;
  name?: string;
  countryId?: string;
  districtIds?:[];
  region?: [];
}


export interface IRegionDistrict {
  id?: string;
  name?: string;
  districtIds?: [];
  locationLat?: number;
  locationLon?: number;
  description?: string;
  type?: RegionType;
  continentName?: string;
  continentId?: number;
  parentName?: string;
  parentId?: string;
  children?: IRegion[];
  level?: number
  key?: string;
  label?: string;
  nodes?: IRegion[];
  parent?: IRegion;
}


export interface IRegionSearchResponse {
  regionResults: [IRegion],
  regionResultEntities: [IRegion]
}

export const defaultValue: Readonly<IRegion> = {};
