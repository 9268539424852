import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import {DropdownItem} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {NavDropdown} from './menu-components';

export const AdminMenuItems = (props) => {


  return (
    <>
      {
        props.menuArray.map((menu) => {
          menu.children.sort((a, b) => a.displayOrder.localeCompare(b.displayOrder));
          return (<>
            {(menu.data.slug === 'administration') ?
              <>
                {
                  menu.children.length > 0 ? menu.children.map((childMenu, index) => {
                      let childShow = false;
                      if (props.pagesView.includes(childMenu.permissions)) {
                        childShow = true;
                      }
                      return (
                        <>
                          <MenuItem show={childShow} key={index + "-" + childMenu.slug} icon="minus"
                                    to={"/admin/" + childMenu.slug}>
                            {childMenu.name}
                          </MenuItem>
                        </>
                      )
                    }) :
                    <></>
                }
              </>
              :
              ''
            }
          </>)
        })
      }
      {/* <MenuItem icon="user" show={true} to="/admin/user-management"> */}
      {/*   User Management */}
      {/* </MenuItem> */}
      {/* <MenuItem icon="money-check-alt" show={true} to="/admin/watotocare-settings"> */}
      {/*   Watotocare Settings */}
      {/* </MenuItem> */}

      {/* <MenuItem icon={faChartLine} show={true} to="/admin/report-settings"> */}
      {/*   Program Dashboard Settings */}
      {/* </MenuItem> */}

      {/* <MenuItem icon={faChartLine} show={true} to="/admin/permission-settings"> */}
      {/*   Manage Permission */}
      {/* </MenuItem> */}

      {/* <MenuItem icon={faMoneyBill} show={true} to="/admin/payment-breakdown"> */}
      {/*   Payment Breakdown */}
      {/* </MenuItem> */}

    </>
  )


};

const swaggerItem = (
  <MenuItem show={true} icon="book" to="/admin/docs">
    API
  </MenuItem>
);

const databaseItem = (
  <DropdownItem tag="a" href="./h2-console" target="_tab">
    <FontAwesomeIcon icon="hdd" fixedWidth/> Database
  </DropdownItem>
);

export const AdminMenu = ({showSwagger, showDatabase, pagesView, menuArray}) => (
  <NavDropdown show={true} permission={pagesView} icon="user-plus" name="Administration" style={{width: '180%'}}
               id="admin-menu">
    <AdminMenuItems pagesView={pagesView} menuArray={menuArray}/>
  </NavDropdown>
);

export default AdminMenu;
