import React, { useEffect, useState } from 'react'
import { DisplayCardGroup } from './displayCardGroup';
import { DisplayIndicator } from './displayCardIndicator';
import { BarChart, PieChart, StackedBarChart } from '../../../charts';
import { SECONDARY_CHART_COLOR, MONTH_LABELS, PRIMARY_CHART_COLOR, COLORS } from '../constants';
import {mergeAndSum} from "app/modules/programDashboard/utils";

export const TriageConditionsSection = ({ countReferrals, emergencyReferrals }) => {
    const [labels, setLabels] = useState([]);
    const [graphData, setGraphData] = useState({});
    const [maternalCount, setMaternalCount] = useState(0);
    const [maternalData, setMaternalData] = useState([]);
    const [neonatalCount, setNeonatalCount] = useState(0);
    const [neonatalData, setNeonatalData] = useState([]);
    const [neonatalLabels, setNeonatalLabels] = useState([]);
    const [neonatalGraphData, setNeonatalGraphData] = useState({});

    const TOTAL_CARD_GROUP_DATA = [
        { label: "3.1 Total maternal emergencies", icon: "content/images/mother_holding_baby.svg", value: countReferrals?.motherCount },
        { label: "3.2 Total neonatal emergencies:", icon: "content/images/new_child_friendly.svg", value: countReferrals?.babyCount },
        { label: "3.3 Total both emergencies:", icon: "content/images/pregnant_woman_black.svg", value: countReferrals?.bothCount },
    ]

    useEffect(() => {
        if (emergencyReferrals?.maternalMaternal && Object.keys(emergencyReferrals?.maternalMaternal)) {
            const months = [];
            const neonatalMonths = [];
            const mMaternalKeys = Object.keys(emergencyReferrals?.maternalByMaternal);
            const data = {
                Maternal: {
                  label: 'Maternal',
                  color: PRIMARY_CHART_COLOR,
                  data: [],
                },
                'In Maternal': {
                  label: 'In Maternal',
                  color: SECONDARY_CHART_COLOR,
                  data: [],
                },
            };
            const neonatalGraph = {
                Community: {
                  label: 'Community',
                  color: PRIMARY_CHART_COLOR,
                  data: [],
                },
                'Health Facility': {
                  label: 'Health Facility',
                  color: SECONDARY_CHART_COLOR,
                  data: [],
                },
            };

            // Maternal
            const aggregatedGraphData = mergeAndSum([
                emergencyReferrals?.maternalMaternal,
                emergencyReferrals?.maternalByMaternal,
                emergencyReferrals?.maternalByMaternal
            ])

            const graphDistrictKeys = Object.keys(aggregatedGraphData)
                .sort(function(a,b){return aggregatedGraphData[b]-aggregatedGraphData[a]});

            for(let i = 0; i < graphDistrictKeys.length; i++) {
                data['Maternal'].data.push(emergencyReferrals?.maternalMaternal[graphDistrictKeys[i]] ?? 0)
                data['In Maternal'].data.push(emergencyReferrals?.maternalByMaternal[graphDistrictKeys[i]] ?? 0)
                data['Maternal'].data.push(emergencyReferrals?.maternalByMaternal[graphDistrictKeys[i]] ?? 0)
            }

            // Neonatal
            const aggregatedNeonatalGraphData = mergeAndSum([
                emergencyReferrals?.neonatalByCommunity,
                emergencyReferrals?.neonatalByHf,
            ])

            const neonatalGraphDistrictKeys = Object.keys(aggregatedNeonatalGraphData)
                .sort(function(a,b){return aggregatedNeonatalGraphData[b]-aggregatedNeonatalGraphData[a]});

            for(let i = 0; i < neonatalGraphDistrictKeys.length; i++) {
                neonatalGraph['Community'].data.push(emergencyReferrals?.neonatalByCommunity[neonatalGraphDistrictKeys[i]] ?? 0)
                neonatalGraph['Health Facility'].data.push(emergencyReferrals?.neonatalByHf[neonatalGraphDistrictKeys[i]] ?? 0)
            }


            let maternalSum = 0;
            let neonatalSum = 0;

            const maternalKeys = Object.keys(emergencyReferrals?.maternalEmergencies).map((el, i) => {
                maternalSum = maternalSum + emergencyReferrals?.maternalEmergencies[el];
                return { label: el, bdColor: '#000000', bgColor: COLORS[i + 1], data: emergencyReferrals?.maternalEmergencies[el] };
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            const neonatalKeys = Object.keys(emergencyReferrals?.neonatalEmergencies).map((el, i) => {
                neonatalSum = neonatalSum + emergencyReferrals?.neonatalEmergencies[el];
                return { label: el, bdColor: '#000000', bgColor: COLORS[i + 1], data: emergencyReferrals?.neonatalEmergencies[el] };
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            setLabels(graphDistrictKeys);
            setGraphData(data);
            setNeonatalLabels(neonatalGraphDistrictKeys);
            setNeonatalGraphData(neonatalGraph)
            setMaternalCount(maternalSum);
            setMaternalData(maternalKeys);
            setMaternalCount(maternalSum);
            setMaternalData(maternalKeys);
            setMaternalCount(maternalSum);
            setMaternalData(maternalKeys);
            setNeonatalCount(neonatalSum);
            setNeonatalData(neonatalKeys);
        }
    }, [emergencyReferrals]);

    return (
        <div className='mt-0 mb-3'>
            <p className="mb-2" style={{ fontSize: "18px", fontWeight: 600, color: SECONDARY_CHART_COLOR }}>3. Triage Conditions</p>
            <div className="row mb-3">
                <DisplayCardGroup data={TOTAL_CARD_GROUP_DATA.slice(0, 3)} col="col-4" />
            </div>
            {/* <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "3.4 Number of Emergencies per stage of pregnancy:", value: "1250" }}>
                        <StackedBarChart chartLabels={DUMMMY_LABELS} chartData={DUMMY_NOEPSOP_DATA} />
                    </DisplayIndicator>
                </div>
            </div> */}
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "3.4 Number of maternal emergencies per stage of pregnancy", value: "" }}>
                        <StackedBarChart chartLabels={labels} chartData={graphData} axis='y' />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "3.5 Maternal emergencies - Triage conditon:", value: maternalCount }}>
                        {/* <PieChart chartData={maternalData} /> */}
                        <BarChart chartLabel='Maternal emergencies' labels={maternalData.map(item => item.label)} dataSet={maternalData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "3.6 Maternal emergencies - Triage conditon:", value: maternalCount }}>
                        {/* <PieChart chartData={maternalData} /> */}
                        <BarChart chartLabel='Maternal emergencies' labels={maternalData.map(item => item.label)} dataSet={maternalData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "3.7 Maternal emergencies - Triage conditon:", value: maternalCount }}>
                        {/* <PieChart chartData={maternalData} /> */}
                        <BarChart chartLabel='Maternal emergencies' labels={maternalData.map(item => item.label)} dataSet={maternalData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "3.8 Neonatal emergencies:", value: "" }}>
                        <StackedBarChart chartLabels={neonatalLabels} chartData={neonatalGraphData} axis='y' />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "3.9 Neonatal emergencies - Triage conditon:", value: neonatalCount }}>
                        {/* <PieChart chartData={neonatalData} /> */}
                        <BarChart chartLabel='Neonatal emergencies' labels={neonatalData.map(item => item.label)} dataSet={neonatalData.map(item => item.data)} axis='y' bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
        </div>
    )
}
