import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction, IPayloadResult } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IWatotoCareEntityDetail } from 'app/shared/model/watotocare-entity-detail.model';
import { IPayload } from 'react-jhipster/src/type/redux-action.type';
import { IWatotoCareEntityMaster } from 'app/shared/model/watotocare-entity-master.model';

export const ACTION_TYPES = {
  FETCH_ENTITY_LIST: 'watotocare-entity/FETCH_ENTITY_LIST',
  FETCH_MASTER_ENTITY_LIST: 'watotocare-entity/FETCH_MASTER_ENTITY_LIST',
  FETCH_MASTER_ENTITY_LIST_BY_NAMES: 'watotocare-entity/FETCH_MASTER_ENTITY_LIST_BY_NAMES',
  FETCH_MASTER_ENTITY_LIST_BY_GROUP_NAMES: 'watotocare-entity/FETCH_MASTER_ENTITY_LIST_BY_GROUP_NAMES',
  FETCH_ENTITY: 'watotocare-entity/FETCH_ENTITY',
  CREATE_ENTITY: 'watotocare-entity/CREATE_ENTITY',
  UPDATE_ENTITY: 'watotocare-entity/UPDATE_ENTITY',
  DELETE_ENTITY: 'watotocare-entity/DELETE_ENTITY',
  RESET: 'watotocare-entity/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IWatotoCareEntityDetail>,
  entity: defaultValue,
  masterEntities: [] as ReadonlyArray<IWatotoCareEntityMaster>,
  masterEntitiesByGroup: {},
  updating: false,
  updateSuccess: false,
  deleteFailure: false
};

export type WatotoCareEntityState = Readonly<typeof initialState>;

// Reducer

export default (state: WatotoCareEntityState = initialState, action): WatotoCareEntityState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ENTITY_LIST):
    case REQUEST(ACTION_TYPES.FETCH_MASTER_ENTITY_LIST):
    case REQUEST(ACTION_TYPES.FETCH_MASTER_ENTITY_LIST_BY_NAMES):
    case REQUEST(ACTION_TYPES.FETCH_MASTER_ENTITY_LIST_BY_GROUP_NAMES):
    case REQUEST(ACTION_TYPES.FETCH_ENTITY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        deleteFailure: false
      };
    case REQUEST(ACTION_TYPES.CREATE_ENTITY):
    case REQUEST(ACTION_TYPES.UPDATE_ENTITY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case REQUEST(ACTION_TYPES.DELETE_ENTITY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
        deleteFailure: false
      };
    case FAILURE(ACTION_TYPES.FETCH_ENTITY_LIST):
    case FAILURE(ACTION_TYPES.FETCH_MASTER_ENTITY_LIST):
    case FAILURE(ACTION_TYPES.FETCH_MASTER_ENTITY_LIST_BY_NAMES):
    case FAILURE(ACTION_TYPES.FETCH_MASTER_ENTITY_LIST_BY_GROUP_NAMES):
    case FAILURE(ACTION_TYPES.FETCH_ENTITY):
    case FAILURE(ACTION_TYPES.CREATE_ENTITY):
    case FAILURE(ACTION_TYPES.UPDATE_ENTITY):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case FAILURE(ACTION_TYPES.DELETE_ENTITY):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        deleteFailure: true,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_ENTITY_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_MASTER_ENTITY_LIST):
    case SUCCESS(ACTION_TYPES.FETCH_MASTER_ENTITY_LIST_BY_NAMES):
      return {
        ...state,
        loading: false,
        masterEntities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_MASTER_ENTITY_LIST_BY_GROUP_NAMES):
      return {
        ...state,
        loading: false,
        masterEntitiesByGroup: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_ENTITY):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_ENTITY):
    case SUCCESS(ACTION_TYPES.UPDATE_ENTITY):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_ENTITY):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        deleteFailure: false,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        entities: [] as ReadonlyArray<IWatotoCareEntityDetail>,
        masterEntities: [] as ReadonlyArray<IWatotoCareEntityMaster>,
        masterEntitiesByGroup: {},
        entity: defaultValue,
        updating: false,
        updateSuccess: false,
        deleteFailure: false
      };
    default:
      return state;
  }
};

const apiUrl = 'api/watotocare-entity-details';
const masterEntityApiUrl = 'api/watotocare-entity-master';

// Actions
export const getEntity: ICrudGetAction<IWatotoCareEntityDetail> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_ENTITY,
    payload: axios.get<IWatotoCareEntityDetail>(requestUrl)
  };
};

export const getMasterEntities: () => IPayload<IWatotoCareEntityMaster> | IPayloadResult<IWatotoCareEntityMaster> = () => async dispatch => {
  return await dispatch({
    type: ACTION_TYPES.FETCH_MASTER_ENTITY_LIST,
    payload: axios.get<IWatotoCareEntityMaster>(`${masterEntityApiUrl}?cacheBuster=${new Date().getTime()}`)
  });
};

export const getMasterEntitiesByEnumNames: (enumNames: string[], countryId: string) => IPayload<IWatotoCareEntityMaster> | IPayloadResult<IWatotoCareEntityMaster> = (enumNames, countryId) => async dispatch => {
  return await dispatch({
    type: ACTION_TYPES.FETCH_MASTER_ENTITY_LIST_BY_NAMES,
    payload: axios.post(`${masterEntityApiUrl}/by-enum-names`, { enumNames, countryId })
  });
};

export const getMasterEntitiesByGroupNames: (groupNames: string[], countryId: string) => IPayload<IWatotoCareEntityMaster> | IPayloadResult<IWatotoCareEntityMaster> = (groupNames, countryId) => async dispatch => {
  return await dispatch({
    type: ACTION_TYPES.FETCH_MASTER_ENTITY_LIST_BY_GROUP_NAMES,
    payload: axios.post(`${masterEntityApiUrl}/by-group-names`, { groupNames, countryId })
  });
};

export const getEntities: (countryId, masterEntityId) => IPayload<IWatotoCareEntityDetail> | IPayloadResult<IWatotoCareEntityDetail> = (countryId, masterEntityId) => async dispatch => {
  return await dispatch({
    type: ACTION_TYPES.FETCH_ENTITY_LIST,
    payload: axios.get<IWatotoCareEntityDetail>(`${apiUrl}?countryId=${countryId}&masterEntityId=${masterEntityId}&cacheBuster=${new Date().getTime()}`)
  });
};

export const createEntity: ICrudPutAction<IWatotoCareEntityDetail> = entity => async dispatch => {
  return await dispatch({
    type: ACTION_TYPES.CREATE_ENTITY,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
};

export const updateEntity: ICrudPutAction<IWatotoCareEntityDetail> = entity => async dispatch => {
  return await dispatch({
    type: ACTION_TYPES.UPDATE_ENTITY,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
};

export const deleteEntity: ICrudDeleteAction<IWatotoCareEntityDetail> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  return await dispatch({
    type: ACTION_TYPES.DELETE_ENTITY,
    payload: axios.delete(requestUrl)
  });
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
