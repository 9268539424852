import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Label, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasAnyPermission } from 'app/shared/auth/permissions';
import { IRootState } from 'app/shared/reducers';
import { getEntities, getMasterEntities } from './watotocare-entity.reducer';
import { IRegion } from 'app/shared/model/region.model';
import { getAllByType as getCountries } from 'app/entities/region/region.reducer';
import 'react-treelist/build/css/index.css';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { IWatotoCareEntityMaster } from 'app/shared/model/watotocare-entity-master.model';
import VillageAvTypeahead from 'app/shared/layout/menus/village-av-typeahead';

export interface IWatotoCareEntityProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

export const WatotoCareEntity = (props: IWatotoCareEntityProps) => {
  const { watotoCareEntityList, masterEntities, countries, match, loading, userCountryId } = props;
  const [activeCountry, setActiveCountry] = useState<IRegion>({});
  const [activeMasterEntity, setActiveMasterEntity] = useState<IWatotoCareEntityMaster>({});
  const [clearValue, setClearValue] = useState(false);
  const paramMasterEntityId = new URLSearchParams(props.location.search).get('masterEntityId') ?? null;

  useEffect(() => {
    props.getCountries();
    props.getMasterEntities();
  }, []);

  useEffect(() => {
    if (userCountryId && countries.length > 0 && masterEntities.length > 0) {
      const ac = countries.find(country => country.id === userCountryId);
      let ame: IWatotoCareEntityMaster;
      if (paramMasterEntityId) {
        ame = masterEntities.find(masterEntity => masterEntity.id === paramMasterEntityId);
      } else {
        ame = masterEntities[0];
      }
      setActiveCountry(ac);
      setActiveMasterEntity(ame);
      props.history.push(`${match.url}?masterEntityId=${ame.id}`);
      props.getEntities(ac.id, ame.id);
    }
  }, [countries, userCountryId, masterEntities]);

  const onCountryChange = (event) => {
    if (!event.target.value) {
      return;
    }
    const countryId = event.target.value;
    const newActiveCountry = countries.find(country => country.id === countryId);
    setActiveCountry(newActiveCountry);
    props.getEntities(countryId, activeMasterEntity.id);
  };

  const onMasterEntityChange = (event) => {
    if (!event.target.value) {
      return;
    }
    setClearValue(!clearValue);
    const masterEntityId = event.target.value;
    const newMasterEntity = masterEntities.find(masterEntity => masterEntity.id === masterEntityId);
    setActiveMasterEntity(newMasterEntity);
    props.history.push(`${match.url}?masterEntityId=${newMasterEntity.id}`);
    props.getEntities(activeCountry.id, masterEntityId);
  };

  const regCountriesList = countries.filter((ele) => {
    return props.isAdmin ? 1 : ele.id === userCountryId;
  });

  const masterEntitiesList = masterEntities ?
    masterEntities.map((masterEntity) => {
      return {
        short: masterEntity.entityName,
        full: `${masterEntity.entityName} (${masterEntity.enumName})`,
        id: masterEntity.id
      };
    }) : [];

  return (
    <div>
      <h2 id="watotoCareEntity-heading">
        watotoCare Entities
        {(activeCountry && typeof activeCountry.id !== 'undefined') && (
          <Link to={`${match.url}/create?masterEntityId=${activeMasterEntity.id}`}
                className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
            <FontAwesomeIcon icon="plus" />
            &nbsp; Create new watotoCare Entity
          </Link>
        )
        }
      </h2>

      <br />
      <AvForm>
        {(regCountriesList && regCountriesList.length > 0) && (
          <AvGroup>
            <Label for="country-filter">Country</Label>
            <AvInput id="country-filter" type="select" className="form-control"
                     name="country-filter" value={activeCountry?.id} onChange={onCountryChange}>
              {regCountriesList
                ? regCountriesList.map(country => (
                  <option value={country.id} key={country.id}>
                    {country.name}
                  </option>
                ))
                : null}
            </AvInput>
          </AvGroup>
        )}
        <br />
        {(masterEntities.length > 0) && (
          <>
            <AvGroup>
              <Label for="watotocare-entities-master">Select Master Entity</Label>
              <AvInput id="watotocare-entities-master" type="select" className="form-control"
                       name="watotocare-entities-master" value={activeMasterEntity?.id}
                       onChange={onMasterEntityChange}>
                {[...masterEntities] // sort by name
                  .sort((a, b) => a.entityName.localeCompare(b.enumName))
                  .map(masterEntity => (
                    <option value={masterEntity.id} key={masterEntity.id}>
                      {masterEntity.entityName} ({masterEntity.enumName})
                    </option>
                  ))}
              </AvInput>
            </AvGroup>
            <div>or</div>
            <br />
            <AvGroup>
              <Label for="watotocare-entities-master-search">Search Master Entity</Label>
              <VillageAvTypeahead
                labelKey="short"
                valueKey="id"
                data={masterEntitiesList}
                placeholder={activeMasterEntity.id ? `${activeMasterEntity.entityName} (${activeMasterEntity.enumName})` : 'Select Master Entity'}
                clearValue={clearValue}
                id="watotocare-entities-master-search"
                loading={masterEntitiesList.length === 0}
                onChange={(id) => onMasterEntityChange({ target: { value: id } })}
                input={{
                  id: 'watotocare-entities-master-search-input',
                  name: 'watotocare-entities-master-search-input'
                }}
              />
            </AvGroup>
          </>
        )}
      </AvForm>

      <br />
      <div className="table-responsive">
        {watotoCareEntityList && watotoCareEntityList.length > 0 ? (
          <Table responsive>
            <thead>
            <tr>
              <th>Id</th>
              <th>Description (English)</th>
              <th>Description (Local Language)</th>
              <th>Display Order</th>
              <th>Active Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            {watotoCareEntityList.map((watotoCareEntity, i) => (
              <tr key={`entity-${i}`}>
                <td>{watotoCareEntity.id}</td>
                <td>{watotoCareEntity.enDescription}</td>
                <td>{watotoCareEntity.stDescription}</td>
                <td>{watotoCareEntity.displayOrder}</td>
                <td>{watotoCareEntity.isActive ? 'Active' : 'Inactive'}</td>
                <td className="text-right">
                  <div className="btn-group flex-btn-group-container">
                    <Button tag={Link} to={`${match.url}/${watotoCareEntity.id}/edit`} color="primary" size="sm">
                      <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">View/Edit</span>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">No WatotoCare Entities Found</div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ watotoCareEntity, authentication, region }: IRootState) => ({
  watotoCareEntityList: watotoCareEntity.entities,
  masterEntities: watotoCareEntity.masterEntities,
  loading: watotoCareEntity.loading,
  countries: region.countries,
  userCountryId: authentication.userCountryId,
  isAdmin: hasAnyPermission(authentication.account.roles, ['ADMIN'])
});

const mapDispatchToProps = {
  getEntities,
  getCountries,
  getMasterEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(WatotoCareEntity);
