import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import MeDashboard from './dashboards/meDashboard/meDashboard';
import PaymentDashboard from './dashboards/paymentDashboard/paymentDashboard';
import CaseManagementDashboard from './dashboards/caseManagementDashboard/caseManagementDashboard';

import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';


const Routes = ({ match, permissionSettings, userRoles }) => {

    const array = [];
    const finalPermissions = permissionSettings
        .filter((item: any) => item.role === userRoles[0])
        .map((item: any) => { return { [item.role]: item.permissions } });

    finalPermissions.map((item: any) => {
        array.push(...Object.values(item))
    })

    console.log("match.url", match.url);

    console.log("ALL PERMISSIONS>>", userRoles, permissionSettings, finalPermissions, array[0]?.includes("ROUTES_VIEW"));

    return (
        <div>
            <ErrorBoundaryRoute path={`${match.url}/me-dashboard`} component={MeDashboard} />
            <ErrorBoundaryRoute path={`${match.url}/payment-dashboard`} component={PaymentDashboard} />
            <ErrorBoundaryRoute path={`${match.url}/case-management-dashboard`} component={CaseManagementDashboard} />
        </div>
    )
};

const mapStateToProps = (storeState: IRootState) => ({
    permissionSettings: storeState.permissionSettings.settings,
    userRoles: storeState.authentication.account.roles
});

export default connect(mapStateToProps)(Routes);
