import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction, IPayloadResult } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, ISymptom } from 'app/shared/model/symptom.model';
import { IRegion } from 'app/shared/model/region.model';
import { IPayload } from 'react-jhipster/src/type/redux-action.type';

export const ACTION_TYPES = {
  FETCH_SYMPTOM_LIST: 'symptom/FETCH_SYMPTOM_LIST',
  FETCH_SYMPTOM: 'symptom/FETCH_SYMPTOM',
  CREATE_SYMPTOM: 'symptom/CREATE_SYMPTOM',
  UPDATE_SYMPTOM: 'symptom/UPDATE_SYMPTOM',
  DELETE_SYMPTOM: 'symptom/DELETE_SYMPTOM',
  RESET: 'symptom/RESET',
  SET_ACTIVE_COUNTRY: 'symptom/SET_ACTIVE_COUNTRY',
  FETCH_ALL_COUNTRIES: 'symptom/FETCH_ALL_COUNTRIES'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ISymptom>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  countries: [] as ReadonlyArray<IRegion>
};

export type SymptomState = Readonly<typeof initialState>;

// Reducer

export default (state: SymptomState = initialState, action): SymptomState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_SYMPTOM_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ALL_COUNTRIES):
    case REQUEST(ACTION_TYPES.FETCH_SYMPTOM):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_SYMPTOM):
    case REQUEST(ACTION_TYPES.UPDATE_SYMPTOM):
    case REQUEST(ACTION_TYPES.DELETE_SYMPTOM):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_SYMPTOM_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SYMPTOM):
    case FAILURE(ACTION_TYPES.CREATE_SYMPTOM):
    case FAILURE(ACTION_TYPES.UPDATE_SYMPTOM):
    case FAILURE(ACTION_TYPES.DELETE_SYMPTOM):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_SYMPTOM_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_SYMPTOM):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_SYMPTOM):
    case SUCCESS(ACTION_TYPES.UPDATE_SYMPTOM):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_SYMPTOM):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case SUCCESS(ACTION_TYPES.FETCH_ALL_COUNTRIES):
      return {
        ...state,
        loading: false,
        countries: action.payload.data
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/symptoms';

// Actions

// export const getEntities: ICrudGetAllAction<ISymptom> = (page, size, sort) => ({
//   type: ACTION_TYPES.FETCH_SYMPTOM_LIST,
//   payload: axios.get<ISymptom>(`${apiUrl}?cacheBuster=${new Date().getTime()}`),
// });
export const getEntities: (page?, size?, sort?) => IPayload<ISymptom> | IPayloadResult<ISymptom> = (page, size, sort) => async dispatch => {
  const requestUrl = 'api/regions/type/COUNTRY';
  const countries = await dispatch({
    type: ACTION_TYPES.FETCH_ALL_COUNTRIES,
    payload: axios.get<IRegion>(`${requestUrl}`)
  });

  const symptoms = await dispatch({
    type: ACTION_TYPES.FETCH_SYMPTOM_LIST,
    payload: axios.get<ISymptom>(`${apiUrl}?cacheBuster=${new Date().getTime()}`)
  });

  return symptoms;
};

export const getEntity: ICrudGetAction<ISymptom> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_SYMPTOM,
    payload: axios.get<ISymptom>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<ISymptom> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_SYMPTOM,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<ISymptom> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SYMPTOM,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<ISymptom> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_SYMPTOM,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => dispatch => {
  return dispatch({
    type: ACTION_TYPES.RESET
  });
};
