import React from 'react';
import {Switch} from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Route from './route';
import RouteDetail from './route-detail';
import CreateRoute from './create-route';
import RouteDeleteDialog from './route-delete-dialogue';
const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/route-detail/:id`} component={RouteDetail} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={RouteDeleteDialog} />
      <ErrorBoundaryRoute exact path={`${match.url}/create-route`} component={CreateRoute} />
      <ErrorBoundaryRoute path={match.url} component={Route} />
     
    </Switch>
  </>
);

export default Routes;
