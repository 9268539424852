import React, {useEffect} from 'react'
import {connect} from 'react-redux';
import {IRootState} from 'app/shared/reducers';
import LoadingOverlay from 'react-loading-overlay';
import {
    getRegionsByType,
    getAllFacility,
    getYear,
    getCaseManagementCountData,
    getCaseManagementData,
    getCaseManagementGroupData,
    filterFacilityByDistrict,
} from './case-management-dashboard.reducer';
import {
    setApplyFilterOption,
    setFilterOption
} from "../../dashboardWrapper/sidebar/subMenu/childSubMenu/child-sub-menu.reducer"
import {faBriefcase, faCar, faChartLine, faMoneyBillAlt} from "@fortawesome/free-solid-svg-icons";
import DashboardWrapper from '../../dashboardWrapper/dashboardWrapper';
import {useInView} from "react-intersection-observer";
import "./caseManagementDashboard.scss";
import {OverviewSection} from "app/modules/programDashboard/dashboards/caseManagementDashboard/components/overviewSection";
import {OPTIONS_FILTERS_CATEGORIES} from "app/modules/programDashboard/dashboards/caseManagementDashboard/constants";
import {PerformanceSection} from "app/modules/programDashboard/dashboards/caseManagementDashboard/components/performanceSection";
import {TransportSection} from "app/modules/programDashboard/dashboards/caseManagementDashboard/components/transportSection";
import {PaymentSection} from "app/modules/programDashboard/dashboards/caseManagementDashboard/components/paymentSection";

export const PRIMARY_CHART_COLOR = '#008631';
export const SECONDARY_CHART_COLOR = '#00ab41';

const CaseManagementDashboard = (props) => {
    const {
        selectedFilterOption,
        applyFilterOption,
        resetFilterOption,
        spinner,
        district,
        healthFacility,
        year,
        caseManagementGroupData,
        caseManagementCountData,
        caseManagementData,
    } = props;

    const OPTIONS_FILTERS = [
        {label: "By Year", values: year, category: "Time", slug: "year"},
        {label: "By Month Range", values: "", category: "Time", slug: "dateRange"},
        {label: "By District", values: district, category: "Geography", slug: "district"},
        {label: "By Health Facility", values: healthFacility, category: "Geography", slug: "healthFacility"},
    ];

    const MENU_ITEMS = [
        {label: "Overview", icon: faBriefcase, path: `${props.match.url}#overview`},
        {label: "Performance", icon: faChartLine, path: `${props.match.url}#performance`},
        {label: "Transport", icon: faCar, path: `${props.match.url}#transport`},
        {label: "Payment", icon: faMoneyBillAlt, path: `${props.match.url}#payment`},
    ]

    const getGraphData = () => {
        props.getCaseManagementCountData(selectedFilterOption);
        props.getCaseManagementData(selectedFilterOption);
        props.getCaseManagementGroupData(selectedFilterOption);
    }

    const getInitData = () => {
        props.getRegionsByType();
        props.getAllFacility();
        props.getYear();
    }

    useEffect(() => {
        getInitData();
        getGraphData();
    }, []);

    useEffect(() => {
        if (applyFilterOption) {
            getGraphData();
            props.setApplyFilterOption(false);
        }
    }, [applyFilterOption])

    useEffect(() => {
        if (resetFilterOption) {
            getGraphData();
        }
    }, [resetFilterOption])

    useEffect(() => {
        if (selectedFilterOption?.district?.length > 0) {
            props.filterFacilityByDistrict(selectedFilterOption.district.map(d => d.value.id));
        } else {
            props.filterFacilityByDistrict('*');
        }
    }, [selectedFilterOption])

    const [overviewRef, overviewInView] = useInView({});
    const [performanceRef, performanceInView] = useInView({});
    const [transportRef, transportInView] = useInView({});
    const [paymentRef, paymentInView] = useInView({});

    const activeTab = () => {
        if (overviewInView) {
            return "Overview";
        } else if (performanceInView) {
            return "Performance";
        } else if (transportInView) {
            return "Transport";
        } else if (paymentInView) {
            return "Payment";
        } else {
            return null
        }
    }

    return (
        <LoadingOverlay active={spinner > 0} spinner text='Loading...'>
            <DashboardWrapper optionsFilters={OPTIONS_FILTERS} optionsFiltersCategories={OPTIONS_FILTERS_CATEGORIES}
                              menuItems={MENU_ITEMS} activeTab={activeTab}>
                <div className='content-container container-fluid'>
                    <p className="mb-2" style={{fontSize: "22px", fontWeight: 600, color: PRIMARY_CHART_COLOR}}>watoto-care
                        Case Management Dashboard</p>
                    <section id="overview" ref={overviewRef}>
                        <OverviewSection caseManagementCountData={caseManagementCountData}
                                         caseManagementData={caseManagementData}/>
                    </section>
                    <section id="performance" ref={performanceRef}>
                        <PerformanceSection caseManagementData={caseManagementData}/>
                    </section>
                    <section id="transport" ref={transportRef}>
                        <TransportSection caseManagementCountData={caseManagementCountData}
                                          caseManagementData={caseManagementData}
                                          caseManagementGroupData={caseManagementGroupData}/>
                    </section>
                    <section id="payment" ref={paymentRef}>
                        <PaymentSection caseManagementData={caseManagementData}/>
                    </section>
                </div>
            </DashboardWrapper>
        </LoadingOverlay>
    )
}

const mapStateToProps = ({caseManagementDashboard, filterOption}: IRootState) => ({
    selectedFilterOption: filterOption.selectedFilterOption,
    applyFilterOption: filterOption.applyFilterOption,
    resetFilterOption: filterOption.resetFilterOption,
    spinner: caseManagementDashboard.spinner,
    region: caseManagementDashboard.region,
    district: caseManagementDashboard.district,
    council: caseManagementDashboard.council,
    dispatchCenter: caseManagementDashboard.dispatchCenter,
    healthFacility: caseManagementDashboard.healthFacility,
    healthFaciliyLevel: caseManagementDashboard.healthFaciliyLevel,
    year: caseManagementDashboard.year,
    month: caseManagementDashboard.month,
    transportType: caseManagementDashboard.transportType,
    journeyType: caseManagementDashboard.journeyType,
    patientType: caseManagementDashboard.patientType,
    emergencyType: caseManagementDashboard.emergencyType,
    bypassType: caseManagementDashboard.bypassType,
    caseManagementGroupData: caseManagementDashboard.caseManagementGroupData,
    caseManagementCountData: caseManagementDashboard.caseManagementCountData,
    caseManagementData: caseManagementDashboard.caseManagementData,
});

const mapDispatchToProps = {
    getRegionsByType,
    getAllFacility,
    getYear,
    getCaseManagementCountData,
    getCaseManagementData,
    getCaseManagementGroupData,
    setFilterOption,
    setApplyFilterOption,
    filterFacilityByDistrict
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CaseManagementDashboard)
