import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import authentication, { AuthenticationState } from './authentication';
import applicationProfile, { ApplicationProfileState } from './application-profile';
import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import userManagement, { UserManagementState } from 'app/modules/administration/user-management/user-management.reducer';
import password, { PasswordState } from 'app/modules/account/password/password.reducer';
import passwordReset, { PasswordResetState } from 'app/modules/account/password-reset/password-reset.reducer';
import journey, { JourneyState } from 'app/entities/journey/journey.reducer';
import route, { RouteState } from 'app/entities/route/route.reducer';
import symptom, { SymptomState } from 'app/entities/symptom/symptom.reducer';

import healthcareFacility, { HealthcareFacilityState } from 'app/entities/healthcare-facility/healthcare-facility.reducer';
import healthcareFacilityLevel, { HealthcareFacilityLevelState } from 'app/entities/healthcare-facility-level/healthcare-facility-level.reducer';
import driver, { DriverState } from 'app/entities/driver/driver.reducer';
import country, { CountryState } from 'app/entities/country/country.reducer';
import district, { DistrictState } from 'app/entities/district/district.reducer';
import council, { CouncilState } from 'app/entities/council/council.reducer';
import village, { VillageState } from 'app/entities/village/village.reducer';
import payment, { PaymentState } from 'app/entities/payment/payment.reducer';
// import headerData, {HeaderState} from 'app/shared/layout/header/header.reducer';
import ambulance, { AmbulanceState } from 'app/entities/ambulance/ambulance.reducer';
import watotocareSettings, { WatotoCareSettingsState } from 'app/modules/administration/watotocare-settings/watotocare-settings.reducer';
import region, { RegionState } from 'app/entities/region/region.reducer';
import dispatchCenter, { DispatchCenterState } from 'app/entities/dispatchCenter/dispatch-center.reducer';
import continent, { ContinentState } from 'app/entities/continent/continent.reducer';
import reportSettings, { ReportSettingsState } from 'app/modules/administration/report-settings/report-settings.reducer';
import permissionSettings, { PermissionSettingsState } from 'app/modules/administration/permission-settings/permission-settings.reducer';
import villageRoute, { villageRouteState } from 'app/entities/village-route/village-route.reducer';
import geofenceRoute, { geofenceRouteState } from 'app/entities/geofence-route/geofence-route.reducer';
import vehicleType, { VehicleTypeState } from 'app/entities/vehicleType/vehicleType.reducer';
import meDashboard, { MeDashboardState } from 'app/modules/programDashboard/dashboards/meDashboard/me-dashboard.reducer';
import filterOption, {
  FilterOptionState,
} from 'app/modules/programDashboard/dashboardWrapper/sidebar/subMenu/childSubMenu/child-sub-menu.reducer';
import caseManagementDashboard, {CaseManagementDashboardState} from "app/modules/programDashboard/dashboards/caseManagementDashboard/case-management-dashboard.reducer";
import patient, {PatientState} from "app/entities/patient/patient.reducer";
import patientBaby, {PatientBabyState} from "app/entities/patient-baby/patient-baby.reducer";
import patientVisit, { PatientVisitState } from 'app/entities/patient-visit/patient-visit.reducer';
import watotoCareEntity, {WatotoCareEntityState} from "app/entities/watotocare-entity/watotocare-entity.reducer";
import patientCaseEntity, {PatientCaseState} from "app/entities/patient-case/patient-case.reducer";
import patientOutcome, { PatientOutcomeState } from 'app/entities/patient-outcome/patient-outcome.reducer';
import physician, { PhysicianState } from 'app/entities/physician/physician.reducer';

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly userManagement: UserManagementState;
  readonly passwordReset: PasswordResetState;
  readonly password: PasswordState;
  readonly journey: JourneyState;
  readonly route: RouteState;
  readonly symptom: SymptomState;
  readonly healthcareFacility: HealthcareFacilityState;
  readonly healthcareFacilityLevel: HealthcareFacilityLevelState;
  readonly driver: DriverState;
  readonly country: CountryState;
  readonly district: DistrictState;
  readonly council: CouncilState;
  readonly village: VillageState;
  readonly patient: PatientState;
  readonly patientBaby: PatientBabyState;
  readonly patientVisit: PatientVisitState;
  readonly patientOutcome: PatientOutcomeState;
  readonly payment: PaymentState;
  // readonly headerData: HeaderState;
  readonly ambulance: AmbulanceState;
  readonly watotocareSettings: WatotoCareSettingsState;
  readonly region: RegionState;
  readonly dispatchCenter: DispatchCenterState;
  readonly continent: ContinentState;
  readonly reportSettings: ReportSettingsState;
  readonly permissionSettings: PermissionSettingsState;
  readonly villageRoute: villageRouteState;
  readonly geofenceRoute: geofenceRouteState;
  readonly loadingBar: any;
  readonly vehicleType: VehicleTypeState;
  readonly meDashboard: MeDashboardState;
  readonly caseManagementDashboard: CaseManagementDashboardState;
  readonly filterOption: FilterOptionState;
  readonly watotoCareEntity: WatotoCareEntityState;
  readonly patientCaseEntity: PatientCaseState;
  readonly physician: PhysicianState;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  applicationProfile,
  administration,
  userManagement,
  passwordReset,
  password,
  watotocareSettings,
  reportSettings,
  journey,
  route,
  symptom,
  healthcareFacility,
  healthcareFacilityLevel,
  driver,
  country,
  district,
  council,
  village,
  payment,
  patient,
  patientBaby,
  patientVisit,
  patientOutcome,
  ambulance,
  region,
  continent,
  dispatchCenter,
  loadingBar,
  permissionSettings,
  villageRoute,
  geofenceRoute,
  vehicleType,
  meDashboard,
  caseManagementDashboard,
  filterOption,
  watotoCareEntity,
  patientCaseEntity,
  physician,
});

export default rootReducer;
