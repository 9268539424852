import {applyMiddleware, compose, createStore} from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';
import reducer, {IRootState} from 'app/shared/reducers';
// import DevTools from './devtools';
// import errorMiddleware from './error-middleware';
// import loggerMiddleware from './logger-middleware';
import {loadingBarMiddleware} from 'react-redux-loading-bar';

const defaultMiddlewares = [
  thunkMiddleware,
  // errorMiddleware,
  promiseMiddleware,
  loadingBarMiddleware(),
  // loggerMiddleware,
];
const composedMiddlewares = middlewares =>
  compose(applyMiddleware(...defaultMiddlewares, ...middlewares));

const initialize = (initialState?: IRootState, middlewares = []) => createStore(reducer, initialState, composedMiddlewares(middlewares));

export default initialize;
