import React, { createRef, useEffect, useState } from 'react'

export const DisplayCardGroup = ({ data, col }) => {
    return (
        <>
            {data.map((el, i) => (
                <div key={i} className={`${col} card-group-container`}>
                    <div className="card-group p-1" style={{ background: "#FFFFFF" }}>
                        <div>
                            <img src={el["icon"]} alt="icon" className="card-group-icon" />
                        </div>
                        <div className="pl-1">{el["label"]} </div>
                        <div className="pl-1" style={{ fontWeight: 600 }}>{el["value"]}</div>
                    </div>
                </div>
            ))}
        </>
    )

}