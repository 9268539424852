import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps, useHistory} from 'react-router-dom';
import {getSortState} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {PERMISSIONS} from 'app/config/constants';
import {hasAnyPermission} from 'app/shared/auth/permissions';
import {Button} from 'reactstrap';
import {IRootState} from 'app/shared/reducers';
import {getAllEntities as getRegions, getEntities, getRegionsByType,getDispatchCenters} from './dispatch-center.reducer';
import {IRegion} from 'app/shared/model/region.model';
import {ITEMS_PER_PAGE} from 'app/shared/util/pagination.constants';
import {overridePaginationStateWithQueryParams} from 'app/shared/util/entity-utils';
import {RegionType} from "app/shared/model/enumerations/region-type.model";
import 'react-simple-tree-menu/dist/main.css';
import TreeList from 'react-treelist';
import 'react-treelist/build/css/index.css';

export interface IRegionProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

export const getCouncilName = (id, councilList) => {
  const council = councilList.find(v => v.id === id);
  return !council?
    { short:'', full: ''}
    :
    {
      short: council.name,
      full: (council.name + ' / ' + council?.parent?.name + ' / ' + council?.parent?.parent?.name + ' / ' + council?.parent?.parent?.parent?.name)
    };
}

export const Region = (props: IRegionProps) => {

  const history = useHistory();
  const regionTypeAndAttributes = [{type: 'STATE', color: 'green'}, {type: 'COUNCIL', color: 'blue'}, {
    type: 'DISTRICT',
    color: 'orange'
  }, {type: 'REGION', color: 'blue'}, {type: 'PROVINCE', color: 'orange'}, {type: 'COUNTRY', color: 'green'}]
  const buttonColor = {'REGION': 'green', 'DISTRICT': 'orange', 'COUNCIL': 'orange'}
  const buildTree = (region: IRegion, regions: Array<IRegion>, level: number) => {
    region['nodes'] = regions.filter(r => r.parentId === region.id)
    region['level'] = level
    region['label'] = region.name + " -8888 " + region.type.toLowerCase()
    region['key'] = region.id

    level += 1

    for (const child of region.nodes) {
      buildTree(child, regions, level)
    }
  }


  const organizeRegions = (regionsByType) => {
    let regions: Array<IRegion> = []
    for (const [key, value] of Object.entries(regionsByType)) {
      regions = regions.concat(value)
    }

    const regionsWithChildren = regions.map(region => {
      region['nodes'] = regions.filter(r => r.parentId === region.id)
      return region
    })

    const countries = regionsWithChildren.filter(r => r.type === RegionType.COUNTRY);

    for (const country of countries) {
      buildTree(country, regionsWithChildren, 0)
    }
    return countries
  }

  /*
    const removeElement = (array, element) => {
      const index = array.indexOf(element)
      array.splice(index, 1)
    }
  */


  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );

  const fetchRegionsByType = () => {
    props.getRegionsByType();
  };

  useEffect(() => {
    fetchRegionsByType()
     props.getRegions()
    props.getDispatchCenters()
  }, []);

   const { match, loading, totalItems, regionsByType,dispatchCenterList} = props;

  let {regionList} = props;
  regionList  =  regionList.filter((ele)=>{
    return  props.isAdmin ? 1  : ele.id === props.userCountryId;
  })

   const getChildType = (id: string) => {
    if (typeof regionList !== 'undefined' && regionList.length > 0) {
      if (typeof regionList.find(region => region.id === id) !== "undefined") {
        switch (regionList.find(region => region.id === id).type) {
          case RegionType.COUNTRY:
            return RegionType.REGION
          
          default:
            return ""
        }
      }
    }
  }

  const createChild = (id: string,country:IRegion) => {
    console.log("country",country)
    const childType = getChildType(id)
    if (typeof childType !== "undefined") {
      return (childType !== "" &&
        <Link key={id} style={{background: `${buttonColor[childType.toString()]}`}}
              to={`${match.url}/new/?country_id=${country.id}`}
              className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus"/>
          &nbsp; Add new Dispatch center
        </Link>
      )
    }
  }

  const itemViewLink = (id: string,data :IRegion) => {
    console.log("type: ",data.type)
    
    return (
      <>
      {data?.type === 'DISPATCH_CENTER' ? 
      <Button tag={Link} to={`${match.url}/${id}/edit?type=`} color="link" size="sm">
        {id}
      </Button>
      :
      <Button color="link" size="sm">
      {id}
    </Button>
    }

      </>
    )
  }
  const COLUMNS = [
    {'title': 'ID', 'field': 'id', 'type': 'string', 'formatter': itemViewLink, 'width': 400},
    {'title': 'Name', 'field': 'name', 'type': 'string'},
    {'title': 'Type', 'field': 'type', 'type': 'string', 'width': 200},
    {
      'title': '',
      'field': 'id',
      'type': 'string',
      'formatter': createChild,
      'width': 230
    }]
     regionList= regionList.filter((region)=>region.type ==='COUNTRY');
    
    /* regionList.push({
      continentName: "Africa",
      description: null,
      id: "9a475ef0-49f6-11eb-89d2-33333333344",
      locationLat: null,
      locationLon: null,
      name: "Dispatch center 2",
      parentId: '9a475ef0-49f6-11eb-89d2-a85e45d05e66',
      parentName: null,
      type: "DIPATCH_CENTER"} )*/
    
     const dispatchCentertres=[];
     for(let i=0; i < regionList.length ;i++){


      for(let key=0;key< dispatchCenterList.length; key++) {
       if(regionList[i].id === dispatchCenterList[key].countryId){
            const obj:IRegion ={
              continentName: "",
              description: null,
              id: dispatchCenterList[key].id,
              locationLat: null,
              locationLon: null,
              name: dispatchCenterList[key].name,
              parentId: dispatchCenterList[key].countryId,
              parentName: null,
              type: RegionType.DISPATCH_CENTER

          }

              regionList.push(obj)

              if(dispatchCenterList[key].region.length > 0){

                for(let j=0;j< dispatchCenterList[key].region.length; j++) {
                  regionList.push({

                    continentName: "",
                    description: null,
                    id: dispatchCenterList[key].region[j].id,
                    locationLat: null,
                    locationLon: null,
                    name: dispatchCenterList[key].region[j].name,
                    parentId: dispatchCenterList[key].id,
                    parentName: null,
                    type: 'DISTRICT'
  
                  })
                }
                
              }
       }
        
      
      }
     
    }
      
     console.log("regionList",regionList)       
     
  return (
    <div>
      <h2 id="region-heading">
        Dispatch Center
      {/* <!--Link to={`${match.url}/new?type=COUNTRY`} className="btn btn-primary float-right jh-create-entity"
              id="jh-create-entity">
          <FontAwesomeIcon icon="plus"/>
          &nbsp; Create new Country
      </Link-->*/}
      </h2>
      <div>
       

        {
          props.allEntitiesRefreshed ? (
            <TreeList
              data={regionList}
              columns={COLUMNS}
              // options={OPTIONS}
              // handlers={HANDLERS}
              id={'id'}
              parentId={'parentId'}>
                
              </TreeList>
          ) : null
          }

      </div>
    </div>
  );
};

const mapStateToProps = ({dispatchCenter,authentication}: IRootState) => ({
  dispatchCenterList: dispatchCenter.dipatchCenters,
  regionList: dispatchCenter.entities,
  loading: dispatchCenter.loading,
  totalItems: dispatchCenter.totalItems,
  regionsByType: dispatchCenter.regionsByType,
  updating: dispatchCenter.updating,
  updateSuccess: dispatchCenter.updateSuccess,
  allEntitiesRefreshed: dispatchCenter.allEntitiesRefreshed,
  userCountryId: authentication.userCountryId,
  isAdmin: hasAnyPermission(authentication.account.roles, ['ADMIN'])
});

const mapDispatchToProps = {
  getEntities, getRegionsByType, getRegions,getDispatchCenters
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Region);
