import React from 'react';
import { Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import Login from 'app/modules/login/login';
import Logout from 'app/modules/login/logout';
import Home from 'app/modules/home/home';
import Report from 'app/modules/report/report';
import ProgramDashboard from './modules/programDashboard';
import Entities from 'app/entities';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PageNotFound from 'app/shared/error/page-not-found';
import Password from 'app/modules/account/password/password';

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: () => <div>loading ...</div>,
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => <div>loading ...</div>,
});

const Routes = ({ isAuthenticated, mustChangePassword, isAdmin, permissionSettings }) => {
  if (mustChangePassword) {
    return (
      <Switch>
        <ErrorBoundaryRoute path="/" component={Password} />
      </Switch>
    )
  } else if (!isAuthenticated) {
    return (
      <Switch>
        <ErrorBoundaryRoute path="/" component={Login} />
      </Switch>
    )
  } else if (isAdmin) {
    return (
      <div className="view-routes">
        <Switch>
          <ErrorBoundaryRoute path="/login" component={Login} />
          <ErrorBoundaryRoute path="/logout" component={Logout} />
          <ErrorBoundaryRoute path="/admin" component={Admin} />
          <ErrorBoundaryRoute path="/account" component={Account} />
          <ErrorBoundaryRoute path="/" exact component={Home} />
          <ErrorBoundaryRoute path="/report/:type" exact component={Report} />
          <ErrorBoundaryRoute path="/program-dashboard" component={ProgramDashboard} />
          <ErrorBoundaryRoute path="/" component={Entities} />
          <ErrorBoundaryRoute component={PageNotFound} />
        </Switch>
      </div>
    )
  } else {
    return (
      <div className="view-routes">
        <Switch>
          <ErrorBoundaryRoute path="/login" component={Login} />
          <ErrorBoundaryRoute path="/logout" component={Logout} />
          <ErrorBoundaryRoute path="/account" component={Account} />
          <ErrorBoundaryRoute path="/" exact component={Home} />
          <ErrorBoundaryRoute path="/report/:type" exact component={Report} />
          <ErrorBoundaryRoute path="/program-dashboard" component={ProgramDashboard} />
          <ErrorBoundaryRoute path="/" component={Entities} />
          <ErrorBoundaryRoute component={PageNotFound} />
        </Switch>
      </div>
    )
  }
};

export default Routes;
