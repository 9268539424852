import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Row, Table, Label} from 'reactstrap';
import {getSortState, JhiItemCount, JhiPagination} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';

import {getEntities,getDriverByName} from './driver.reducer';
import {ITEMS_PER_PAGE} from 'app/shared/util/pagination.constants';
import {overridePaginationStateWithQueryParams} from 'app/shared/util/entity-utils';
import {getVillageName} from "app/entities/village/village";
import {getEntities as getVillages} from "app/entities/village/village.reducer";
import {getAllFacility} from "app/entities/healthcare-facility/healthcare-facility.reducer";

import {hasAnyPermission} from 'app/shared/auth/permissions';
import {AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {PERMISSIONS} from 'app/config/constants';
import {getAllByType as getCountries} from "app/entities/region/region.reducer";
import {IRegion} from "app/shared/model/region.model";
import { convertDateTimeFromServer } from 'app/shared/util/date-utils';

export interface IDriverProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const Driver = (props: IDriverProps) => {
  const [activeCountry, setActiveCountry] = useState<IRegion>()
  const [searchStr, setSearchString] = useState<string>()
  const {  match, loading, totalItems, villages,userCountryId } = props;
  const { driverList } = props;
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );

  const getAllEntities = (countryId: string) => {
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`, countryId);
  };

  const sortEntities = (countryId) => {
    if(searchStr){
      props.getDriverByName(searchStr,countryId,paginationState.activePage)
    }else{
      getAllEntities(countryId);
    }

    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  const onCountryChange = (event) => {
    const countryId = event.target.value
    const newActiveCountry = props.countries.find(country => country.id === countryId)
    setActiveCountry(newActiveCountry)
    sortEntities(countryId);
  }
  useEffect(() => {
    props.getCountries();
  }, []);

  useEffect(() => {
    const ac = props.countries.find(country => country.id === userCountryId)
    setActiveCountry(ac)
  }, [props.countries,userCountryId]);

  useEffect(() => {
    if (activeCountry) {
      sortEntities(activeCountry.id);
    }
  }, [activeCountry]);

  useEffect(() => {
    props.getVillages();
    if(activeCountry){
      sortEntities(activeCountry.id);
    }
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });
  const regCountriesList = props.countries.filter((ele)=>{
    return  props.isAdmin ? 1  : ele.id === activeCountry?.id;
  })
 // / driverList = driverList.filter(val=>val.homeVillage.council?.parent?.parent?.parent?.id === activeCountry?.id)
  return (
    <div>
      <h2 id="driver-heading">
        Drivers
        <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp; Create new Driver
        </Link>
      </h2>
      {(regCountriesList && regCountriesList.length > 0) && (<AvForm>
          <AvGroup>
            <AvInput id="healthcare-facility-country" type="select" className="form-control"
                     name="healthcare-facility-country" value={activeCountry?.id} onChange={onCountryChange}>
              {regCountriesList
                ? regCountriesList.map(country => (
                  <option value={country.id} key={country.id}>
                    {country.name}
                  </option>
                ))
                : null}
            </AvInput>
          </AvGroup>
        </AvForm>
      )
      }
      <AvForm model={{}}>
      <AvGroup>
          <Label for="status-filter">Search by driver name</Label>
          <AvInput

            id="status-filter"
            type="input"
            className="form-control"
            name={'status-filter'}

            onChange={(e) => {
              const serachString  = e.target.value;
              setSearchString(serachString)
              if(e.target.value){
                setPaginationState({
                  ...paginationState,
                  activePage:1
                });

                props.getDriverByName(serachString,activeCountry?.id,paginationState.activePage)
              }else{
                if (activeCountry) {
                  sortEntities(activeCountry.id);
                }
              }

            }}
          // value={filterList.status}
          >
          </AvInput>
        </AvGroup>
        </AvForm>

      <div className="table-responsive">
        {driverList && driverList.length > 0 ? (
          <Table responsive>
            <thead>
            <tr>
              <th className="hand" onClick={sort('first_name')}>
                First Name <FontAwesomeIcon icon="sort" />
              </th>
              <th className="hand" onClick={sort('last_name')}>
                Last Name <FontAwesomeIcon icon="sort" />
              </th>
              <th className="hand" onClick={sort('created_date')}>
                Created Date <FontAwesomeIcon icon="sort" />
              </th>
              <th className="hand" onClick={sort('vehicle_type_id')}>
                Vehicle Type <FontAwesomeIcon icon="sort" />
              </th>
              <th className="hand" onClick={sort('phone_number')}>
                Phone Number <FontAwesomeIcon icon="sort" />
              </th>

              <th className="hand" onClick={sort('mpesa_number')}>
                M-PESA Number <FontAwesomeIcon icon="sort" />
              </th>

              <th>
                Home Village <FontAwesomeIcon icon="sort" />
              </th>
              <th className="hand" onClick={sort('council')}>
               Council <FontAwesomeIcon icon="sort" />
              </th>
              <th className="hand" onClick={sort('district')}>
               District <FontAwesomeIcon icon="sort" />
              </th>
              <th className="hand" onClick={sort('is_active')}>
               Status <FontAwesomeIcon icon="sort" />
              </th>
              <th />
            </tr>
            </thead>
            <tbody>
            {driverList.map((driver, i) => {
              const villageName =  getVillageName(driver.homeVillageId, villages);

             // console.log("villageName",villageName,villages)

              return (
                <tr key={`entity-${i}`}>
                  <td>{driver.firstName}</td>
                  <td>{driver.lastName}</td>
                  <td>{convertDateTimeFromServer(driver.createdDate)}</td>
                  <td>{driver.vehicleType?.enType}</td>
                  <td>{driver.phoneNumber}</td>

                  <td>{driver.mpesaNumber}</td>

                  <td title={villageName.full}>
                    {searchStr? driver.homeVillage?.name :villageName.short}</td>
                  <td >{searchStr? driver.homeVillage?.council?.name :villageName.council}</td>
                  <td >{searchStr? driver.homeVillage?.council?.parent?.name :villageName.district}</td>

                   <td >{driver.activated ? 'Active' :'Inactive'}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${driver.id}`} color="info" size="sm">
                        <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">View</span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${driver.id}/edit`}
                        color="primary"
                        size="sm"
                      >
                        <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                      </Button>
                      {hasAnyPermission(props.permissions, [PERMISSIONS.USER_ADMIN]) &&
                      <Button
                        tag={Link}
                        to={`${match.url}/${driver.id}/delete`}
                        color="danger"
                        size="sm"
                      >
                      <FontAwesomeIcon icon="trash" /> <span className="d-none d-md-inline">Delete</span>
                    </Button>
                      }
                    </div>
                  </td>
                </tr>
              )}
            )}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">No Drivers found</div>
        )}
      </div>
      {props.totalItems ? (
        <div className={driverList && driverList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={props.totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  driverList: storeState.driver.entities,
  loading: storeState.driver.loading,
  totalItems: storeState.driver.totalItems,
  villages: storeState.village.entities,
  permissions: storeState.authentication.permissions,
  countries: storeState.region.countries,
  userCountryId: storeState.authentication.userCountryId,
  allHealthfacility: storeState.healthcareFacility.allFacility,
  isAdmin: hasAnyPermission(storeState.authentication.account.roles, ['ADMIN'])
});

const mapDispatchToProps = {
  getEntities,
  getVillages,
  getCountries,
  getDriverByName,
  getAllFacility
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Driver);
