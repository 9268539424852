import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Row, Table, Label} from 'reactstrap';
import {getSortState, JhiItemCount, JhiPagination} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {PERMISSIONS} from 'app/config/constants';
import {hasAnyPermission} from 'app/shared/auth/permissions';
import {IRootState} from 'app/shared/reducers';
import {getEntities
  // ,getHfByName
} from './healthcare-facility-level.reducer';
import {getAllByType as getCountries} from "app/entities/region/region.reducer";
import {ITEMS_PER_PAGE} from 'app/shared/util/pagination.constants';
import {overridePaginationStateWithQueryParams} from 'app/shared/util/entity-utils';
import {IRegion} from "app/shared/model/region.model";

export interface IHealthcareFacilityLevelProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

export const HealthcareFacilityLevel = (props: IHealthcareFacilityLevelProps) => {
  const { match, loading, totalItems, userCountryId} = props;
  const {healthcareFacilityLevelList} = props;
  const [activeCountry, setActiveCountry] = useState<IRegion>()
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );


  const getAllEntities = (countryId: string) => {
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`, countryId);
  };

  
  const sortEntities = (countryId: string) => {
    getAllEntities(countryId);
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    props.getCountries();
  }, []);

  useEffect(() => {
    sortEntities(activeCountry?.id)
  }, [activeCountry]);

  useEffect(() => {
    const ac = props.countries.find(country => country.id === userCountryId)
    setActiveCountry(ac)
  }, [props.countries,userCountryId]);

  useEffect(() => {
    sortEntities(activeCountry?.id);
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const onCountryChange = (event) => {
    const countryId = event.target.value
    const newActiveCountry = props.countries.find(country => country.id === countryId)
    setActiveCountry(newActiveCountry)
    sortEntities(countryId)
  }
  const regCountriesList = props.countries.filter((ele)=>{
    return  props.isAdmin ? 1  : ele.id === activeCountry?.id;
  })

  console.log("LIST > > > ", healthcareFacilityLevelList)

  // healthcareFacilityLevelList = healthcareFacilityLevelList.filter((val: any)=>val.council?.parent?.parent?.parent?.id === activeCountry?.id)
  return (
    <div>
      <h2 id="healthcare-facility-heading">
        Healthcare Facility Levels
        <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus"/>
          &nbsp; Create new Healthcare Facility Level
        </Link>
      </h2>

      {(regCountriesList && regCountriesList.length > 0) && (<AvForm>
          <AvGroup>
            <AvInput id="healthcare-facility-level-country" type="select" className="form-control" name="healthcare-facility-level-country" value={activeCountry?.id} onChange={onCountryChange}>
              {regCountriesList
                ? regCountriesList.map(country => (
                  <option value={country.id} key={country.id}>
                    {country.name}
                  </option>
                ))
                : null}
            </AvInput>
          </AvGroup>
        </AvForm>
      )
      }
      {/* <AvForm model={{}}>
      <AvGroup>
          <Label for="status-filter">Search by healthcare facility name</Label>
          <AvInput

            id="status-filter"
            type="input"
            className="form-control"
            name={'status-filter'}

            onChange={(e) => {
              const serachString  = e.target.value;
              if(e.target.value){
                props.getHfByName(serachString)
              }else{
                if (activeCountry) {
                  sortEntities(activeCountry.id);
                }
              }
             
            }}
          // value={filterList.status}
          >
          </AvInput>
        </AvGroup>
        </AvForm> */}
      <div className="table-responsive">
        {healthcareFacilityLevelList && healthcareFacilityLevelList.length > 0 ? (
          <Table responsive>
            <thead>
            <tr>
              <th>
                Country
              </th>
              <th >
                Level
              </th>
              <th >
                En_Description
              </th>
              <th >
                St_Description
              </th>
            </tr>
            </thead>
            <tbody>
            {healthcareFacilityLevelList.map((healthcareFacilityLevel, i) => (
              <tr key={`entity-${i}`}>
                <td>{healthcareFacilityLevel.countryId.name}</td>
                <td>{healthcareFacilityLevel.level}</td>
                <td>{healthcareFacilityLevel.en_description}</td>
                <td>{healthcareFacilityLevel.st_description}</td>
                
                <td className="text-right">
                  <div className="btn-group flex-btn-group-container">
                    <Button tag={Link} to={`${match.url}/${healthcareFacilityLevel.countryId.id}/${healthcareFacilityLevel.level}`} color="info" size="sm">
                      <FontAwesomeIcon icon="eye"/> <span className="d-none d-md-inline">View</span>
                    </Button>
                    <Button
                      tag={Link}
                      to={`${match.url}/${healthcareFacilityLevel.countryId.id}/${healthcareFacilityLevel.level}/edit`}
                      color="primary"
                      size="sm"
                    >
                      <FontAwesomeIcon icon="pencil-alt"/> <span className="d-none d-md-inline">Edit</span>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">No Healthcare Facility Level found</div>
        )}
      </div>
      {props.totalItems ? (
        <div className={healthcareFacilityLevelList && healthcareFacilityLevelList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems}
                          itemsPerPage={paginationState.itemsPerPage}/>
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={props.totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  healthcareFacilityLevelList: storeState.healthcareFacilityLevel.entities,
  loading: storeState.healthcareFacilityLevel.loading,
  totalItems: storeState.healthcareFacilityLevel.totalItems,
  countries: storeState.region.countries,
  userCountryId: storeState.authentication.userCountryId,
  isAdmin: hasAnyPermission(storeState.authentication.account.roles, ['ADMIN'])
});

const mapDispatchToProps = {
  getEntities,
  getCountries,
  // getHfByName
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(HealthcareFacilityLevel);
