import React from 'react';
import {Switch} from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import HealthcareFacility from './healthcare-facility';
import HealthcareFacilityDetail from './healthcare-facility-detail';
import HealthcareFacilityUpdate from './healthcare-facility-update';

const Routes = ({match}) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={HealthcareFacilityUpdate}/>
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={HealthcareFacilityUpdate}/>
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={HealthcareFacilityDetail}/>
      <ErrorBoundaryRoute path={match.url} component={HealthcareFacility}/>
    </Switch>
  </>
);

export default Routes;
