import { PaymentStatus } from 'app/shared/model/enumerations/payment-status.model';
import { IPatientCase } from './patient-case.model';
import { IRoute } from './route.model';
import { IAmbulance } from 'app/shared/model/ambulance.model';
import { IHealthcareFacility } from 'app/shared/model/healthcare-facility.model';
import { IWatotoCareEntityDetail } from 'app/shared/model/watotocare-entity-detail.model';

export interface IPaymentDriver {
  id: string;
  firstName?: string;
  lastName: string;
  phoneNumber: string;
  mpesaNumber: string;
}

export interface IPaymentPhysician {
  id: string;
  firstName?: string;
  lastName: string;
  phoneNumber: string;
  mpesaNumber: string;
  healthcareFacility?: IHealthcareFacility;
}

export interface IPaymentJourney {
  endVillage?: any;
  endHealthcareFacility?: any;
  startVillage?: any;
  startHealthcareFacility?: any;
  id: string;
  incidentReportId?: string;
  incidentReportDetail?: IWatotoCareEntityDetail;
  incidentReportDescription?: string;
  totalCost: number;
  totalDurationSeconds: number;
  totalDistance: number;
  driver: IPaymentDriver;
  physician: IPaymentPhysician;
  route?: IRoute;
  patientCase?: IPatientCase;
  ambulance?: IAmbulance;
}

export interface IPayment {
  id?: string;
  status?: PaymentStatus;
  requestedAmount?: number;
  processingError?: string;
  mpesaTransactionId?: string;
  overrideAmount?: number;

  tenderedDateTime?: string;

  approverId?: string;
  approvedDateTime?: string;
  approverNote?: string;

  processorId?: string;
  processedDateTime?: string;
  processorNote?: string;

  additionalNotes?: string;
  countryId?: string;
  driverJourney?: IPaymentJourney;
  physicianJourney?: IPaymentJourney;

  createdDateTime?: Date;
  rejectedDateTime?: Date;
}

export interface IPaymentUpdate {
  status?: PaymentStatus;
  overrideAmount?: number;
  paymentMethod?: string;
  additionalNotes?: string;
}

export interface IPaymentFilter {
  countryId?: string;
  status?: string;
  activePage?: number;
  paymentId?: string;
  driverName?: string;
  sort?: ISortType;
}

export interface ISortType {
  sortType?: string;
  sortOrder?: string;
}

export const defaultValue: Readonly<IPayment> = {};
