import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IPatientBaby } from 'app/shared/model/patient-baby.model';
import { IOutcomeDashboardEntry } from 'app/shared/model/outcome-dashboard-entry.model';
import { IDispatcherDashboardEntry } from 'app/shared/model/dispatcher-dashboard-entry.model';

export const ACTION_TYPES = {
  FETCH_BABY_LIST: 'patient-baby/FETCH_BABY_LIST',
  FETCH_OUTCOME_DASHBOARD: 'patient-baby/FETCH_OUTCOME_DASHBOARD',
  FETCH_DISPATCHER_DASHBOARD: 'patient-baby/FETCH_DISPATCHER_DASHBOARD',
  FETCH_BABY: 'patient-baby/FETCH_BABY',
  CREATE_BABY: 'patient-baby/CREATE_BABY',
  UPDATE_BABY: 'patient-baby/UPDATE_BABY',
  DELETE_BABY: 'patient-baby/DELETE_BABY',
  RESET: 'patient-baby/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IPatientBaby>,
  outcomeDashboardEntries: [] as ReadonlyArray<IOutcomeDashboardEntry>,
  dispatcherDashboardEntries: [] as ReadonlyArray<IDispatcherDashboardEntry>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false
};

export type PatientBabyState = Readonly<typeof initialState>;

// Reducer

export default (state: PatientBabyState = initialState, action): PatientBabyState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_BABY_LIST):
    case REQUEST(ACTION_TYPES.FETCH_OUTCOME_DASHBOARD):
    case REQUEST(ACTION_TYPES.FETCH_DISPATCHER_DASHBOARD):
    case REQUEST(ACTION_TYPES.FETCH_BABY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_BABY):
    case REQUEST(ACTION_TYPES.UPDATE_BABY):
    case REQUEST(ACTION_TYPES.DELETE_BABY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_BABY_LIST):
    case FAILURE(ACTION_TYPES.FETCH_OUTCOME_DASHBOARD):
    case FAILURE(ACTION_TYPES.FETCH_DISPATCHER_DASHBOARD):
    case FAILURE(ACTION_TYPES.FETCH_BABY):
    case FAILURE(ACTION_TYPES.CREATE_BABY):
    case FAILURE(ACTION_TYPES.UPDATE_BABY):
    case FAILURE(ACTION_TYPES.DELETE_BABY):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_BABY_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_OUTCOME_DASHBOARD):
      return {
        ...state,
        loading: false,
        outcomeDashboardEntries: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_DISPATCHER_DASHBOARD):
      return {
        ...state,
        loading: false,
        dispatcherDashboardEntries: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_BABY):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_BABY):
    case SUCCESS(ACTION_TYPES.UPDATE_BABY):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_BABY):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        entities: [] as ReadonlyArray<IPatientBaby>,
        outcomeDashboardEntries: [] as ReadonlyArray<IOutcomeDashboardEntry>,
        dispatcherDashboardEntries: [] as ReadonlyArray<IDispatcherDashboardEntry>,
        entity: defaultValue,
        updating: false,
        updateSuccess: false
      };
    default:
      return state;
  }
};

const apiUrl = 'api/patient-babies';

// Actions

export const getOutcomeDashboardEntries = (countryId, date) => {
  const requestUrl = `${apiUrl}/outcome-dashboard?date=${date}&countryId=${countryId}&cacheBuster=${new Date().getTime()}`;
  return {
    type: ACTION_TYPES.FETCH_OUTCOME_DASHBOARD,
    payload: axios.get<IPatientBaby>(requestUrl)
  };
};

export const getDispatcherDashboardEntries = (countryId, date) => {
  const requestUrl = `${apiUrl}/dispatcher-dashboard?date=${date}&countryId=${countryId}&cacheBuster=${new Date().getTime()}`;
  return {
    type: ACTION_TYPES.FETCH_DISPATCHER_DASHBOARD,
    payload: axios.get<IPatientBaby>(requestUrl)
  };
}

export const getEntity: ICrudGetAction<IPatientBaby> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_BABY,
    payload: axios.get<IPatientBaby>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IPatientBaby> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_BABY,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const updateEntity: ICrudPutAction<IPatientBaby> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_BABY,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IPatientBaby> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_BABY,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
