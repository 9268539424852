import { ISymptom } from 'app/shared/model/symptom.model';
import { IJourney } from 'app/shared/model/journey.model';
import { IWatotoCareEntityDetail } from 'app/shared/model/watotocare-entity-detail.model';

export interface IPatientVisit {
  id?: string;
  patientCaseId?: string;
  visitCount?: number;
  riskProfile?: string;
  createdAt?: string;
  journeyIds?: string[];
  journeys?: IJourney[];
  incompleteReason?: string;
  incompleteReasonComment?: string;
  patientVisitEntities?: {
    patientVisitId?: string;
    entityDetailId?: string;
    entityDetail?: IWatotoCareEntityDetail;
    comment?: string;
  }[];
  motherSymptoms?: {
    symptomId?: number
    symptom?: ISymptom
  }[];
  babySymptoms?: {
    symptomId?: number
    symptom?: ISymptom
  }[];
}

export const defaultValue: Readonly<IPatientVisit> = {};
