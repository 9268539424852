import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './vehicleType.reducer';

export interface IVehicleTypeDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const VehicleTypeDetail = (props: IVehicleTypeDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { vehicleTypeEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          VehicleType [<b>{vehicleTypeEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">

          <dt>
            <span id="enCondition">Vehicle Type (English)</span>
          </dt>
          <dd>{vehicleTypeEntity.enType}</dd>
          <dt>
            <span id="stCondition">Vehicle Type (Sesotho)</span>
          </dt>
          <dd>{vehicleTypeEntity.stType}</dd>
          <dt>
            <span id="vehicleLevel">Vehicle Level</span>
          </dt>
          <dd>{vehicleTypeEntity.vehicleLevel}</dd>
          <dt>
            <span id="requiredFacilityLevel">Country</span>
          </dt>
          <dd>{props.countries.find(country => country.id === vehicleTypeEntity.countryId)?.name}</dd>
          <dt>
            <span id="paymentRequired">Payment Required</span>
          </dt>
          <dd>{vehicleTypeEntity.paymentRequired ? 'True' : 'False'}</dd>
          <dt>
            <span id="vehicleImage">Vehicle Image</span>
          </dt>
          <dd><img src={`data:image/svg+xml;base64,${vehicleTypeEntity.image}`} alt="vehicle_image" width={50}
                   height={50} /></dd>
        </dl>
        <Button tag={Link} to="/vehicle-type" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/vehicle-type/${vehicleTypeEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ vehicleType, authentication }: IRootState) => ({
  vehicleTypeEntity: vehicleType.entity,
  countries: authentication.countries
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VehicleTypeDetail);
