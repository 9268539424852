import { IAmbulance } from 'app/shared/model/ambulance.model';
import { ICouncil } from 'app/shared/model/council.model';
import {IRegion} from "app/shared/model/region.model";

export interface IHealthcareFacilityLevel {
  countryId?: any;
  en_description?: string;
  level?: string;
  st_description?: string;
}

export const defaultValue: Readonly<IHealthcareFacilityLevel> = {};
