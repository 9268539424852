import axios from 'axios';
import { isEmpty } from 'lodash';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { IRegion, RegionType, IRegionDistrict, IHealthcareFacility } from '../../models/payment-dashboard.model';
import { getFormattedFilterSubMenuOptions, getYearList, getMonthList, get6MonthsDateRange, getRequestBody } from '../../utils';

export const ACTION_TYPES = {
  FETCH_REGION_LIST_BY_TYPE: 'FETCH_REGION_LIST_BY_TYPE',
  FETCH_DISPATCH_CENTER: 'FETCH_DISPATCH_CENTER',
  ALL_FETCH_HEALTHCAREFACILITY_LIST: 'ALL_FETCH_HEALTHCAREFACILITY_LIST',
  HEALTH_FACILITY_LEVEL: 'HEALTH_FACILITY_LEVEL',
  YEAR: 'YEAR',
  MONTH: 'MONTH',
  TRANSPORT_TYPE: 'TRANSPORT_TYPE',
  JOURNEY_TYPE: 'JOURNEY_TYPE',
  PATIENT_TYPE: 'PATIENT_TYPE',
  EMERGENCY_TYPE: 'EMERGENCY_TYPE',
  BYPASS_TYPE: 'BYPASS_TYPE',
  OVERVIEW_SECTION_TOT_EMG_REF: 'OVERVIEW_SECTION_TOT_EMG_REF',
  OVERVIEW_SECTION_TOT_PATIENT_CASES: 'OVERVIEW_SECTION_TOT_PATIENT_CASES',
  OVERVIEW_SECTION_TOT_MAT_EMG: 'OVERVIEW_SECTION_TOT_MAT_EMG',
  OVERVIEW_SECTION_TOT_NEO_EMG: 'OVERVIEW_SECTION_TOT_NEO_EMG',
  OVERVIEW_SECTION_TOT_BOTH_EMG: 'OVERVIEW_SECTION_TOT_BOTH_EMG',
  OVERVIEW_SEC_TOT_REF_PER_MONTH: 'OVERVIEW_SEC_TOT_REF_PER_MONTH',
};

const initialState = {
  loading: false,
  errorMessage: null,
  updating: false,
  updateSuccess: false,
  region: [],
  district: [],
  council: [],
  dispatchCenter: [],
  healthFacility: [],
  healthFaciliyLevel: [],
  year: [],
  month: [],
  transportType: [],
  journeyType: [],
  patientType: [],
  emergencyType: [],
  bypassType: [],
  OSTotalEmergencyReferrals: null,
  OSTotalPatientCases: null,
  OSTotalNeonatalEmergencies: null,
  OSTotalMaternalEmergencies: null,
  OSTotalBothEmergencies: null,
  OSTotalReferralPerMonth: [],
};

export type MeDashboardState = Readonly<typeof initialState>;

const COUNTRY_NAME = 'Lesotho';
const COUNTRY_ID = '9a475ef0-49f6-11eb-89d2-a85e45d05e66';

// Reducer
export default (state: MeDashboardState = initialState, action): MeDashboardState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_REGION_LIST_BY_TYPE):
    case REQUEST(ACTION_TYPES.FETCH_DISPATCH_CENTER):
    case REQUEST(ACTION_TYPES.ALL_FETCH_HEALTHCAREFACILITY_LIST):
    case REQUEST(ACTION_TYPES.OVERVIEW_SECTION_TOT_EMG_REF):
    case REQUEST(ACTION_TYPES.OVERVIEW_SECTION_TOT_PATIENT_CASES):
    case REQUEST(ACTION_TYPES.OVERVIEW_SECTION_TOT_MAT_EMG):
    case REQUEST(ACTION_TYPES.OVERVIEW_SECTION_TOT_NEO_EMG):
    case REQUEST(ACTION_TYPES.OVERVIEW_SECTION_TOT_BOTH_EMG):
    case REQUEST(ACTION_TYPES.OVERVIEW_SEC_TOT_REF_PER_MONTH):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_REGION_LIST_BY_TYPE):
    case FAILURE(ACTION_TYPES.FETCH_DISPATCH_CENTER):
    case FAILURE(ACTION_TYPES.ALL_FETCH_HEALTHCAREFACILITY_LIST):
    case FAILURE(ACTION_TYPES.OVERVIEW_SECTION_TOT_EMG_REF):
    case FAILURE(ACTION_TYPES.OVERVIEW_SECTION_TOT_PATIENT_CASES):
    case FAILURE(ACTION_TYPES.OVERVIEW_SECTION_TOT_MAT_EMG):
    case FAILURE(ACTION_TYPES.OVERVIEW_SECTION_TOT_NEO_EMG):
    case FAILURE(ACTION_TYPES.OVERVIEW_SECTION_TOT_BOTH_EMG):
    case FAILURE(ACTION_TYPES.OVERVIEW_SEC_TOT_REF_PER_MONTH):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };

    case SUCCESS(ACTION_TYPES.FETCH_REGION_LIST_BY_TYPE):
      return {
        ...state,
        loading: false,
        region: getFormattedFilterSubMenuOptions(action.payload.data['REGION'].filter(el => el['parentName'] === COUNTRY_NAME)),
        district: getFormattedFilterSubMenuOptions(action.payload.data['DISTRICT'].filter(el => el['parentName'] === COUNTRY_NAME)),
        council: getFormattedFilterSubMenuOptions(
          action.payload.data['COUNCIL'].filter(council => {
            const districts = action.payload.data['DISTRICT'].filter(el => el['parentName'] === COUNTRY_NAME);
            const filteredCouncils = districts.filter(district => council.parentId === district.id);
            return filteredCouncils;
          })
        ),
      };
    case SUCCESS(ACTION_TYPES.FETCH_DISPATCH_CENTER):
      return {
        ...state,
        loading: false,
        dispatchCenter: getFormattedFilterSubMenuOptions(action.payload.data.filter(el => el['countryId'] === COUNTRY_ID)),
      };
    case SUCCESS(ACTION_TYPES.ALL_FETCH_HEALTHCAREFACILITY_LIST):
      return {
        ...state,
        loading: false,
        healthFacility: getFormattedFilterSubMenuOptions(
          action.payload.data.filter(el => el['council']['parent']['parent']['parent']['id'] === COUNTRY_ID)
        ),
        // healthFacility: getFormattedFilterSubMenuOptions(action.payload.data),
      };
    case SUCCESS(ACTION_TYPES.OVERVIEW_SECTION_TOT_EMG_REF):
      return {
        ...state,
        loading: false,
        OSTotalEmergencyReferrals: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.OVERVIEW_SECTION_TOT_PATIENT_CASES):
      return {
        ...state,
        loading: false,
        OSTotalPatientCases: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.OVERVIEW_SECTION_TOT_MAT_EMG):
      return {
        ...state,
        loading: false,
        OSTotalMaternalEmergencies: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.OVERVIEW_SECTION_TOT_NEO_EMG):
      return {
        ...state,
        loading: false,
        OSTotalNeonatalEmergencies: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.OVERVIEW_SECTION_TOT_BOTH_EMG):
      return {
        ...state,
        loading: false,
        OSTotalBothEmergencies: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.OVERVIEW_SEC_TOT_REF_PER_MONTH):
      return {
        ...state,
        loading: false,
        OSTotalReferralPerMonth: action.payload.data,
      };
    case ACTION_TYPES.HEALTH_FACILITY_LEVEL:
      return {
        ...state,
        loading: false,
        healthFaciliyLevel: getFormattedFilterSubMenuOptions(action.payload),
      };
    case ACTION_TYPES.YEAR:
      return {
        ...state,
        loading: false,
        year: action.payload,
      };
    case ACTION_TYPES.MONTH:
      return {
        ...state,
        loading: false,
        month: action.payload,
      };
    case ACTION_TYPES.TRANSPORT_TYPE:
      return {
        ...state,
        loading: false,
        transportType: getFormattedFilterSubMenuOptions(action.payload),
      };
    case ACTION_TYPES.JOURNEY_TYPE:
      return {
        ...state,
        loading: false,
        journeyType: getFormattedFilterSubMenuOptions(action.payload),
      };
    case ACTION_TYPES.PATIENT_TYPE:
      return {
        ...state,
        loading: false,
        patientType: getFormattedFilterSubMenuOptions(action.payload),
      };
    case ACTION_TYPES.EMERGENCY_TYPE:
      return {
        ...state,
        loading: false,
        emergencyType: getFormattedFilterSubMenuOptions(action.payload),
      };
    case ACTION_TYPES.BYPASS_TYPE:
      return {
        ...state,
        loading: false,
        bypassType: getFormattedFilterSubMenuOptions(action.payload),
      };
    default:
      return state;
  }
};

// Actions

export const getRegionsByType = () => {
  const apiUrl = 'api/regions';
  const requestUrl = `${apiUrl}/all-grouped-by-type`;
  return {
    type: ACTION_TYPES.FETCH_REGION_LIST_BY_TYPE,
    payload: axios.get<Map<RegionType, IRegion[]>>(`${requestUrl}?cacheBuster=${new Date().getTime()}`),
  };
};

export const getDispatchCenters = () => {
  const requestUrl = `${'api/getAllDispatchCenter'}`;
  return {
    type: ACTION_TYPES.FETCH_DISPATCH_CENTER,
    payload: axios.get<IRegionDistrict>(requestUrl),
  };
};

export const getAllFacility = (parentCountryId = '') => {
  const apiUrl = 'api/healthcare-facilities';
  const requestUrl = parentCountryId !== '' || parentCountryId != null ? `${apiUrl + '?countryId=' + parentCountryId}` : `${apiUrl}`;
  // const requestUrl = `${apiUrl + '?countryId=' + parentId}`;
  return {
    type: ACTION_TYPES.ALL_FETCH_HEALTHCAREFACILITY_LIST,
    payload: axios.get<IHealthcareFacility>(`${requestUrl}`),
  };
};

export const getOSTotalEmergencyReferrals = () => {
  const apiUrl = 'api/totalEmergencyReferral';
  const requestUrl = `${apiUrl}?countryId=${COUNTRY_ID}`;
  return {
    type: ACTION_TYPES.OVERVIEW_SECTION_TOT_EMG_REF,
    payload: axios.get(requestUrl),
  };
};

export const getOSTotalPatientCases = () => {
  const apiUrl = 'api/totalPatientCases';
  const requestUrl = `${apiUrl}?countryId=${COUNTRY_ID}`;
  return {
    type: ACTION_TYPES.OVERVIEW_SECTION_TOT_PATIENT_CASES,
    payload: axios.get(requestUrl),
  };
};

export const getOSTotalMaternalEmergencies = () => {
  const apiUrl = 'api/totalMaternalReferral';
  const requestUrl = `${apiUrl}?countryId=${COUNTRY_ID}`;
  return {
    type: ACTION_TYPES.OVERVIEW_SECTION_TOT_MAT_EMG,
    payload: axios.get(requestUrl),
  };
};

export const getOSTotalNeonatalEmergencies = () => {
  const apiUrl = 'api/totalNeonatalReferral';
  const requestUrl = `${apiUrl}?countryId=${COUNTRY_ID}`;
  return {
    type: ACTION_TYPES.OVERVIEW_SECTION_TOT_NEO_EMG,
    payload: axios.get(requestUrl),
  };
};

export const getOSTotalBothEmergencies = () => {
  const apiUrl = 'api/totalBothReferral';
  const requestUrl = `${apiUrl}?countryId=${COUNTRY_ID}`;
  return {
    type: ACTION_TYPES.OVERVIEW_SECTION_TOT_BOTH_EMG,
    payload: axios.get(requestUrl),
  };
};

export const getHealthFacilityLevel = () => {
  return {
    type: ACTION_TYPES.HEALTH_FACILITY_LEVEL,
    payload: [
      { name: '1', value: '1' },
      { name: '2', value: '2' },
      { name: '3', value: '3' },
    ],
  };
};

export const getYear = () => {
  return {
    type: ACTION_TYPES.YEAR,
    payload: getYearList(),
  };
};

export const getMonth = () => {
  return {
    type: ACTION_TYPES.MONTH,
    payload: getMonthList(),
  };
};

export const getTransportType = () => {
  return {
    type: ACTION_TYPES.TRANSPORT_TYPE,
    payload: [
      { name: 'Ambulance', value: 'AMBULANCE' },
      { name: 'Driver', value: 'DRIVER' },
    ],
  };
};

export const getJourneyType = () => {
  return {
    type: ACTION_TYPES.JOURNEY_TYPE,
    payload: [
      { name: 'Community to HF', value: 'Community to HF' },
      { name: 'HF to HF', value: 'HF to HF' },
    ],
  };
};

export const getPatientType = () => {
  return {
    type: ACTION_TYPES.PATIENT_TYPE,
    payload: [
      { name: 'Mother', value: 'MOTHER' },
      { name: 'Baby', value: 'BABY' },
      { name: 'Both', value: 'BOTH' },
      { name: 'Other', value: 'OTHER' },
    ],
  };
};

export const getEmergencyType = () => {
  return {
    type: ACTION_TYPES.EMERGENCY_TYPE,
    payload: [
      { name: 'Maternal', value: 'MATERNAL' },
      { name: 'Maternal', value: 'MATERNAL' },
      { name: 'Maternal', value: 'MATERNAL' },
      { name: 'Neonatal', value: 'NEONATAL' },
    ],
  };
};

export const getBypassType = () => {
  return {
    type: ACTION_TYPES.BYPASS_TYPE,
    payload: [
      { name: 'True', value: 'TRUE' },
      { name: 'False', value: 'FALSE' },
    ],
  };
};

export const getTotalReferralsPerMonth = filterOptions => {
  const apiUrl = 'api/emergencyReferrals';
  const requestUrl = `${apiUrl}?countryId=${COUNTRY_ID}`;
  let requestBody: object;

  if (isEmpty(filterOptions)) {
    const [startDate, endDate] = get6MonthsDateRange();
    requestBody = {
      startDate,
      endDate,
    };
  } else {
    requestBody = getRequestBody(filterOptions);
  }

  return {
    type: ACTION_TYPES.OVERVIEW_SEC_TOT_REF_PER_MONTH,
    payload: axios.post(requestUrl, requestBody),
  };
};
