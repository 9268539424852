export const ACTION_TYPES = {
  FILTER_OPTION: 'FILTER_OPTION',
  APPLY_FILTER_OPTION: 'APPLY_FILTER_OPTION',
  RESET_FILTER_OPTION: 'RESET_ALL_FILTER_OPTION',
};

const DEFAULT_FILTER = {
  "year": [new Date().getFullYear().toString()]
};

const initialState = {
  loading: false,
  errorMessage: null,
  updating: false,
  updateSuccess: false,
  selectedFilterOption: {},
  resetFilterOption: false,
  applyFilterOption: false,
};

export type FilterOptionState = Readonly<typeof initialState>;

// Reducer
export default (state: FilterOptionState = initialState, action): FilterOptionState => {
  switch (action.type) {
    case ACTION_TYPES.FILTER_OPTION:
      return {
        ...state,
        selectedFilterOption: action.payload,
      };
    case ACTION_TYPES.RESET_FILTER_OPTION:
      return {
        ...state,
        resetFilterOption: action.payload,
        selectedFilterOption: action.payload ? DEFAULT_FILTER : state.selectedFilterOption
      };
    case ACTION_TYPES.APPLY_FILTER_OPTION:
      return {
        ...state,
        applyFilterOption: action.payload,
      };
    default:
      return state;
  }
};

// Actions
export const setFilterOption = (filterOption: any) => {
  return {
    type: ACTION_TYPES.FILTER_OPTION,
    payload: filterOption,
  };
};

export const setResetFilterOption = (flag: boolean) => {
  return {
    type: ACTION_TYPES.RESET_FILTER_OPTION,
    payload: flag,
  };
};

export const setApplyFilterOption = (flag: boolean) => {
  return {
    type: ACTION_TYPES.APPLY_FILTER_OPTION,
    payload: flag,
  };
};
