import React, { useState } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import SideBar from "./sidebar/sidebar";
import "./dashboardWrapper.scss";

const DashboardWrapper = ({ optionsFilters, optionsFiltersCategories, menuItems, activeTab, children }) => {

  return (
    <Router>
      <div className='dashboard-container'>
        <div className="sidebar-container">
          <SideBar optionsFilters={optionsFilters} optionsFiltersCategories={optionsFiltersCategories} menuItems={menuItems} activeTab={activeTab} />
        </div>
        <div className='content'>{children}</div>
      </div>
    </Router>
  )
}

export default DashboardWrapper
