import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import PatientBabyDetail from './patient-baby-detail';
import PatientBabyUpdate from './patient-baby-update';
import PatientBabyDischarge from './patient-baby-discharge';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact key="patient-baby-add" path={`${match.url}/create`} component={PatientBabyUpdate} />
      <ErrorBoundaryRoute exact key="patient-baby-update" path={`${match.url}/:id/edit`}
                          component={PatientBabyUpdate} />
      <ErrorBoundaryRoute exact key="patient-baby-view" path={`${match.url}/:id`} component={PatientBabyDetail} />
      <ErrorBoundaryRoute exact key="patient-baby-discharge" path={`${match.url}/:id/discharge`}
                          component={PatientBabyDischarge} />
    </Switch>
  </>
);

export default Routes;
