import React from 'react';
import {connect} from 'react-redux';

import {IRootState} from 'app/shared/reducers';
import {logout} from 'app/shared/reducers/authentication';
import {RouteComponentProps} from "react-router-dom";

export interface ILogoutProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }>{}

export const Logout = (props: ILogoutProps) => {

  props.logout();

  return (
    <div className="p-5">
      <h4>Logging Out...</h4>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  logoutUrl: storeState.authentication.logoutUrl,
  idToken: storeState.authentication.idToken,
  isAuthenticated : storeState.authentication.isAuthenticated
});

const mapDispatchToProps = { logout };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
