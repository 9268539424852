import React, { useEffect, useState } from 'react'
import { DisplayCardGroup } from './displayCardGroup';
import { DisplayIndicator } from './displayCardIndicator';
import { BarChart, LineChart, StackedBarChart } from '../../../charts';
import { MONTH_LABELS, PRIMARY_CHART_COLOR, SECONDARY_CHART_COLOR } from '../constants';
import { DUMMY_DATA, DUMMMY_LABELS, BAR_CHART_DATA_SET, DUMMY_TBTOD_DATA, DUMMY_TBTOV_DATA, DUMMY_10_REASONS_FOR_DRIVER_REFUSAL } from '../dummyData';
import {mergeAndSum} from "app/modules/programDashboard/utils";

export const TransportSection = ({countReferrals, emergencyReferrals, referrals}) => {
    const [labels, setLabels] = useState([]);
    const [typeOfDriversLabels, setTypeOfDriversLabels] = useState([]);
    const [typeOfDriversData, setTypeOfDriversData] = useState({});
    const [journeysPerDriverData, setJourneysPerDriverData] = useState([]);
    const [ambulanceByDistrictData, setAmbulanceByDistrictData] = useState([]);
    const [ambulanceByDistrict, setAmbulanceByDistrict] = useState(0);
    // const [ambulanceByHfData, setAmbulanceByHfData] = useState([]);
    // const [ambulanceByHf, setAmbulanceByHf] = useState(0);
    const [driversByDistrictData, setDriversByDistrictData] = useState([]);
    const [driversByDistrict, setDriversByDistrict] = useState(0);
    // const [driversByHfData, setDriversByHfData] = useState([]);
    const [driverRefusalReasonData, setDriverRefusalReasonData] = useState([]);
    const [avgCostPerTripLabels, setAvgCostPerTripLabels] = useState([]);
    const [avgCostPerTripData, setAvgCostPerTripData] = useState([]);

    const TOTAL_CARD_GROUP_DATA = [
        { label: "5.1 Community drivers journeys:", icon: "content/images/new_car.svg", value: countReferrals?.driverCount },
        { label: "5.2 Ambulance drivers journeys:", icon: "content/images/new_ambulance.svg", value: countReferrals?.ambulanceCount },
        // { label: "5.3 Total cumulative cost (sum of all referrals):", icon: "content/images/cost_icon.svg", value: countReferrals?.totalCost },
        // { label: "5.4 Total cumulative distance (sum of all the referrals):", icon: "content/images/distance_icon.svg", value: countReferrals?.totalDistance },
    ]

    useEffect(() => {
      if(emergencyReferrals?.communityDrivers && typeof emergencyReferrals.communityDrivers === 'object'){
          let ambulanceByDistrictCount = 0;
            // let ambulanceByHfKeysCount = 0;
            let driversByDistrictCount = 0;

            const data = {
                'Commmunity Driver': {
                  label: 'Commmunity Driver',
                  color: PRIMARY_CHART_COLOR,
                  data: [],
                },
                Ambulance: {
                  label: 'Ambulance',
                  color: '#00c04b',
                  data: [],
                },
              };


          const aggregatedGraphData = mergeAndSum([
              emergencyReferrals?.communityDrivers,
              emergencyReferrals?.ambulances,
          ])

          const graphDistrictKeys = Object.keys(aggregatedGraphData)
             .sort(function(a,b){return aggregatedGraphData[b]-aggregatedGraphData[a]});

          for(let i = 0; i < graphDistrictKeys.length; i++) {
              data['Commmunity Driver'].data.push(emergencyReferrals?.communityDrivers[graphDistrictKeys[i]] ?? 0)
              data['Ambulance'].data.push(emergencyReferrals?.ambulances[graphDistrictKeys[i]] ?? 0)
          }


            const ambulanceByDistrictKeys = Object.keys(emergencyReferrals?.activeAmbulanceByDiStrict).map((el, i) => {
                ambulanceByDistrictCount = ambulanceByDistrictCount + emergencyReferrals?.activeAmbulanceByDiStrict[el];
                return { label: el, data: emergencyReferrals?.activeAmbulanceByDiStrict[el]}
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            // const ambulanceByHfKeys = Object.keys(emergencyReferrals?.activeAmbulanceByHf).map((el, i) => {
            //     ambulanceByHfKeysCount = ambulanceByHfKeysCount + emergencyReferrals?.activeAmbulanceByHf[el];
            //     return { label: el, data: emergencyReferrals?.activeAmbulanceByHf[el]}
            // }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            const driversByDistrictKeys = Object.keys(emergencyReferrals?.activeDriversByDiStrict).map((el, i) => {
                driversByDistrictCount = driversByDistrictCount + emergencyReferrals?.activeDriversByDiStrict[el];
                return { label: el, data: emergencyReferrals?.activeDriversByDiStrict[el]}
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            // const driversByHfKeys = Object.keys(emergencyReferrals?.activeDriversByHf).map((el, i) => {
            //     return { label: el, data: emergencyReferrals?.activeDriversByHf[el]}
            // }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            setTypeOfDriversData(data);
            setTypeOfDriversLabels(graphDistrictKeys);
            setAmbulanceByDistrictData(ambulanceByDistrictKeys);
            setAmbulanceByDistrict(ambulanceByDistrictCount);
            // setAmbulanceByHfData(ambulanceByHfKeys);
            // setAmbulanceByHf(ambulanceByHfKeysCount);
            setDriversByDistrictData(driversByDistrictKeys);
            setDriversByDistrict(driversByDistrictCount);
            // setDriversByHfData(driversByHfKeys);
        }
    }, [emergencyReferrals]);

    useEffect(() => {
        if(referrals?.journeysPerDriver && Object.keys(referrals?.journeysPerDriver).length){
            const months = [];

            const journeysKeys = Object.keys(referrals?.journeysPerDriver);
            const avgCostData = [];
            const journeysKeysData = [];
            const avgCostKeys = Object.keys(referrals?.avgCostPertrip)
                .sort(function(a,b){return referrals?.avgCostPertrip[b]-referrals?.avgCostPertrip[a]});

            for (let i = 0; i < avgCostKeys.length; i++) {
                avgCostData.push(referrals?.avgCostPertrip[avgCostKeys[i]] ? referrals?.avgCostPertrip[avgCostKeys[i]] / 100 : 0)
            }


          for(let i = 0; i < MONTH_LABELS.length; i++) {
                if(journeysKeys.includes(MONTH_LABELS[i].month.toString())) {
                    months.push(MONTH_LABELS[i].shortMonth);
                    journeysKeysData.push(referrals?.journeysPerDriver[MONTH_LABELS[i].month.toString()] ? referrals?.journeysPerDriver[MONTH_LABELS[i].month.toString()] : 0)
                }
            }
            const driverRefusalReasonKeys = Object.keys(referrals?.driverRefusalReason).map((el, i) => {
                return { label: el, data: referrals?.driverRefusalReason[el]}
            }).sort((a,b) => a.data > b.data ? -1 : a.data < b.data ? 1 : 0);

            setLabels(months);
            setJourneysPerDriverData(journeysKeysData);
            setAvgCostPerTripLabels(avgCostKeys);
            setAvgCostPerTripData(avgCostData);
            setDriverRefusalReasonData(driverRefusalReasonKeys);
        }
    }, [referrals]);

    return (
        <div className='mt-0 mb-3'>
            <p className="mb-2" style={{ fontSize: "18px", fontWeight: 600, color: SECONDARY_CHART_COLOR }}>5. Journey</p>
            <div className="row mb-3">
                <DisplayCardGroup data={TOTAL_CARD_GROUP_DATA.slice(0, 2)} col="col-6" />
            </div>
            <div className="row mb-3">
                <DisplayCardGroup data={TOTAL_CARD_GROUP_DATA.slice(2)} col="col-6" />
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "5.3 Number of active drivers per district / region:", value: driversByDistrict }}>
                        <BarChart chartLabel="Number of active drivers per district / region" labels={driversByDistrictData.map(item => item.label)} dataSet={driversByDistrictData.map(item => item.data)} bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} axis='y' flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "5.4 Number of active ambulance drivers per district / region:", value: ambulanceByDistrict }}>
                        <BarChart chartLabel="Number of active ambulance drivers per district / region" labels={ambulanceByDistrictData.map(item => item.label)} dataSet={ambulanceByDistrictData.map(item => item.data)} bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} axis='y' flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
            {/* <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "5.7 Number of active community drivers per HF", value: "" }}>
                        <BarChart chartLabel="Number of active drivers per HF" labels={ambulanceByHfData.map(item => item.label)} dataSet={ambulanceByHfData.map(item => item.data)} bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} axis='y' flag={true} />
                    </DisplayIndicator>
                </div>
            </div> */}
            {/* <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "5.8 Number of active ambulance drivers per HF", value: "" }}>
                        <BarChart chartLabel="Number of active ambulance drivers per HF" labels={driversByHfData.map(item => item.label)} dataSet={driversByHfData.map(item => item.data)} bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} axis='y' flag={true} />
                    </DisplayIndicator>
                </div>
            </div> */}
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "5.5 Transportation by type of driver", value: "" }}>
                        <StackedBarChart chartLabels={typeOfDriversLabels} chartData={typeOfDriversData} />
                    </DisplayIndicator>
                </div>
            </div>
            {/* <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "5.10 Transportation by type of Vehicle", value: "" }}>
                        <StackedBarChart chartLabels={DUMMMY_LABELS} chartData={DUMMY_TBTOV_DATA} />
                    </DisplayIndicator>
                </div>
            </div> */}
            {/* <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "5.6 Average number of journeys per driver", value: "" }}>
                        <BarChart chartLabel="Average number of journeys per drivers" labels={labels} dataSet={journeysPerDriverData} bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} axis='x' flag={true} />
                    </DisplayIndicator>
                </div>
            </div> */}
            {/* <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "5.12 Average number of journeys per ambulance", value: "" }}>
                        <BarChart chartLabel="Average number of journeys per ambulance" labels={DUMMMY_LABELS} dataSet={BAR_CHART_DATA_SET} bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} axis='x' flag={true} />
                    </DisplayIndicator>
                </div>
            </div> */}
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "5.6 Average cost per community driver journey", value: "" }}>
                        <BarChart chartLabel="Average cost per community driver journey" labels={avgCostPerTripLabels} dataSet={avgCostPerTripData} bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} axis='y' flag={true}/>
                    </DisplayIndicator>
                </div>
            </div>
            {/* <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "5.14 Average distance per journey", value: "" }}>
                        <LineChart totalData={DUMMY_DATA} label="Average distance per journey" color={PRIMARY_CHART_COLOR} />
                    </DisplayIndicator>
                </div>
            </div> */}
            {/* <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "5.15 Number of refusals journey by driver", value: "" }}>
                        <BarChart chartLabel="Number of refusals journey by driver" labels={DUMMMY_LABELS} dataSet={BAR_CHART_DATA_SET} bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} axis='x' flag={true} />
                    </DisplayIndicator>
                </div>
            </div> */}
            <div className="row mb-3">
                <div className="col-12">
                    <DisplayIndicator data={{ label: "5.7 Top 10 Reasons why drivers refuse journeys", value: "" }}>
                        <BarChart chartLabel="Top 10 Reasons why drivers refuse journeys" labels={driverRefusalReasonData.map(item => item.label)} dataSet={driverRefusalReasonData.map(item => item.data)} bdColor={PRIMARY_CHART_COLOR} bgColor={SECONDARY_CHART_COLOR} axis='y' flag={true} />
                    </DisplayIndicator>
                </div>
            </div>
        </div>
    )
}
