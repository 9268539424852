import React, { createRef, useEffect, useState } from 'react'
import { CardGroup, Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText, Button } from "reactstrap";

export const DisplayIndicator = ({ data, children }) => {
    return (
        <>
            <Card style={{}} className="">
                <CardTitle style={{ fontSize: "10px" }}>
                    <span>{data["label"]}</span>
                    <span style={{ fontSize: "12px", fontWeight: 600, marginLeft: "2px" }}>{data["value"]}</span>
                </CardTitle>
                <CardBody className="p-0">
                    {children}
                </CardBody>
            </Card>
        </>
    )
}