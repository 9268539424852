import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './ambulance.reducer';
import {getEntities as getHealthcareFacilities} from "../healthcare-facility/healthcare-facility.reducer";

export interface IAmbulanceDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const AmbulanceDetail = (props: IAmbulanceDetailProps) => {
  useEffect(() => {
    props.getHealthcareFacilities();
    props.getEntity(props.match.params.id);
  }, []);

  const {ambulanceEntity, healthcareFacilities} = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          Ambulance [<b>{ambulanceEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="driverFirstName">Driver First Name</span>
          </dt>
          <dd>{ambulanceEntity.driverFirstName}</dd>
          <dt>
            <span id="driverLastName">Driver Last Name</span>
          </dt>
          <dd>{ambulanceEntity.driverLastName}</dd>
          <dt>
            <span id="driverVehicleType">Vehicle Type</span>
          </dt>
          <dd>{ambulanceEntity.vehicleType?.enType}</dd>
          <dt>
            <span id="phoneNumber">Phone Number</span>
          </dt>
          <dd>{(ambulanceEntity.phoneNumber && ambulanceEntity.phoneNumber !== '') ? ambulanceEntity.phoneNumber : '-'}</dd>
          <dt>
            <span id="phoneNumber2">Phone Number 2</span>
          </dt>
          <dd>{(ambulanceEntity.phone_number_2 && ambulanceEntity.phone_number_2 !== '') ? ambulanceEntity.phone_number_2 : '-'}</dd>
          <dt>
            <span id="phoneNumber3">Phone Number 3</span>
          </dt>
          <dd>{(ambulanceEntity.phone_number_3 && ambulanceEntity.phone_number_3 !== '') ? ambulanceEntity.phone_number_3 : '-'}</dd>
          <dt>Healthcare Facility</dt>
          <dd>
            {
              ambulanceEntity.healthcareFacilityId ?
                healthcareFacilities.find(v => v.id === ambulanceEntity.healthcareFacilityId)?.name
                : ''
            }
          </dd>
          <dt>
            <span id="phoneNumber">M-PESA Number</span>
          </dt>
          <dd>{ambulanceEntity.mpesaNumber ?? '-'}</dd>
        </dl>
        <Button tag={Link} to="/ambulance" replace color="info">
          <FontAwesomeIcon icon="arrow-left"/> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/ambulance/${ambulanceEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt"/> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ambulance, healthcareFacility}: IRootState) => ({
  ambulanceEntity: ambulance.entity,
  healthcareFacilities: healthcareFacility.entities,
});

const mapDispatchToProps = {
  getEntity,
  getHealthcareFacilities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AmbulanceDetail);
