import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction, IPayloadResult } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IPatientOutcome } from 'app/shared/model/patient-outcome.model';
import { IPayload } from 'react-jhipster/src/type/redux-action.type';
import { IPatientOutcomeCall } from 'app/shared/model/patient-outcome-call.model';
import { IOutcomeSettings } from 'app/shared/model/outcome-settings.model';

export const ACTION_TYPES = {
  FETCH_OUTCOME_LIST: 'patient-outcome/FETCH_OUTCOME_LIST',
  FETCH_OUTCOME_SETTINGS: 'patient-outcome/FETCH_OUTCOME_SETTINGS',
  FETCH_OUTCOME: 'patient-outcome/FETCH_OUTCOME',
  CREATE_OUTCOME: 'patient-outcome/CREATE_OUTCOME',
  CREATE_OUTCOME_CALL: 'patient-outcome/CREATE_OUTCOME_CALL',
  UPDATE_OUTCOME: 'patient-outcome/UPDATE_OUTCOME',
  DELETE_OUTCOME: 'patient-outcome/DELETE_OUTCOME',
  RESET: 'patient-outcome/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IPatientOutcome>,
  settings: [] as ReadonlyArray<IOutcomeSettings>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  fetchSuccess: false
};

export type PatientOutcomeState = Readonly<typeof initialState>;

// Reducer

export default (state: PatientOutcomeState = initialState, action): PatientOutcomeState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_OUTCOME_LIST):
    case REQUEST(ACTION_TYPES.FETCH_OUTCOME):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        fetchSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_OUTCOME):
    case REQUEST(ACTION_TYPES.CREATE_OUTCOME_CALL):
    case REQUEST(ACTION_TYPES.UPDATE_OUTCOME):
    case REQUEST(ACTION_TYPES.DELETE_OUTCOME):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_OUTCOME_LIST):
    case FAILURE(ACTION_TYPES.FETCH_OUTCOME):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
        fetchSuccess: false
      };
    case FAILURE(ACTION_TYPES.FETCH_OUTCOME_SETTINGS):
      return {
        ...state,
        errorMessage: action.payload
      };
    case FAILURE(ACTION_TYPES.CREATE_OUTCOME):
    case FAILURE(ACTION_TYPES.CREATE_OUTCOME_CALL):
    case FAILURE(ACTION_TYPES.UPDATE_OUTCOME):
    case FAILURE(ACTION_TYPES.DELETE_OUTCOME):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_OUTCOME_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        fetchSuccess: true
      };
    case SUCCESS(ACTION_TYPES.FETCH_OUTCOME_SETTINGS):
      return {
        ...state,
        settings: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_OUTCOME):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
        fetchSuccess: true
      };
    case SUCCESS(ACTION_TYPES.CREATE_OUTCOME):
    case SUCCESS(ACTION_TYPES.CREATE_OUTCOME_CALL):
    case SUCCESS(ACTION_TYPES.UPDATE_OUTCOME):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_OUTCOME):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        entities: [] as ReadonlyArray<IPatientOutcome>,
        settings: [] as ReadonlyArray<IOutcomeSettings>,
        entity: defaultValue,
        updating: false,
        updateSuccess: false,
        fetchSuccess: false
      };
    default:
      return state;
  }
};

const apiUrl = 'api/patient-outcomes';
const settingsApiUrl = 'api/outcome-settings';

// Actions

export const getEntities: (patientBabyId: string) => IPayload<IPatientOutcome[]> | IPayloadResult<IPatientOutcome[]> = (patientBabyId) => {
  const requestUrl = `${apiUrl}?patientBabyId=${patientBabyId}`;
  return {
    type: ACTION_TYPES.FETCH_OUTCOME_LIST,
    payload: axios.get<IPatientOutcome[]>(requestUrl)
  };
};

export const getSettings: (countryId: string) => IPayload<IOutcomeSettings[]> | IPayloadResult<IOutcomeSettings[]> = (countryId) => {
  const requestUrl = `${settingsApiUrl}?countryId=${countryId}`;
  return {
    type: ACTION_TYPES.FETCH_OUTCOME_SETTINGS,
    payload: axios.get<IOutcomeSettings[]>(requestUrl)
  };
};

export const getEntity: ICrudGetAction<IPatientOutcome> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_OUTCOME,
    payload: axios.get<IPatientOutcome>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IPatientOutcome> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_OUTCOME,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const createOutcomeCall: ICrudPutAction<IPatientOutcomeCall> = data => async dispatch => {
  return await dispatch({
    type: ACTION_TYPES.CREATE_OUTCOME_CALL,
    payload: axios.post(apiUrl + '/add-outcome-call', cleanEntity(data))
  });
};

export const updateEntity: ICrudPutAction<IPatientOutcome> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_OUTCOME,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IPatientOutcome> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_OUTCOME,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
