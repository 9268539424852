const config = {
  VERSION: process.env.SPRING_ENV,
};

// eslint-disable-next-line
console.log(process.env)

export default config;

export const SERVER_API_URL = process.env.SERVER_API_URL;

export const PERMISSIONS = {
  USER_ADMIN: 'USER_ADMIN',

  PATIENT_VIEW: 'PATIENT_VIEW',
  PATIENT_MODIFY: 'PATIENT_MODIFY',

  GEOPOLITICAL_MODIFY: 'GEOPOLITICAL_MODIFY',
  PAYMENTS_PROCESS: 'PAYMENTS_PROCESS',
  PAYMENTS_APPROVE: 'PAYMENTS_APPROVE',

  // TODO: Add the rest...
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'YYYY-MM-DD';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const APP_LOCAL_DATETIME_FORMAT_Z = 'YYYY-MM-DD HH:mm Z';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';

export const SECONDS_IN_MINUTE = 60;
export const SECONDS_IN_HOUR = 3600;
export const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1Ijoib21hcmZhcm9vayIsImEiOiJjbG83Y2MwZ2EwM2RjMm5wbms5aGMxbjF3In0.xfmjIs_5NRimv2bSj6LhtQ';
